import { Slide } from '@mui/material'
import { StyledPanel } from './StyledPanel'
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export const PanelWrapper = ({
  isOpen,
  onDismiss,
  keepMounted = false,
  children,
}) => {
  return (
    <StyledPanel
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
      onClose={onDismiss}
      aria-labelledby="alert-panel-title"
      aria-describedby="alert-panel-description"
    >
      {children}
    </StyledPanel>
  )
}
