import * as React from 'react'
const SvgTree = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <path fill="#93642D" d="M10.592 8.98h1.936L14.108 24H8.989z" />
      <path fill="#714D22" d="M10.578 8.98h1.924l.836 8.084H9.742z" />
      <path
        fill="#66A865"
        fillRule="evenodd"
        d="M13.205 2.098a3.171 3.171 0 0 1 4.73 2.763v.204q-.001.183-.022.362A3.17 3.17 0 0 1 20.2 8.473v.204c0 1.702-1.34 3.09-3.023 3.168.088.783-.132 1.6-.678 2.253l-.135.162a3.02 3.02 0 0 1-3.713.742q-.08.116-.171.226l-.135.162a3.02 3.02 0 0 1-4.242.39 2.995 2.995 0 0 1-.937-3.24 3.137 3.137 0 0 1-4.34-2.898V9.44a3.14 3.14 0 0 1 2.48-3.067q-.015-.15-.015-.304v-.201a3.137 3.137 0 0 1 3.03-3.135 2.603 2.603 0 0 1 4.884-.635"
        clipRule="evenodd"
      />
      <path
        fill="#83C082"
        fillRule="evenodd"
        d="M8.435 2.538a2.1 2.1 0 0 1 2.738-1.134 2.087 2.087 0 0 1 1.132 2.731l-.054.128q-.046.111-.103.214c.709.566.989 1.552.623 2.432l-.053.128A2.1 2.1 0 0 1 9.98 8.172 2.08 2.08 0 0 1 8.8 6.92a2.1 2.1 0 0 1-1.723.056 2.087 2.087 0 0 1-1.132-2.732L6 4.115a2.1 2.1 0 0 1 2.314-1.26q.03-.095.068-.188z"
        clipRule="evenodd"
      />
      <path
        fill="#458244"
        fillRule="evenodd"
        d="M3.284 7.891A3.1 3.1 0 0 0 2.8 9.566v.201a3.137 3.137 0 0 0 4.34 2.898 2.995 2.995 0 0 0 .937 3.241 3.02 3.02 0 0 0 4.242-.39l.135-.162q.09-.11.171-.226a3.02 3.02 0 0 0 3.713-.742l.135-.162c.546-.654.766-1.47.678-2.254a3.17 3.17 0 0 0 3.023-3.168v-.204c0-.332-.05-.652-.146-.952a3.17 3.17 0 0 1-2.542 1.489c.087.783-.132 1.6-.678 2.254l-.135.162a3.02 3.02 0 0 1-3.713.742q-.08.117-.172.227l-.135.161a3.02 3.02 0 0 1-4.242.39 2.995 2.995 0 0 1-.937-3.241 3.138 3.138 0 0 1-4.19-1.939"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgTree
