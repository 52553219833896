import React, { memo } from 'react'
import { Helmet } from 'react-helmet-async'
const BASE_URL = window.origin

const INITIAL_THUMBNAIL =
  'https://firebasestorage.googleapis.com/v0/b/gopopme-7353a.appspot.com/o/images%2F1694971980882?alt=media&token=b1d8ceeb-7675-4e73-98bf-234bfb89adea'
const INITIAL_CONTENT =
  'Budly allow you to create your virtual online profile to easily share all of your contact information and social media links to anyone. With Budly, you will be able to play our mini game in which you will be able to earn discounts for our offers and to plant trees in real life with your virtual actions.'
const INITIAL_TITLE = 'Budly - App that plants trees for real'

const SEO = memo(({ seoData }) => {
  const { urlFavicon, title, ogTitle, ogDescription, image, ogUrl } = seoData

  return (
    <Helmet>
      <title>{urlFavicon === '/favicon.ico' && `${title}`}</title>
      <link rel="icon" type="image/x-icon" href={urlFavicon} />
      <meta name="description" content={ogDescription || INITIAL_CONTENT} />
      <meta property="og:title" content={ogTitle || INITIAL_TITLE} />
      <meta
        property="og:description"
        content={ogDescription || INITIAL_CONTENT}
      />
      <meta property="og:image" content={image || INITIAL_THUMBNAIL} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content={ogUrl || BASE_URL + '/sign-in'} />

      <meta property="twitter:title" content={ogTitle || INITIAL_TITLE} />
      <meta
        property="twitter:description"
        content={ogDescription || INITIAL_CONTENT}
      />
      <meta property="twitter:image" content={image || INITIAL_THUMBNAIL} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content={ogUrl || BASE_URL + '/sign-in'} />
    </Helmet>
  )
})
export default SEO
