import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineNewVersion = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={2}
          d="M12 17v.023"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M20 12c0 1.092-.112 2.184-.336 3.26l-.095.455a6.43 6.43 0 0 1-4.653 4.907c-1.911.504-3.92.504-5.832 0a6.43 6.43 0 0 1-4.653-4.907l-.095-.456a16 16 0 0 1 0-6.518l.095-.456a6.43 6.43 0 0 1 4.653-4.906A11.4 11.4 0 0 1 12 3"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.3}
          d="M20.934 6.775c0 2.208-1.792 4-4 4s-3.556-2.224-3.556-2.224m0 0h1.808m-1.808 0v2m-.444-3.776c0-2.208 1.776-4 4-4 2.668 0 4 2.224 4 2.224m0 0V3m0 2h-1.776"
        />
      </g>
    </svg>
  )
}
export default SvgLineNewVersion
