import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineLink = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M18.285 14.743a.64.64 0 0 1-.915 0 .64.64 0 0 1 0-.917c1.778-1.779 1.778-4.67 0-6.44s-4.668-1.779-6.437 0c-1.769 1.78-1.778 4.67 0 6.44a.64.64 0 0 1 0 .916.64.64 0 0 1-.915 0c-2.285-2.286-2.285-6.004 0-8.28 2.284-2.278 6-2.287 8.276 0a5.87 5.87 0 0 1-.009 8.28"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M5.715 10.758a.64.64 0 0 1 .915 0 .64.64 0 0 1 0 .916c-1.778 1.779-1.778 4.67 0 6.44s4.668 1.779 6.437 0c1.769-1.78 1.778-4.67 0-6.44a.64.64 0 0 1 0-.916.64.64 0 0 1 .915 0c2.285 2.286 2.285 6.004 0 8.28-2.284 2.278-6 2.287-8.276 0a5.87 5.87 0 0 1 .009-8.28"
        />
      </g>
    </svg>
  )
}
export default SvgLineLink
