const items = {
  address: 'Your address',
  phone: 'Your phone',
  mail: 'Your mail',
  skype: 'Username or phone number',
  discord: 'Your pseudo',
  site: 'Your url',
  placeholder_url: 'https://example.com/',
  site_title: 'Site title',
  platform: 'Platform',
}


export default items
