import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineTrash = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M3 6.283a.75.75 0 0 0 0 1.5zm18 1.5a.75.75 0 0 0 0-1.5zm-16-.75v-.75h-.75v.75zm14 0h.75v-.75H19zm-.658 9.797.72.208zm-4.618 4.025.125.74zm-3.448 0 .125-.74zm-.158-.026-.125.74zm-4.46-3.999-.72.208zm8.224 3.999-.125-.74zm-6.04-15.34.681.315zm.976-1.308-.5-.558zm1.46-.874.26.703zm3.444 0 .261-.703zm2.435 2.182.681-.314zM3 7.783h18v-1.5H3zm10.757 12.306-.158.027.25 1.479.158-.027zm-3.356.027-.158-.027-.25 1.48.158.026zM18.25 7.033v5.143h1.5V7.033zm-12.5 5.143V7.033h-1.5v5.143zm12.5 0c0 1.505-.212 3.002-.629 4.446l1.441.416c.456-1.58.688-3.217.688-4.862zm-4.651 7.94a9.6 9.6 0 0 1-3.198 0l-.25 1.479c1.224.207 2.474.207 3.698 0zm-3.356-.027a4.88 4.88 0 0 1-3.864-3.467l-1.441.416a6.38 6.38 0 0 0 5.055 4.53zM6.38 16.622a16 16 0 0 1-.629-4.446h-1.5c0 1.645.231 3.282.688 4.862zm7.628 4.946a6.38 6.38 0 0 0 5.055-4.53l-1.44-.416a4.88 4.88 0 0 1-3.865 3.467zM8.25 7.033c0-.42.092-.837.273-1.229l-1.361-.63a4.4 4.4 0 0 0-.412 1.859zm.273-1.229c.182-.393.45-.755.796-1.064L8.317 3.623c-.49.44-.884.966-1.155 1.552zM9.32 4.74c.345-.31.759-.559 1.22-.73l-.522-1.406c-.63.234-1.209.579-1.7 1.019zm1.22-.73c.461-.171.958-.26 1.461-.26v-1.5c-.679 0-1.352.12-1.983.354zM12 3.75c.503 0 1 .089 1.461.26l.522-1.406A5.7 5.7 0 0 0 12 2.25zm1.461.26c.461.171.875.42 1.22.73l1.002-1.117a5.3 5.3 0 0 0-1.7-1.02zm1.22.73c.345.309.614.671.796 1.064l1.361-.63a4.8 4.8 0 0 0-1.156-1.551zm.796 1.064c.181.392.273.81.273 1.229h1.5c0-.64-.14-1.272-.412-1.858zM5 7.783h14v-1.5H5z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M10 12v4m4-4v4"
        />
      </g>
    </svg>
  )
}
export default SvgLineTrash
