import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineGallery = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <rect
          width={4}
          height={4}
          x={13}
          y={7.75}
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          rx={2}
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m4.718 17.951 1.739-1.739a4.16 4.16 0 0 1 5.885 0l1.739 1.74m0 0 .704-.705a3.12 3.12 0 0 1 4.604.209l.413.495m-5.721 0 2.6 2.601M3.353 15.8a13.35 13.35 0 0 1 0-6.1A7.51 7.51 0 0 1 8.95 4.103c2.006-.47 4.094-.47 6.1 0A7.51 7.51 0 0 1 20.647 9.7c.47 2.006.47 4.094 0 6.1a7.51 7.51 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0A7.51 7.51 0 0 1 3.353 15.8"
        />
      </g>
    </svg>
  )
}
export default SvgLineGallery
