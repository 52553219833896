import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import { Tooltip as MuiTooltip } from '@mui/material'
import styled, { css } from 'styled-components'
import { useTheme } from '@/Hooks'
import Text from './Texts/Text'

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(
  ({ theme }) => css`
    .MuiTooltip-tooltip {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
      border-radius: 12px;
      box-shadow: 0px 0px 10px ${theme.colors.grey2 + '50'};
    }
    .MuiTooltip-arrow {
      color: ${theme.colors.white} !important;
    }
  `,
)

export const Tooltip = ({ title, subtitle, placement = 'top', children }) => {
  const { theme } = useTheme()

  return (
    <StyledTooltip
      title={
        <div className="p-2 max-w-52">
          <Text className="MuiTooltip-tooltip-title font-semibold text-sm pb-1">
            {title}
          </Text>
          <Text className="block pt-1" color={theme.colors.grey3}>
            {subtitle}
          </Text>
        </div>
      }
      placement={placement}
      arrow
    >
      <IconButton>{children}</IconButton>
    </StyledTooltip>
  )
}
