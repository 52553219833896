import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineTicket = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 14})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.417 11.91a1.98 1.98 0 0 0 1.979-1.978V8.356a1.307 1.307 0 0 1 0-2.612V4.169a1.98 1.98 0 0 0-1.976-1.981H3.584a1.98 1.98 0 0 0-1.98 1.978v1.628c.697-.024 1.28.52 1.305 1.217l.001.039c0 .72-.582 1.305-1.302 1.306h-.004V9.93c0 1.092.886 1.979 1.978 1.979z"
          clipRule="evenodd"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m7.217 5.287.359.727c.035.071.103.121.181.133l.804.117c.198.029.277.273.134.412l-.581.566a.24.24 0 0 0-.07.214l.137.8a.242.242 0 0 1-.35.254l-.719-.377a.24.24 0 0 0-.225 0l-.718.377a.242.242 0 0 1-.35-.255l.136-.799a.24.24 0 0 0-.07-.214l-.58-.566a.242.242 0 0 1 .134-.412l.803-.117a.24.24 0 0 0 .182-.133l.359-.727a.242.242 0 0 1 .434 0"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
export default SvgLineTicket
