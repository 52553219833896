import { LineMenu2 } from '@/Assets/icons/components'
import Text from '@/Components/Texts/Text'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
  `}
`

const Gradient = styled.div`
  ${({ theme }) => css`
    background: linear-gradient(
      to right,
      ${theme.colors.grey2 + 50},
      ${theme.colors.grey1 + 50}
    );
  `}
`

const GradientOverlay = styled.div`
  ${({ theme }) => css`
    background: linear-gradient(to bottom, transparent, ${theme.colors.grey1});
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `}
`

const IconWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
    border-radius: 999px;
    padding: 4px;
  `}
`
const ProfilePreviewTemplate = ({ icon, name }) => {
  const { t } = useTranslation()
  return (
    <Wrapper className="relative w-full rounded-t-xl ">
      <div className="w-full pt-3 px-2">
        <div className="w-full flex flex-row justify-between items-center mb-2">
          <Text className="text-[10px]">{t('onboarding.step1.profile')}</Text>
          <IconWrapper>
            <LineMenu2 size={8} />
          </IconWrapper>
        </div>
        <div className="relative mb-4 ">
          <Gradient className="w-full aspect-[1.92] rounded-xl" />
          <Wrapper className="absolute w-[30%] aspect-square -bottom-[30%] ml-2 rounded-full overflow-hidden">
            <Gradient className="w-full h-full flex flex-col justify-center items-center">
              {icon}
            </Gradient>
          </Wrapper>
        </div>
        <Text className="text-[10px] wdesktop:text-[8px]">{name}</Text>
      </div>

      <div className="w-full relative">
        <div className="w-full px-2 pb-3 opacity-50">
          <Gradient className="w-1/2 h-2 rounded-lg" />
          <div className="flex flex-row mt-[2px]">
            <Gradient className="w-1/5 h-2 rounded-lg" />
            <Gradient className="w-1/5 h-2 ml-1 rounded-lg" />
          </div>
          <div className="flex flex-row justify-between mt-1">
            <Gradient className="w-1/2 h-2 rounded-lg" />
            <Gradient className="w-1/4 h-2 rounded-lg" />
          </div>
        </div>
        <GradientOverlay />
      </div>
    </Wrapper>
  )
}

export default ProfilePreviewTemplate
