import ShareBloc from './ShareBloc'
import { LineGallery } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

const QrcodeBloc = ({ disabled, config }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <ShareBloc
      title={t('share_profil.qr_code.my_qrcode')}
      options={[
        {
          text: t('share_profil.qr_code.save_img'),
          icon: <LineGallery color={theme.colors.black} />,
          disabled,
          ...config,
        },
      ]}
    />
  )
}

export default QrcodeBloc
