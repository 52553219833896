import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgPlantedSeed = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 12})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M2.499.75A4.084 4.084 0 0 1 6.53 4.189a3.78 3.78 0 0 1 2.676-1.106h1.167c.805 0 1.458.653 1.458 1.459a3.79 3.79 0 0 1-3.791 3.791H6.582v2.334a.583.583 0 0 1-1.166 0V6.583H4.249A4.083 4.083 0 0 1 .166 2.5c0-.966.783-1.75 1.75-1.75zm8.166 3.792a.29.29 0 0 0-.291-.292H9.207a2.625 2.625 0 0 0-2.625 2.625v.292h1.459a2.625 2.625 0 0 0 2.624-2.625M2.5 1.917h-.583a.583.583 0 0 0-.584.583A2.917 2.917 0 0 0 4.25 5.417h1.167v-.584a2.917 2.917 0 0 0-2.917-2.916"
        />
      </g>
    </svg>
  )
}
export default SvgPlantedSeed
