import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgTotalRevenue = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      viewBox="5 5 30 30"
      {...props}
    >
      <g transform={`scale(${1})`}>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M19.877 0h.246c4.186 0 7.735.835 10.669 2.404a16.36 16.36 0 0 1 6.804 6.804C39.165 12.142 40 15.691 40 19.878v.245c0 4.186-.836 7.735-2.404 10.669a16.36 16.36 0 0 1-6.804 6.804C27.858 39.165 24.309 40 20.122 40h-.245c-4.186 0-7.735-.836-10.669-2.404a16.36 16.36 0 0 1-6.804-6.804C.835 27.858 0 24.309 0 20.122v-.245c0-4.186.835-7.735 2.404-10.669a16.36 16.36 0 0 1 6.804-6.804C12.142.835 15.691 0 19.878 0"
          clipRule="evenodd"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M13.495 12.045a1.25 1.25 0 0 1 1.04-.557h10.93a1.25 1.25 0 0 1 1.039.557l3.59 5.381c.121.183.135.414.039.606a32.5 32.5 0 0 1-7.907 10.127l-1.75 1.5.325.38-.325-.38a.73.73 0 0 1-.95 0l-1.75-1.5a32.5 32.5 0 0 1-7.907-10.127.6.6 0 0 1 .037-.605zm.416.276-2.982 4.475-.459.688.823.086 1.405.147q1.06.113 2.125.191l.44.033.087-.432q.024-.12.072-.233l1.915-4.596.288-.692h-3.09a.75.75 0 0 0-.624.333Zm-2.578 5.756-.948-.1.456.836a32 32 0 0 0 7.105 8.833l.796-.553-3.125-8.334-.113-.301-.32-.022a71 71 0 0 1-2.536-.22zm5.452.45-.012.505-.455.171 3.214 8.574.468 1.25.468-1.25 3.215-8.574.266-.71-.757.035a70 70 0 0 1-6.054.015l-.005-.015h-.348Zm8.034-.091-.321.022-.113.301-3.125 8.334.796.553a32 32 0 0 0 7.105-8.833l.456-.837-.948.1-1.314.14q-1.267.132-2.536.22Zm3.89-.866.822-.087-.459-.688-2.983-4.474a.75.75 0 0 0-.624-.333h-3.09l.288.692 1.916 4.595q.047.114.07.233l.087.434.441-.033a69 69 0 0 0 2.125-.191zm-5.09.437-.013-.505.447-.186-2.091-5.02-.129-.308h-3.666l-.129.308-2.09 5.02-.273.654.708.037c2.411.125 4.826.125 7.236 0Z"
        />
      </g>
    </svg>
  )
}
export default SvgTotalRevenue
