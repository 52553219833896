const promotions_page = {
  tab_name: 'Promos',
  title: 'Promotions',
  label_All_Offers: 'Toutes les offres',
  label_My_Offers: 'Mes offres',
  bloc_title_packs: 'Packs',
  bloc_title_promotion: 'Offres promotionnelles',
  offer_successfully_buy: 'Offre achetée avec succès !',
  empty_results: {
    no_offer_found: 'Aucune offre trouvée',
    it_looks_like:
      "Il semble qu'il n'y ait aucune offre correspondant à votre recherche.",
  },
  possessed: 'Possédée',
}


export default promotions_page
