const benefitspage = {
  title: 'Mis ventajas',
  input: {
    search: 'Buscar un beneficio',
  },
  filter: {
    title: 'Filtrado',
    date: {
      title: 'Fecha',
      all_date: 'todas las fechas',
      week: 'Esta semana',
      last_week: 'La semana pasada',
      last_month: 'El mes pasado',
      last_two_month: 'Los últimos 2 pasado',
      last_three_month: 'Los últimos 3 meses',
      last_six_month: 'Los últimos 6 meses',
      valid: 'Validar',
    },
    categories: {
      title: 'Categorías',
      all: 'Todas las categorias',
      travel: 'Viaje',
      food: 'Alimento',
      works: 'Trabajar',
      no_cate: 'Sin categorizar',
    },
    offers: {
      title: 'Tipos de oferta',
      all: 'Todos los tipos',
      coupon: 'Cupón',
      offer: 'Oferta',
    },
    type: {
      title: 'Tipo',
    },
  },
  benefits_title: 'Todos nuestros beneficios',
  copy_benefits: 'Beneficio de copia !',
  benefit_btn: 'Disfrutar',
  no_result: 'No se encontraron resultados',
}


export default benefitspage
