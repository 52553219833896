import { useGetAllQuestsByCategoryQuery } from '@/Services/modules/userQuests'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

const useWelcomeQuestsFinished = () => {
  const { currentUser } = useSelector(state => state.user)
  const {
    data: quests,
    isLoading,
    refetch,
  } = useGetAllQuestsByCategoryQuery(
    {
      uid: currentUser.uid,
      category: 'welcome',
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  )

  const welcomeQuestsFinished = useMemo(() => {
    if (isLoading) {
      return null
    }
    return quests
      ? quests.welcomeQuests?.every(quest => quest.current === quest.goal)
      : false
  }, [isLoading, quests])

  useEffect(() => {
    refetch()
  }, [refetch])

  return welcomeQuestsFinished
}

export default useWelcomeQuestsFinished
