const createBusinessCardByUid = build =>
  build.mutation({
    query: ({ userId, businessCard }) => {
      return {
        url: `/users/${userId}/business-cards`,
        method: 'POST',
        body: businessCard,
      }
    },
    invalidatesTags: ['BusinessCards'],
  })

export default createBusinessCardByUid
