import getAvailableLinkByUrl from './getAvailableLinkByUrl'
import checkIfLinkIsAvailable from './checkIfLinkIsAvailable'
import { api } from '@/Services/api'

export const linkApi = api.injectEndpoints({
  endpoints: build => ({
    getAvailableLinkByUrl: getAvailableLinkByUrl(build),
    checkIfLinkIsAvailable: checkIfLinkIsAvailable(build),
  }),
  overrideExisting: true,
})

export const {
  useLazyGetAvailableLinkByUrlQuery,
  useLazyCheckIfLinkIsAvailableQuery,
} = linkApi
