const discoverpage = {
  discover: 'Découvrir',
  title: 'Pour vous',
  follow: 'Suivre',
  unfollow: 'Ne plus suivre',
  pending: 'En attente',
  empty_results: {
    no_user_found: 'Aucun utilisateur trouvé',
    no_results_matched:
      'Aucun résultat ne correspond à votre recherche. Veuillez réessayer.',
  },
}


export default discoverpage
