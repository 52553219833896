import { StyledContent } from './StyledModalContent'
import { ModalHeader } from './ModalHeader'
import { ModalFooter } from './ModalFooter'

export const ModalContent = ({
  loading,
  title,
  subtitle,
  actions,
  children,
  onDismiss,
  renderCustomFooter,
  center = false,
  scrollable = true,
  displayCloseButton = true,
  header,
}) => {
  return (
    <>
      <ModalHeader
        center={center}
        title={title}
        subtitle={subtitle}
        onDismiss={onDismiss}
        displayCloseButton={displayCloseButton}
        header={header}
      />
      {children && (
        <StyledContent scrollable={scrollable}>{children}</StyledContent>
      )}
      <ModalFooter
        renderCustomFooter={renderCustomFooter}
        actions={actions}
        onDismiss={onDismiss}
        loading={loading}
      />
    </>
  )
}
