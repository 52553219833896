import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineSaved = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M19 20.017V8.595c0-2.204-1.575-4.1-3.76-4.53a16.8 16.8 0 0 0-6.48 0C6.576 4.495 5 6.391 5 8.595v11.422c0 1.337 1.468 2.169 2.634 1.493l3.187-1.844a2.36 2.36 0 0 1 2.358 0l3.187 1.844c1.166.675 2.634-.156 2.634-1.493"
        />
      </g>
    </svg>
  )
}
export default SvgLineSaved
