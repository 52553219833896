import { LineArrowRight } from '@/Assets/icons/components'
import styled, { css } from 'styled-components'
import Text from './Texts/Text'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@/Hooks'
import clsx from 'clsx'

const Settings = styled.div`
  ${({ theme, marginBottom }) => css`
    margin-bottom: ${marginBottom ? theme.space.xs : theme.space.l};
  `}
`
const Title = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
    margin-bottom: ${theme.space.m};
    font-weight: 500;
    font-size: ${theme.fontSize.xxs};
    line-height: ${theme.fontSize.std};
    letter-spacing: 1px;
  `}
`

const Options = styled.div`
  ${({ theme }) => css`
    background: ${({ bgDisabled }) =>
      !bgDisabled ? theme.colors.secondary : theme.colors.white};
    border-radius: ${theme.borderRadius.l};
  `}
`
const WrapperInformation = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    padding: 0px 16px;
  `}
`
const Information = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.s};
    font-weight: 400;
    line-height: ${theme.space.m};
  `}
`

const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.space.xs};
    align-items: center;
    display: flex;
  `}
`
const WrapperTextIcon = styled.div`
  ${({ theme }) => css`
    height: ${theme.space.xxl};
    flex-direction: row;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`
const WrapperArrowIcon = styled.div`
  ${({ theme }) => css`
    height: ${theme.space.l};
    width: ${theme.space.l};
    align-items: center;
    justify-content: center;
  `}
`
const OptionText = styled(Text)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.fontSize.s};
    color: ${theme.colors.grey3};
  `}
`
const OptionSubText = styled(Text)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.fontSize.xs};
    color: ${theme.colors.grey2};
  `}
`

const Option = styled.div`
  ${({ theme }) => css`
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:hover {
      opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
    }
  `}
`

const Label = styled(Text)`
  color: #8e8e95;
`

const SettingOption = ({
  text,
  subText,
  icon: Icon,
  onClick,
  toggle,
  iconArrow,
  lastChild,
  disabled,
  label,
}) => {
  const { theme } = useTheme()
  return (
    <Option
      onClick={onClick}
      lastChild={lastChild}
      disabled={disabled}
      className={clsx('flex px-3 py-1', onClick && 'cursor-pointer')}
    >
      <IconWrapper>{<Icon color={theme.colors.grey3} />}</IconWrapper>
      <WrapperTextIcon>
        <div>
          <OptionText>{text}</OptionText>
          {subText && <OptionSubText>{subText}</OptionSubText>}
        </div>
        {toggle ? toggle : null}
        {label ? <Label>{label}</Label> : null}
        {iconArrow && (
          <WrapperArrowIcon>
            <LineArrowRight size={12} />
          </WrapperArrowIcon>
        )}
      </WrapperTextIcon>
    </Option>
  )
}

const DefaultWrapper = ({ children }) => children(null)

const OptionBloc = ({ title, options, informationText, bgDisabled }) => {
  const { t } = useTranslation()
  return (
    <Settings marginBottom={options[0].subText}>
      {title && <Title>{title}</Title>}
      <Options bgDisabled={bgDisabled}>
        {options?.map(
          (
            {
              text,
              parent: Parent = DefaultWrapper,
              onClick = () => null,
              parentProps,
              ...props
            },
            index,
          ) => (
            <Parent key={text} {...parentProps}>
              {onParentClick => (
                <SettingOption
                  text={t(text)}
                  lastChild={options.length === index + 1}
                  onClick={() => onClick(onParentClick)}
                  {...props}
                />
              )}
            </Parent>
          ),
        )}
      </Options>
      {informationText && (
        <WrapperInformation>
          <Information>{informationText}</Information>
        </WrapperInformation>
      )}
    </Settings>
  )
}

export default OptionBloc
