import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const BadgeFollow = ({ resourceUserId, ...props }) => {
  const { currentUser } = useSelector(state => state.user)

  const mine = useMemo(
    () => resourceUserId === currentUser.uid,
    [resourceUserId, currentUser.uid],
  )

  if (mine) {
    return null
  }
}

export default BadgeFollow
