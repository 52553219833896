const addBookmark = build =>
  build.mutation({
    query: ({ uid, data }) => {
      const date = Date.now()
      return {
        url: `/users/${uid}/bookmark`,
        method: 'POST',
        body: { ...data, date },
      }
    },
    invalidatesTags: ['Bookmarks', 'Progress'],
  })

export default addBookmark
