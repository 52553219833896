import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineCalendar = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m3.833 15.999.731-.168zm0-5.896.731.168zm16.334 0-.731.168zm0 5.896-.731-.168zm-5.287 5.41.175.73zm-5.76 0 .175-.73zm0-16.716.175.73zm5.76 0 .175-.73zM8.82 3.75a.75.75 0 0 0-1.5 0zm-1.5 2.514a.75.75 0 0 0 1.5 0zm9.36-2.514a.75.75 0 0 0-1.5 0zm-1.5 2.514a.75.75 0 0 0 1.5 0zM4.564 15.83a12.45 12.45 0 0 1 0-5.56l-1.462-.335a13.95 13.95 0 0 0 0 6.23zm14.872-5.56c.419 1.828.419 3.731 0 5.56l1.462.335c.47-2.049.47-4.181 0-6.23zm-4.73 10.409a11.6 11.6 0 0 1-5.411 0l-.35 1.458a13.1 13.1 0 0 0 6.11 0zM9.294 5.422c1.78-.427 3.63-.427 5.41 0l.35-1.458a13.1 13.1 0 0 0-6.11 0zm0 15.258c-2.339-.562-4.177-2.435-4.73-4.85l-1.463.336c.677 2.955 2.935 5.274 5.843 5.972zm5.76 1.458c2.908-.698 5.166-3.017 5.843-5.972l-1.462-.335c-.554 2.414-2.392 4.287-4.73 4.849zm-.35-16.716c2.339.562 4.177 2.434 4.73 4.849l1.463-.335c-.677-2.955-2.935-5.275-5.843-5.972zm-5.76-1.458C6.037 4.66 3.779 6.98 3.102 9.936l1.462.335c.554-2.415 2.392-4.287 4.73-4.849zM4.14 9.779h15.72v-1.5H4.14zM7.32 3.75v2.514h1.5V3.75zm7.86 0v2.514h1.5V3.75zM13.442 14.674c.405-.288.657-.72.657-1.305 0-1.215-.936-1.845-2.043-1.845s-2.052.63-2.052 1.845c0 .585.261 1.017.657 1.305a1.74 1.74 0 0 0-.864 1.521c0 1.161.855 1.881 2.259 1.881 1.395 0 2.259-.72 2.259-1.881a1.73 1.73 0 0 0-.873-1.521m-1.386-1.98c.468 0 .81.27.81.738 0 .459-.342.747-.81.747s-.81-.288-.81-.747c0-.468.342-.738.81-.738m0 4.212c-.594 0-1.026-.306-1.026-.873 0-.558.432-.864 1.026-.864s1.026.306 1.026.864c0 .567-.432.873-1.026.873"
        />
      </g>
    </svg>
  )
}
export default SvgLineCalendar
