import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineRow = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M19.11 13H4.89C3.54 13 3 13.602 3 15.099V18.9C3 20.398 3.54 21 4.89 21h14.22c1.35 0 1.89-.602 1.89-2.099V15.1C21 13.602 20.46 13 19.11 13M19.11 3H4.89C3.54 3 3 3.602 3 5.099V8.9C3 10.398 3.54 11 4.89 11h14.22c1.35 0 1.89-.602 1.89-2.099V5.1C21 3.602 20.46 3 19.11 3"
        />
      </g>
    </svg>
  )
}
export default SvgLineRow
