import { LineDrag } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import React from 'react'

const DraggableFlatList = ({
  data,
  renderItem,
  keyExtractor = item => null,
  listHeaderComponent,
  listFooterComponent,
  onDragEnd = newData => null,
  draggable = true,
}) => {
  const dragItem = React.useRef(0)
  const draggedOverItem = React.useRef(0)
  const { theme } = useTheme()

  const handleSort = () => {
    const dataClone = [...data]
    const temp = dataClone[dragItem.current]
    dataClone[dragItem.current] = dataClone[draggedOverItem.current]
    dataClone[draggedOverItem.current] = temp
    onDragEnd(dataClone)
  }

  return (
    <>
      {listHeaderComponent && listHeaderComponent}
      <div className="flex flex-col mt-3">
        {data.map((item, index) => {
          const key = keyExtractor(item)
          const Item = renderItem({ item })
          return (
            <div
              key={key}
              draggable={draggable}
              onDragStart={() => (dragItem.current = index)}
              onDragEnter={() => (draggedOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={e => e.preventDefault()}
              className="relative rounded-xl overflow-hidden opacity-95"
            >
              {Item}
              {draggable && (
                <div className="absolute top-2 right-0 bottom-0 left-0 flex flex-row justify-end items-center px-3">
                  <LineDrag
                    color={theme.colors.grey2}
                    className="cursor-move"
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
      {listFooterComponent && listFooterComponent}
    </>
  )
}

export default DraggableFlatList
