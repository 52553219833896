import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'

export const QrCodeFooter = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const { isQrCodeChanged } = useEditCurrentUser()

  if (!isQrCodeChanged) {
    return null
  }

  return (
    <div className="absolute bottom-16 px-10 flex tablet:bottom-8">
      <Text
        color={theme.colors.grey2}
        regular
        className="text-center tablet:text-xs"
      >
        {t('edit_profil.qr_code.pay_and_save')}
      </Text>
    </div>
  )
}
