import React from 'react'
import { DayPicker } from 'react-day-picker'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import { enUS, es, fr } from 'date-fns/locale'

const CURENT_DATE = new Date()
const CURENT_YEAR = CURENT_DATE.getFullYear()

export const DatePicker = ({
  open,
  setSelectedDate,
  selectedDate,
  ...props
}) => {
  const { theme } = useTheme()
  const {
    i18n: { language },
  } = useTranslation()
  const lang = language === 'fr' ? fr : language === 'es' ? es : enUS
  const classNames = {
    head: 'custom-head',
    day_selected: 'custom-day-seleted',
  }

  const css = `
  .rdp  { 
    margin: 0px
  }
  // .rdp-months {
  //   margin-Bottom: ${theme.space.std}
  // }
`
  return (
    <div {...props}>
      {open && (
        <>
          <style>{css}</style>
          <style>{`.custom-day-seleted { background-color: ${theme.colors.primary} !important`}</style>
          <style>{`.custom-day-seleted { color: ${theme.colors.white} `}</style>
          <DayPicker
            mode="single"
            captionLayout="dropdown-buttons"
            selected={selectedDate}
            onSelect={setSelectedDate}
            defaultMonth={selectedDate}
            fromYear={1940}
            toYear={CURENT_YEAR}
            locale={lang}
            styles={{
              caption: { color: theme.colors.primary },
              month: {
                background: theme.colors.grey1,
                padding: theme.space.m,
              },
            }}
            classNames={classNames}
          />
        </>
      )}
    </div>
  )
}
