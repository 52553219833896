import { Subtitle } from './styles'
import React from 'react'
import styled, { css } from 'styled-components'

const Bar = styled.div`
  ${({ theme }) => css`
    width: ${props => props.progress || '100%'};
    height: 8px;
    background-color: ${props =>
      props.progress ? theme.colors.primary : theme.colors.grey2 + '20'};
    border-radius: ${theme.space.xs};
    overflow: hidden;
  `}
`

const ProgressBar = ({ current, goal, children }) => {
  return (
    <div className="flex flex-row items-center w-full">
      <div className="grow">
        <Bar>
          <Bar progress={(current / goal) * 100 + '%'} />
        </Bar>
      </div>
      {children || <Subtitle className="pl-2">{`${current}/${goal}`}</Subtitle>}
    </div>
  )
}

export default ProgressBar
