import { Config } from '@/Config'
import { doc, onSnapshot } from 'firebase/firestore'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { db } from '@/Firebase/Database'

export const useListenerUser = ({ uid, setUser, validate, checked }) => {
  let unsubscribeUser = useRef()
  const dispatch = useDispatch()

  const handleSnapshot = useCallback(
    snap => {
      const data = snap.data()
      if (data?.uid && data?.whiteLabel === Config.SLUG) {
        const isValid = validate(data)
        if (!isValid) {
          return
        }
        dispatch(setUser(data))
        return
      }

      dispatch(setUser(null))
    },

    [dispatch, setUser, validate],
  )

  useEffect(() => {
    if (uid) {
      const userRef = doc(db, 'users', uid)
      if (unsubscribeUser.current) {
        unsubscribeUser.current()
      }

      unsubscribeUser.current = onSnapshot(userRef, handleSnapshot, error => {
        console.log(error)
      })

      return () => unsubscribeUser.current()
    }
    if (checked) {
      dispatch(setUser(null))
    }
  }, [dispatch, handleSnapshot, uid, setUser, checked])
}
