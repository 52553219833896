const prompt_suggestion_page = {
  suggestion: 'Suggestions',
  landscape: {
    label: 'Landscape',
    a_lush_forest: 'A lush forest bathed in twilight light.',
    a_deserted_beach: 'A deserted beach with gentle waves.',
    a_starry_sky: 'A starry sky above a snowy mountain.',
    a_field_colorful: 'A field of colorful flowers under a summer sky.',
  },
  animals: {
    label: 'Animals',
    playful_kitten: 'A kitten playing with balls of yarn.',
    elephant_at_sunset: 'An elephant walking in the savannah at sunset.',
    penguins_on_iceberg: 'A group of penguins on an iceberg.',
    horse_in_wildflowers: 'A horse galloping in a field of wildflowers.',
  },
  architecture: {
    label: 'Architecture',
    medieval_castle_lake: 'A medieval castle by a lake.',
    futuristic_skyscrapers: 'Futuristic skyscrapers with hanging gardens.',
    country_house_vineyards: 'A country house surrounded by vineyards.',
    ancient_temples_jungle: 'Ancient temples in a lush jungle.',
  },
  scifi: {
    label: 'Sci-Fi',
    spaceship_landing: 'A spaceship landing on an alien planet.',
    dystopian_city: 'A dystopian city with robots and neon lights.',
    ancient_tech_explorers:
      'Explorers discovering ancient technology on a deserted moon.',
    contemplative_cyborg: 'A contemplative cyborg in a cybernetic garden.',
  },
  gastronomy: {
    label: 'Gastronomy',
    french_pastries: 'An assortment of French pastries in a vintage bakery.',
    festive_table_world_cuisine:
      'A festive table with traditional dishes from around the world.',
    chef_decorating_cake: 'A chef meticulously decorating a cake.',
    colorful_cocktails: 'Colorful cocktails at a beach bar at sunset.',
  },
  fashion: {
    label: 'Fashion',
    couture_fashion_show: 'A haute couture fashion show in a castle.',
    vintage_outfit_photoshoot:
      'A photoshoot with vintage outfits on a Paris street.',
    exotic_fashion_accessories:
      'Exotic fashion accessories against a jungle backdrop.',
    sewing_workshop: 'A sewing workshop with fabrics and design sketches.',
  },
  nature_space: {
    label: 'Nature & Space',
    aurora_glass_igloo: 'The aurora borealis viewed from a glass igloo.',
    hidden_waterfall: 'A hidden waterfall in an enchanted forest.',
    solar_system_mars_view:
      'The solar system as seen from the surface of Mars.',
    grand_canyon_storm: 'A view of the Grand Canyon during a dramatic storm.',
  },
}

export default prompt_suggestion_page
