import deepEqual from 'deep-equal'

export const infiniteScrollOptions = {
  forceRefetch: ({ currentArg, previousArg }) => {
    return (
      currentArg?.lastDate !== previousArg?.lastDate ||
      currentArg?.userId !== previousArg?.userId
    )
  },
  serializeQueryArgs: ({ endpointName }) => {
    return endpointName
  },
  merge: (_currentState, incomingState = []) => {
    const currentState = _currentState || []
    const state = currentState.reduce((changedState, item) => {
      const incomingSameItem = incomingState.find(({ id }) => item.id === id)

      if (incomingSameItem) {
        const equal = deepEqual(item, incomingSameItem)

        if (!equal) {
          return [...changedState, incomingSameItem]
        }
      }

      return [...changedState, item]
    }, [])

    return [
      ...state,
      ...incomingState.filter(({ id }) => !state.find(p => p.id === id)),
    ].sort((a, b) => b.createdAt - a.createdAt)
  },
}
