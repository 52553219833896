const getDropsByTreeId = build =>
  build.query({
    query: id => {
      return {
        url: `/trees/${id}/get-drops`,
        method: 'GET',
      }
    },
  })

export default getDropsByTreeId
