import { usePutCurrentUserMutation } from '@/Services/modules/users'
import { updateCurrentUser } from '@/Store/User'
import { useDispatch, useSelector } from 'react-redux'

const useCurrentUser = () => {
  const { currentUser } = useSelector(state => state.user)
  const [putCurrentUser] = usePutCurrentUserMutation()
  const dispatch = useDispatch()

  const setCurrentUser = async body => {
    try {
      const response = await putCurrentUser({
        body,
        userId: currentUser.uid,
      })
      if (response.error) {
        console.warn(response.error)
        throw new Error(response.error.data.error)
      }
      dispatch(updateCurrentUser(body))
    } catch (err) {
      throw new Error(err)
    }
  }

  return { currentUser, setCurrentUser }
}

export default useCurrentUser
