import { LineDrag } from '@/Assets/icons/components'
import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import ModalContentDeleteConfirmation from '@/Components/Modals/ModalContentDeleteConfirmation'
import { CustomThumbnail } from '@/Components/Profile/CustomThumbnail'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import React from 'react'
import styled, { css } from 'styled-components'
import PanelEditContent from '@/Components/Panels/ContentPanels/PanelEditContent'

const Wrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    display: flex;
    flex-direction: row;
    border-radius: ${theme.borderRadius.l};
    background-color: ${theme.colors.grey1};
    align-items: center;
    padding: ${theme.space.std};
    margin-top: ${theme.space.xs};
  `}
`
const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.space.std};
  `}
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: ${({ theme }) => theme.space.s};
  align-items: flex-start;
`
const Label = styled(Text)`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.semibold};
    flex-shrink: 1;
  `}
`
const WrapperContent = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
  `}
`

const Content = ({ icon: Icon, content, label, item, draggable }) => {
  const { theme } = useTheme()
  return (
    <>
      {draggable && (
        <IconWrapper>
          <LineDrag color={theme.colors.grey2} />
        </IconWrapper>
      )}
      <IconWrapper>
        {item.thumbnail ? <CustomThumbnail src={item.thumbnail} /> : Icon}
      </IconWrapper>
      <ContentWrapper>
        <Label numberOfLines={1} ellipsizeMode="tail">
          {label}
        </Label>
        <WrapperContent
          className="text-xs"
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {content}
        </WrapperContent>
      </ContentWrapper>
    </>
  )
}

export const EditProfileItem = ({ item, type, drag: onDrag, ...props }) => {
  const Parent = React.Fragment
  // const Parent = onDrag
  //   ? ({ children }) => (
  //       <ScaleDecorator activeScale={0.96}>{children}</ScaleDecorator>
  //     )
  //   : React.Fragment

  const handleLongPress = setIsOpen => {
    if (onDrag) {
      onDrag()
      return
    }
    setIsOpen(true)
  }

  return (
    <PanelEditContent item={item} type={type}>
      {setIsEditContentOpen => (
        <ModalContentDeleteConfirmation
          type={type}
          contentId={item.id}
          onDismiss={() => setIsEditContentOpen(false)}
        >
          {setIsOpen => (
            <Parent>
              <Wrapper
                onLongPress={() => handleLongPress(setIsOpen(true))}
                onClick={() => setIsEditContentOpen(true)}
              >
                <Content {...props} item={item} draggable={Boolean(onDrag)} />
              </Wrapper>
            </Parent>
          )}
        </ModalContentDeleteConfirmation>
      )}
    </PanelEditContent>
  )
}
