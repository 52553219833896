import * as React from 'react'
const SvgSocialCameo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    viewBox="0 0 150 150"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#60e9e2"
        d="M90.28 9.375H70.774l6.16 37.982h8.212z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
      <path
        fill="#c949f7"
        d="m133.885 34.405-13.844-13.738-22.38 31.3 5.829 5.784Z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
      <path
        fill="#e33251"
        d="m40.825 21.693-14.19 13.38 30.537 23.41 5.975-5.634z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
      <path
        fill="#60e9e2"
        d="m28.083 114.995 13.95 13.63 22.139-31.47-5.874-5.739Z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
      <path
        fill="#c949f7"
        d="m15.342 65.57.302 19.503 37.881-6.746-.126-8.211z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
      <path
        fill="#60e9e2"
        d="m120.493 128.384 14.165-13.407-30.58-23.352-5.965 5.645z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
      <path
        fill="#e33251"
        d="m71.509 140.164 19.498.461-5.26-38.116-8.21-.194z"
        style={{
          strokeWidth: 1.02653,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialCameo
