const getUserResourcesGainedByType = build =>
  build.query({
    query: ({ uid, type }) => {
      return {
        url: `/resource-gains/${uid}/${type}`,
        method: 'GET',
      }
    },
    providesTags: ['ResourcesGained'],
  })

export default getUserResourcesGainedByType
