import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineSearch = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m3.316 13.781.73-.171zm0-5.457.73.171zm15.473 0 .73-.171zm0 5.457.73.171zm-5.008 5.008-.171-.73zm-5.457 0-.171.73zm0-15.473-.171-.73zm5.457 0 .171-.73zM20.47 21.53a.75.75 0 1 0 1.06-1.06zM4.046 13.61a11.2 11.2 0 0 1 0-5.115l-1.46-.342a12.7 12.7 0 0 0 0 5.8zm14.013-5.115a11.2 11.2 0 0 1 0 5.115l1.46.342a12.7 12.7 0 0 0 0-5.8zm-4.45 9.564a11.2 11.2 0 0 1-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0zM8.496 4.046a11.2 11.2 0 0 1 5.115 0l.342-1.46a12.7 12.7 0 0 0-5.8 0zm0 14.013a5.97 5.97 0 0 1-4.45-4.45l-1.46.343a7.47 7.47 0 0 0 5.568 5.568zm5.457 1.46a7.47 7.47 0 0 0 5.568-5.567l-1.46-.342a5.97 5.97 0 0 1-4.45 4.45zM13.61 4.047a5.97 5.97 0 0 1 4.45 4.45l1.46-.343a7.47 7.47 0 0 0-5.568-5.567zm-5.457-1.46a7.47 7.47 0 0 0-5.567 5.567l1.46.342a5.97 5.97 0 0 1 4.45-4.45zm8.652 15.28 3.665 3.664 1.06-1.06-3.665-3.665z"
        />
      </g>
    </svg>
  )
}
export default SvgLineSearch
