import * as React from 'react'
const SvgUp = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 48})`}>
      <path
        fill="#fff"
        d="M24.666 28.527V21.08l3.253 3.253c.26.26.687.26.947 0s.26-.68 0-.94L24.473 19a.664.664 0 0 0-.94 0l-4.4 4.387a.664.664 0 1 0 .94.94l3.26-3.247v7.447c0 .366.3.666.666.666s.667-.3.667-.666"
      />
      <rect
        width={46.5}
        height={46.5}
        x={0.75}
        y={47.25}
        stroke="#fff"
        strokeWidth={1.5}
        rx={23.25}
        transform="rotate(-90 .75 47.25)"
      />
    </g>
  </svg>
)
export default SvgUp
