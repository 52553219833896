import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgDesktop = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M19.666 5h-14a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1m-14-2a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zM1.666 20a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2h-20a1 1 0 0 1-1-1"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
export default SvgDesktop
