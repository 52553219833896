import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineEyeSlash = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M20.74 8.272a.75.75 0 0 0-1.295.758zm-.647 7.077-.648-.38zM9.165 18.778a.75.75 0 1 0-.489 1.418zm-5.258-3.43.648-.378zm0-6.697.648.38zm10.01 1.317a.75.75 0 0 0 1.034-1.085zm-5.174 4.948a.75.75 0 0 0 1.14-.975zm7.564-2.855a.75.75 0 0 0-1.5 0zm-4.308 2.862a.75.75 0 0 0 0 1.5zm8.41-10.397a.75.75 0 0 0-1.069-1.052zM4.154 18.902a.75.75 0 0 0 1.069 1.053zM19.445 9.03a5.88 5.88 0 0 1 0 5.94l1.295.757a7.38 7.38 0 0 0 0-7.455zm0 5.94c-2.223 3.799-6.605 5.073-10.28 3.808l-.489 1.418c4.298 1.48 9.451-.005 12.064-4.469zm-14.89 0a5.88 5.88 0 0 1 0-5.94L3.26 8.272a7.38 7.38 0 0 0 0 7.455zm1.898 2.269a8.7 8.7 0 0 1-1.898-2.27l-1.295.758a10.2 10.2 0 0 0 2.224 2.657zM4.555 9.03c2.713-4.636 8.647-5.51 12.568-2.605l.893-1.205C13.417 1.813 6.45 2.824 3.26 8.272zm4.638 3.03c0-1.593 1.268-2.863 2.806-2.863v-1.5c-2.39 0-4.306 1.964-4.306 4.364zM12 9.198c.74 0 1.414.291 1.917.771l1.035-1.085A4.27 4.27 0 0 0 12 7.697zm-2.116 4.744a2.88 2.88 0 0 1-.69-1.88h-1.5c0 1.09.396 2.089 1.05 2.855zm4.924-1.88c0 1.592-1.268 2.862-2.808 2.862v1.5c2.39 0 4.308-1.963 4.308-4.362zm4.533-8.587L4.153 18.902l1.069 1.053L20.41 4.526z"
        />
      </g>
    </svg>
  )
}
export default SvgLineEyeSlash
