const promotions_page = {
  tab_name: 'Promos',
  title: 'Promociones',
  label_All_Offers: 'Todas las ofertas',
  label_My_Offers: 'Mis ofertas',
  bloc_title_packs: 'Packs',
  bloc_title_promotion: 'Ofertas Promocionales',
  offer_successfully_buy: 'Oferta comprada con éxito!',
  empty_results: {
    no_offer_found: 'No se encontró ninguna oferta',
    it_looks_like: 'Parece que no hay ofertas que coincidan con tu búsqueda.',
  },
  possessed: 'Poseído',
}


export default promotions_page
