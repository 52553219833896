import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTranslation } from 'react-i18next'
import InteractiveText from '../Controls/InteractiveText/InteractiveText'
import Tags from '../Tags'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.l} ${theme.space.m};
  `}
`
const Title = styled.div`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: ${theme.fontWeight.semibold};
    margin-bottom: ${theme.space.xs};
    color: ${theme.colors.black};
  `}
`

const Description = styled(InteractiveText)`
  ${({ theme }) => css`
    color: ${theme.colors.grey3};
  `}
`

export const ProfileDescription = memo(({ user, ...props }) => {
  const { profile = {} } = useProfileContext()
  const { t } = useTranslation()
  return (
    <Wrapper {...props}>
      <Title>
        {profile.businessMode
          ? t('previewpage.aboutus')
          : t('previewpage.aboutme')}
      </Title>
      <Description className="text-sm font-normal">
        {user?.description || profile.description}
      </Description>
      <Tags tags={profile.hashtags} type="profiles" />
    </Wrapper>
  )
})
