const feedpage = {
  feed: "Fil d'actualité",
  title_tab_bottom: 'Accueil',
  insight: 'Accueil',
  fav: 'Mes favoris',
  add_post_to_bookmarks: 'Ajouter ce post aux favoris',
  remove_post_from_bookmarks: 'Retirer ce post des favoris',
  search: 'Rechercher',
  scan: {
    qrcode: 'Scanner via QRCode',
    nfc: 'Scanner via NFC',
  },
  notification: {
    title: 'Mes activités',
    all: 'Toutes',
    add: 'Ajouter',
    requests: 'Requêtes',
    added: 'Ajouté',
    unfollow: 'Abonné',
    follow: "S'abonner",
    empty_resource: "La ressource n'existe plus",
    people_watering_post: 'Personnes ont arrosé votre publication',
    no_notification_title: 'Aucune notification trouvée',
    no_notification_subtitle:
      "Pour le moment il n'y a pas d'activité sur votre compte. Revenez plus tard !",
  },
  modify_post: {
    modify: 'Modifier ce post',
    delete: 'Supprimer ce post',
    edit_btn: 'Modifier',
  },
  bookmark_images: 'Vos images enregistrées',
  bookmark_posts: 'Vos publications enregistrées',
}


export default feedpage
