import { Dialog, DialogContent } from '@mui/material'
import styled, { css } from 'styled-components'

export const StyledDialog = styled(Dialog)`
  ${({ theme }) => css`
    .MuiPaper-root {
      border-radius: 24px !important;
      overflow: visible;
      width: ${props => props.width || '530px'};
      height: auto;
      max-width: 100%;
      max-height: 720px;
      background: ${theme.colors.white};
    }
  `}
`

export const StyledContent = styled(DialogContent)`
  width: 100%;
  padding: 40px !important;
  height: 100%;
  overflow: auto;

  .MuiTypography-root {
    font-size: 14px;
    font-family: 'Poppins' !important;
  }
`
