import { LineClose } from '@/Assets/icons/components'
import IconButton from '@/Components/Buttons/IconButton'
import { SubTitle } from '@/Components/Texts/SubTitle'
import React from 'react'
import { styled } from 'styled-components'
import Text from '../Texts/Text'

const Header = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey1}`};
  width: 100%;
  padding: 24px 40px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const PanelHeader = ({ title, subtitle, onDismiss, ...props }) => {
  if (!title) return null

  return (
    <Header {...props}>
      <Text>{title}</Text>
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      <div className="absolute right-10">
        <IconButton icon={<LineClose />} onClick={onDismiss} />
      </div>
    </Header>
  )
}
