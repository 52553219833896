import { Dialog, DialogContent } from '@mui/material'

import styled, { css } from 'styled-components'

export const StyledPanel = styled(Dialog)`
  ${({ theme }) => css`
    .MuiDialog-container {
      justify-content: flex-end;
    }
    .MuiPaper-root {
      border-radius: 40px !important;
      overflow: visible;
      max-width: none;
      height: 100%;
      max-height: calc(100vh - 48px);
      margin: 24px;
      background: ${theme.colors.white};
    }
  `}
`

export const StyledContent = styled(DialogContent)`
  width: 610px;
  padding: 40px !important;
  height: 100%;
  overflow: auto;

  .MuiTypography-root {
    font-size: 14px;
    font-family: 'Poppins' !important;
  }
`
