const statistics = {
  title: 'My statistics',
  active_profiles: 'Active profiles',
  unique_views: 'Unique views',
  clicks: 'Clicks',
  ctr: 'Click-through rate (CTR)',
  ctr_graph: 'CTR (click-through rate)',
  views: 'Views',
  views_by_device: 'Views by device',
  views_by_country: 'Views by country',
  top_links: 'My Top Links',
  top_contacts: 'My Top Contacts',
  top_networks: 'My Top Networks',
  change_vs_last_month: 'vs last month',
  filter_week: 'This week',
  filter_month: 'This month',
  filter_last_three_month: 'Last three months',
  filter_last_six_month: 'Last six months',
  filter_year: 'This year',
  title_links: 'Title',
  graph: 'Graph',
  views_graph: 'views',
  unique_views_graph: 'unique views',
  member_statistics: 'Statistics',
}

export default statistics
