import addBookmark from './addBookmark'
import getBookmarksByUserId from './getBookmarksByUserId'
import removeBookmark from './removeBookmark'
import { api } from '@/Services/api'

export const bookmarksApi = api.injectEndpoints({
  endpoints: build => ({
    getBookmarksByUserId: getBookmarksByUserId(build),
    addBookmark: addBookmark(build),
    removeBookmark: removeBookmark(build),
  }),
  overrideExisting: true,
})

export const {
  useGetBookmarksByUserIdQuery,
  useAddBookmarkMutation,
  useRemoveBookmarkMutation,
} = bookmarksApi
