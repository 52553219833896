import React, { createContext, useContext } from 'react'

const ShareUrlContext = createContext()

export const ShareUrlProvider = ({ value, children }) => {
  return (
    <ShareUrlContext.Provider value={value}>
      {children}
    </ShareUrlContext.Provider>
  )
}

export function useShareUrl() {
  return useContext(ShareUrlContext)
}
