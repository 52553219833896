import { useGetFollowersQuery } from '@/Services/modules/followers'
import { useGetFollowingsQuery } from '@/Services/modules/followings'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useGetTabs = ({ tab, profileUID }) => {
  const { data: followings } = useGetFollowingsQuery(profileUID)
  const { data: followers } = useGetFollowersQuery(profileUID)
  const { t } = useTranslation()

  const [followersCount, setFollowersCount] = useState(0)
  const [followingsCount, setFollowingsCount] = useState(0)

  const [tabs, setTabs] = useState([
    {
      label: t('follow.followed'),
      name: 'followings',
      count: 0,
    },
    {
      label: t('follow.followers'),
      name: 'followers',
      count: 0,
    },
  ])

  const initialTab = useMemo(
    () => tabs.find(_tab => _tab.name === tab),
    [tab, tabs],
  )

  const [selectedTab, setSelectedTab] = useState(initialTab || tabs[0])

  useEffect(() => {
    setFollowersCount(followers?.length || 0)
    setFollowingsCount(followings?.length || 0)
  }, [followers, followings])

  useEffect(() => {
    setTabs(prevState =>
      prevState.map(tab => {
        const count =
          tab.name === 'followers' ? followersCount : followingsCount
        return { ...tab, count: `(${count})` }
      }),
    )
  }, [followersCount, followingsCount])

  return {
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    followersCount,
    followingsCount,
  }
}

export default useGetTabs
