import ThumbnailOption from '@/Components/ThumbnailOption'
import React from 'react'

const OptionProduct = ({ product, ...props }) => {
  const { product_image, name, category } = product

  return (
    <ThumbnailOption
      {...props}
      title={name}
      subtitle={category}
      renderThumbnail={() => (
        <img
          className="w-full h-full"
          src={
            product_image ||
            'https://firebasestorage.googleapis.com/v0/b/gopopme-7353a.appspot.com/o/images%2F1653137070994?alt=media&token=3ab83ffa-2e59-4bb4-aee5-87f8e8163f11'
          }
          alt="product"
        />
      )}
    />
  )
}

export default OptionProduct
