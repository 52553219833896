import React from 'react'
import { styled } from 'styled-components'

const MainSection = styled.section`
  display: flex;
  width: 100%;

  @media screen and (max-width: 940px) {
    flex-direction: column;
    width: 24rem;
    section.left, section.right {
      flex:1;
    }
  }
  @media screen and (min-width: 940px) {
    max-width: 1536px
    padding: 0;
    flex: 1;
    flex-direction: row;
    section.left {
      width: 50%;
      min-height: 100%;
      flex-shrink: 0;
    }
    section.right {
      width: 50%;
      padding: 24px;
    }
  }
`

export const Layout = ({ leftContent, rigthContent }) => {
  return (
    <div className="flex-1 flex h-full justify-center px-6 sm:px-0 wtablet:h-screen">
      <MainSection>
        <section className="left flex flex-col relative">
          {leftContent()}
        </section>
        <section className="right py-6">{rigthContent()}</section>
      </MainSection>
    </div>
  )
}
