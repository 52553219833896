import * as React from 'react'
const SvgStar = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 16})`}>
      <path
        fill="#FDD835"
        d="m8.673.98 1.682 3.838a.88.88 0 0 0 .728.524l4.099.368a.565.565 0 0 1 .312.963l-3.088 2.594a.88.88 0 0 0-.295.859l.898 4.201a.565.565 0 0 1-.82.595l-3.578-2.095a.88.88 0 0 0-.89 0l-3.577 2.094a.565.565 0 0 1-.82-.595l.897-4.201a.88.88 0 0 0-.295-.86L.838 6.676a.565.565 0 0 1 .312-.964l4.099-.368a.88.88 0 0 0 .727-.523L7.66.982A.566.566 0 0 1 8.673.981"
      />
      <path
        fill="#FFFF8D"
        d="M8.55 5.048 8.267 2.22c-.012-.157-.044-.427.208-.427.2 0 .309.416.309.416l.855 2.27c.323.864.19 1.16-.121 1.335-.358.2-.885.044-.966-.766"
      />
      <path
        fill="#F4B400"
        d="m12.077 9.016 2.452-1.914c.121-.101.34-.263.165-.446-.139-.145-.514.063-.514.063l-2.146.84c-.64.22-1.065.548-1.102.96-.05.55.445.974 1.145.497"
      />
    </g>
  </svg>
)
export default SvgStar
