import React, { useEffect, useState } from 'react'
import LabeledInput from '../Controls/LabeledInput'
import { LockedFieldSwitch } from '../LockedFieldSwitch'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useLockedField } from '@/Hooks/useLockedField'

export const LabeledInputLock = ({
  fieldName,
  title,
  placeholder,
  value,
  onChange,
  disabled,
  type = 'user',
}) => {
  const { editCurrentUser } = useEditCurrentUser()
  const { onLocked } = useLockedField()
  const [isLocked, setIsLocked] = useState(
    editCurrentUser.lockedFields?.[type]?.includes(fieldName) || false,
  )
  useEffect(() => {
    setIsLocked(editCurrentUser.lockedFields?.[type]?.includes(fieldName))
  }, [editCurrentUser.lockedFields, type, fieldName])
  return (
    <LabeledInput
      title={title}
      className="mb-3"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      regular
      disabled={disabled}
    >
      <div className="absolute right-[3px] top-[3px] cursor-pointer">
        <LockedFieldSwitch
          variant="secondary"
          isLocked={isLocked}
          onChange={e =>
            onLocked({
              e: e,
              setIsLocked: setIsLocked,
              type: type,
              fieldName,
            })
          }
        />
      </div>
    </LabeledInput>
  )
}
