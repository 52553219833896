import * as React from 'react'
const SvgTarget = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 38})`}>
      <path
        fill="#FF2A23"
        d="M17.791.837C9.566.649.494 7.47.669 18.52c.153 9.716 7.453 17.069 16.913 17.222 9.459.153 17.84-6.1 17.89-17.272.053-11.26-8.634-17.428-17.681-17.634"
      />
      <path
        fill="#FFFDFE"
        d="M17.817 4.78c-6.525.223-13.11 5.091-13.263 13.676-.153 8.584 7.35 13.366 13.263 13.366 7.506 0 13.778-5.913 13.621-13.779-.153-7.862-6.065-13.518-13.621-13.262"
      />
      <path
        fill="#FF2A23"
        d="M17.97 8.125C12.056 7.99 7.687 13.215 7.634 18.2c-.05 5.09 3.134 10.28 10.231 10.537 7.097.256 10.435-5.397 10.538-10.178.106-4.781-3.7-10.281-10.435-10.434"
      />
      <path
        fill="#fff"
        d="M11.21 18.387c-.05 4.472 3.135 7.044 6.991 7.044 3.504 0 6.941-2.21 6.941-6.887 0-4.216-3.187-6.735-6.734-6.888-3.547-.156-7.147 2.416-7.197 6.731"
      />
      <path
        fill="#FB2B22"
        d="M14.373 18.353c-.19 2.666 1.978 3.94 3.931 3.94 1.85 0 3.675-1.215 3.728-3.684.047-2.319-1.771-3.734-3.728-3.803-2.018-.069-3.778 1.381-3.93 3.547"
      />
      <path
        fill="#CC1935"
        d="m18.948 18.562-1.047.58 1.997 2.841s1.544.12 1.9-.284c.357-.403-.047-1.59-.047-1.59z"
      />
      <path
        fill="#ACB1B5"
        d="M25.411 15.474c-.281-.58-.569-1.046-1.331-.903-.76.144-2.163.95-2.425 1.332-.24.35-.475.784-.475.784s-2.688 1.469-3.113 1.71c-.665.38-.206 1.156.263 1.068.64-.119 3.503-1.284 3.503-1.284s.606.143.962.143c.357 0 2.388-1.003 2.663-1.187.453-.31.237-1.069-.047-1.663"
      />
      <path
        fill="#C8C8C8"
        d="M21.742 20.103s-.15.53-.697 1.08a3.7 3.7 0 0 1-1.153.794l1.803 2.644s1.34.097 2.316-.853.55-2.194.55-2.194z"
      />
      <path
        fill="#CC1935"
        d="m23.53 27.4-1.838-2.782s.306-.162.71-.437c.74-.507 1.153-.91 1.68-1.735.288-.444.473-.875.473-.875l2.84 1.403s-.062 2.194-.99 3.263c-.929 1.069-2.875 1.162-2.875 1.162"
      />
      <path
        fill="#C8C8C8"
        d="m23.53 27.4 1.578 2.365s2.06.204 3.747-1.818 1.237-3.685 1.237-3.685l-2.697-1.29s-.525 1.262-1.569 2.475C24.784 26.659 23.53 27.4 23.53 27.4"
      />
      <path
        fill="#CC1935"
        d="m27.444 33.261-2.334-3.493s1.76-1.029 3.01-2.507c1.296-1.534 1.974-2.993 1.974-2.993l3.779 1.878s-.85 2.197-2.735 4.181c-1.844 1.934-3.694 2.934-3.694 2.934"
      />
      <path
        fill="#006CA9"
        d="M23.595 16.4c.354.659.938.665 1.557.403.618-.263 3.54-2.328 3.54-2.328s3.969 1.996 6.535.997c2.565-.997 2.493-3.707 2.493-3.707l-1.518-.962s1.106-.575 1.462-.788c.175-.106.434-.5.29-.853-.112-.275-.543-.369-.9-.225-.355.144-1.384.466-1.384.466s.41-1.703.338-1.797-.81-.475-.81-.475-2.4 0-2.709.119c-.31.118-2.684 2.471-2.684 2.471l-1.14 2.329-.488 1.037s-3.328 1.49-3.828 1.703c-.497.216-1.188.807-.754 1.61"
      />
      <path
        fill="#1F87FD"
        d="M28.886 12.8s1.4-1.013 2.397-1.513 2.847-1.11 3.394-1.175.772.082.868.338c.097.256-.224.578-.5.725-.271.143-1.721.803-3.328 1.656s-2.368 1.478-3.018 1.625c-.329.075-.75-.219-.775-.71-.02-.38.056-.7.306-1.43.306-.9.978-2.254 1.544-3.135.837-1.303 2.043-2.188 3.378-2.428s2.428.097 2.703.322c.272.225.256.456.16.534-.098.078-.241.14-.966-.116-.504-.178-2.107-.178-3.232.66-1.137.844-1.415 1.365-1.912 2.284S28.886 12.8 28.886 12.8"
      />
    </g>
  </svg>
)
export default SvgTarget
