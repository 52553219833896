import * as React from 'react'
const SvgSocialGmail = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 34})`}>
      <g filter="url(#socialGmail_svg__a)">
        <path
          fill="#fff"
          d="M2 12.706c0-3.485 0-5.228.678-6.559a6.22 6.22 0 0 1 2.72-2.719c1.33-.678 3.073-.678 6.558-.678h8.088c3.485 0 5.228 0 6.559.678a6.22 6.22 0 0 1 2.719 2.72C30 7.477 30 9.22 30 12.705v8.088c0 3.485 0 5.228-.678 6.559a6.22 6.22 0 0 1-2.72 2.719c-1.33.678-3.073.678-6.558.678h-8.088c-3.485 0-5.228 0-6.559-.678a6.22 6.22 0 0 1-2.719-2.72C2 26.023 2 24.28 2 20.795z"
        />
        <path
          fill="#EA4335"
          d="m22.051 9.273-5.987 4.672L9.94 9.273v.001l.008.007v6.542l6.047 4.774 6.056-4.59z"
        />
        <path
          fill="#FBBC05"
          d="M23.624 8.136 22.05 9.273v6.735L27 12.209V9.921s-.6-3.268-3.375-1.785"
        />
        <path
          fill="#34A853"
          d="M22.051 16.008v8.735h3.792S26.923 24.63 27 23.4V12.21z"
        />
        <path
          fill="#C5221F"
          d="M9.948 24.75v-8.927l-.008-.006zM9.94 9.274l-1.564-1.13C5.602 6.66 5 9.927 5 9.927v2.288l4.94 3.602z"
        />
        <path fill="#C5221F" d="M9.94 9.274v6.543l.008.006V9.28z" />
        <path
          fill="#4285F4"
          d="M5 12.217v11.192a1.35 1.35 0 0 0 1.157 1.341h3.792l-.009-8.933z"
        />
      </g>
      <defs>
        <filter
          id="socialGmail_svg__a"
          width={36}
          height={36}
          x={-2}
          y={-0.25}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_49_6652"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_49_6652"
            result="shape"
          />
        </filter>
      </defs>
    </g>
  </svg>
)
export default SvgSocialGmail
