import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineTag = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m4.405 16.276.53-.53zm4.53 4.53-.53.53zm6.75 0 .529.532.001-.001zm4.39-11.14.532-.529-.003-.002zm-4.54-4.52-.53.53v.002zm-3.6-1.39.036.75h.003zm-5 .24.033.75h.003zm-3.69 3.67-.75-.037v.001zm-.24 5-.75-.036v.003zm.87 4.14 4.53 4.53 1.06-1.06-4.53-4.53zm4.53 4.53c2.153 2.154 5.647 2.152 7.809.002l-1.058-1.063a4.03 4.03 0 0 1-5.69.001zm7.81 0 4.39-4.39-1.06-1.06-4.39 4.39zm4.39-4.39c2.154-2.153 2.152-5.647.002-7.809l-1.064 1.058a4.03 4.03 0 0 1 .001 5.691zm-.001-7.811-4.54-4.52-1.058 1.063 4.54 4.52zm-4.539-4.519a5.5 5.5 0 0 0-4.17-1.609l.079 1.498a4 4 0 0 1 3.03 1.172zM11.9 3.007l-5 .24.072 1.499 5-.24zm-4.998.24A4.614 4.614 0 0 0 2.496 7.63l1.498.075a3.114 3.114 0 0 1 2.974-2.958zM2.496 7.63l-.24 5 1.498.072.24-5zm-.24 5.003a5.55 5.55 0 0 0 1.618 4.174l1.061-1.061A4.05 4.05 0 0 1 3.754 12.7zm9.229-2.157a1.75 1.75 0 0 1-1.75 1.75v1.5a3.25 3.25 0 0 0 3.25-3.25zm-1.75 1.75a1.75 1.75 0 0 1-1.75-1.75h-1.5a3.25 3.25 0 0 0 3.25 3.25zm-1.75-1.75c0-.966.783-1.75 1.75-1.75v-1.5a3.25 3.25 0 0 0-3.25 3.25zm1.75-1.75c.966 0 1.75.784 1.75 1.75h1.5a3.25 3.25 0 0 0-3.25-3.25z"
        />
      </g>
    </svg>
  )
}
export default SvgLineTag
