import * as React from 'react'
const SvgSocialAdress = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 8.467 8.467"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        d="M1.873 4.26a3 3 0 0 1-.2-.776 3.7 3.7 0 0 1 .007-.742q.092-.592.408-1.06c.121-.18.16-.223.184-.206.01.007.272.226.582.487l.587.494c.024.02-.01.063-.727.916-.414.492-.766.909-.782.926l-.03.03z"
        style={{
          fill: '#ea4335',
          fillOpacity: 1,
          stroke: '#3b7844',
          strokeWidth: 0.0131708,
          strokeOpacity: 0.473684,
        }}
      />
      <path
        d="M2.887 1.976a69 69 0 0 1-.605-.513c-.018-.018-.008-.033.104-.146A2.57 2.57 0 0 1 4.88.616c.082.02.114.033.11.045a187 187 0 0 1-1.502 1.792c-.01.011-.159-.106-.6-.477z"
        style={{
          fill: '#1a73e8',
          fillOpacity: 1,
          stroke: '#3b7844',
          strokeWidth: 0.0131708,
          strokeOpacity: 0.473684,
        }}
      />
      <path
        d="M5.112 3.588c0-.005.014-.04.031-.079.08-.181.103-.432.056-.624a1.2 1.2 0 0 0-.201-.402 1.05 1.05 0 0 0-.538-.337 1.02 1.02 0 0 0-.781.144c-.039.027-.068.043-.066.038.002-.006.314-.379.693-.83s.688-.824.688-.832c0-.019 0-.019.142.034.343.129.653.327.911.584.176.176.36.417.445.589l.026.05-.67.797-.704.837c-.017.021-.031.036-.031.031z"
        style={{
          fill: '#4285f4',
          fillOpacity: 1,
          stroke: '#3b7844',
          strokeWidth: 0.0131708,
          strokeOpacity: 0.473684,
        }}
      />
      <path
        d="M2.8 5.661c-.496-.63-.654-.854-.813-1.16-.077-.147-.077-.148-.053-.18.054-.072 1.42-1.69 1.424-1.686a.2.2 0 0 1-.021.055.85.85 0 0 0-.088.425c0 .201.023.303.107.463a.997.997 0 0 0 1.416.368.5.5 0 0 1 .083-.05c.002.003-1.746 2.084-1.771 2.109A5 5 0 0 1 2.8 5.66z"
        style={{
          fill: '#fbbc04',
          fillOpacity: 1,
          stroke: '#3b7844',
          strokeWidth: 0.0131708,
          strokeOpacity: 0.473684,
        }}
      />
      <path
        d="M4.159 7.923c-.09-.029-.133-.1-.225-.382-.17-.513-.332-.817-.722-1.35a2 2 0 0 1-.116-.169c0-.013 3.415-4.079 3.426-4.079.014 0 .112.222.155.35.1.297.143.612.13.934-.028.719-.24 1.257-.771 1.956-.047.062-.205.263-.35.448-.406.512-.688.903-.828 1.149a4 4 0 0 0-.325.76c-.06.198-.121.322-.176.355a.28.28 0 0 1-.198.028z"
        style={{
          fill: '#34a853',
          fillOpacity: 1,
          stroke: '#3b7844',
          strokeWidth: 0.0131708,
          strokeOpacity: 0.473684,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialAdress
