import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { BannerAvatar } from './BannerAvatar'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTheme } from '@/Hooks'

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    margin-bottom: ${parseInt(theme.size.xl, 10) / 2}px;
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const ProfileBanner = memo(() => {
  const { theme } = useTheme()
  const { profile } = useProfileContext()
  return (
    <Wrapper>
      <BannerAvatar profile={profile} avatarSize={theme.size.xl} />
    </Wrapper>
  )
})

export default ProfileBanner
