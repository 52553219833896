import * as React from 'react'
const SvgSeedSunglasses = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 60})`}>
      <path
        fill="#133441"
        d="M39.073 39.394v.001zm0 .001.001.001zm1.072 3.9q-.225-.03-.426-.125c-.328-.156-1.061-.672-.783-2.118.224-1.164.135-1.642.124-1.696a.802.802 0 0 1 1.512-.536c.052.129.17.546.097 1.432.292.002.54.034.752.097.475.142.702.424.809.635.235.466.13 1.02-.286 1.52a2.33 2.33 0 0 1-.972.693c-.28.1-.563.133-.827.098M6.469 50.367c-2.331 0-4.89-.906-5.555-2.742-.416-1.152-.232-3.034 3.004-5.471a.552.552 0 1 1 .667.882c-2.106 1.584-3.039 3.08-2.629 4.213.454 1.253 2.55 2.113 4.875 2.005a.553.553 0 1 1 .053 1.103q-.205.01-.415.01"
      />
      <path
        fill="#133441"
        d="M7.613 51.527q-.255.001-.497-.074c-.71-.225-1.105-.888-1.055-1.772.063-1.142.713-1.519 1.263-1.5.32.013.628.163.87.391.47.108.85.355 1.073.718.26.419.268.925.018 1.322a2.03 2.03 0 0 1-1.672.915M23.483 59.96h-.038c-.938-.063-1.418-.42-1.469-.46a.55.55 0 0 1-.208-.506l.846-6.497a.553.553 0 1 1 1.097.142l-.794 6.108c.14.046.34.093.601.11.306.021.536.284.517.589a.554.554 0 0 1-.552.515M10.87 60a.553.553 0 0 1-.1-1.095c.295-.055.764-.169 1.006-.273.293-.596 1.033-3.542 1.61-6.181a.553.553 0 0 1 1.08.234c-.002.017-.347 1.59-.738 3.174-.844 3.415-1.059 3.539-1.306 3.684-.433.253-1.285.417-1.451.447a.5.5 0 0 1-.101.01"
      />
      <path
        fill="green"
        d="M33.826 5.219C33.361 3.17 31.432.697 28.42.145c-3.198-.587-6.336.579-8.068 4.055a10.4 10.4 0 0 0-.89 2.62c-.967-1.61-2.826-3.828-5.831-4.128-4.28-.424-6.796 2.065-7.502 4.124-.967 2.811-.075 5.12 1.996 5.427 2.074.307 3.545-.985 4.884-1.688 1.939-1.018 5.236-1.102 5.93.837q.148.773.255 1.549c.005.7-.995 4.014-1.316 4.628-.436.837-.874 1.37-.495 1.831.32.391.931.53 1.485.53 1.743 0 2.02-.251 2.241-.53.268-.336 1.52-4.434.128-7.897-.474-2.523 2.525-3.009 3.374-3.03 1.713-.04 4.336.56 5.98.772 3.726.48 3.571-2.54 3.235-4.026"
      />
      <path
        fill="#A4B46B"
        d="M11.562 6.55q.29-.481.66-.907c.258-.3.847-.619.869-1.056.033-.588-.887-.528-1.25-.502a6 6 0 0 0-.706.089c-.42.077-.832.199-1.222.369a5.1 5.1 0 0 0-1.755 1.263c-.354.398-.66.862-.832 1.367-.407 1.175-.396 2.289.022 2.976q.351.575 1.034.676c1.896.28 2.295-1.83 2.74-3.21.114-.36.245-.738.44-1.065m10.113-1.862c-.109.383-.142.8.057 1.085.144.203.518.31.76.25.712-.175 1.219-1.136 1.573-1.71.274-.446.624-.872.991-1.246.23-.234.536-.501.615-.835.146-.616-.796-.37-1.075-.302-1.089.274-2.133 1.137-2.66 2.12a3.3 3.3 0 0 0-.261.638"
      />
      <path
        fill="#133441"
        d="M36.133 47.674c-1.19 0-2.456-.597-3.62-1.737a.552.552 0 0 1 .775-.787c1.285 1.258 2.605 1.702 3.715 1.25 1.224-.498 2.01-2.007 2.049-3.938a.553.553 0 0 1 .553-.54h.013a.55.55 0 0 1 .54.563c-.048 2.379-1.097 4.27-2.738 4.939-.413.167-.845.25-1.288.25"
      />
      <path
        fill="#A37B65"
        d="M34.956 39.281c0 8.216-6.716 14.877-15 14.877s-15-6.66-15-14.877c0-9.809 8.551-22.004 12.74-27.39a2.835 2.835 0 0 1 4.52 0c4.19 5.386 12.74 17.581 12.74 27.39"
      />
      <path
        fill="#75503F"
        fillOpacity={0.8}
        d="M20.112 53.003c-5.89.113-10.736-3.454-10.824-7.967s4.619-8.264 10.508-8.377c5.891-.114 10.738 3.453 10.826 7.966.087 4.513-4.619 8.264-10.51 8.377"
      />
      <path
        fill="#fff"
        d="M18.789 14.172c-.016-.127-.178-.206-.291-.175-.69.182-1.151 1.11-1.54 1.66a40 40 0 0 0-1.484 2.259c-.468.769-.928 1.543-1.357 2.335-.364.67-1.103 1.612-1.034 2.394.032.374.411.724.796.489.701-.43 1.001-1.462 1.382-2.17.426-.793.85-1.589 1.295-2.372q.666-1.179 1.336-2.351c.347-.608.985-1.341.896-2.069M12.651 24.182c-.381-.118-.705.11-.85.448q-.12.286-.244.573c-.137.24-.249.496-.326.768-.1.343.09.721.437.827.379.116.679-.11.828-.437l.605-1.33c.152-.334-.13-.751-.45-.85M22.446 44.157c-.384-.419-1.225-.057-2.524-.057-1.3 0-2.171-.355-2.545.057-.163.18-.224.428-.19.701q.012.081.034.167c.197.76 1.087 1.173 2.552 1.224q.07.003.137.004.393-.002.736-.068c1.164-.22 1.896-.493 2.027-1.167q.017-.087.02-.17a.93.93 0 0 0-.247-.691"
      />
      <path
        fill="#B9B9B9"
        d="M22.142 44.985c.656-.635.42-.704-.88-.704-.361 0-.806.006-1.336.006-.569 0-1.315-.101-1.862-.101-.655 0-1.024.143-.463.777a8 8 0 0 1-.413-.105 1 1 0 0 1-.01-.145c0-.215.066-.41.2-.556.136-.15.341-.199.608-.199.462 0 1.112.142 1.936.142s1.462-.145 1.917-.145c.264 0 .466.049.607.202a.93.93 0 0 1 .248.643l-.001.047q-.181.056-.551.138M19.774 46.249c-1.466-.051-2.357-.464-2.553-1.223.165.027.332.045.487.059-.007.237.067.939 2.066 1.164M20.647 46.186c.939-.306 1.945-.406 1.341-1.09a7 7 0 0 0 .685-.077c-.131.674-.863.947-2.026 1.166"
      />
      <path
        fill="#231F20"
        d="M22.142 44.985c-.195.042-.427.086-.704.13 0 0 .235.002.55-.02q-.021-.026-.046-.05s.092-.007.2-.06"
      />
      <path
        fill="#231F20"
        d="M21.941 45.045q.026.024.047.05a7 7 0 0 0 .685-.077q.017-.087.02-.17-.181.054-.551.137c-.108.053-.2.06-.2.06M17.708 45.085c.389.032.699.03.699.03a12 12 0 0 1-.806-.152.7.7 0 0 0 .11.078q-.002 0-.003.044"
      />
      <path
        fill="#231F20"
        d="M17.71 45.04a.7.7 0 0 1-.109-.077 6 6 0 0 1-.413-.106q.01.083.033.169c.165.027.332.045.487.059q.002-.044.003-.044"
      />
      <path
        fill="#75503F"
        d="M23.25 44.432c.042-.422-.296-.605-.296-.605q.036-.002.068-.002c.664 0 .228.607.228.607M16.624 44.432s-.438-.607.226-.607q.033 0 .07.002s-.34.184-.297.605"
      />
      <path
        fill="#00111A"
        d="M39.612 33.725c-.169.227-.734.544-1.152.758.015.172.027 1.093.027 1.281 0 3.405.416 9.484-8.245 9.484-5.949 0-8.548-4.271-8.981-8.47h-.023s-.235-1.239-1.301-1.239c-1.068 0-1.303 1.239-1.303 1.239h-.023c-.431 4.199-3.032 8.47-8.981 8.47-8.66 0-8.245-6.079-8.245-9.484 0-.188.012-1.109.027-1.281-.418-.214-.983-.53-1.152-.758-.26-.352-.338-.99-.175-1.084.572-.334 2.027-1.02 4.167-1.471 2.2-.464 7.542-.746 12.334.558 1.493.406 5.145.43 6.7 0 4.781-1.32 10.135-1.022 12.335-.558 2.139.452 3.596 1.136 4.167 1.47.162.094.084.733-.176 1.085"
      />
      <path
        fill="#353B42"
        d="M17.363 35.904c0-2.516-2.701-3.76-7.426-3.76-4.726 0-7.231.821-7.231 3.985 0 2.886-.352 8.035 6.986 8.035 5.555 0 7.671-4.394 7.671-8.26M37.166 36.129c0-3.164-2.505-3.985-7.23-3.985s-7.427 1.244-7.427 3.76c0 3.866 2.118 8.26 7.672 8.26 7.337 0 6.985-5.15 6.985-8.035"
      />
      <path
        fill="#292E33"
        d="M15.665 37.57c-.996-1.593-2.688-.27-4.819 1.059-2.132 1.328-3.894 2.158-2.9 3.75.996 1.592 3.532 1.806 5.663.478 2.13-1.328 3.052-3.695 2.056-5.288M36.13 37.57c.996 1.592.075 3.959-2.056 5.287-2.13 1.328-4.666 1.114-5.66-.478-.997-1.592.767-2.422 2.897-3.75 2.131-1.329 3.823-2.652 4.819-1.06"
      />
      <path
        fill="#fff"
        d="M4.593 33.746c.477.024.394.946.243 2.002-.15 1.056-.312 1.848-.788 1.823-.478-.025-.742-.9-.591-1.957.15-1.056.66-1.893 1.136-1.868M4.692 39.311c.065.424-.114.804-.4.847-.289.044-.574-.264-.639-.688s.115-.802.403-.846c.286-.044.571.264.636.687M24.917 33.625c.476.025.393.947.242 2.004-.15 1.056-.31 1.847-.787 1.823s-.741-.902-.59-1.959c.15-1.056.658-1.891 1.135-1.868M25.017 39.193c.065.424-.115.803-.401.847-.288.044-.573-.264-.638-.688s.115-.802.401-.846c.288-.045.573.263.638.687"
      />
    </g>
  </svg>
)
export default SvgSeedSunglasses
