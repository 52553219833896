import * as React from 'react'
const SvgSampleUserCardGreen = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 150})`}>
      <rect width={108} height={150} fill="#7ACB8B" rx={16} />
      <circle cx={54} cy={58} r={15} fill="#EBF8EB" />
      <ellipse cx={54} cy={92} fill="#EBF8EB" rx={27} ry={15} />
    </g>
  </svg>
)
export default SvgSampleUserCardGreen
