import { api } from '@/Services/api'

const updateCachePost = (state, id) => {
  state = state.map(post => {
    if (post.id === id) {
      post.deleted = true
    }

    return post
  })
}
const deletePost = build =>
  build.mutation({
    query: id => {
      return {
        url: `/posts/${id}`,
        method: 'DELETE',
      }
    },
    async onQueryStarted(id, { dispatch, queryFulfilled }) {
      const patchPosts = dispatch(
        api.util.updateQueryData('getPosts', id, state =>
          updateCachePost(state, id),
        ),
      )
      const patchUserPosts = dispatch(
        api.util.updateQueryData('getPostsByUserId', id, state =>
          updateCachePost(state, id),
        ),
      )
      try {
        await queryFulfilled
      } catch {
        patchPosts.undo()
        patchUserPosts.undo()
      }
    },
    invalidatesTags: ['PostsByHashtag'],
  })

export default deletePost
