import * as React from 'react'
const SvgNfc = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 96})`}>
      <g clipPath="url(#nfc_svg__a)">
        <path
          fill="#fff"
          d="M75.929 72.624a3.2 3.2 0 0 1-1.734-.14c-1.803-.464-2.746-2.476-2.247-4.117-.035-.163 3.648-12.369.78-25.723-2.868-13.355-11.11-23.17-11.11-23.17-1.163-1.454-1.094-3.513.36-4.677 1.453-1.165 3.512-1.096 4.676.358.431.419 9.662 10.874 12.95 26.183s-.838 28.632-1.058 29.19c-.477.954-1.477 1.85-2.617 2.096m-9.009-8.629c.093-.36 1.834-8.913-.544-19.987-2.378-11.075-7.477-18.158-7.71-18.449-1-1.488-3.13-1.883-4.584-.719-1.489 1.001-1.883 3.13-.72 4.584 0 0 4.436 6.374 6.5 15.983s.636 17.241.636 17.241c-.302 1.769.77 3.583 2.538 3.885.523.058.884.15 1.372.046 1.303-.28 2.303-1.176 2.512-2.584m-16.45-1.238c.941-.372 1.616-1.199 1.93-2.118.092-.36 2.381-7.156.947-13.833s-6.346-12.097-6.544-12.225c-1.326-1.419-3.386-1.488-4.642-.196-1.418 1.327-1.487 3.387-.195 4.643 0 0 3.818 4.29 4.867 9.177.455 2.117.456 4.502.154 6.27l-25.09-13.693c-.954-.477-2.163-.558-3.07-.022-.908.535-1.745 1.396-1.826 2.606.035.163-.707 5.434.517 11.134s4.064 10.201 4.134 10.527c1.002 1.489 3.003 2.081 4.654 1.045 1.49-1.001 2.082-3.002 1.046-4.654 0 0-2.376-3.92-3.32-8.317-.28-1.303-.397-2.64-.479-3.816l24.135 13.218c.757.348 1.478.534 2.292.36z"
        />
      </g>
      <defs>
        <clipPath id="nfc_svg__a">
          <path
            fill="#fff"
            d="M.98 17.162 79.154.374l16.788 78.174-78.174 16.788z"
          />
        </clipPath>
      </defs>
    </g>
  </svg>
)
export default SvgNfc
