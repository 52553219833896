import * as React from 'react'
const SvgSocialDiscord = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <path
        fill="url(#socialDiscord_svg__a)"
        d="M2 12.35c0-3.36 0-5.04.654-6.324a6 6 0 0 1 2.622-2.622C6.56 2.75 8.24 2.75 11.6 2.75h8.8c3.36 0 5.04 0 6.324.654a6 6 0 0 1 2.622 2.622C30 7.31 30 8.99 30 12.35v8.8c0 3.36 0 5.04-.654 6.324a6 6 0 0 1-2.622 2.622c-1.283.654-2.964.654-6.324.654h-8.8c-3.36 0-5.04 0-6.324-.654a6 6 0 0 1-2.622-2.622C2 26.19 2 24.51 2 21.15z"
      />
      <path
        fill="#fff"
        d="M24.275 10.777c-1.513-1.28-3.33-1.92-5.247-2.027l-.303.32c1.715.427 3.23 1.28 4.642 2.453a14.8 14.8 0 0 0-5.651-1.813c-.606-.107-1.11-.107-1.716-.107s-1.11 0-1.716.107a14.8 14.8 0 0 0-5.651 1.813c1.413-1.173 2.927-2.026 4.642-2.453l-.303-.32c-1.917.107-3.733.747-5.247 2.027C6.009 14.19 5.1 18.03 5 21.977c1.514 1.706 3.633 2.773 5.853 2.773 0 0 .707-.853 1.211-1.6-1.312-.32-2.523-1.067-3.33-2.24.706.427 1.413.853 2.12 1.173.908.427 1.816.64 2.724.854.807.106 1.615.213 2.422.213s1.615-.107 2.422-.213c.908-.214 1.816-.427 2.725-.854.706-.32 1.413-.746 2.12-1.173-.808 1.173-2.02 1.92-3.331 2.24.504.747 1.21 1.6 1.21 1.6 2.221 0 4.34-1.067 5.854-2.773-.1-3.947-1.01-7.787-2.725-11.2m-11.605 9.28c-1.01 0-1.918-.96-1.918-2.134s.909-2.133 1.918-2.133 1.917.96 1.917 2.133-.908 2.134-1.917 2.134m6.66 0c-1.009 0-1.917-.96-1.917-2.134s.908-2.133 1.917-2.133c1.01 0 1.918.96 1.918 2.133s-.909 2.134-1.918 2.134"
      />
      <defs>
        <linearGradient
          id="socialDiscord_svg__a"
          x1={16}
          x2={16}
          y1={2.75}
          y2={30.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#687EC9" />
          <stop offset={1} stopColor="#5971C3" />
        </linearGradient>
      </defs>
    </g>
  </svg>
)
export default SvgSocialDiscord
