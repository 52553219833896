const follow = {
  fllw: "S'abonner",
  un_flw: 'Se désabonner',
  followers: 'Abonnés',
  followed: 'Abonnements',
  contacts: 'Contacts',
  nothing: "Il n'y a rien à afficher",
  result: 'résultat',
  results: 'résultats',
}


export default follow
