import * as React from 'react'
const SvgArrowTopRight = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 20})`}>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 18.333 18.333 1m0 0v16.64m0-16.64H1.693"
      />
    </g>
  </svg>
)
export default SvgArrowTopRight
