const games_page = {
  btn_play: 'Jugar',
  tab_details: 'Detalles',
  tab_ranking: 'Clasificación',
  tab_Historical: 'Histórico',
  cost_game_ticket: '1 boleto',
  cost_game_bolt: '25% de vitalidad de tu compañero',
  costs_per_game: 'Costos por partida',
  winnings_per_game: 'Ganancias por partida',
  your_best_score: 'Tu mejor puntuación',
  gain_x2: 'Ganar x2',
}

export default games_page
