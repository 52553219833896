import * as React from 'react'
const SvgSeed = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 28})`}>
      <path
        fill="green"
        d="M20.897 3.726C20.474 2.432 19.118.955 17.223.79c-2.012-.176-3.87.777-4.708 3.134a7 7 0 0 0-.374 1.75c-.704-.98-1.998-2.294-3.873-2.297C5.6 3.375 4.213 5.144 3.915 6.519c-.41 1.879.294 3.314 1.593 3.38 1.3.067 2.121-.861 2.9-1.4 1.129-.782 3.002-.77 3.56.439q.142.49.26.984c.05.452-.346 2.658-.504 3.076-.213.568-.448.94-.183 1.215.224.232.61.283.951.248 1.076-.111 1.54-.656 1.658-.85.143-.234.643-2.962-.447-5.112-.46-1.6 1.203-2.016 1.725-2.085 1.054-.134 2.712.087 3.741.12 2.331.073 2.034-1.87 1.728-2.808"
      />
      <path
        fill="#BDCF46"
        d="M7.249 6.002a4 4 0 0 1 .346-.628c.14-.211.482-.454.466-.738-.018-.382-.582-.285-.804-.245a4 4 0 0 0-.43.102c-.254.077-.5.182-.73.316a3.2 3.2 0 0 0-.998.929c-.192.28-.35.598-.422.936-.173.786-.092 1.504.212 1.922a.86.86 0 0 0 .682.371c1.19.06 1.295-1.328 1.477-2.249.047-.24.102-.492.2-.716m6.115-1.846c-.041.254-.034.525.108.697.102.122.34.167.485.113.428-.158.677-.811.857-1.205.14-.306.327-.604.528-.868.127-.166.298-.358.324-.58.05-.407-.516-.188-.683-.126-.654.246-1.24.87-1.5 1.54-.046.117-.092.269-.119.429"
      />
      <path
        fill="#A37B65"
        d="M23.272 19.007c1.078 3.57-1.012 7.32-4.667 8.374s-7.491-.987-8.568-4.558c-1.165-3.862.51-9.468 1.685-12.625.152-.408.224-1.022.723-1.046.39-.019.58-.173.957-.327.49-.201.979.124 1.44.466 2.739 2.027 7.264 5.85 8.43 9.716"
      />
      <path
        fill="#75503F"
        fillOpacity={0.8}
        d="M18.393 26.79c-2.597.788-5.206-.153-5.825-2.101-.62-1.949.985-4.167 3.582-4.956 2.598-.788 5.207.152 5.826 2.1.62 1.95-.985 4.168-3.583 4.956"
      />
      <path
        fill="#fff"
        d="M12.842 12.201c-.022-.053-.103-.07-.15-.043-.286.16-.38.62-.489.905q-.215.573-.391 1.16c-.117.39-.23.782-.326 1.177-.082.336-.299.833-.174 1.166.059.16.27.268.413.12.262-.269.271-.754.357-1.108.095-.396.189-.792.293-1.187l.315-1.182c.082-.306.279-.7.152-1.008M11.304 17.288c-.185-.007-.302.13-.326.295l-.04.279a1.6 1.6 0 0 0-.054.374.31.31 0 0 0 .294.31c.183.005.29-.128.317-.288l.111-.651c.027-.165-.148-.313-.302-.319"
      />
      <path
        fill="green"
        d="M13.828 8.77c.043.123-.235.585-.71.751s-.988-.07-1.031-.193c-.043-.124.322-.31.797-.477.474-.166.9-.205.944-.081"
      />
    </g>
  </svg>
)
export default SvgSeed
