import * as React from 'react'
const SvgDropWaving = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 60})`}>
      <path
        fill="#3C3F42"
        d="m23.895 60-.037-.001c-.915-.061-1.383-.41-1.433-.45a.54.54 0 0 1-.206-.448l.284-6.35a.54.54 0 0 1 .563-.514.54.54 0 0 1 .515.563l-.268 6.003c.138.049.341.101.617.119A.54.54 0 0 1 23.895 60M5.055 49.823c-2.308 0-4.506-1.547-4.967-3.54-.23-.987-.313-3.471 3.82-5.259a.54.54 0 0 1 .429.992c-2.386 1.03-3.552 2.498-3.198 4.022.365 1.579 2.207 2.787 4.106 2.702a.54.54 0 0 1 .051 1.078 5 5 0 0 1-.241.005"
      />
      <path
        fill="#3C3F42"
        d="M6.007 50.96c-.164 0-.328-.022-.484-.072-.694-.22-1.078-.868-1.03-1.731.062-1.117.702-1.495 1.232-1.467.312.012.613.16.85.383.458.106.828.347 1.046.7.254.41.26.906.018 1.292a1.97 1.97 0 0 1-1.632.896M13.182 60a.54.54 0 0 1-.035-1.078c.274-.018.477-.07.616-.12l-.268-6.002a.54.54 0 0 1 .515-.563.54.54 0 0 1 .563.514l.285 6.35a.54.54 0 0 1-.207.448c-.05.04-.518.389-1.433.45zM33.492 44.213c-.674 0-1.39-.128-2.138-.387a.54.54 0 1 1 .353-1.02c1.5.519 2.766.429 3.768-.269 1.26-.877 2.009-2.674 2.055-4.93a.54.54 0 0 1 .54-.53h.01a.54.54 0 0 1 .53.551c-.053 2.606-.971 4.717-2.519 5.795-.754.525-1.633.79-2.599.79"
      />
      <path
        fill="#3C3F42"
        d="M39.932 35.557c-.003.007-.2.327-.523.657a8 8 0 0 0-.11-1.111.53.53 0 0 0-.617-.438.545.545 0 0 0-.444.622c.07.427.1.892.088 1.232a6.3 6.3 0 0 1-.371-1.093.53.53 0 0 0-.647-.39.543.543 0 0 0-.398.652c.104.435.245.895.43 1.287a2.1 2.1 0 0 1-.774-.234.54.54 0 0 0-.736.176.535.535 0 0 0 .164.74c.046.028 1.134.69 2.65.27l.017-.004.07-.022c1.272-.4 2.086-1.74 2.12-1.799l.02-.035a.533.533 0 0 0-.201-.699.54.54 0 0 0-.738.19"
      />
      <path
        fill="#2EA3F2"
        d="M32.672 39.732c0 8.05-6.526 14.574-14.574 14.574-8.05 0-14.575-6.524-14.575-14.574 0-9.599 8.29-21.528 12.365-26.814a2.762 2.762 0 0 1 4.419 0c4.076 5.286 12.365 17.215 12.365 26.814"
      />
      <path
        fill="#0C71C3"
        d="M18.412 53.374c-5.745 0-10.405-3.576-10.405-7.987s4.66-7.987 10.405-7.987c5.747 0 10.406 3.576 10.406 7.987s-4.659 7.987-10.406 7.987"
      />
      <path
        fill="#fff"
        d="M16.948 15.12c-.015-.124-.172-.2-.284-.17-.672.177-1.12 1.084-1.501 1.622q-.763 1.079-1.448 2.207a53 53 0 0 0-1.324 2.281c-.355.656-1.075 1.576-1.008 2.34.033.366.4.707.776.478.684-.42.977-1.43 1.348-2.12.417-.776.83-1.553 1.263-2.318q.65-1.151 1.303-2.298c.34-.594.961-1.31.875-2.022M10.964 24.902c-.373-.114-.69.108-.83.439l-.24.56a3.4 3.4 0 0 0-.318.75.66.66 0 0 0 .427.808c.37.114.662-.108.808-.428l.591-1.299c.148-.326-.128-.734-.438-.83M7.25 40.742a3.92 3.92 0 0 0 4.087 3.743 3.92 3.92 0 0 0 3.748-4.082 3.92 3.92 0 0 0-4.088-3.743 3.916 3.916 0 0 0-3.747 4.082"
      />
      <path
        fill="#3C3F42"
        d="M12.913 42.09c-.242.274-.624.334-.852.133-.228-.2-.216-.584.026-.86.242-.273.622-.334.85-.133.228.2.217.585-.024.86m-2.08-1.795c-.418.475-1.075.578-1.47.232-.394-.346-.375-1.012.043-1.485.418-.475 1.077-.58 1.47-.232.395.345.376 1.01-.043 1.485m3.03.16a2.7 2.7 0 0 0-2.815-2.578 2.7 2.7 0 0 0-2.583 2.812 2.7 2.7 0 0 0 2.816 2.578 2.696 2.696 0 0 0 2.582-2.811"
      />
      <path
        fill="#fff"
        d="M21.63 40.742a3.92 3.92 0 0 0 4.09 3.743 3.92 3.92 0 0 0 3.748-4.082 3.92 3.92 0 0 0-4.089-3.743 3.92 3.92 0 0 0-3.748 4.082"
      />
      <path
        fill="#3C3F42"
        d="M27.476 41.991c-.241.276-.622.335-.85.135s-.217-.585.024-.86c.242-.274.624-.334.852-.134s.217.586-.026.86m-2.222-1.854c-.475.419-1.142.439-1.489.046-.348-.394-.245-1.05.23-1.47.473-.418 1.14-.438 1.487-.045.348.394.245 1.051-.228 1.47m2.991.319a2.7 2.7 0 0 0-2.814-2.579 2.7 2.7 0 0 0-2.583 2.812 2.7 2.7 0 0 0 2.816 2.578 2.696 2.696 0 0 0 2.581-2.811M22.758 33.865c.086.688 1.357.772 2.705.574 1.206-.177 2.398-.242 2.445-1.164.096-1.894-5.408-1.482-5.15.59M8.808 33.275c.047.922 1.239.987 2.446 1.164 1.348.198 2.618.114 2.705-.574.257-2.073-5.247-2.484-5.151-.59"
      />
      <path
        fill="#B6DFE9"
        d="M29.766 45.52c0 .504-.762.912-1.703.912s-1.704-.408-1.704-.911c0-.502.762-.911 1.704-.911.94 0 1.703.409 1.703.91M10.023 45.52c0 .504-.763.912-1.703.912-.942 0-1.705-.408-1.705-.911 0-.502.763-.911 1.705-.911.94 0 1.703.409 1.703.91"
      />
      <path
        fill="#AB1700"
        d="M20.607 44.804c.745.901.02 2.58-2.43 2.58s-3.051-1.814-2.428-2.58c.36-.439 1.184-.22 2.428-.22 1.245 0 2.06-.227 2.43.22"
      />
      <path
        fill="#fff"
        d="M20.259 44.967c0 .205-.902.371-2.014.371-1.11 0-2.011-.166-2.011-.37 0-.206.901-.156 2.011-.156 1.112 0 2.014-.05 2.014.155"
      />
      <path
        fill="#FD6C6C"
        d="M16.516 46.704c.38.236.93.398 1.672.398.832 0 1.435-.19 1.83-.459-1.42-1.37-2.756-.615-3.502.06"
      />
    </g>
  </svg>
)
export default SvgDropWaving
