import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineCopy = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M7.426 12.268a10.3 10.3 0 0 1 0-4.691 5.78 5.78 0 0 1 4.306-4.306 10.3 10.3 0 0 1 4.691 0 5.78 5.78 0 0 1 4.306 4.306 10.3 10.3 0 0 1 0 4.691 5.78 5.78 0 0 1-4.306 4.306 10.3 10.3 0 0 1-4.691 0m-4.306-4.306a5.78 5.78 0 0 0 4.306 4.306m-4.306-4.306a10.3 10.3 0 0 1-.253-2.957l-.25.054a4.95 4.95 0 0 0-3.69 3.69 8.8 8.8 0 0 0 0 4.022 4.95 4.95 0 0 0 3.69 3.69c1.322.31 2.699.31 4.021 0a4.95 4.95 0 0 0 3.69-3.69q.03-.125.055-.25a10.3 10.3 0 0 1-2.957-.253"
        />
      </g>
    </svg>
  )
}
export default SvgLineCopy
