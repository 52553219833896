import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgEyesPswOpen = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 20})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M10 7.273A2.73 2.73 0 0 0 7.273 10 2.73 2.73 0 0 0 10 12.727 2.73 2.73 0 0 0 12.727 10 2.73 2.73 0 0 0 10 7.273"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M10 3.182C5.455 3.182 1.573 6.009 0 10c1.573 3.991 5.455 6.818 10 6.818 4.55 0 8.427-2.827 10-6.818-1.573-3.99-5.45-6.818-10-6.818m0 11.364A4.547 4.547 0 0 1 5.455 10 4.547 4.547 0 0 1 10 5.455 4.547 4.547 0 0 1 14.546 10 4.547 4.547 0 0 1 10 14.546"
        />
      </g>
    </svg>
  )
}
export default SvgEyesPswOpen
