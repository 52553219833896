const items = {
  address: 'Votre adresse',
  phone: 'Votre téléphone',
  mail: 'Votre mail',
  skype: 'Nom d’utilisateur ou numéro de téléphone',
  discord: 'Votre pseudo',
  site: 'Votre url',
  placeholder_url: 'https://exemple.com/',
  site_title: 'Titre du site',
  platform: 'Platforme',
}


export default items
