const timer = {
  days: 'Day{{plurial}}',
  hours: 'Hr{{plurial}}',
  minutes: 'Min{{plurial}}',
  seconds: 'Sec{{plurial}}',
  text: 'Launch of the Mall in :',
}


export default timer
