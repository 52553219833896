import { useTheme } from '@/Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Text from './Texts/Text'
import { Avatar } from './Profile/Avatar'
import CertificationIcon from '@/Assets/icons/CertificationIcon'

const Wrapper = styled.div`
  ${({ theme }) => css`
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.space.m};
    height: ${theme.size.l};
    cursor: pointer;
  `}
`
const Label = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
  `}
`

const ProfileItem = ({ profile, onClick }) => {
  return (
    <Wrapper
      onClick={
        onClick
          ? () => onClick(profile.id)
          : () => window.open(window.origin + `/${profile?.customUrl}`)
      }
    >
      <ProfileItemContent profile={profile} />
    </Wrapper>
  )
}

export const ProfileItemContent = ({ profile }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const getJob = job => (job ? job : '')
  const getBusinessName = businessName =>
    businessName
      ? `${
          profile.job ? ` ${t('profilepage.at_home_bar')} ` : ''
        }${businessName}`
      : ''

  const getTitle = () => {
    if (profile.businessMode) {
      return profile.businessName
    }
    return `${profile.firstName} ${profile.lastName}`
  }
  return (
    <>
      <Avatar size={theme.size.std} profile={profile} />
      <div className="flex-1 pl-3 mr-1">
        <div className="flex flex-row items-center">
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            className="text-base font-bold mr-1"
          >
            {getTitle()}
          </Text>
          {profile.isCertified && (
            <CertificationIcon
              color={
                profile?.styles?.['background-color'] || theme.colors.primary
              }
            />
          )}
        </div>
        <Label>
          {getJob(profile.job)}
          {getBusinessName(profile.businessName)}
        </Label>
      </div>
    </>
  )
}

export default ProfileItem
