import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineDrag = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M9 20.75q-.825 0-1.412-.587A1.93 1.93 0 0 1 7 18.75q0-.825.588-1.413A1.93 1.93 0 0 1 9 16.75q.825 0 1.413.587.587.588.587 1.413t-.587 1.413A1.93 1.93 0 0 1 9 20.75m6 0q-.825 0-1.412-.587A1.93 1.93 0 0 1 13 18.75q0-.825.588-1.413A1.93 1.93 0 0 1 15 16.75q.825 0 1.413.587.587.588.587 1.413t-.587 1.413A1.93 1.93 0 0 1 15 20.75m-6-6q-.825 0-1.412-.588A1.92 1.92 0 0 1 7 12.75q0-.825.588-1.413A1.93 1.93 0 0 1 9 10.75q.825 0 1.413.587.587.588.587 1.413t-.587 1.412A1.93 1.93 0 0 1 9 14.75m6 0q-.825 0-1.412-.588A1.92 1.92 0 0 1 13 12.75q0-.825.588-1.413A1.93 1.93 0 0 1 15 10.75q.825 0 1.413.587.587.588.587 1.413t-.587 1.412A1.93 1.93 0 0 1 15 14.75m-6-6q-.825 0-1.412-.588A1.92 1.92 0 0 1 7 6.75q0-.824.588-1.412A1.92 1.92 0 0 1 9 4.75q.825 0 1.413.588Q11 5.925 11 6.75q0 .824-.587 1.412A1.93 1.93 0 0 1 9 8.75m6 0q-.825 0-1.412-.588A1.92 1.92 0 0 1 13 6.75q0-.824.588-1.412A1.92 1.92 0 0 1 15 4.75q.825 0 1.413.588Q17 5.925 17 6.75q0 .824-.587 1.412A1.93 1.93 0 0 1 15 8.75"
        />
      </g>
    </svg>
  )
}
export default SvgLineDrag
