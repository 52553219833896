import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineSliderVertical = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M6.5 2h11m-11 20h11M3.424 15.554l.048.167c.486 1.68 1.904 2.952 3.665 3.287 3.18.604 6.45.604 9.63 0 1.76-.335 3.178-1.606 3.665-3.287l.048-.167c.629-2.173.629-4.474 0-6.647l-.048-.167c-.487-1.68-1.904-2.952-3.665-3.287a25.8 25.8 0 0 0-9.63 0C5.376 5.788 3.958 7.06 3.472 8.74l-.048.167a11.95 11.95 0 0 0 0 6.647"
        />
      </g>
    </svg>
  )
}
export default SvgLineSliderVertical
