import TextInput from '@/Components/Controls/TextInput'
import { ProLabel } from './ProLabel'

export const ProTextInput = ({
  value,
  onChange,
  label,
  placeholder,
  ...props
}) => {
  return (
    <>
      <ProLabel label={label} />
      <TextInput
        {...props}
        className="mb-2"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  )
}
