import PhoneInput from '@/Components/Controls/PhoneInput'
import { ProTextInput } from './ProTextInput'
import capitalizeFirstLetter from '@/Utils/capitalizeFirstLetter'
import { useTranslation } from 'react-i18next'

const InputValue = ({
  type,
  label,
  valueLabel,
  placeholder,
  value,
  setValue,
  ...props
}) => {
  const { t } = useTranslation()
  const lowerCaseRequired = label !== 'Music' && label !== 'Video'
  return (
    <>
      {type === 'phone' ? (
        <PhoneInput
          {...props}
          label={t('modal.addContent.label_phone')}
          value={value}
          onChange={phoneValue => setValue(phoneValue)}
        />
      ) : (
        <ProTextInput
          {...props}
          value={lowerCaseRequired ? value.toLowerCase?.() : value}
          onChange={setValue}
          label={capitalizeFirstLetter(valueLabel || placeholder || 'Username')}
          placeholder={placeholder || 'username'}
          keyboardType={lowerCaseRequired ? 'visible-password' : undefined}
        />
      )}
    </>
  )
}

export default InputValue
