import { useTheme } from '@/Hooks'
import { useState } from 'react'
import { Modal } from './ModalWrapper/Modal'
import { useTranslation } from 'react-i18next'
import { PickerOverlay } from 'filestack-react'
import { Config } from '@/Config'

const ModalChooseThumbnail = ({ onChange, onReset, children: button }) => {
  const { theme } = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const [isPickerOpen, setIsPickerOpen] = useState(false)

  const onUploadDone = async (res, type) => {
    if (res.filesUploaded[0]?.url) {
      onChange(res.filesUploaded[0].url)
      setIsPickerOpen(false)
      setIsOpen(false)
    }
  }

  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.chooseThumbnail.title')}
        actions={[
          {
            label: t('modal.chooseThumbnail.btn.perso'),
            onClick: () => {
              setIsPickerOpen(true)
            },
          },
          {
            label: t('modal.chooseThumbnail.btn.default'),
            onClick: () => {
              onReset()
              setIsOpen(false)
            },
            color: theme.colors.grey1,
          },
        ]}
      >
        {isPickerOpen && (
          <PickerOverlay
            apikey={Config.FILESTACK_API_KEY}
            onUploadDone={onUploadDone}
            pickerOptions={{
              maxFiles: 1,
              accept: 'image/*',
              uploadInBackground: false,
              fromSources: ['local_file_system', 'url'],
              transformations: {
                crop: {
                  aspectRatio: 1,
                  force: true,
                },
                circle: false,
              },
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default ModalChooseThumbnail
