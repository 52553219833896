import { useTheme } from '@/Hooks'
import { useState } from 'react'
import { Modal } from './ModalWrapper/Modal'
import { useTranslation } from 'react-i18next'
import useEditContent from '@/Hooks/useEditContent'

const ModalContentDeleteConfirmation = ({
  onDismiss,
  type,
  contentId,
  children: button,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { deleteContent } = useEditContent()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => {
          onDismiss()
          setIsOpen(false)
        }}
        title={t('modal.contentDeleteConfirmation.title')}
        subtitle={t('modal.contentDeleteConfirmation.subtitle')}
        actions={[
          {
            color: theme.colors.grey1,
            label: t('modal.btn.cancel'),
            onClick: () => setIsOpen(false),
          },
          {
            label: t('modal.btn.confirm'),
            onClick: async () => {
              deleteContent({ type, contentId })
              setIsOpen(false)
              onDismiss()
            },
            color: theme.colors.primary,
          },
        ]}
      />
    </>
  )
}

export default ModalContentDeleteConfirmation
