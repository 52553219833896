import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineScan = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M20.25 8.25a.75.75 0 0 0 1.5 0zM16.5 3a.75.75 0 0 0 0 1.5zm-9 1.5a.75.75 0 0 0 0-1.5zM2.25 8.25a.75.75 0 0 0 1.5 0zm4.383 3.985a.75.75 0 1 0 .61 1.37zm1.255.263-.305-.686zm8.224 0-.304.685zm.646 1.107a.75.75 0 0 0 .61-1.37zm-1.181 7.74.147.735zm-.561.111-.148-.735zm-5.866.011-.145.736zm-.722-.142.144-.736zm-4.678-6.43a.75.75 0 0 0-1.5 0zm18 0a.75.75 0 0 0-1.5 0zm0-6.645C21.75 5.35 19.4 3 16.5 3v1.5a3.75 3.75 0 0 1 3.75 3.75zM7.5 3a5.25 5.25 0 0 0-5.25 5.25h1.5A3.75 3.75 0 0 1 7.5 4.5zm-.258 10.605.95-.422-.609-1.37-.95.422zm8.566-.422.95.422.61-1.37-.951-.423zm-7.616 0a9.38 9.38 0 0 1 7.615 0l.61-1.37a10.88 10.88 0 0 0-8.834 0zm7.237 7.426-.56.112.294 1.47.56-.111zm-6.134.122-.723-.142-.29 1.472.723.142zm5.573-.01a14.3 14.3 0 0 1-5.573.01l-.29 1.472c2.033.4 4.127.396 6.158-.011zM2.25 14.895c0 3.495 2.54 6.478 6.033 7.166l.29-1.472C5.75 20.034 3.75 17.64 3.75 14.895zm18 0c0 2.749-1.997 5.148-4.821 5.714l.295 1.47c3.492-.7 6.026-3.688 6.026-7.184z"
        />
      </g>
    </svg>
  )
}
export default SvgLineScan
