import { LineClose } from '@/Assets/icons/components'
import IconButton from '@/Components/Buttons/IconButton'
import { SubTitle } from '@/Components/Texts/SubTitle'
import React from 'react'
import { styled } from 'styled-components'
import Text from '../../Texts/Text'

const Header = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey1}`};
  width: 100%;
  padding: 24px 40px 16px;
  display: flex;
  justify-content: center;
  align-items: 'flex-start';
`

export const ModalHeader = ({
  title,
  subtitle,
  onDismiss,
  header,
  displayCloseButton,
  ...props
}) => {
  if (!title) return null

  return (
    <>
      {header}
      <Header {...props}>
        <div className="flex flex-col flex-1 gap-1">
          <Text className="text-2xl">{title}</Text>
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </div>
        {displayCloseButton && (
          <IconButton icon={<LineClose />} onClick={onDismiss} />
        )}
      </Header>
    </>
  )
}
