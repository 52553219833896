import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgDarkmode = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M7.154 5.942c0-1.547.227-3.11.808-4.442C4.103 3.18 1.5 7.12 1.5 11.596 1.5 17.618 6.382 22.5 12.404 22.5c4.476 0 8.417-2.603 10.096-6.462-1.332.58-2.897.808-4.442.808-6.022 0-10.904-4.882-10.904-10.904"
        />
      </g>
    </svg>
  )
}
export default SvgDarkmode
