import { LineSaved } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../Buttons/IconButton'
import { Button } from '../Buttons/Button'
import {
  useAddBookmarkMutation,
  useGetBookmarksByUserIdQuery,
  useRemoveBookmarkMutation,
} from '@/Services/modules/bookmarks'

export const ToggleBookmark = ({ data, type, label }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.user)
  const options = {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  }
  const { data: bookmarks = [] } = useGetBookmarksByUserIdQuery(
    { userId: currentUser.uid },
    options,
  )

  const [addBookmark] = useAddBookmarkMutation()
  const [removeBookmark] = useRemoveBookmarkMutation()
  const existingPost = useMemo(() => {
    return bookmarks.find(bookmark => bookmark.id === data.id)
  }, [data.id, bookmarks])

  const labelText = useMemo(
    () =>
      existingPost
        ? t('feedpage.remove_post_from_bookmarks')
        : t('feedpage.add_post_to_bookmarks'),
    [existingPost, t],
  )

  const iconColor = existingPost ? theme.colors.primary : theme.colors.black
  const icon = useMemo(
    () => (
      <LineSaved
        className={label ? 'mr-2' : ''}
        fill={existingPost ? iconColor : 'none'}
        color={iconColor}
      />
    ),
    [existingPost, iconColor, label],
  )

  const handleToggle = useCallback(async () => {
    try {
      if (existingPost) {
        await removeBookmark({
          uid: currentUser.uid,
          data: { ...data, type },
        })
      } else {
        await addBookmark({
          uid: currentUser.uid,
          data: { ...data, type },
        })
      }
    } catch (error) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: error.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }, [
    existingPost,
    data,
    type,
    currentUser.uid,
    addBookmark,
    removeBookmark,
    dispatch,
    t,
  ])

  if (!label) {
    return <IconButton icon={icon} onClick={() => handleToggle()} />
  }

  return (
    <Button
      icon={() => icon}
      label={label && labelText}
      color={theme.colors.grey1}
      onClick={() => handleToggle()}
      className="justify-start flex-none"
    />
  )
}
