import { api } from '@/Services/api'
import deepEqual from 'deep-equal'

const updateCachePost = (state, patch) => {
  state = state.map(post => {
    if (post.id === patch.id) {
      const values = {
        content: post.content,
        tags: post.tags,
        link: post.link,
        images: post.images,
      }
      if (!deepEqual(values, patch.post)) {
        post.content = patch.post.content
        post.tags = patch.post.tags
        post.link = patch.post.link
        post.images = patch.post.images
      }
    }

    return post
  })
}

const updatePostById = build =>
  build.mutation({
    query: ({ id, post }) => {
      return {
        url: `/posts/${id}`,
        method: 'PUT',
        body: post,
      }
    },
    async onQueryStarted(patch, { dispatch, queryFulfilled }) {
      const patchPosts = dispatch(
        api.util.updateQueryData('getPosts', patch.id, state =>
          updateCachePost(state, patch),
        ),
      )
      const patchUserPosts = dispatch(
        api.util.updateQueryData('getPostsByUserId', patch.id, state =>
          updateCachePost(state, patch),
        ),
      )
      try {
        await queryFulfilled
      } catch {
        patchPosts.undo()
        patchUserPosts.undo()
      }
    },
    invalidatesTags: ['PostsByHashtag', 'AvailableResources'],
  })

export default updatePostById
