const create_image_AI_page = {
  generate_image_title: 'Générer une image',
  negative_prompt: 'Prompt négatif',
  suggestion: 'Suggestions',
  placeholder: 'Écrivez votre prompt ici...',
  picture_style: "Style de l'image",
  see_all: 'Tout voir',
  optional: '(optionnel)',
  toast_error_title: "L'interprétation du prompt a échoué",
  toast_error_subtitle:
    "L'IA a des limites dans la génération de textes dans l'image. Privilégiez des illustrations sans texte ou avec un texte très bref et précis.",
}

export default create_image_AI_page
