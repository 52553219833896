const companion_descriptions = {
  'aloe vera':
    'Aloe Vera est un compagnon originaire des régions désertiques. Il possède un coeur tendre et est souvent décrit comme apaisant par les autres compagnons.',
  dianthus:
    'Dianthus est délicate et élégante, avec des pétales colorés et parfumés. Elle utilise son charme pour calmer les conflits et apporter la paix.',
  nymphaea:
    'Nymphaea est un compagnon aquatique, ce qui lui permet de vivre et de se déplacer aisément dans l’eau. Ses capacités aquatiques lui servent de radeaux ou de boucliers.',
  oak: 'Oak est connu pour sa force et sa résilience. Avec une écorce épaisse et des branches robustes, Oak est un protecteur naturel et un pilier solide pour ses compagnons.',
  palmatum:
    'Palmatum est un compagnon originaire du Japon. Agile et gracieux, il utilise ses feuilles tranchantes comme des lames pour défendre et explorer son environnement.',
}

export default companion_descriptions
