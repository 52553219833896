import * as React from 'react'
const SvgClaimed = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 39})`}>
      <ellipse cx={19.544} cy={17} fill="#00AD30" rx={19.082} ry={17} />
      <ellipse cx={19.544} cy={14.614} fill="#79D57D" rx={19.082} ry={14.614} />
      <path
        fill="#D6FFD7"
        d="M17.994 4.543c0-.972-.79-1.768-1.757-1.681-1.948.175-3.84.64-5.569 1.372-2.244.952-4.141 2.326-5.513 3.994-1.373 1.669-2.177 3.578-2.338 5.55a9.2 9.2 0 0 0 .631 4.158c.377.953 1.479 1.322 2.441.972 1.279-.464 1.772-2.002 1.548-3.343a6.4 6.4 0 0 1-.067-1.566c.113-1.38.676-2.717 1.636-3.885.961-1.168 2.289-2.13 3.86-2.796a12.9 12.9 0 0 1 3.374-.903c.963-.124 1.754-.9 1.754-1.872"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.02 8.635h5.055c1.976 0 3.3 1.413 3.3 3.514V17c0 2.096-1.324 3.509-3.3 3.509H17.02c-1.977 0-3.306-1.413-3.306-3.508v-4.85c0-2.102 1.33-3.515 3.306-3.515m2.192 7.711 2.77-2.82a.53.53 0 0 0 0-.736.507.507 0 0 0-.723 0l-2.408 2.452-1.02-1.039a.507.507 0 0 0-.724 0 .53.53 0 0 0 0 .736l1.388 1.407c.099.101.227.149.355.149a.5.5 0 0 0 .362-.149"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgClaimed
