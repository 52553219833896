import addAnalytics from './addAnalytics'
import addImageByUid from './addImageByUid'
import createUserByUid from './createUserByUid'
import deleteUserByUid from './deleteUserByUid'
import editImageByUid from './editImageByUid'
import getCodeByPhone from './getCodeByPhone'
import getCurrentProfileOrRedirect from './getCurrentProfileOrRedirect'
import getProfilesByUid from './getProfilesByUid'
import getUser from './getUser'
import getUserByCustomFieldForWhitelabel from './getUserByCustomFieldForWhitelabel'
import getUserByUrl from './getUserByUrl'
import getUsersByHashtag from './getUsersByHashtag'
import putCurrentUser from './putCurrentUser'
import removeImageByUid from './removeImageByUid'
import sendCodeVerificationByPhone from './sendCodeVerificationByPhone'
import sendWelcomeEmail from './sendWelcomeEmail'
import verifyEmailCode from './verifyEmailCode'
import verifyUserByUid from './verifyUserByUid'
import { api } from '@/Services/api'
import getTokenAuth from './getTokenAuth'

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    getUser: getUser(build),
    putCurrentUser: putCurrentUser(build),
    createUserByUid: createUserByUid(build),
    deleteUserByUid: deleteUserByUid(build),
    getUserByUrl: getUserByUrl(build),
    getUsersByHashtag: getUsersByHashtag(build),
    getProfilesByUid: getProfilesByUid(build),
    verifyUserByUid: verifyUserByUid(build),
    verifyEmailCode: verifyEmailCode(build),
    getCurrentProfileOrRedirect: getCurrentProfileOrRedirect(build),
    addAnalytics: addAnalytics(build),
    addImageByUid: addImageByUid(build),
    editImageByUid: editImageByUid(build),
    removeImageByUid: removeImageByUid(build),
    getUserByCustomFieldForWhitelabel: getUserByCustomFieldForWhitelabel(build),
    getCodeByPhone: getCodeByPhone(build),
    sendWelcomeEmail: sendWelcomeEmail(build),
    sendCodeVerificationByPhone: sendCodeVerificationByPhone(build),
    getTokenAuth: getTokenAuth(build),
  }),
  overrideExisting: true,
})

export const {
  useLazyGetUserByCustomFieldForWhitelabelQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useCreateUserByUidMutation,
  usePutCurrentUserMutation,
  useDeleteUserByUidMutation,
  useLazyGetUserByUrlQuery,
  useGetUserByUrlQuery,
  useGetUsersByHashtagQuery,
  useGetProfilesByUidQuery,
  useVerifyUserByUidMutation,
  useVerifyEmailCodeMutation,
  useGetCurrentProfileOrRedirectQuery,
  useAddAnalyticsMutation,
  useAddImageByUidMutation,
  useEditImageByUidMutation,
  useRemoveImageByUidMutation,
  useGetUserByCustomFieldForWhitelabelQuery,
  useLazyGetCodeByPhoneQuery,
  useSendWelcomeEmailMutation,
  useSendCodeVerificationByPhoneMutation,
  useLazyGetTokenAuthQuery,
} = userApi
