import React from 'react'
import styled, { css } from 'styled-components'
import { getFontsizeByLength } from './utils'

const StyledContact = styled.span`
  ${({ length }) => css`
    font-size: ${getFontsizeByLength(length)};
    color: #3c3f42;
    text-decoration: none;
  `}
`

export const Contact = ({ children, ...props }) => {
  return (
    <StyledContact length={children.length} {...props}>
      {children}
    </StyledContact>
  )
}
