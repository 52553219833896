import * as React from 'react'
const SvgTrees = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 103})`}>
      <path fill="#93642D" d="M15.728 27.013h3.909l3.185 30.81h-10.33z" />
      <path fill="#714D22" d="M15.7 27.013h3.883l1.689 16.583h-7.26z" />
      <path
        fill="#66A865"
        fillRule="evenodd"
        d="M21.015 12.873a6.402 6.402 0 0 1 9.533 5.585v.626q0 .323-.031.637a6.405 6.405 0 0 1 4.604 6.146v.626a6.4 6.4 0 0 1-6.099 6.395 6.17 6.17 0 0 1-1.36 4.61l-.295.36a6.02 6.02 0 0 1-7.483 1.51 6 6 0 0 1-.335.448l-.296.36a6.02 6.02 0 0 1-8.551.787c-1.972-1.66-2.646-4.315-1.9-6.638A6.332 6.332 0 0 1 .05 28.472v-.619a6.334 6.334 0 0 1 4.997-6.19 6 6 0 0 1-.021-.519v-.619a6.33 6.33 0 0 1 6.102-6.327 5.255 5.255 0 0 1 9.887-1.325"
        clipRule="evenodd"
      />
      <path
        fill="#83C082"
        fillRule="evenodd"
        d="M11.391 13.755a4.19 4.19 0 0 1 5.509-2.283 4.28 4.28 0 0 1 2.303 5.558l-.144.353a4 4 0 0 1-.186.395 4.285 4.285 0 0 1 1.272 4.943l-.144.352a4.19 4.19 0 0 1-5.509 2.283 4.26 4.26 0 0 1-2.387-2.555 4.18 4.18 0 0 1-3.472.099 4.28 4.28 0 0 1-2.303-5.558l.144-.353a4.19 4.19 0 0 1 4.65-2.54q.054-.172.123-.34z"
        clipRule="evenodd"
      />
      <path
        fill="#458244"
        fillRule="evenodd"
        d="M.952 24.771A6.3 6.3 0 0 0 0 28.111v.62a6.332 6.332 0 0 0 8.752 5.852c-.747 2.323-.074 4.978 1.899 6.639a6.02 6.02 0 0 0 8.55-.788l.296-.36q.18-.218.335-.447a6.02 6.02 0 0 0 7.483-1.51l.296-.36a6.17 6.17 0 0 0 1.36-4.61 6.4 6.4 0 0 0 6.099-6.395v-.626c0-.64-.094-1.259-.27-1.842a6.4 6.4 0 0 1-5.153 3.046 6.17 6.17 0 0 1-1.36 4.611l-.296.36a6.02 6.02 0 0 1-7.483 1.51 6 6 0 0 1-.335.447l-.296.36a6.02 6.02 0 0 1-8.551.787c-1.972-1.66-2.646-4.315-1.899-6.638A6.334 6.334 0 0 1 .952 24.77"
        clipRule="evenodd"
      />
      <path fill="#93642D" d="M86.446 31.229H92v26.516h-5.554z" />
      <path fill="#714D22" d="M86.446 30.999h5.402v20.174h-5.402z" />
      <path
        fill="#66A865"
        fillRule="evenodd"
        d="M81.775 29.147a7.817 7.817 0 0 1 15.633 0v.764q0 .393-.038.778a7.82 7.82 0 0 1 5.622 7.505v.764a7.817 7.817 0 0 1-13.68 5.17 7.817 7.817 0 0 1-13.68-5.17v-.764A7.82 7.82 0 0 1 81.8 30.55a8 8 0 0 1-.025-.64z"
        clipRule="evenodd"
      />
      <path
        fill="#83C082"
        fillRule="evenodd"
        d="M82.631 15.665a6.775 6.775 0 0 1 13.55 0v.662q0 .341-.034.675a6.78 6.78 0 0 1 4.872 6.504v.661a6.774 6.774 0 0 1-11.855 4.482 6.775 6.775 0 0 1-11.856-4.481v-.662a6.78 6.78 0 0 1 5.345-6.624 7 7 0 0 1-.022-.555z"
        clipRule="evenodd"
      />
      <path
        fill="#458244"
        fillRule="evenodd"
        d="M75.839 36.47a7.82 7.82 0 0 0 13.482 3.416 7.82 7.82 0 0 0 13.482-3.417q.195.848.197 1.754v.764a7.817 7.817 0 0 1-13.68 5.17 7.817 7.817 0 0 1-13.68-5.17v-.764q.004-.906.199-1.754"
        clipRule="evenodd"
      />
      <path fill="#93642D" d="M53.545 27.189h3.908l3.186 30.81h-10.33z" />
      <path fill="#714D22" d="M53.517 27.189H57.4l1.688 16.583h-7.26z" />
      <path
        fill="#83C082"
        d="M67.214 40.958c3.897.398 1.315-4.348-.569-8.567s-5.958-12.157-12.212-12.157c-5.126 0-14.838 18.624-12.563 20.377 1.82 1.402 6.09.302 8.933-1.905.853 1.947 2.141 2.858 4.188 3.014s3.24-1.262 4.852-3.014c.568.65 3.972 1.905 7.371 2.252"
      />
      <path
        fill="#458244"
        fillRule="evenodd"
        d="M66.48 31.919c-2.35-4.681-6.41-10.885-12.173-10.885-2.964 0-7.341 5.735-10.413 11.386 2.09-.215 4.573-1.234 6.47-2.706.927 2.115 2.448 4.315 4.672 4.484 2.224.17 3.883-2.038 5.15-4.484.52.595 3.237 1.66 6.293 2.205"
        clipRule="evenodd"
      />
      <path
        fill="#66A865"
        d="M67.214 30.236c3.73 1.211 1.315-4.347-.569-8.567S60.688 9.512 54.433 9.512c-5.126 0-14.838 18.625-12.563 20.377 1.82 1.402 6.09-2.034 8.933-4.24.853 1.946 2.141 5.194 4.188 5.35s3.24-3.598 4.852-5.35c.568.648 3.972 3.483 7.371 4.587"
      />
      <path
        fill="#458244"
        fillRule="evenodd"
        d="M64.721 17.332c.903 1.946 1.563 3.37 1.457 3.36-2.889-.294-6.238-1.585-6.721-2.135-.99 1.907-2.513 3.122-4.252 2.99-1.74-.132-3.327-1.6-4.052-3.248-2.416 1.869-6.899 2.564-6.584 2.051a30 30 0 0 0 1.52-2.865c2.882-4.673 6.39-8.76 8.894-8.76 3.023 0 5.578 1.707 7.663 4.006q.16.412.325.78c.595 1.33 1.215 2.668 1.75 3.821"
        clipRule="evenodd"
      />
      <path
        fill="#83C082"
        d="M66.01 17.615c2.7.94.685-3.91-.844-7.352C63.636 6.822 60.609.37 55.543.005c-4.738-.34-13.327 15.668-11.48 17.097 1.477 1.143 5.39-1.11 7.7-2.91.692 1.587 1.738 4.236 3.4 4.363s2.632-2.934 3.94-4.364c.462.53 4.498 2.585 6.907 3.424"
      />
    </g>
  </svg>
)
export default SvgTrees
