import * as React from 'react'
const SvgSeedReward = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 155})`}>
      <path
        fill="#133441"
        d="M98.727 102.213a1.41 1.41 0 0 1-.475-2.738c12.809-4.61 18.99-15.307 19.054-15.417a1.408 1.408 0 0 1 2.45 1.388c-.268.476-6.72 11.707-20.554 16.682a1.4 1.4 0 0 1-.475.085"
      />
      <path
        fill="#133441"
        d="M124.593 88.541q-.065-.001-.134-.007c-.18-.018-4.425-.444-6.755-3.175-2.676-3.133-2.317-6.473-2.303-6.61a1.41 1.41 0 0 1 2.799.317c-.011.112-.194 2.311 1.644 4.46 1.573 1.844 4.847 2.2 4.879 2.203a1.408 1.408 0 0 1-.13 2.812"
      />
      <path
        fill="#133441"
        d="M119.51 86.162c-.764 0-1.419-.26-1.877-.895-1.429-1.97 1.081-4.665 3.436-6.582a1.41 1.41 0 0 1 1.778 2.188c-.824.67-1.634 1.476-2.169 2.128.813-.36 1.88-.958 2.809-1.61a1.41 1.41 0 0 1 1.613 2.311c-1.782 1.248-3.939 2.46-5.59 2.46M29.267 114.455q-.132.001-.264-.025c-15.85-3.019-22.497-13.33-22.771-13.767a1.41 1.41 0 0 1 .44-1.945 1.41 1.41 0 0 1 1.943.444c.06.091 6.325 9.718 20.913 12.499a1.407 1.407 0 0 1 1.12 1.649 1.41 1.41 0 0 1-1.38 1.145"
      />
      <path
        fill="#133441"
        d="M1.75 104.521a1.41 1.41 0 0 1-.327-2.78c.032-.007 3.218-.803 4.53-2.861 1.532-2.407 1.014-4.613 1.007-4.634a1.41 1.41 0 0 1 1.004-1.723 1.41 1.41 0 0 1 1.72 1.004c.036.138.856 3.394-1.358 6.868-1.929 3.031-6.076 4.045-6.248 4.088a1.4 1.4 0 0 1-.328.038"
      />
      <path
        fill="#133441"
        d="M5.827 99.394h.004zm.278 2.086c-1.58 0-3.605-.814-5.333-1.691a1.41 1.41 0 1 1 1.274-2.516c1.01.514 2.144.955 3 1.198a16.3 16.3 0 0 0-2.436-1.797 1.41 1.41 0 0 1 1.453-2.417c2.602 1.568 5.46 3.89 4.32 6.039-.45.853-1.275 1.184-2.278 1.184M101.177 140.369c-.458 0-.902-.222-1.173-.627-.056-.085-5.931-8.704-14.703-9.602a1.411 1.411 0 0 1 .288-2.805c7.924.814 13.56 6.772 15.753 9.5.173-.24.356-.536.539-.899a1.405 1.405 0 0 1 1.89-.62c.697.349.975 1.198.623 1.892-1.077 2.136-2.394 2.907-2.538 2.988a1.4 1.4 0 0 1-.679.173M54.802 155a1.409 1.409 0 0 1-.091-2.816c.38-.024.707-.074.986-.133-1.37-2.464-3.595-7.665-2.148-13.588a1.4 1.4 0 0 1 1.7-1.036 1.41 1.41 0 0 1 1.035 1.705c-1.661 6.808 2.574 12.69 2.616 12.749.45.614.334 1.473-.264 1.942-.13.102-1.355 1.015-3.739 1.173q-.047.005-.095.004"
      />
      <path
        fill="green"
        d="M80.122 18.801c-.776-5.3-5.184-11.974-12.716-13.971-7.997-2.124-16.186.226-21.266 8.73a26.6 26.6 0 0 0-2.773 6.49c-2.136-4.286-6.415-10.294-13.98-11.65-10.773-1.92-17.646 3.919-19.842 9.017-3.007 6.964-1.199 13.012 3.996 14.2 5.2 1.19 9.187-1.809 12.721-3.333 5.118-2.209 13.5-1.774 14.88 3.297.146 1.328.264 2.66.341 3.989-.126 1.782-3.314 10.019-4.249 11.518-1.27 2.044-2.487 3.312-1.618 4.561.737 1.058 2.26 1.532 3.663 1.64 4.424.344 5.175-.243 5.79-.907.746-.804 4.728-10.983 1.879-20.069-.705-6.51 6.997-7.157 9.157-7.046 4.353.235 10.888 2.28 15.017 3.143 9.36 1.952 9.56-5.76 9-9.609"
      />
      <path
        fill="#A4B46B"
        d="M23.379 17.811a16.5 16.5 0 0 1 1.851-2.18c.716-.713 2.272-1.407 2.414-2.515.2-1.488-2.147-1.518-3.073-1.522-.604-.007-1.21.02-1.808.086-1.082.114-2.15.343-3.172.699a12.9 12.9 0 0 0-4.7 2.869c-.976.942-1.842 2.062-2.38 3.315-1.264 2.91-1.455 5.744-.53 7.574.515 1.018 1.352 1.665 2.49 1.923 4.755 1.086 6.18-4.202 7.581-7.627.363-.893.768-1.828 1.327-2.622M49.4 15.063c-.35.95-.516 2.006-.067 2.77.325.545 1.253.889 1.876.784 1.842-.303 3.317-2.648 4.328-4.04.784-1.081 1.755-2.097 2.76-2.974.63-.55 1.458-1.17 1.725-2.004.492-1.539-1.948-1.1-2.669-.981-2.815.483-5.635 2.475-7.165 4.872-.27.42-.566.973-.788 1.572"
      />
      <path
        fill="#A37B65"
        d="M99.49 92.604c5.435 20.304-6.6 41.175-26.883 46.616s-41.133-6.611-46.568-26.915c-5.95-22.23 4.385-54.146 11.303-71.672 2.686-6.802 10.844-8.988 16.57-4.438 14.745 11.717 39.629 34.18 45.578 56.409"
      />
      <path
        fill="#75503F"
        fillOpacity={0.8}
        d="M72.798 135.606c-14.315 4.45-28.69-.859-32.103-11.86s5.429-23.528 19.743-27.978c14.318-4.451 28.692.858 32.105 11.859s-5.428 23.528-19.746 27.979"
      />
      <path
        fill="#fff"
        d="M43.253 41.153c-.123-.303-.57-.391-.83-.243-1.574.902-2.095 3.495-2.69 5.11a105 105 0 0 0-2.158 6.546c-.64 2.203-1.264 4.416-1.795 6.646-.45 1.896-1.648 4.704-.961 6.586.327.902 1.489 1.512 2.277.68 1.444-1.522 1.497-4.263 1.968-6.258.525-2.238 1.042-4.475 1.616-6.702q.86-3.34 1.735-6.674c.454-1.73 1.535-3.958.838-5.691M34.777 69.872c-1.018-.04-1.666.736-1.796 1.666l-.221 1.572a9 9 0 0 0-.3 2.11c-.014.91.7 1.713 1.623 1.749 1.007.035 1.595-.72 1.746-1.625l.612-3.675c.152-.927-.816-1.766-1.665-1.797"
      />
      <path
        fill="#AB1700"
        d="M73.024 112.595c2.933 1.871 2.422 7.294-4.516 9.546s-10.303-2.34-9.24-5.085c.612-1.572 3.147-1.709 6.674-2.851 3.524-1.145 5.625-2.537 7.082-1.61"
      />
      <path
        fill="#fff"
        d="M72.187 113.377c.186.581-2.211 1.882-5.358 2.904-3.147 1.018-5.854 1.374-6.04.792-.19-.581 2.407-1.265 5.554-2.287 3.147-1.021 5.653-1.99 5.844-1.409"
      />
      <path
        fill="#FD6C6C"
        d="M63.179 121.739c1.291.321 2.999.275 5.1-.409 2.355-.764 3.893-1.853 4.76-2.981-5.277-2.579-8.368.786-9.86 3.39"
      />
      <path
        fill="#fff"
        d="M36.34 109.72c1.732 5.377 7.498 8.327 12.873 6.59 5.379-1.738 8.332-7.503 6.6-12.876-1.735-5.378-7.498-8.327-12.876-6.59-5.376 1.737-8.332 7.502-6.597 12.876"
      />
      <path
        fill="#133441"
        d="M51.519 109.188c-.42.856-1.335 1.272-2.05.927-.71-.346-.95-1.322-.53-2.182.415-.859 1.333-1.275 2.044-.93.715.349.951 1.325.536 2.185m-6.474-3.076c-.722 1.483-2.306 2.202-3.538 1.603s-1.643-2.287-.922-3.771c.722-1.483 2.306-2.202 3.538-1.603 1.232.596 1.644 2.287.922 3.771m7.73-1.699a7.04 7.04 0 0 0-8.867-4.539c-3.707 1.199-5.741 5.17-4.548 8.87a7.04 7.04 0 0 0 8.87 4.538 7.05 7.05 0 0 0 4.545-8.869"
      />
      <path
        fill="#fff"
        d="M68.2 99.755c1.732 5.374 7.498 8.323 12.873 6.59 5.38-1.738 8.333-7.502 6.6-12.88-1.735-5.374-7.497-8.323-12.876-6.59-5.375 1.738-8.332 7.503-6.597 12.88"
      />
      <path
        fill="#133441"
        d="M83.767 98.846c-.42.86-1.334 1.276-2.046.93-.714-.345-.954-1.325-.535-2.18.42-.86 1.334-1.276 2.046-.931.714.349.954 1.325.535 2.181m-6.879-3.126c-.9 1.382-2.562 1.896-3.71 1.146-1.147-.747-1.345-2.474-.44-3.855.901-1.382 2.563-1.896 3.71-1.15 1.148.751 1.345 2.478.44 3.86m7.748-1.275a7.04 7.04 0 0 0-8.867-4.535 7.05 7.05 0 0 0-4.548 8.87 7.045 7.045 0 0 0 8.87 4.534 7.05 7.05 0 0 0 4.545-8.87M68.585 83.461c.694 1.674 3.947 1.005 7.2-.43 2.914-1.282 5.864-2.272 5.346-4.623-1.07-4.838-14.633.014-12.546 5.053M32.644 92.751c.753 2.284 3.798 1.621 6.956 1.23 3.527-.437 6.663-1.53 6.403-3.32-.785-5.398-14.915-2.614-13.36 2.09"
      />
      <path
        fill="#543B2F"
        d="M93.576 105.716c.349 1.268-1.285 2.822-3.654 3.474-2.365.656-4.565.155-4.914-1.11s1.289-2.822 3.654-3.474 4.566-.155 4.914 1.11M47.777 120.333c.348 1.269-1.289 2.823-3.654 3.475s-4.566.155-4.914-1.11c-.349-1.269 1.285-2.823 3.65-3.475 2.37-.651 4.57-.155 4.918 1.11"
      />
    </g>
  </svg>
)
export default SvgSeedReward
