import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import AvatarShape from './AvatarShape'
import BtnStyle from './BtnStyle'
import HideInformation from './HideInformation'
import ReplicateProfile from './ReplicateProfile'
import ThemeColor from './ThemeColor'
import { useLockedField } from '@/Hooks/useLockedField'
import { useMemo } from 'react'
export const useGetOptions = () => {
  const { isFieldDisabled } = useLockedField()

  const options = useMemo(
    () => [
      {
        name: 'background-color',
        title: 'edit_profil.appearance.color_theme.title',
        component: props => <ThemeColor {...props} />,
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch variant="secondary" fieldName={fieldName} />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'background-color' }),
      },
      {
        name: 'shape-avatar',
        title: 'edit_profil.appearance.shape_avatar.title',
        component: props => <AvatarShape {...props} />,
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch variant="secondary" fieldName={fieldName} />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'shape-avatar' }),
      },
      {
        name: 'btnShape',
        title: 'edit_profil.appearance.btn_style.title',
        component: props => <BtnStyle {...props} />,
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch variant="secondary" fieldName={fieldName} />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'btnShape' }),
      },
      {
        name: 'hide-information',
        title: 'edit_profil.appearance.hide_informations.title',
        component: props => <HideInformation {...props} />,
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch variant="secondary" fieldName={fieldName} />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'hide-information' }),
      },
      {
        name: 'replicate-profile',
        title: 'edit_profil.appearance.replica_profil.title',
        component: props => <ReplicateProfile {...props} />,
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch variant="secondary" fieldName={fieldName} />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'replicate-profile' }),
      },
    ],
    [isFieldDisabled],
  )

  return options
}
