import * as React from 'react'
const SvgSocialInstagram = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <rect
        width={28}
        height={28}
        x={2}
        y={2.75}
        fill="url(#socialInstagram_svg__a)"
        rx={6}
      />
      <rect
        width={28}
        height={28}
        x={2}
        y={2.75}
        fill="url(#socialInstagram_svg__b)"
        rx={6}
      />
      <rect
        width={28}
        height={28}
        x={2}
        y={2.75}
        fill="url(#socialInstagram_svg__c)"
        rx={6}
      />
      <path fill="#fff" d="M23 11.25a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16 21.75a5 5 0 1 0 0-10 5 5 0 0 0 0 10m0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6 16.35c0-3.36 0-5.04.654-6.324a6 6 0 0 1 2.622-2.622C10.56 6.75 12.24 6.75 15.6 6.75h.8c3.36 0 5.04 0 6.324.654a6 6 0 0 1 2.622 2.622C26 11.31 26 12.99 26 16.35v.8c0 3.36 0 5.04-.654 6.324a6 6 0 0 1-2.622 2.622c-1.283.654-2.964.654-6.324.654h-.8c-3.36 0-5.04 0-6.324-.654a6 6 0 0 1-2.622-2.622C6 22.19 6 20.51 6 17.15zm9.6-7.6h.8c1.713 0 2.878.002 3.778.075.877.072 1.325.202 1.638.361a4 4 0 0 1 1.748 1.748c.16.313.29.761.36 1.638.074.9.076 2.065.076 3.778v.8c0 1.713-.002 2.878-.075 3.778-.072.877-.202 1.325-.361 1.638a4 4 0 0 1-1.748 1.748c-.313.16-.761.29-1.638.36-.9.074-2.065.076-3.778.076h-.8c-1.713 0-2.878-.002-3.778-.075-.877-.072-1.325-.202-1.638-.361a4 4 0 0 1-1.748-1.748c-.16-.313-.29-.761-.36-1.638-.074-.9-.076-2.065-.076-3.778v-.8c0-1.713.002-2.878.075-3.778.072-.877.202-1.325.361-1.638a4 4 0 0 1 1.748-1.748c.313-.16.761-.29 1.638-.36.9-.074 2.065-.076 3.778-.076"
        clipRule="evenodd"
      />
      <defs>
        <radialGradient
          id="socialInstagram_svg__a"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(-55.376 28.63 .44)scale(25.5196)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B13589" />
          <stop offset={0.793} stopColor="#C62F94" />
          <stop offset={1} stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="socialInstagram_svg__b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(-65.136 30.353 7.264)scale(22.5942)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset={0.445} stopColor="#FB8A2E" />
          <stop offset={0.715} stopColor="#E2425C" />
          <stop offset={1} stopColor="#E2425C" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="socialInstagram_svg__c"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(38.50003 -5.5 1.1764 8.23476 .5 3.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.157} stopColor="#406ADC" />
          <stop offset={0.468} stopColor="#6A45BE" />
          <stop offset={1} stopColor="#6A45BE" stopOpacity={0} />
        </radialGradient>
      </defs>
    </g>
  </svg>
)
export default SvgSocialInstagram
