import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineShieldVulnerable = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="m15.875 20.237.18-.124c.566-.391.849-.587 1.101-.796a7.5 7.5 0 0 0 2.603-4.476c.056-.323.086-.664.144-1.346l.03-.353a18 18 0 0 0-.028-3.392l-.036-.349a5.75 5.75 0 0 0-3.205-4.574 10.64 10.64 0 0 0-9.328 0A5.75 5.75 0 0 0 4.13 9.4l-.036.35a18 18 0 0 0-.029 3.391l.03.353c.06.682.089 1.023.145 1.346a7.5 7.5 0 0 0 2.603 4.476c.253.21.535.405 1.1.796l.18.124c.769.532 1.153.797 1.538.982a5.41 5.41 0 0 0 4.676 0c.385-.185.77-.45 1.537-.982Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="m13.768 10.982-3.536 3.536m3.536 0-3.536-3.536"
        />
      </g>
    </svg>
  )
}
export default SvgLineShieldVulnerable
