import React from 'react'
import { useEditBusinessCard } from '../Dashboard/Contexts/EditBusinessCardContext'
import DynamicTemplate from './DynamicTemplate'
import { useTranslation } from 'react-i18next'
import Text from '../Texts/Text'
import { useTheme } from '@/Hooks'
import { Spinner } from '../Spinner'

const BusinessCardPreview = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { selectedBusinessCard, lang, editBusinessCard } = useEditBusinessCard()

  if (selectedBusinessCard && !editBusinessCard) {
    return <Spinner />
  }

  return (
    <>
      {selectedBusinessCard && editBusinessCard ? (
        <DynamicTemplate businessCard={editBusinessCard} lang={lang} />
      ) : (
        <Text
          regular
          className="max-w-[75%] m-auto text-center"
          color={theme.colors.grey2}
        >
          {t('businessCard.no_business_card_selected')}
        </Text>
      )}
    </>
  )
}

export default BusinessCardPreview
