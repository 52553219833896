const create_image_AI_page = {
  generate_image_title: 'Generate an image',
  negative_prompt: 'Negative prompt',
  suggestion: 'Suggestions',
  placeholder: 'Write your prompt here...',
  picture_style: 'Image style',
  see_all: 'See all',
  optional: '(optional)',
  toast_error_title: 'Prompt interpretation failed',
  toast_error_subtitle:
    'The AI has limitations in generating text in images. Favor illustrations without text or with very brief and precise text.',
}

export default create_image_AI_page
