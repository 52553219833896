import getAllQuestsByCategory from './getAllQuestsByCategory'
import { api } from '@/Services/api'

export const userQuestsApi = api.injectEndpoints({
  endpoints: build => ({
    getAllQuestsByCategory: getAllQuestsByCategory(build),
  }),
  overrideExisting: true,
})

export const { useGetAllQuestsByCategoryQuery } = userQuestsApi
