import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineCloudCheck = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M12 20H6.737C4.12 20 2 17.851 2 15.2a4.8 4.8 0 0 1 3.164-4.529 6 6 0 0 1-.006-.271C5.158 6.865 8.22 4 12 4c3.549 0 6.467 2.527 6.809 5.762 1.38.6 2.456 1.78 2.929 3.238"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.182 18.333 17.364 21l3.818-5"
        />
      </g>
    </svg>
  )
}
export default SvgLineCloudCheck
