const previewpage = {
  title_tab_bottom: 'Vista previa',
  insight: 'Vista previa del perfil',
  my_contact: 'Mis contactos',
  aboutme: 'Acerca de mí',
  aboutus: 'Acerca de nosotros',
  activated_account: 'Este perfil aún no está activado',
  my_networks: 'Mis redes',
  Statistics: 'Estadísticas',
  companion: 'Compañero',
  news_tab_Publications: 'Publicaciones',
  report_title: 'Denunciar a este usuario',
  report_title_page: 'Señalar',
  no_publications: 'No hay publicaciones',
  see_this_profile: {
    seeProfileTitle: 'No se le permite ver este perfil',
    redirectedHomeTitle: 'Serás redirigido a la página de inicio...',
  },
  blocked_Profile: {
    text: 'Este perfil está bloqueado, Si desea acceder al contenido completo, por favor, desbloquear el perfil.',
  },
  follow: {
    pending: 'En espera de aprobación',
    add_contact: 'Agregar la tarjeta de contacto',
    followed: 'Agregado',
    no_follow: 'Agregar',
    following: 'Agregar a mi red',
    title1_page: 'Mi red',
    title2_page: 'Red',
  },
  newstab: {
    title_tab: 'Noticias',
  },
  preview_stat_tabs: {
    title_tabs1: 'seguidore',
    title_tabs2: 'seguido',
    title_tabs3: 'vista',
  },
  reportModal: {
    title: 'Gestionar las interacciones con este usuario',
    subtitle:
      'Al informar a este usuario, nos ayuda a mantener una comunidad respetuosa permitiéndonos moderar sus publicaciones. Además, si quieres dejar de ver su contenido, puedes optar por bloquearlo.',
    blockedTitleBtn1: 'Bloquear a este usuario',
    blockedTitleBtn2: 'Desbloquear este usuario',
  },
  planted: 'plantados',
}


export default previewpage
