const businessCard_placeholder = {
  businessName: "Nom de l'entreprise",
  fullName: 'Nom complet',
  job: 'Votre poste',
  phone: '+1234567890',
  email: 'votre@mail.com',
  website: 'votredomaine.com',
  address: 'Adresse',
  addressComplement: "Complément d'adresse",
  zipCode: 'Code postal',
  city: 'Ville',
  state: 'État',
  your_logo: 'VOTRE LOGO',
  title_screen_preview: 'Carte de visite',
}


export default businessCard_placeholder
