import { SocialUnsplash } from '@/Assets/icons/components'
import { OptionItem } from '@/Components/OptionItem'
import styled, { css } from 'styled-components'
import ModalSearchList from '../../ModalSearchList'
import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'

const RNImage = styled.img`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
  `}
`

const type = 'unsplash'
const originType = 'image'
const title = 'modal.imageOriginSelection.choice2.title'
const subtitle = 'modal.imageOriginSelection.choice2.subtitle'
const icon = SocialUnsplash

const useGetOnClick = ({ setIsParentOpen }) => {
  return () => setIsParentOpen(true)
}

const onFetch = async ({ query, page }) => {
  try {
    const url = `https://api.unsplash.com/search/photos?client_id=CHVzcf5tqWkKvAM28TIbCTXiM8tZ2wV0FyERb0gm2hA&query=${
      query || 'wallpaper'
    }&page=${page}&per_page=10`
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(response.status)
    }
    const responseObject = await response.json()
    const results = responseObject.results.map(result => {
      return { thumbnail: result.urls.small, url: result.urls.full }
    })
    return results
  } catch (error) {
    console.warn(error)
    return []
  }
}

const getId = item => {
  const splittedUrl = item.url.split('/')
  const slug = splittedUrl[splittedUrl.length - 1]
  const splittedSlug = slug.split('?')
  return splittedSlug[0].slice(6, splittedSlug[0].length)
}

const UnsplashItem = ({ onSelected, onDismiss, cosmetic, ...item }) => {
  return (
    <OptionItem
      onSelected={() => {
        onSelected(originType, item?.thumbnail, getId(item))
        onDismiss()
      }}
      cosmetic={cosmetic}
      id={getId(item)}
    >
      <RNImage className="h-full w-full" src={item?.thumbnail} />
    </OptionItem>
  )
}

const ModalUnsplash = ({ onSelected, ...props }) => {
  const { banner = [] } = useCosmetics()
  const cosmetic = banner.find(b => b.label === type)

  return (
    <ModalSearchList
      {...props}
      renderItem={params => (
        <UnsplashItem {...params} onSelected={onSelected} cosmetic={cosmetic} />
      )}
      onFetch={onFetch}
    />
  )
}

export const unsplash = {
  type,
  originType,
  title,
  subtitle,
  icon,
  useGetOnClick,
  parent: ModalUnsplash,
}
