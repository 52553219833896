import { StyleOptionReport } from './StyledOptionsReport'

export const OptionsReport = ({ text, openOption, setValueReport }) => {
  return (
    <StyleOptionReport
      onClick={() => {
        openOption(true)
        setValueReport(state => ({ ...state, option: text }))
      }}
    >
      <span> {text} </span>
    </StyleOptionReport>
  )
}
