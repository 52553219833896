import * as React from 'react'
const SvgSocialReddit = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <path
        fill="#FC471E"
        d="M16 2.75c-7.722 0-14 6.278-14 14s6.278 14 14 14 14-6.278 14-14-6.278-14-14-14"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20.02 9.66a1.6 1.6 0 0 0-.02.246c0 .848.672 1.536 1.5 1.536s1.5-.688 1.5-1.536-.672-1.535-1.5-1.535c-.37 0-.707.136-.968.363l-3.896-.984-1.408 5.765c-1.873.126-3.557.707-4.828 1.583a1.97 1.97 0 0 0-1.4-.585c-1.105 0-2 .917-2 2.047 0 .787.434 1.47 1.07 1.813Q8 18.74 8 19.12c0 3.11 3.582 5.63 8 5.63s8-2.52 8-5.63q0-.38-.07-.747A2.05 2.05 0 0 0 25 16.56c0-1.13-.895-2.047-2-2.047a1.97 1.97 0 0 0-1.4.585c-1.385-.955-3.26-1.558-5.335-1.606l1.099-4.5zM12.5 19.63c.828 0 1.5-.687 1.5-1.535s-.672-1.536-1.5-1.536-1.5.688-1.5 1.536.672 1.535 1.5 1.535m7 0c.828 0 1.5-.687 1.5-1.535s-.672-1.536-1.5-1.536-1.5.688-1.5 1.536.672 1.535 1.5 1.535m-6.723 1.622a.493.493 0 0 0-.693.142.52.52 0 0 0 .139.71c1.088.742 2.432 1.114 3.777 1.114s2.69-.372 3.777-1.114a.52.52 0 0 0 .139-.71.493.493 0 0 0-.693-.142c-.92.628-2.072.942-3.223.942-.683 0-1.366-.11-2-.331a5.2 5.2 0 0 1-1.223-.611"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgSocialReddit
