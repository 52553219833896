import React, { useMemo } from 'react'
import ProgressBar from '../Profile/ProgressBar'
import ModalDropsButton from './ModalDropsButton'
import { Pill } from './Pill'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useModalDownloadAppContext } from '../Profile/Contexts/ModalDownloadAppContext'
import { useTheme } from '@/Hooks'
import { LinePlus, LineTick, Tree } from '@/Assets/icons/components'

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  flex-shrink: 1;
`

export const Footer = ({ post }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { currentUser } = useSelector(state => state.user)
  const { openModalDownloadApp } = useModalDownloadAppContext()

  const onOpenModalDownloadApp = () => {
    const title = t('modal_download_app.download_the_budly_app')
    const subtitle = t('modal_download_app.explore_budly')
    openModalDownloadApp({ title, subtitle })
  }

  const mine = useMemo(
    () => post.userId === currentUser?.uid,
    [post.userId, currentUser?.uid],
  )

  const dropAlreadyAdded = useMemo(() => {
    return post.drops?.some(drop => drop.userId === currentUser?.uid)
  }, [post.drops, currentUser?.uid])

  return (
    <>
      <div className="shrink-0 h-[74px] w-full">
        <div className="mb-3 flex flex-row items-center w-full">
          <ProgressBar current={post.drops.length} goal={100} />
        </div>
        <StyledFooter>
          <div className="flex flex-row">
            <ModalDropsButton mine={mine} drops={post.drops} />
            <Pill
              className="cursor-pointer hover:opacity-75"
              onClick={onOpenModalDownloadApp}
            >
              {dropAlreadyAdded ? (
                <>
                  <LineTick className="mr-1" color={theme.colors.primary} />
                  <span color={theme.colors.primary}>
                    {t('others.watered')}
                  </span>
                </>
              ) : (
                <>
                  <LinePlus className="mr-1" />
                  <span>{t('others.water')}</span>
                </>
              )}
            </Pill>
          </div>
          <Pill>
            <Tree />
            <span>{post.treesCount}</span>
          </Pill>
        </StyledFooter>
      </div>
    </>
  )
}
