const shop = {
  screen_name: 'Shop',
  free_ressources: 'Free Deals',
  seeds_packages: 'Seeds packs',
  coins_packages: 'Coins packs',
  drops_packages: 'Drops packs',
  tickets_packages: 'Tickets packs',
  pack_title: 'Get {{quantity}} ',
  seed: 'seed',
  coin: 'coin',
  drop: 'drop',
  ticket: 'ticket',
  chest: 'chest',
  free_subtitle: 'Watch an ad to earn your {{resource}} for free',
  resources_subtitle: 'Get {{ resource }} to plant trees in real life.',
  subscriptions_subtitle: 'Budly Features',
  free_seed_button: 'Watch an ad',
  already_recovered: 'Already recovered',
  remaining: 'remaining',
  five_seeds_pack_subtitle:
    'Each purchase increases the money donated to projects...',
  fifty_seeds_pack_subtitle: 'And help the Budly Team develop ...',
  two_hundred_seeds_pack_subtitle: 'A lot of new features for you.',
  ticket_pack_subtitle: 'To play our mini-games.',
  chest_pack_subtitle: 'And win accessories for your companion.',
  tab_all: 'All',
  tab_free: 'Free',
  tab_coins: 'Coins',
  tab_resources: 'Resources',
  tab_products: 'Products',
  tab_subscriptions: 'Subscriptions',
  delivery: 'Delivery',
  order: 'Order',
  select: 'Select',
  subscribe: 'Subscribe',
  price: '{{ price }}€',
  resources_price: 'Starting from {{ price }}€',
  products_price: '{{ price }}€ / {{ product }}',
  duration: 'month',
  subscriptions_price: '{{ price }}€ / {{ duration }}',
  subscriptions_price_subtitle: '+ {{ price }}€ only one time',
  thanks_for_purchase_title: 'Thanks for your purchase!',
  thanks_for_purchase_description:
    'Your purchase has been made, make good use of it!',
  thanks_for_watching_add_description: 'Your reward has been awarded to you!',
  available_soon: 'Available soon',
  shop_creation_success: 'Your shop has been successfully created',
  cart: 'Cart',
  add_to_cart: 'Add to cart',
  cancel: 'Cancel',
  quantity: 'Quantity',
  total: 'Total expenses',
  pay_and_save_btn: 'Pay and save',
  monthly_subscription: 'Monthly subscription',
  monthly_benefits: 'Monthly benefits',
  badge_text: '€ of products',
  physical_products: 'of physical products',
  physical_products_offered: 'Physical products offered',
  product_added_success: 'The product has been successfully added to the cart!',
  sponsor: 'Sponsor',
  subscription_mandatory: 'Mandatory subscription',
  already_subscribed: 'Subscription already in progress',
}

export default shop
