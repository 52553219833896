import styled, { css } from 'styled-components'

const Badge = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.l};
  `}
`
export default Badge
