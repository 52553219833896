import * as React from 'react'
const SvgDiamond = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        fill="#1A96F0"
        d="m12 1.5 9.743 5.625v11.25L12 24l-9.743-5.625V7.125z"
      />
      <path fill="#fff" d="M3.45 7.913 12 3.075v5.4l-3.6 2.138z" />
    </g>
  </svg>
)
export default SvgDiamond
