import React from 'react'
import styled, { css } from 'styled-components'
import MaskedInput from 'react-text-mask'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import moment from 'moment'

export const InputMasked = ({ placeholder, onChange, type, ...props }) => {
  const { t } = useTranslation()
  return (
    <MaskedInput
      placeholder={t(placeholder)}
      guide={false}
      id="my-input-id"
      onChange={event => {
        if (type === 'date') {
          const value = event.target.value
          if (value.length === 10) {
            const valid = moment(value, 'DD/MM/YYYY').isValid()
            if (!valid) {
              toast.error("La date n'est pas valide")
              return onChange('')
            }
          }
        }
        onChange(event.target.value)
      }}
      {...props}
      render={(ref, props) => (
        <StyledTextInput
          ref={input => ref(input)}
          variant="primary"
          {...props}
        />
      )}
    />
  )
}

const StyledTextInput = styled.input`
  ${({ theme, variant, disabled, error }) => css`
    font-family: 'Poppins-Medium';
    color: ${variant === 'primary' ? theme.colors.black : theme.colors.grey3};
    background-color: ${variant === 'primary'
      ? theme.colors.grey1
      : theme.colors.white};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.std} ${theme.space.m};
    min-height: ${error ? theme.size.none : theme.size.m};
    height: ${error ? 'calc(100% - 8px)' : '100%'};
    opacity: ${disabled ? 0.5 : 1};
    outline: none;
    width: 100%;

    @media screen and (max-height: 732px) {
      padding: ${theme.space.s} ${theme.space.m};
      min-height: ${error ? theme.size.none : '40px'};
    }

    &::placeholder {
      color: ${theme.colors.grey2};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.grey1} inset !important;
    }
  `}
`
