import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import useCurrentMember from './useCurrentMember'

export const useLockedField = () => {
  const { currentUser } = useSelector(state => state.user)
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser() || {
    editCurrentUser: currentUser,
  }
  const { currentMember, isMultiMemberEdition } = useCurrentMember()
  const onLocked = async ({ e, setIsLocked = () => null, type, fieldName }) => {
    e.stopPropagation()
    if (!setEditCurrentUser) {
      return null
    }

    const isFieldNameIncluded =
      editCurrentUser.lockedFields?.[type]?.includes(fieldName)
    if (isFieldNameIncluded) {
      setIsLocked(false)
      const deleteLockedField = editCurrentUser.lockedFields[type].filter(
        lockedFieldName => lockedFieldName !== fieldName,
      )
      setEditCurrentUser(prev => {
        return {
          ...prev,
          lockedFields: {
            ...prev.lockedFields,
            [type]: deleteLockedField,
          },
        }
      })
      return
    }
    setIsLocked(true)
    setEditCurrentUser(prev => {
      return {
        ...prev,
        lockedFields: {
          ...(prev.lockedFields || {}),
          [type]:
            prev.lockedFields && prev.lockedFields[type]
              ? [...prev.lockedFields[type], fieldName]
              : [fieldName],
        },
      }
    })
  }

  const isFieldDisabled = useCallback(
    ({ type = 'user', fieldName }) => {
      if (!editCurrentUser?.uid) {
        return false
      }

      const isFirstNameOrLastName =
        fieldName === 'firstName' || fieldName === 'lastName'
      if (isMultiMemberEdition && isFirstNameOrLastName) {
        return true
      }

      if (currentMember) {
        return false
      }

      return editCurrentUser.lockedFields?.[type]?.includes?.(fieldName)
    },
    [
      editCurrentUser?.uid,
      editCurrentUser?.lockedFields,
      currentMember,
      isMultiMemberEdition,
    ],
  )

  return {
    onLocked,
    memberUserId: currentMember?.uid,
    isFieldDisabled,
  }
}
