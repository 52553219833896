import { createSlice } from '@reduxjs/toolkit'

const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        address: null,
        provider: null,
    },
    reducers: {
        setAccount: (state, action) => {
            state.address = action.payload.address
            state.provider = action.payload.provider
        },
        clearAccount: (state) => {
            state.address = null
            state.provider = null
        },
    },
})

export const { setAccount, clearAccount } = walletSlice.actions
export default walletSlice.reducer
