import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineNoWifi = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M14.245 16a.82.82 0 0 1-.468-.141 3.17 3.17 0 0 0-3.551 0c-.336.22-.814.167-1.069-.115-.254-.292-.193-.707.133-.928a4.9 4.9 0 0 1 5.413 0c.336.22.397.636.132.928-.132.168-.356.256-.59.256M15.833 13.843a.7.7 0 0 0 .446.157.67.67 0 0 0 .564-.285.73.73 0 0 0-.127-1.033c-1.25-.978-2.66-1.53-4.09-1.655l2.12 2.122q.563.286 1.087.694M7.15 13.715c.243.315.7.373 1.02.128 1.445-1.128 3.147-1.557 4.788-1.287l-1.534-1.533c-1.45.115-2.881.668-4.148 1.659a.75.75 0 0 0-.126 1.033"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M7.071 10.867a8.6 8.6 0 0 1 2.846-1.352L8.912 8.51c-.927.314-1.822.773-2.659 1.377a.6.6 0 0 0-.115.872c.222.266.64.316.933.108m9.851 0C15.11 9.561 12.98 9.045 10.917 9.32L9.844 8.245c2.674-.617 5.52-.07 7.896 1.642a.587.587 0 0 1 .116.872.67.67 0 0 1-.525.241.7.7 0 0 1-.409-.133"
          clipRule="evenodd"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M2.47 2.47a.75.75 0 0 1 1.06 0l18 18a.75.75 0 1 1-1.06 1.06l-18-18a.75.75 0 0 1 0-1.06"
          clipRule="evenodd"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M14.878 3.833a12.6 12.6 0 0 0-5.756 0 6.76 6.76 0 0 0-5.039 5.039 12.6 12.6 0 0 0 0 5.756 6.76 6.76 0 0 0 5.039 5.039c1.893.444 3.863.444 5.756 0a6.76 6.76 0 0 0 5.039-5.039 12.6 12.6 0 0 0 0-5.756 6.76 6.76 0 0 0-5.039-5.039M8.78 2.373a14.1 14.1 0 0 1 6.442 0 8.26 8.26 0 0 1 6.156 6.156 14.1 14.1 0 0 1 0 6.442 8.26 8.26 0 0 1-6.156 6.156 14.1 14.1 0 0 1-6.442 0 8.26 8.26 0 0 1-6.156-6.156 14.1 14.1 0 0 1 0-6.442 8.26 8.26 0 0 1 6.156-6.156"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
export default SvgLineNoWifi
