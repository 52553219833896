import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import formSteps from './FormSteps'
import { useSearchParams } from 'react-router-dom'

const OnboardingContext = createContext()

export const OnboardingProvider = ({ children }) => {
  const { currentUser } = useSelector(state => state.user)
  const [step, setStep] = useState(0)
  const [values, setValues] = useState({})
  const [searchParams] = useSearchParams()
  const isVerificationEmailByUser =
    searchParams.get('email_verification') || false

  const stepByParams = Boolean(isVerificationEmailByUser) ? 5 : step
  const formStep = formSteps.find(formStep => {
    return formStep.step === stepByParams
  })

  const value = useMemo(() => {
    return {
      values,
      setValues,
      step,
      setStep,
      formStep,
      isVerificationEmailByUser: Boolean(isVerificationEmailByUser),
    }
  }, [values, step, formStep, isVerificationEmailByUser])

  useEffect(() => {
    if (currentUser) {
      setValues({
        customUrl: currentUser?.customUrl || '',
        about: currentUser?.about || '',
        firstName: currentUser?.firstName || '',
        lastName: currentUser?.lastName || '',
        businessName: currentUser?.businessName || '',
        job: currentUser?.job || '',
        sponsorId: currentUser?.sponsorId || '',
        birthday: currentUser?.birthday || '',
        tags: currentUser?.tags || [],
        businessMode: currentUser?.businessMode || false,
        description: currentUser?.description || '',
        photoURL: currentUser?.photoURL || '',
        logoURL: currentUser?.logoURL || '',
        banner: currentUser?.banner || null,
        hashtags: currentUser?.hashtags || [],
        code: '',
      })
    }
  }, [currentUser])

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  )
}

export function useOnboardingContext() {
  return useContext(OnboardingContext)
}
