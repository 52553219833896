export default build =>
  build.query({
    query: uid => {
      return {
        url: `/users/${uid}/companions`,
        method: 'GET',
      }
    },
    providesTags: ['Companions'],
  })
