const getCodeByPhone = build =>
  build.query({
    query: phone => {
      return {
        url: `/users/send-code/${phone}`,
        method: 'GET',
      }
    },
  })

export default getCodeByPhone
