import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgTablet = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M18.334 4h-12a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1m-12-2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3z"
          clipRule="evenodd"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M13.334 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        />
      </g>
    </svg>
  )
}
export default SvgTablet
