const getDetailsTreesPlantByUser = build =>
  build.query({
    query: uid => {
      return {
        url: `/trees/${uid}/details-planted-trees`,
        method: 'GET',
      }
    },
    providesTags: ['DetailsTrees'],
  })

export default getDetailsTreesPlantByUser
