import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineEdit2 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M17.25 11.742c-2.121.707-4.95-2.121-4.242-4.242m.871-.871-4.57 4.57a15.5 15.5 0 0 0-4.077 7.2l-.22.884a.376.376 0 0 0 .455.455l.883-.22a15.5 15.5 0 0 0 7.202-4.078l4.57-4.57a3 3 0 1 0-4.243-4.241Z"
        />
      </g>
    </svg>
  )
}
export default SvgLineEdit2
