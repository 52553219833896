import { StyledContent } from './StyledPanel'
import { ModalFooter } from '@/Components/Modals/ModalWrapper/ModalFooter'
import { PanelHeader } from '../PanelHeader'
import { throttle } from 'lodash'

export const PanelContent = ({
  loading,
  title,
  subtitle,
  actions,
  children,
  onDismiss,
  renderCustomFooter,
  center = true,
  onScroll = () => null,
}) => {
  return (
    <>
      <PanelHeader
        title={title}
        subtitle={subtitle}
        onDismiss={onDismiss}
        center={center}
      />
      <StyledContent onScroll={onScroll}>{children && children}</StyledContent>
      <ModalFooter
        renderCustomFooter={renderCustomFooter}
        actions={actions}
        onDismiss={onDismiss}
        loading={loading}
      />
    </>
  )
}
