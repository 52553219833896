import { useTheme } from '@/Hooks'
import { backgroundIsLight } from '@/Utils/transformColor'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Spinner } from '../Spinner'
import { TouchableOpacity } from './TouchableOpacity'

const commonStyles = css`
  ${({ theme, color, variant, disabled }) => `
    height: 100%;
    cursor: ${disabled ? 'default' : 'pointer'} !important;
    flex: 1;
    flex-shrink: 0;
    padding: 12px;
    background: ${
      variant === 'primary' ? color || theme.colors.primary : 'transparent'
    };
    color: ${
      variant === 'primary'
        ? backgroundIsLight(color)
          ? theme.colors.black
          : theme.colors.white
        : theme.colors.primary
    };
    border: ${
      variant === 'secondary' ? `2px solid ${theme.colors.primary}` : 'none'
    };
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.m};
    opacity: ${disabled ? 0.3 : 1};
    gap: 8px;

    &:hover {
      opacity: ${disabled ? 0.5 : 0.8};
    }
  `}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  ${commonStyles}
`

const StyledButton = styled(TouchableOpacity)`
  display: flex;
  ${commonStyles}
`

const StyledText = styled.span`
  ${({ theme, isDarkMode }) => css`
    flex-shrink: 1;
    color: ${props =>
      props.variant === 'primary'
        ? backgroundIsLight(props.color, isDarkMode)
          ? theme.colors.black
          : theme.colors.white
        : theme.colors.primary};
    white-space: nowrap;
    flex-grow: 1;
  `}
`

export const Button = ({
  onClick,
  icon: Icon,
  label,
  loading = false,
  variant = 'primary',
  afterIcon: AfterIcon,
  disabled = false,
  type = 'button',
  ...props
}) => {
  const { theme, isDarkMode } = useTheme()
  const Component = type === 'link' ? StyledLink : StyledButton
  const handleClick = e => {
    e.preventDefault()
    onClick(e)
  }

  return (
    <Component
      onClick={onClick ? handleClick : undefined}
      variant={variant}
      disabled={loading || disabled}
      color={props.color || theme.colors.primary}
      type={type}
      isDarkMode={isDarkMode}
      {...props}
    >
      {loading && (
        <Spinner
          className="opacity-100 absolute"
          size={20}
          color={
            variant === 'primary'
              ? backgroundIsLight(props.color)
                ? theme.colors.black
                : theme.colors.white
              : theme.colors.primary
          }
        />
      )}
      <div
        className={clsx(
          loading && 'opacity-0',
          'flex items-center justify-center',
        )}
      >
        {Icon && (
          <Icon
            className={label ? 'mr-2' : ''}
            color={
              variant === 'primary'
                ? backgroundIsLight(
                    props.color || theme.colors.primary,
                    isDarkMode,
                  )
                  ? theme.colors.black
                  : theme.colors.white
                : theme.colors.primary
            }
          />
        )}
        <div className="flex flex-row items-center">
          {label && (
            <StyledText
              variant={variant}
              color={props.color || theme.colors.primary}
              isDarkMode={isDarkMode}
            >
              {label}
            </StyledText>
          )}
          {AfterIcon && (
            <AfterIcon
              className="ml-2"
              color={
                variant === 'primary'
                  ? backgroundIsLight(
                      props.color || theme.colors.primary,
                      isDarkMode,
                    )
                    ? theme.colors.black
                    : theme.colors.white
                  : theme.colors.primary
              }
            />
          )}
        </div>
      </div>
    </Component>
  )
}
