const publication_modal = {
  title: 'Publicación',
  title2: 'Modificar una publicación',
  write_publish: 'Escribir una publicación...',
  hashtags: 'Agrega un hashtag',
  publish: 'Publicar',
  description_price: 'Costo de descripción',
  confirm: 'Confirmar',
}


export default publication_modal
