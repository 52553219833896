import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLinePreference = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 14.7c0 3.483-2.817 6.3-6.3 6.3l.945-1.575M3 9.3C3 5.817 5.817 3 9.3 3l-.945 1.575m4.18 4.315a7.4 7.4 0 0 1 0-3.388 4.17 4.17 0 0 1 3.11-3.11 7.4 7.4 0 0 1 3.388 0 4.17 4.17 0 0 1 3.11 3.11 7.4 7.4 0 0 1 0 3.388 4.17 4.17 0 0 1-3.11 3.11 7.4 7.4 0 0 1-3.388 0 4.17 4.17 0 0 1-3.11-3.11M1.892 18.5a7.4 7.4 0 0 1 0-3.388 4.17 4.17 0 0 1 3.11-3.11 7.4 7.4 0 0 1 3.388 0 4.17 4.17 0 0 1 3.11 3.11 7.4 7.4 0 0 1 0 3.388 4.17 4.17 0 0 1-3.11 3.11 7.4 7.4 0 0 1-3.388 0 4.17 4.17 0 0 1-3.11-3.11"
        />
      </g>
    </svg>
  )
}
export default SvgLinePreference
