import React from 'react'
import ProfileItem from '@/Components/ProfileItem'

const FollowersListItem = ({ item }) => {
  return (
    <div className="flex flex-row items-center pr-3">
      <ProfileItem profile={item} />
    </div>
  )
}

export default FollowersListItem
