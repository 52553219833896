import * as React from 'react'
const SvgSocialVimeo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        d="M29.986 9.508q-.187 4.078-5.71 11.17-5.71 7.405-9.663 7.405-2.447 0-4.142-4.518l-2.258-8.283q-1.257-4.52-2.699-4.52-.313 0-2.197 1.318L2 10.386a369 369 0 0 0 4.079-3.64q2.76-2.384 4.141-2.51 3.264-.313 4.017 4.456.815 5.146 1.129 6.4.94 4.267 2.07 4.267.878 0 2.636-2.761 1.758-2.76 1.882-4.204.25-2.385-1.882-2.386-1.004 0-2.07.44 2.07-6.714 7.907-6.526 4.327.126 4.077 5.586"
        style={{
          fill: '#1ab7ea',
          strokeWidth: 0.0112026,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialVimeo
