import * as React from 'react'
const SvgSocialOpensea = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 1.28 1.28"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <circle
        cx={0.64}
        cy={0.64}
        r={0.56}
        fill="#2081e2"
        style={{
          strokeWidth: 0.0112,
        }}
      />
      <g fill="#fff">
        <path d="M.356.659.36.655.504.427a.005.005 0 0 1 .01 0C.536.483.557.55.548.593a.3.3 0 0 1-.035.072L.51.666H.36A.005.005 0 0 1 .357.66" />
        <path d="M1.006.701v.036l-.003.005a.2.2 0 0 0-.066.045C.896.844.864.925.794.925H.502A.19.19 0 0 1 .315.737V.734Q.315.73.32.73h.163q.005 0 .005.006a.06.06 0 0 0 .006.032.06.06 0 0 0 .051.031h.08V.736H.547A.005.005 0 0 1 .542.727L.545.723.574.677l.02-.04.003-.008.004-.012.003-.01A.2.2 0 0 0 .608.553L.606.538.604.523.599.502V.499L.593.484.58.439.572.422.562.399.558.39.553.38.55.373.54.355A.003.003 0 0 1 .544.35l.061.017.009.002.009.003h.003V.337C.626.318.64.304.658.304q.012 0 .022.01.01.008.01.022V.39l.006.002h.001l.007.006.008.006.027.024L.77.46l.01.01.008.012L.8.497l.005.007.017.03a.2.2 0 0 1 .01.034l.002.01A.1.1 0 0 1 .83.606L.828.618.823.63.811.654.805.662.8.671.784.69.772.704.765.712.758.719l-.01.01-.006.005-.004.002H.69v.062h.062A.06.06 0 0 0 .788.785C.792.78.808.768.827.747L.829.746l.17-.05a.005.005 0 0 1 .007.005" />
      </g>
    </g>
  </svg>
)
export default SvgSocialOpensea
