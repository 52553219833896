import * as React from 'react'
const SvgSeedJumping = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 60})`}>
      <path
        fill="#133441"
        d="M29.5 60a.54.54 0 0 1-.519-.406c-.206-.757-.187-1.378-.187-1.404a.55.55 0 0 1 .536-.536c.594-.004 2.233-.216 2.68-1.112.385-.773-.215-1.933-.787-2.77a.565.565 0 0 1 .131-.775.53.53 0 0 1 .751.134c1.082 1.585 1.373 2.903.863 3.921-.612 1.224-2.202 1.573-3.062 1.673.022.165.057.361.113.57a.56.56 0 0 1-.373.684.5.5 0 0 1-.146.021M12.523 58.623a.5.5 0 0 1-.25-.062c-.022-.013-.556-.303-1.093-.863a.566.566 0 0 1 .004-.785.53.53 0 0 1 .763.004q.125.13.243.232c.636-2.18 2.338-5.195 5.49-4.819a.554.554 0 0 1 .474.617.545.545 0 0 1-.599.488c-3.476-.416-4.492 4.688-4.503 4.74a.55.55 0 0 1-.529.448M38.242 41.316a.53.53 0 0 1-.402-.186.57.57 0 0 1 .043-.785c5.32-4.884 7.404-6.465 7.49-6.53a.53.53 0 0 1 .754.121.57.57 0 0 1-.118.776c-.02.016-2.14 1.626-7.409 6.463a.53.53 0 0 1-.358.141"
      />
      <path
        fill="#133441"
        d="m48.033 35.704-.039-.001c-.069-.006-1.699-.138-2.614-1.194-1.052-1.21-.943-2.53-.937-2.586a.545.545 0 0 1 .589-.5c.295.029.51.299.485.601-.002.046-.055.913.666 1.745.623.717 1.877.824 1.89.825.296.024.519.29.497.596a.546.546 0 0 1-.537.514"
      />
      <path
        fill="#133441"
        d="M46.05 34.81c-.28 0-.523-.098-.698-.336-.563-.765.374-1.849 1.262-2.624a.53.53 0 0 1 .759.064.567.567 0 0 1-.062.784c-.31.27-.611.594-.812.855a6.7 6.7 0 0 0 1.062-.658.53.53 0 0 1 .754.12.566.566 0 0 1-.117.777c-.681.514-1.512 1.018-2.148 1.018M11.327 43.42a.5.5 0 0 1-.278-.08c-6.036-3.766-8.407-4.95-8.43-4.962a.565.565 0 0 1-.252-.741.533.533 0 0 1 .72-.26c.097.047 2.426 1.211 8.52 5.012.255.16.336.501.183.764a.54.54 0 0 1-.463.268"
      />
      <path
        fill="#133441"
        d="M.707 39.742a.54.54 0 0 1-.52-.415.56.56 0 0 1 .384-.678c.012-.003 1.226-.343 1.711-1.166.564-.958.355-1.805.345-1.84a.56.56 0 0 1 .378-.682c.286-.08.58.087.66.38.016.055.357 1.33-.462 2.719C2.49 39.27.911 39.708.845 39.724a.5.5 0 0 1-.138.018"
      />
      <path
        fill="#133441"
        d="M2.322 38.508c-.6 0-1.361-.297-2.016-.623a.56.56 0 0 1-.252-.74.53.53 0 0 1 .718-.261c.393.194.83.36 1.161.447a6.3 6.3 0 0 0-.95-.689.564.564 0 0 1-.199-.758.53.53 0 0 1 .737-.206c1.008.596 2.122 1.485 1.704 2.344-.171.351-.5.486-.903.486"
      />
      <path
        fill="green"
        d="M37.598 5.25C37.022 3.222 34.994.86 32.03.492c-3.146-.39-6.128.977-7.602 4.576-.35.859-.588 1.762-.706 2.686-1.039-1.557-2.982-3.667-5.923-3.78-4.189-.16-6.485 2.497-7.047 4.608-.77 2.884.238 5.146 2.272 5.324 2.035.18 3.388-1.21 4.648-1.999 1.824-1.143 5.026-1.434 5.82.47q.19.766.341 1.539c.047.702-.724 4.093-.998 4.73-.374.867-.767 1.429-.371 1.869.335.372.938.474 1.476.44 1.696-.11 1.95-.38 2.149-.673.24-.355 1.208-4.547-.356-7.938-.614-2.503 2.273-3.178 3.098-3.253 1.664-.147 4.251.292 5.864.402 3.654.249 3.32-2.773 2.902-4.245"
      />
      <path
        fill="#A4B46B"
        d="M16.022 7.978q.252-.503.586-.953c.233-.318.787-.674.782-1.114-.004-.592-.896-.475-1.247-.426q-.346.045-.681.133a5.3 5.3 0 0 0-1.166.447 5 5 0 0 0-1.63 1.378c-.32.421-.59.906-.727 1.425-.324 1.205-.246 2.322.203 2.986q.375.555 1.046.614c1.862.163 2.121-1.98 2.47-3.394.09-.369.194-.756.364-1.096m9.724-2.502c-.082.39-.09.812.122 1.086.152.195.522.278.753.203.683-.22 1.117-1.216 1.427-1.815a7.2 7.2 0 0 1 .888-1.313c.21-.25.49-.537.548-.876.105-.628-.797-.323-1.065-.237-1.042.343-2.005 1.275-2.458 2.295-.08.179-.163.411-.215.657"
      />
      <path
        fill="#A37B65"
        d="M39.155 40.437c0 8.287-6.521 15.004-14.564 15.004s-14.564-6.718-14.564-15.004c0-9.88 8.283-22.163 12.356-27.604 1.135-1.517 3.281-1.517 4.416 0 4.073 5.441 12.356 17.723 12.356 27.604"
      />
      <path
        fill="#75503F"
        fillOpacity={0.8}
        d="M24.692 54.2c-5.741 0-10.397-3.682-10.397-8.223s4.656-8.222 10.397-8.222c5.743 0 10.398 3.68 10.398 8.222s-4.655 8.222-10.398 8.222"
      />
      <path
        fill="#fff"
        d="M23.442 15.1c-.015-.127-.171-.208-.283-.177-.672.183-1.12 1.117-1.5 1.67a40 40 0 0 0-1.448 2.273 55 55 0 0 0-1.322 2.348c-.355.675-1.075 1.623-1.008 2.41.033.377.4.727.776.49.683-.431.976-1.469 1.347-2.182.416-.798.829-1.597 1.262-2.386q.65-1.185 1.302-2.364c.34-.612.96-1.35.874-2.083M17.462 25.169c-.372-.118-.69.11-.83.451l-.238.577a3.6 3.6 0 0 0-.318.772c-.097.346.089.725.426.832.37.117.662-.111.808-.439l.59-1.339c.149-.336-.128-.756-.438-.854"
      />
      <path
        fill="#AB1700"
        d="M27.123 45.994c.743.928.019 2.654-2.428 2.654-2.449 0-3.05-1.867-2.426-2.654.357-.453 1.181-.227 2.426-.227 1.243 0 2.057-.233 2.428.227"
      />
      <path
        fill="#fff"
        d="M26.774 46.16c0 .213-.9.384-2.01.384-1.112 0-2.012-.171-2.012-.384 0-.21.9-.158 2.011-.158 1.11 0 2.01-.053 2.01.158"
      />
      <path
        fill="#FD6C6C"
        d="M23.034 47.948c.38.243.928.41 1.67.41.832 0 1.435-.194 1.828-.472-1.418-1.41-2.753-.634-3.498.062"
      />
      <path
        fill="#133441"
        d="M20.536 41.704c-.02-1.286-1.328-2.018-2.443-2.174-1.39-.196-2.717.296-3.546 1.478-.69.984.937 1.991 1.595.96.767-1.203 2.78-.72 3.688.04.263.217.713.096.706-.304M29.48 42.007c.91-.759 2.921-1.242 3.69-.04.656 1.03 2.284.026 1.593-.96-.829-1.181-2.154-1.673-3.546-1.477-1.115.155-2.421.886-2.443 2.174-.007.401.444.522.706.303"
      />
      <path
        fill="#543B2F"
        d="M36.046 44.725c0 .519-.762.938-1.702.938-.941 0-1.703-.42-1.703-.938 0-.516.762-.937 1.703-.937.94 0 1.702.42 1.702.937M16.67 44.725c0 .519-.762.938-1.702.938-.941 0-1.703-.42-1.703-.938 0-.516.762-.937 1.703-.937.94 0 1.701.42 1.701.937"
      />
      <path
        fill="#133441"
        d="M30.182 35.061c-.155.696 1.01 1.224 2.343 1.508 1.193.254 2.335.61 2.694-.264.738-1.8-4.572-3.34-5.037-1.244M14.087 36.305c.359.874 1.5.518 2.693.264 1.333-.284 2.498-.812 2.343-1.508-.465-2.096-5.775-.555-5.036 1.244"
      />
    </g>
  </svg>
)
export default SvgSeedJumping
