import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import { isValidUrl } from '@/Utils/validators'
import React from 'react'

const ensureUrlScheme = url => {
  if (!url.match(/^(http|https|ftp|mailto):\/\//)) {
    return `https://${url}`
  }
  return url
}

const Url = ({ children = '' }) => {
  const { theme } = useTheme()
  const loadInBrowser = async () => {
    try {
      const urlWithScheme = ensureUrlScheme(children)
      window.open(urlWithScheme)
    } catch (error) {
      console.warn("Couldn't load page", error)
    }
  }

  return (
    <Text
      color={theme.colors.primary}
      onClick={loadInBrowser}
      className="cursor-pointer"
    >
      {children}
    </Text>
  )
}

const interaction = {
  check: string => isValidUrl(string),
  component: Url,
}

export default interaction
