import { useEffect, useRef } from 'react'

const useLiveRef = value => {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  })
  return ref
}

export default useLiveRef
