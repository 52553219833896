import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineWork = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m10.87 3.897-.192-.725zm2.26 0-.192.725zM3.383 17.153l.722-.206zm-.003-5.467.721.206zm17.24 0 .721-.206zm-.004 5.467.722.206zm-4.623 4.216-.142-.737zm-7.986 0 .142-.737zM8.773 7.31l.142.737zm6.454 0 .142-.736zm1.074.207-.142.737zm4.226 3.843-.721.206zM7.699 7.517l-.142-.736zM3.473 11.36l-.721-.206zm7.589-6.738a3.7 3.7 0 0 1 1.876 0l.384-1.45a5.2 5.2 0 0 0-2.644 0zm5.865 3.191c0-2.182-1.485-4.081-3.605-4.641l-.384 1.45c1.475.39 2.489 1.703 2.489 3.191zm-8.354 0c0-1.488 1.014-2.802 2.489-3.191l-.384-1.45c-2.12.56-3.605 2.46-3.605 4.641zm-.732.44 1.074-.206-.284-1.473-1.074.207zm7.244-.206 1.074.207.284-1.473-1.074-.207zm4.72 3.519.094.326 1.442-.412-.093-.326zm-15.704.326.093-.326-1.442-.412-.093.326zm.004 5.055a9.23 9.23 0 0 1-.004-5.055L2.66 11.48a10.73 10.73 0 0 0 .003 5.879zm15.794-5.055a9.23 9.23 0 0 1-.004 5.055l1.443.412c.547-1.917.55-3.96.003-5.879zm-4.048 8.74c-2.543.49-5.159.49-7.702 0l-.284 1.473c2.73.527 5.54.527 8.27 0zM8.915 8.047a16.3 16.3 0 0 1 6.17 0l.284-1.473a17.8 17.8 0 0 0-6.738 0zm-.766 12.585a5.24 5.24 0 0 1-4.044-3.685l-1.443.412a6.74 6.74 0 0 0 5.203 4.746zm7.986 1.473a6.74 6.74 0 0 0 5.203-4.746l-1.443-.412a5.24 5.24 0 0 1-4.044 3.685zm.024-13.851a4.7 4.7 0 0 1 3.647 3.312l1.442-.412c-.64-2.24-2.501-3.929-4.805-4.373zM7.557 6.78c-2.304.444-4.166 2.132-4.805 4.373l1.442.412a4.7 4.7 0 0 1 3.647-3.312zm-4.171 5.76a24.85 24.85 0 0 0 17.228 0l-.52-1.406a23.35 23.35 0 0 1-16.188 0z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M8 11.25v3.5M16 11.25v3.5"
        />
      </g>
    </svg>
  )
}
export default SvgLineWork
