import React from 'react'
import styled, { css } from 'styled-components'

const GlobalText = styled.span`
  white-space: pre-wrap;
  text-align: left;
  font-weight: ${({ regular }) => (regular ? 300 : 500)};
  ${({ theme }) => css`
    color: ${props => props.color || theme.colors.black};
  `}
`

const Text = ({ children, ...props }) => {
  return <GlobalText {...props}>{children}</GlobalText>
}

export default Text
