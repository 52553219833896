import { useLockedField } from '@/Hooks/useLockedField'
import { ColorBusinessCard } from './ColorBusinessCard'
import { InformationBusinessCard } from './InformationBusinessCard'
import { LogoBusinessCard } from './LogoBusinessCard'
import { TemplateBusinessCard } from './TemplateBusinessCard'
import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import { useMemo } from 'react'
export const useGetOptions = () => {
  const { isFieldDisabled } = useLockedField()

  const options = useMemo(
    () => [
      {
        name: 'template',
        component: TemplateBusinessCard,
        title: 'businessCard.title_screen3',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                type="businessCard"
                variant="secondary"
                fieldName={fieldName}
              />
            </div>
          )
        },
        disabled: isFieldDisabled({
          fieldName: 'template',
          type: 'businessCard',
        }),
      },
      {
        name: 'information',
        component: InformationBusinessCard,
        title: 'businessCard.title_screen4',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                type="businessCard"
                variant="secondary"
                fieldName={fieldName}
              />
            </div>
          )
        },
        disabled: isFieldDisabled({
          fieldName: 'information',
          type: 'businessCard',
        }),
      },
      {
        name: 'logo',
        component: LogoBusinessCard,
        title: 'businessCard.title_screen5',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                type="businessCard"
                variant="secondary"
                fieldName={fieldName}
              />
            </div>
          )
        },
        disabled: isFieldDisabled({
          fieldName: 'logo',
          type: 'businessCard',
        }),
      },
      {
        name: 'color',
        component: ColorBusinessCard,
        title: 'businessCard.title_screen6',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                type="businessCard"
                variant="secondary"
                fieldName={fieldName}
              />
            </div>
          )
        },
        disabled: isFieldDisabled({
          fieldName: 'color',
          type: 'businessCard',
        }),
      },
    ],
    [isFieldDisabled],
  )

  return options
}
