import { LineDragVertical } from '@/Assets/icons/components'
import IconButton from '@/Components/Buttons/IconButton'
import DraggableFlatList from '@/Components/DraggableFlatList'
import Bloc from '@/Components/Profile/Bloc'
import { useTheme } from '@/Hooks'
import { useState } from 'react'

export const DraggableBloc = ({
  list = [],
  item: Item,
  onDragEnd,
  ...props
}) => {
  const { theme } = useTheme()
  const [draggable, setDraggable] = useState(false)
  return (
    <Bloc
      {...props}
      renderIconButton={() => (
        <IconButton
          onClick={() => setDraggable(!draggable)}
          icon={
            <LineDragVertical
              color={draggable ? theme.colors.primary : theme.colors.black}
            />
          }
        />
      )}
    >
      <DraggableFlatList
        data={list}
        keyExtractor={item => item.id}
        renderItem={({ item }) => <Item item={item} />}
        onDragEnd={newData => onDragEnd(newData)}
        draggable={draggable}
      />
    </Bloc>
  )
}
