import { useTheme } from '@/Hooks'
import styled, { css } from 'styled-components'
import { CustomThumbnail } from './Profile/CustomThumbnail'
import Text from './Texts/Text'
import { Edit } from '@/Assets/icons/components'
import ModalChooseThumbnail from './Modals/ModalChooseThumbnail'
import { TouchableOpacity } from './Buttons/TouchableOpacity'

const ItemWrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    height: ${theme.size.fill};
    padding: ${theme.space.l};
    background-color: ${props =>
      props.variant === 'primary' ? theme.colors.grey1 : theme.colors.white};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.space.std};
  `}
`

export const ThumbnailPreview = ({
  icon,
  label,
  thumbnail,
  setThumbnail,
  initialValue = null,
  width = 50,
  height = 50,
  variant = 'primary',
}) => {
  const { theme } = useTheme()
  return (
    <ModalChooseThumbnail
      onChange={src => setThumbnail(src)}
      onReset={() => setThumbnail(initialValue)}
    >
      {setIsOpen => (
        <ItemWrapper
          onClick={() => setIsOpen(true)}
          className="relative"
          variant={variant}
        >
          {thumbnail ? <CustomThumbnail src={thumbnail} /> : icon}
          <Text className="mt-3">{label}</Text>
          <Edit className="absolute top-3 right-3" color={theme.colors.black} />
        </ItemWrapper>
      )}
    </ModalChooseThumbnail>
  )
}

export default ThumbnailPreview
