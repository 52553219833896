const referral = {
  title: 'afiliación',
  overview: 'Visión general',
  stats: 'Estadísticas',
  bonus: 'Bono',
  portfolio: 'Cartera',
  copy: 'Copiar',
  overviewData: {
    bonusPool: 'Bonus Pool',
    personnalCustomers: 'Ventas activas personales',
    lastMonthRevenue: 'Ingresos del mes actual',
    totalRevenue: 'Ingresos totales',
    salesThisMonth: 'Ventas este mes',
    levelOne: 'Nivel 1',
    levelTwo: 'Nivel 2',
  },
  statsData: {
    newMonthlySell: 'Nuevas ventas mensuales',
    monthlyActiveCustomer: 'Clientes activos mensuales',
    monthlyRevenue: 'Ingresos mensuales',
  },
  myTransactions: 'Mis transacciones',
  noTransactions: 'Sin transacciones',
  mySales: 'Mis ventas',
  date: 'Fecha',
  user: 'Usuario',
  sellBonus: 'Bono de venta',
  profit: 'Beneficio',
  myPortfolio: 'Mi cartera',
  connectMetamask: 'Conectar con Metamask',
  receivedAt: 'Recibido en',
  collected: 'Recogido',
  fromYourSales: 'De tus ventas',
  fromPoolBonusSilver: 'Del bono de pool plata',
  fromPoolBonusGold: 'Del bono de pool oro',
  fromPoolBonusPlatinum: 'Del bono de pool platino',
  connectSequence: 'Conectar con Sequence',
  connectWalletConnect: 'Conectar con WalletConnect',
  connected: 'Conectado',
  actionsBalance: 'Saldo de acciones de Budly',
  salesBalance: 'Saldo de ventas',
  disconnect: 'Desconectar',
  myClients: 'Mis clientes',
  bonusData: {
    qualificationRequirements: 'Requisitos de calificación',
    qualificationRequirementsDescription:
      'Cada mes, puedes calificar para los 3 pools haciendo nuevas ventas. Cuando se envía el bono del pool, la cantidad se dividirá por el número de usuarios calificados.',
    make: 'Hacer',
    newSalesCurrentMonth: 'nuevas ventas este mes',
    missingSales: 'ventas faltantes',
    validated: 'validado',
    bronze: 'Bronce',
    silver: 'Plata',
    gold: 'Oro',
    platinum: 'Platino',
    unqualified: 'No calificado',
  },
  sellBonusText1:
    'Su porcentaje de comisión aumenta con el número de sus ventas mensuales activas. Una venta es activa si se paga en el mes en curso.',
  sellBonusText2:
    'Los suscripciones anuales se consideran activas durante 12 meses, mientras que las suscripciones mensuales prolongan su actividad cada renovación.',
  sellBonusText3: 'Maximice sus ingresos fidelizando a sus clientes.',
  paymentPercentage: 'Porcentaje de pago real',
  directActive: 'Activo directo',
  bud_monney: '¡Tus BUDs te hacen ganar dinero!',
  affiliate_dashboard: 'Tablero de afiliados',
  become_ambassador: 'Conviértete en embajador por 99€/año',
  nextPaymentIn: "Próximo pago en",
  days: "días",
  hours: "horas",
  minutes: "minutos",

}

export default referral
