const verifyEmailCode = build =>
  build.mutation({
    query: ({ userId, code = '' }) => {
      return {
        url: `/users/verify/${userId}?code=${code}`,
        method: 'PUT',
      }
    },
  })

export default verifyEmailCode
