import * as React from 'react'
const SvgBudIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 40})`}>
      <g clipPath="url(#budIcon_svg__a)">
        <path
          fill="url(#budIcon_svg__b)"
          d="M17.188 2.874a5.63 5.63 0 0 1 5.625 0L33.426 9a5.63 5.63 0 0 1 2.812 4.872v12.254A5.63 5.63 0 0 1 33.426 31l-10.613 6.127a5.63 5.63 0 0 1-5.625 0L6.575 31a5.63 5.63 0 0 1-2.813-4.872V13.873A5.63 5.63 0 0 1 6.575 9z"
        />
        <path
          fill="url(#budIcon_svg__c)"
          fillRule="evenodd"
          d="M32.913 9.946 22.25 3.79a4.5 4.5 0 0 0-4.5 0L7.087 9.946a4.5 4.5 0 0 0-2.25 3.897v12.313a4.5 4.5 0 0 0 2.25 3.897L17.75 36.21a4.5 4.5 0 0 0 4.5 0l10.663-6.157a4.5 4.5 0 0 0 2.25-3.897V13.843a4.5 4.5 0 0 0-2.25-3.897M22.625 3.14a5.25 5.25 0 0 0-5.25 0L6.712 9.297a5.25 5.25 0 0 0-2.625 4.546v12.313c0 1.876 1 3.61 2.625 4.547l10.663 6.156a5.25 5.25 0 0 0 5.25 0l10.663-6.156a5.25 5.25 0 0 0 2.625-4.547V13.843c0-1.875-1-3.608-2.625-4.546z"
          clipRule="evenodd"
        />
        <g filter="url(#budIcon_svg__d)">
          <path
            fill="#fff"
            fillOpacity={0.6}
            fillRule="evenodd"
            d="M25.98 23.332c.757 2.494-.71 5.113-3.275 5.85-2.565.735-5.257-.69-6.013-3.185-.818-2.697.358-6.614 1.182-8.819q.03-.082.057-.174c.046-.15.095-.307.182-.417a.7.7 0 0 0-.059-.256c-.391-.845-1.706-.853-2.497-.307q-.169.119-.345.26c-.454.355-.967.756-1.691.719-.912-.047-1.406-1.05-1.119-2.362.21-.96 1.183-2.196 3.056-2.195 1.315.002 2.224.921 2.718 1.605.035-.418.123-.83.263-1.222.587-1.647 1.892-2.313 3.304-2.19 1.33.116 2.281 1.147 2.578 2.05.215.657.423 2.014-1.213 1.963-.226-.007-.495-.028-.782-.049-.627-.047-1.335-.1-1.843-.035-.454.058-1.46.61-1.21 1.456q.022.076.054.154c.251.022.5.19.737.366 1.922 1.416 5.098 4.087 5.917 6.788"
            clipRule="evenodd"
            shapeRendering="crispEdges"
          />
        </g>
        <path
          fill="#fff"
          fillOpacity={0.4}
          fillRule="evenodd"
          d="m15.103 13.7-.008.01c-.042.045-.082.09-.11.131"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillOpacity={0.4}
          d="M22.556 28.768c-1.823.55-3.653-.106-4.088-1.468-.435-1.36.691-2.91 2.514-3.461 1.824-.55 3.654.106 4.089 1.467s-.691 2.911-2.515 3.462M19.035 12.99c-.03.178-.024.368.075.487.072.086.239.117.34.08.27-.1.437-.475.56-.75l.042-.092c.098-.214.23-.422.37-.607l.035-.044c.083-.106.177-.225.193-.36.034-.279-.345-.139-.472-.092l-.008.003c-.458.172-.87.608-1.052 1.076a1.5 1.5 0 0 0-.083.3M14.742 14.28q.103-.23.244-.439c.027-.042.067-.086.109-.131l.008-.01c.103-.112.217-.236.21-.374-.013-.267-.41-.2-.565-.171a2.4 2.4 0 0 0-.813.292 2.2 2.2 0 0 0-.701.648 1.9 1.9 0 0 0-.296.655c-.122.548-.065 1.05.148 1.342q.18.244.48.26c.744.037.883-.732.995-1.355l.04-.216c.033-.168.072-.344.141-.5"
        />
      </g>
      <defs>
        <linearGradient
          id="budIcon_svg__b"
          x1={14.469}
          x2={23.188}
          y1={5.375}
          y2={36.219}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D85FD" />
          <stop offset={1} stopColor="#9F96FD" />
        </linearGradient>
        <linearGradient
          id="budIcon_svg__c"
          x1={11.915}
          x2={24.781}
          y1={7.229}
          y2={33.781}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0.4} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <clipPath id="budIcon_svg__a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
        <filter
          id="budIcon_svg__d"
          width={37.859}
          height={42.75}
          x={6.321}
          y={2.625}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={6} dy={4} />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.385244 0 0 0 0 0.350632 0 0 0 0 0.835189 0 0 0 0.4 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15642_27444"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_15642_27444"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={-1} dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.394996 0 0 0 0 0.372517 0 0 0 0 0.681559 0 0 0 0.2 0" />
          <feBlend in2="shape" result="effect2_innerShadow_15642_27444" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={2} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend
            in2="effect2_innerShadow_15642_27444"
            result="effect3_innerShadow_15642_27444"
          />
        </filter>
      </defs>
    </g>
  </svg>
)
export default SvgBudIcon
