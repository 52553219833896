import useFilteredUsers from './useFilteredUsers'
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllMembersQuery } from '@/Services/modules/teams'
import { useState } from 'react'
import { setCheckedAllMemberUid } from '@/Store/Member'

export const useSelectAllMembers = () => {
  const { currentUser } = useSelector(state => state.user)
  const { checkedAllMemberUid } = useSelector(state => state.member)
  const { data: members = [], isLoading } = useGetAllMembersQuery(
    currentUser?.main_uid || currentUser?.uid,
  )
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const filteredUsers = useFilteredUsers(members, search)

  const activeMembers = [
    currentUser,
    ...filteredUsers.filter(member => !member.needToActiveAccount),
  ]

  const inactiveMembers = filteredUsers.filter(
    member => member.needToActiveAccount,
  )

  return {
    isLoading,
    activeMembers,
    inactiveMembers,
    setSearch,
    search,
    members: [currentUser, ...members],
    checkedAllMemberUid,
    setCheckedAllMemberUid: payload =>
      dispatch(setCheckedAllMemberUid(payload)),
  }
}
