import createBusinessCardByUid from './createBusinessCardByUid'
import deleteBusinessCardById from './deleteBusinessCardById'
import downloadVCardById from './downloadVCardById'
import getBusinessCardById from './getBusinessCardById'
import getBusinessCardTemplatesByWhitelabel from './getBusinessCardTemplatesByWhitelabel'
import getBusinessCardsByUid from './getBusinessCardsByUid'
import updateBusinessCardById from './updateBusinessCardById'
import addBusinessCardAnalytics from './addBusinesscardAnalytics'
import { api } from '@/Services/api'

export const BusinessCardApi = api.injectEndpoints({
  endpoints: build => ({
    getBusinessCardsByUid: getBusinessCardsByUid(build),
    createBusinessCardByUid: createBusinessCardByUid(build),
    getBusinessCardById: getBusinessCardById(build),
    deleteBusinessCardById: deleteBusinessCardById(build),
    updateBusinessCardById: updateBusinessCardById(build),
    downloadVCardById: downloadVCardById(build),
    getBusinessCardTemplatesByWhitelabel:
      getBusinessCardTemplatesByWhitelabel(build),
    addBusinessCardAnalytics: addBusinessCardAnalytics(build),
  }),
  overrideExisting: true,
})

export const {
  useGetBusinessCardByIdQuery,
  useGetBusinessCardsByUidQuery,
  useCreateBusinessCardByUidMutation,
  useDeleteBusinessCardByIdMutation,
  useUpdateBusinessCardByIdMutation,
  useLazyDownloadVCardByIdQuery,
  useGetBusinessCardTemplatesByWhitelabelQuery,
  useAddBusinessCardAnalyticsMutation,
} = BusinessCardApi
