const benefitspage = {
  title: 'My benefits',
  input: {
    search: 'Look for an advantage',
  },
  filter: {
    title: 'Filter',
    date: {
      title: 'Date',
      all_date: 'All dates',
      week: 'This week',
      last_week: 'Last week',
      last_two_week: 'Last 2 weeks',
      last_month: 'Last month',
      last_three_month: 'The last 3 months',
      last_six_month: 'The last 6 months',
      valid: 'Validate',
    },
    categories: {
      title: 'Categories',
      all: 'All categories',
      travel: 'Travel',
      food: 'Food',
      works: 'Work',
      no_cate: 'Uncategorized',
    },
    offers: {
      title: 'Types of offer',
      all: 'All the type',
      coupon: 'Coupon',
      offer: 'Offer',
    },
    type: {
      title: 'Type',
    },
  },
  benefits_title: 'All our benefits',
  copy_benefits: 'Copy Perk !',
  benefit_btn: 'Benefit',
  no_result: 'No result found',
}


export default benefitspage
