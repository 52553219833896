import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineEdit3 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M16.5 9.886c-1.818.606-4.242-1.818-3.636-3.636m.747-.747L9.694 9.42a13.3 13.3 0 0 0-3.495 6.172l-.19.757a.322.322 0 0 0 .391.391l.758-.19a13.3 13.3 0 0 0 6.172-3.494l3.917-3.917a2.571 2.571 0 1 0-3.636-3.636Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M19 20.75H5"
        />
      </g>
    </svg>
  )
}
export default SvgLineEdit3
