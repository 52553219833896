const reward_modal = {
  title: 'Vous avez gagné',
  subtitle: 'Félicitations !',
  daily_seed_reward: 'Vous venez de récupérer votre {{resource}} quotidienne',
  daily_quest_reward: 'Vous venez de terminer votre quête quotidienne',
  daily_rewards_title: 'Ressources journalières récupérées',
  daily_rewards_description: 'Vous avez récupéré vos ressources journalières',
}


export default reward_modal
