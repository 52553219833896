import React from 'react'
import { useProfileContext } from './Contexts/ProfileContext'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LineLock } from '@/Assets/icons/components'

const StyledAlternativeContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`

const AlternativeContent = () => {
  const { t } = useTranslation()
  return (
    <StyledAlternativeContent>
      <LineLock size={32} />
      <span className="mt-4">{t('others.private_profile')}</span>
    </StyledAlternativeContent>
  )
}

export const RestrictedByFollow = ({ content = false, children }) => {
  const { profile, isFollow, isMine, profilePublic } = useProfileContext()

  if (profile?.isPrivateMode && !isFollow && !isMine && profilePublic) {
    return content ? <AlternativeContent /> : null
  }
  return children
}
