import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgGame = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M9 10v4M11 12H7M15.107 10.5H15M17.107 13.5H17"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M2.42 8.72c.691-2.65 2.802-4.676 5.452-5.235l.507-.107a17.5 17.5 0 0 1 7.242 0l.507.107c2.65.559 4.76 2.586 5.451 5.234.561 2.15.561 4.411 0 6.562-.69 2.649-2.8 4.675-5.451 5.234l-.507.107a17.5 17.5 0 0 1-7.242 0l-.507-.107c-2.65-.559-4.76-2.585-5.451-5.234-.561-2.15-.561-4.411 0-6.562Z"
        />
      </g>
    </svg>
  )
}
export default SvgGame
