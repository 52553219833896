import { randomId } from '@/Utils/randomId'

const BASE_URL = 'https://api.shoprocket.io/v1/partners'
const API_KEY = 'sr_part_d560607bd1c1fa9e484e542f2a88f2ae6359'

const onFetch = async (url, options) => {
  try {
    const response = await fetch(url, options)
    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export const onCreateShop = async ({ user }) => {
  try {
    const url = `${BASE_URL}/stores`
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        store_name: `Store ${user.uid}`,
        address_line1: 'unknown',
        address_city: 'unknown',
        address_country_code: 'FR',
        address_zipcode: 'unknown',
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    }
    const data = await onFetch(url, options)
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export const onConnectShop = async ({ email, storeID }) => {
  const password = randomId()
  try {
    const url = `${BASE_URL}/team`
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        email: email,
        store_id: storeID,
        role: 'admin',
        is_verified: true,
        password,
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    }
    const data = await onFetch(url, options)
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export const onSwitchLiveStore = async ({ storeApiKey }) => {
  const urlEncoded = new URLSearchParams()
  urlEncoded.append('store_environment', 'live')

  try {
    const url = 'https://api.shoprocket.io/v1/settings/mode'
    const options = {
      method: 'POST',
      body: urlEncoded,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-api-key': storeApiKey,
      },
    }
    const data = await onFetch(url, options)
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export const onRedirectShop = async ({ user }) => {
  const urlEncoded = new URLSearchParams()
  urlEncoded.append('user_id', user.shopRocket.user_id)
  urlEncoded.append('store_id', user.shopRocket.store_id)

  try {
    const url = `${BASE_URL}/create-session`
    const options = {
      method: 'POST',
      body: urlEncoded,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-api-key': API_KEY,
      },
    }
    const data = await onFetch(url, options)
    return data
  } catch (error) {
    throw new Error(error)
  }
}
