import * as React from 'react'
const SvgSocialEtsy = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="72.424 42.569 4.34 4.338"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#f27224"
        d="M73.102 44.488v-.509c0-.019.002-.03.034-.03h.432c.076 0 .117.064.147.185l.025.096h.073c.013-.273.025-.392.025-.392s-.185.02-.294.02h-.553l-.296-.009v.08l.1.018c.07.013.087.028.093.093 0 0 .005.188.005.5 0 .31-.005.497-.005.497 0 .056-.023.077-.093.09l-.1.02v.078l.296-.01h.494c.111 0 .37.01.37.01.005-.068.043-.375.049-.409h-.07l-.074.168c-.058.132-.143.141-.237.141h-.281q-.14.002-.14-.118v-.43s.206 0 .278.006c.052.003.084.018.101.092l.023.098h.081l-.006-.247.012-.249h-.081l-.027.11c-.017.071-.028.084-.102.092-.082.01-.279.007-.279.007zm1.118-.456c-.026.117-.052.207-.145.268a.3.3 0 0 1-.135.052v.072h.167v.579c0 .162.108.241.251.241.111 0 .226-.047.266-.145l-.04-.05a.19.19 0 0 1-.154.078c-.083 0-.129-.056-.129-.2v-.51l.28.02.015-.128-.294.011v-.286zm.596.89-.071.002a2 2 0 0 1 0 .264s.143.053.288.053c.196 0 .353-.095.353-.28 0-.316-.476-.269-.476-.482 0-.088.08-.122.166-.122.068 0 .125.024.136.06l.047.141.07-.003c.006-.076.01-.163.02-.232a.8.8 0 0 0-.265-.042c-.185 0-.334.081-.334.262 0 .315.464.25.464.483 0 .083-.051.141-.166.141-.105 0-.158-.054-.179-.11zm1.136.218c-.109.305-.242.384-.363.384-.05 0-.075-.023-.083-.058l-.028-.147-.079.004c-.015.086-.03.182-.05.261a.34.34 0 0 0 .188.055c.13 0 .327-.017.508-.446l.303-.715c.024-.059.033-.064.105-.093l.04-.015v-.066l-.18.01-.191-.01v.066l.049.015c.049.015.071.034.071.068a.2.2 0 0 1-.015.06 14 14 0 0 1-.25.587l.047-.017a16 16 0 0 1-.228-.605.1.1 0 0 1-.005-.03c0-.03.02-.055.065-.065l.063-.013v-.066l-.258.01-.205-.01v.066l.033.012c.048.015.059.028.087.094.156.357.23.553.33.809z"
        style={{
          strokeWidth: 0.0112,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialEtsy
