const hashtagsPage = {
  profiles: 'Perfiles',
  images: 'Imágenes',
  posts: 'Publicaciones',
  nothing: 'No hay nada que mostrar',
  result: 'resultado',
  results: 'resultados',
}


export default hashtagsPage
