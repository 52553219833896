import * as React from 'react'
const SvgSocialSpotify = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        d="M16 2C8.268 2 2 8.268 2 16s6.268 14 14 14 14-6.268 14-14c0-7.731-6.268-13.999-14-14m6.42 20.191a.87.87 0 0 1-1.2.29c-3.286-2.008-7.425-2.462-12.298-1.35a.872.872 0 1 1-.389-1.7c5.333-1.219 9.908-.694 13.598 1.56.41.254.542.79.29 1.2m1.713-3.81a1.09 1.09 0 0 1-1.501.359c-3.762-2.313-9.5-2.983-13.95-1.632a1.093 1.093 0 0 1-1.363-.727 1.093 1.093 0 0 1 .728-1.361c5.084-1.543 11.405-.796 15.726 1.86.514.316.677.988.36 1.5m.148-3.97c-4.513-2.68-11.957-2.927-16.265-1.62a1.308 1.308 0 1 1-.76-2.506c4.946-1.5 13.166-1.211 18.361 1.873a1.31 1.31 0 0 1-1.336 2.252"
        style={{
          fill: '#2ebd59',
          strokeWidth: 0.00955305,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialSpotify
