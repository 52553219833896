import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getMobileOperatingSystem } from '@/Utils/isMobileOrDesktop'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@/Hooks'
import { Button } from '../Buttons/Button'

const Message = styled.div`
  @media screen and (max-width: 400px) {
    display: none;
  }
`

const MagicIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 13.0003C8.16864 13.0003 12.8515 8.32409 12.8515 0.166992C12.8515 8.32409 17.5343 13.0003 25.703 13.0003C17.5343 13.0003 12.8515 17.6766 12.8515 25.8337C12.8515 17.6766 8.16864 13.0003 0 13.0003Z"
        fill="#101828"
      />
    </svg>
  )
}

export const DownloadApplicationBanner = ({ isPreview }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [OS, setOS] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const result = getMobileOperatingSystem()
      setOS(result)
    }
  }, [])

  if (!OS || isPreview) return null

  return (
    <div className="w-full flex justify-between items-center px-3 flex-1 mb-4 gap-3">
      <Message className="flex gap-2 items-center flex-grow">
        <MagicIcon />
        <span className="font-semibold">
          {t('profilepage.application_available')}
        </span>
      </Message>
      <div>
        <Button
          color={theme.colors.secondary}
          label={t('profilepage.download_app')}
          type="link"
          to="/dl-app"
        />
      </div>
    </div>
  )
}
