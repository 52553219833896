import { useTheme } from '@/Hooks'
import { useDeleteBusinessCardByIdMutation } from '@/Services/modules/businessCards'
import { useState } from 'react'
import { Modal } from './ModalWrapper/Modal'
import { useTranslation } from 'react-i18next'

const ModalDeleteBusinesscard = ({
  onDismiss,
  businessCardId,
  children: button,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [deleteBusinessCardById] = useDeleteBusinessCardByIdMutation()
  const [isOpen, setIsOpen] = useState(false)

  const handleDeleteBusinessCard = async () => {
    await deleteBusinessCardById({ cardId: businessCardId })
    onDismiss()
    setIsOpen(false)
  }

  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.deleteBusinessCard.title')}
        actions={[
          {
            label: t('modal.btn.confirm'),
            onClick: handleDeleteBusinessCard,
            color: theme.colors.red,
          },
          {
            color: theme.colors.grey1,
            label: t('modal.btn.cancel'),
            onClick: () => setIsOpen(false),
          },
        ]}
      />
    </>
  )
}

export default ModalDeleteBusinesscard
