import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { LineEdit } from '@/Assets/icons/components'
import { useLockedField } from '@/Hooks/useLockedField'

const Button = styled.div`
  outline: none;
  overflow: hidden;
  position: relative;
  opacity: 1;
  &:hover {
    opacity: 1;
    cursor: ${props => (props.haveOnClick ? 'pointer' : 'default')};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

const Overlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  &:hover {
    opacity: ${props => (props.haveOnClick ? 1 : 0)};
  }
`

const Badge = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border-radius: 999px;
    padding: 8px;
  `}
`

const ButtonEditable = ({ type = 'button', fieldName, children, ...props }) => {
  const { isFieldDisabled } = useLockedField()
  const disabled = isFieldDisabled({ fieldName })

  const haveOnClick = useMemo(() => Boolean(props.onClick), [props.onClick])
  return (
    <Button
      disabled={disabled}
      type={type}
      haveOnClick={haveOnClick}
      {...props}
    >
      {children}
      <Overlay haveOnClick={haveOnClick}>
        <Badge>
          <LineEdit />
        </Badge>
      </Overlay>
    </Button>
  )
}

export default ButtonEditable
