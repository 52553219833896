import * as React from 'react'
const SvgSocialTwitch = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 3.84 3.84"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#65459b"
        fillRule="evenodd"
        d="M2.576 3.235H1.92l-.438.439h-.438v-.44H.24V.9L.458.314h2.994v2.045Zm.585-1.022V.606H.751v2.118h.657v.438l.438-.438h.803z"
        clipRule="evenodd"
        style={{
          strokeWidth: 0.0112,
        }}
      />
      <path
        fill="#65459b"
        d="M2.357 1.19v.878h.292V1.19Zm-.803.877h.292V1.19h-.292Z"
        style={{
          strokeWidth: 0.0112,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialTwitch
