import React from 'react'
import templates from './templates'

const DynamicTemplate = ({ businessCard, ...props }) => {
  const Component = templates.find(
    template => template.slug === businessCard.template,
  )?.component
  if (!Component) {
    return null
  }

  return <Component businessCard={businessCard} {...props} />
}

export default DynamicTemplate
