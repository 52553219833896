const seed_game_explanation_page = {
  title: 'Jeu de graines',
  subtitle: {
    main_text:
      'Avec notre jeu de graines, vous pouvez jouer avec la communauté et planter des arbres gratuitement.\nChaque post est une graine que vous pouvez arroser pour faire pousser <color>{{1}}</color>',
    1: 'un nouvel arbre dans la vraie vie !',
  },
  plant_seed_title: 'Plantez votre graine',
  plant_seed_description:
    "Transformez un geste éco-responsable de plantation d'arbres en une expérience ludique, vous permettant de contribuer activement à la lutte contre le changement climatique tout en vous amusant.",
  global_movement_title: "Faites partie d'un mouvement global",
  global_movement_description:
    "Intégrez une communauté mondiale et amplifiez votre impact positif sur l'environnement grâce à une dynamique sociale virale, inspirant ainsi d'autres à prendre part à cette initiative significative.",
  ecological_commitment_title: 'Valorisez votre engagement écologique',
  ecological_commitment_description:
    "Mettez en lumière votre engagement en faveur de l'environnement, vous recevez également une reconnaissance spéciale qui valorise et célèbre votre contribution à cette noble cause.",
}

export default seed_game_explanation_page
