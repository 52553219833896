const create_image_AI_page = {
  generate_image_title: 'Generar una imagen',
  negative_prompt: 'Prompt negativo',
  suggestion: 'Sugerencias',
  placeholder: 'Escribe tu prompt aquí...',
  picture_style: 'Estilo de la imagen',
  see_all: 'Ver todo',
  optional: '(opcional)',
  toast_error_title: 'La interpretación del prompt ha fallado',
  toast_error_subtitle:
    'La IA tiene limitaciones en generar texto en imágenes. Prefiera ilustraciones sin texto o con un texto muy breve y preciso.',
}

export default create_image_AI_page
