import useCurrentMember from '@/Hooks/useCurrentMember'
import useCurrentUser from '@/Hooks/useCurrentUser'
import { useGetCosmeticsByUIdQuery } from '@/Services/modules/cosmetics'
import React, { createContext, useContext } from 'react'

const CosmeticsContext = createContext()

export const CosmeticsProvider = ({ children }) => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const { data: cosmetics } = useGetCosmeticsByUIdQuery({
    uid: user.uid,
  })

  return (
    <CosmeticsContext.Provider value={cosmetics}>
      {children}
    </CosmeticsContext.Provider>
  )
}

export function useCosmetics() {
  return useContext(CosmeticsContext) || {}
}
