const hashtagsPage = {
  profiles: 'Profils',
  images: 'Images',
  posts: 'Publications',
  nothing: 'Il n’y a rien à afficher',
  result: ' résultat',
  results: ' résultats',
}


export default hashtagsPage
