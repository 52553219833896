import React from 'react'
import Label from './Label'
import { InputAdornment } from './InputAdornment'
import clsx from 'clsx'

const LabeledInput = ({
  title,
  name,
  value,
  placeholder,
  onChange,
  children,
  adornment,
  type,
  register,
  error,
  disabled,
  childrenClassName,
  require,
  ...props
}) => {
  return (
    <>
      <Label name={title} {...props}>
        {title}
        {require && '*'}
      </Label>
      <InputAdornment
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        adornment={adornment}
        childrenClassName={clsx(
          childrenClassName,
          'mt-2 mb-4',
          'tablet:mt-1 tablet:mb-2',
        )}
        type={type}
        register={register}
        error={error}
        mask={props.mask}
        maxLength={props.maxLength}
        disabled={disabled}
      >
        {children}
      </InputAdornment>
    </>
  )
}

export default LabeledInput
