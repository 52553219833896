import { css } from '@emotion/react'
import styled from 'styled-components'
import { Button } from './Buttons/Button'
import { useTranslation } from 'react-i18next'

const Footer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${theme.space.std} ${theme.space.l};
    background-color: ${theme.colors.grey1};
    border-top-left-radius: ${theme.borderRadius.xl};
    width: 100%;
    border-top-right-radius: ${theme.borderRadius.xl};
  `}
`

const ButtonsFooter = ({
  onLeftPress = () => null,
  onRightPress,
  leftLabel,
  rightLabel,
  leftDisabled = false,
  rightDisabled = false,
  leftLoading = false,
  rightLoading = false,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Footer {...props}>
      <div className={!onRightPress ? 'w-full' : 'w-1/2'}>
        <Button
          label={leftLabel || t('parametre.general.languages.save')}
          onClick={onLeftPress}
          disabled={leftDisabled}
          loading={leftLoading}
          className="mr-1"
        />
      </div>
      {onRightPress && (
        <div className="w-1/2">
          <Button
            label={rightLabel || t('edit_profil.insight')}
            onClick={onRightPress}
            disabled={rightDisabled}
            loading={rightLoading}
            className="ml-1"
            variant="secondary"
          />
        </div>
      )}
    </Footer>
  )
}

export default ButtonsFooter
