import { useEffect, useState } from 'react'
import { ProLabel } from './ProLabel'
import { Select } from '@/Components/Controls/Select'

const musicOptions = [
  { label: 'Spotify', value: 'spotify' },
  { label: 'Apple Music', value: 'appleMusic' },
]

const videoOptions = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Twitch', value: 'twitch' },
  { label: 'Vimeo', value: 'vimeo' },
  { label: 'Facebook', value: 'facebook' },
]

function SelectPlatform({ label, platform, setPlatform }) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(label === 'Music' ? musicOptions : videoOptions)
    setPlatform(
      platform ||
        (label === 'Music' ? musicOptions[0].value : videoOptions[0].value),
    )
  }, [platform, label, setPlatform])

  if (label !== 'Music' && label !== 'Video') {
    return null
  }

  return (
    <>
      <ProLabel label="Platform" />
      <div className="h-7 mb-8">
        <Select
          value={options.find(option => option.value === platform)}
          onSelect={option => setPlatform(option.value)}
          options={options}
        />
      </div>
    </>
  )
}

export default SelectPlatform
