import React, { createContext, memo, useContext, useMemo } from 'react'

const DashboardLayoutContext = createContext()

export const DashboardLayoutProvider = memo(({ topBarHeight, children }) => {
  const value = useMemo(() => {
    return {
      topBarHeight,
    }
  }, [topBarHeight])

  return (
    <DashboardLayoutContext.Provider value={value}>
      {children}
    </DashboardLayoutContext.Provider>
  )
})

export function useDashboardLayoutContext() {
  return useContext(DashboardLayoutContext)
}
