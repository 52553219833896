import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineMenu2 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M4 19.738h16m0-7H4m16-7H4"
        />
      </g>
    </svg>
  )
}
export default SvgLineMenu2
