import styled, { css } from 'styled-components'
import ColorPickerModal from './Modals/ColorPickerModal'
import React, { useEffect, useState } from 'react'
import TextInput from './Controls/TextInput'
import { TouchableOpacity } from './Buttons/TouchableOpacity'

const ColorPreview = styled(TouchableOpacity)`
  ${({ theme, editCurrentUser }) => css`
    height: 48px;
    aspect-ratio: 1;
    border-radius: 42%;
    background-color: ${props => props.color};
    border: 2px solid ${theme.colors.grey2};
  `}
`

const ColorPickerField = ({ value, onChange }) => {
  const [textValue, setTextValue] = useState(value)

  const handleChangeColor = col => {
    const checkHexadecimalValue = '^#([A-Fa-f0-9]{6})$'
    const isValid = col.match(checkHexadecimalValue)
    if (isValid) {
      onChange(col)
    }
    setTextValue(col)
  }

  useEffect(() => {
    setTextValue(value)
  }, [value])

  return (
    <ColorPickerModal value={value} onChange={onChange}>
      {setIsOpen => (
        <div className="flex flex-row gap-2">
          <ColorPreview color={value} onClick={() => setIsOpen(true)} />
          <TextInput
            value={textValue}
            onChange={handleChangeColor}
            variant="secondary"
          />
        </div>
      )}
    </ColorPickerModal>
  )
}

export default ColorPickerField
