const businessCard_placeholder = {
  businessName: 'Nombre de la empresa',
  fullName: 'Nombre completo',
  job: 'Tu trabajo',
  phone: '+1234567890',
  email: 'tu@mail.com',
  website: 'tudominio.com',
  address: 'Dirección',
  addressComplement: 'Complemento de dirección',
  zipCode: 'Código postal',
  city: 'Ciudad',
  state: 'Estado',
  your_logo: 'SU LOGO',
}


export default businessCard_placeholder
