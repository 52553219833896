import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgDiscovery = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M3.353 9.7A7.51 7.51 0 0 1 8.95 4.103c2.006-.47 4.094-.47 6.1 0A7.51 7.51 0 0 1 20.647 9.7c.47 2.006.47 4.094 0 6.1a7.51 7.51 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0A7.51 7.51 0 0 1 3.353 15.8a13.35 13.35 0 0 1 0-6.1Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M10.182 12.046a2 2 0 0 1 1.114-1.114l2.038-.815c.816-.327 1.626.483 1.3 1.3l-.816 2.037a2 2 0 0 1-1.114 1.114l-2.037.815c-.817.327-1.627-.483-1.3-1.3z"
        />
      </g>
    </svg>
  )
}
export default SvgDiscovery
