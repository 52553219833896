import getFollowers from './getFollowers'
import { api } from '@/Services/api'
import getFollowersList from './getFollowersList'

export const followersApi = api.injectEndpoints({
  endpoints: build => ({
    getFollowers: getFollowers(build),
    getFollowersList: getFollowersList(build),
  }),
  overrideExisting: true,
})

export const { useGetFollowersQuery, useLazyGetFollowersListQuery } =
  followersApi
