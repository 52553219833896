import * as React from 'react'
const SvgSocialClubHouse = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        fill="#f4d44e"
        d="m29.438 22.854-.5-.671a1.27 1.27 0 0 1-.216-1.051c.204-.842.455-2.5-.302-3.989a3.6 3.6 0 0 1-.349-1.036c-.42-2.517.848-5.908.873-5.985.444-1.406.62-1.967-.454-2.484-1.169-.563-2.208.087-2.814 1.19-.083.15-1.303 2.416-1.583 5.36l-7.535-10.14c-.534-.718-1.531-.939-2.294-.472a1.72 1.72 0 0 0-.487 2.496L12.9 4.89c-.56-.754-1.625-.952-2.392-.412a1.72 1.72 0 0 0-.39 2.433l.901 1.214a1.72 1.72 0 0 0-2.505-.274c-.686.592-.774 1.627-.233 2.355l1.666 2.242a1.72 1.72 0 0 0-2.53-.253c-.666.596-.742 1.616-.208 2.334l8.194 11.026a6.38 6.38 0 0 0 3.31 2.318c1.205.359 1.697 1.31 1.838 1.459l.057.078a1.46 1.46 0 0 0 2.047.3l6.485-4.823a1.453 1.453 0 0 0 .299-2.033"
        style={{
          strokeWidth: 0.0633484,
        }}
      />
      <path
        fill="#4a4a4a"
        d="M24.951 6.967a13.4 13.4 0 0 0-3.118-4.193.444.444 0 1 1 .596-.66 14.3 14.3 0 0 1 3.325 4.472.444.444 0 0 1-.803.381M22.46 7.662a10.9 10.9 0 0 0-2.009-2.703.444.444 0 0 1 .622-.635c.87.855 1.606 1.843 2.174 2.924a.444.444 0 0 1-.786.414M5.643 21.54a14.3 14.3 0 0 1-3.325-4.472.444.444 0 0 1 .803-.381 13.4 13.4 0 0 0 3.118 4.193.444.444 0 0 1-.596.66M6.999 19.33a11.8 11.8 0 0 1-2.174-2.924.444.444 0 0 1 .786-.414 10.9 10.9 0 0 0 2.01 2.703.444.444 0 1 1-.622.635"
        style={{
          strokeWidth: 0.0633484,
        }}
      />
      <path
        fill="#e8c842"
        d="m10.179 6.988 5.494 7.36a.803.803 0 0 0 1.165.129.8.8 0 0 0 .121-1.086l-4.48-6.002-.006-.007-.006-.009-.006-.008-.005-.006-.867-1.162a1.71 1.71 0 0 1-.007-2.04c-1.375-.088-2.424 1.462-1.403 2.83M13.18 16.805c.295.4.872.45 1.23.106a.77.77 0 0 0 .087-1.014L9.76 9.474a1.775 1.775 0 0 1-.022-2.086c-1.455-.086-2.335 1.586-1.453 2.782zM22.09 28.717l-.059-.078c-.14-.149-.632-1.1-1.836-1.458a6.38 6.38 0 0 1-3.31-2.318c-.418-.56-7.92-10.657-8.194-11.025a1.76 1.76 0 0 1-.022-2.08c-1.457-.084-2.347 1.575-1.458 2.772l8.193 11.026a6.38 6.38 0 0 0 3.31 2.318c1.205.358 1.696 1.309 1.837 1.458l.058.078a1.46 1.46 0 0 0 2.046.3l.545-.404a1.46 1.46 0 0 1-1.11-.589M15.268 5.372a1.71 1.71 0 0 1-.005-2.038c-.303-.02-.61.04-.892.186a1.7 1.7 0 0 0-.392.279h-.001c-.6.567-.711 1.49-.249 2.186l.055.078 4.96 6.635a.81.81 0 0 0 1.206.102.873.873 0 0 0 .093-1.163zm-.476-.636-.026-.035zM25.66 8.783c-.051.094-.543 1.014-.973 2.409-.26.84-.496 1.852-.6 2.96.455.611 1.42.314 1.458-.45l.013-.244c.278-2.949 1.491-5.219 1.573-5.369.144-.264.314-.502.503-.704-.817-.012-1.519.563-1.974 1.398"
        style={{
          strokeWidth: 0.0633484,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialClubHouse
