import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { useGetOptions } from '@/Components/Dashboard/Profile/QrCode/options'
import Form from '@/Components/Form/Form'
import FormSubmitButton from '@/Components/Form/FormSubmitButton'
import Headings from '@/Components/Dashboard/Profile/Headings'
import React, { useMemo } from 'react'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'
import { OptionsLock } from '@/Components/ControlsLocked/OptionsLock'
import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import { useTranslation } from 'react-i18next'

const TITLE = 'edit_profil.qr_code.title'
const SUBTITLE = 'edit_profil.qr_code.subtitle'

const QrCode = () => {
  const { onSubmit, setEditCurrentUser, editCurrentUser } = useEditCurrentUser()
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const { isAllTabFieldLocked, currentMember, LOCKED_FIELDS } =
    useCurrentMember()
  const user = currentMember || currentUser
  const options = useGetOptions()

  const isLocked = useMemo(() => {
    return isAllTabFieldLocked(
      LOCKED_FIELDS.qrcode,
      editCurrentUser?.lockedFields?.qrcode,
    )
  }, [
    LOCKED_FIELDS.qrcode,
    editCurrentUser?.lockedFields?.qrcode,
    isAllTabFieldLocked,
  ])

  const handleLockAllFields = () => {
    const tabFieldsLock = isLocked ? [] : LOCKED_FIELDS.qrcode
    setEditCurrentUser(prev => {
      return {
        ...prev,
        lockedFields: {
          ...prev.lockedFields,
          qrcode: tabFieldsLock,
        },
      }
    })
  }

  return (
    <>
      <Headings
        title={TITLE}
        subtitle={SUBTITLE}
        renderComponent={() => (
          <LockedFieldSwitch
            label={t('businessCard.block_all_fields')}
            onChange={handleLockAllFields}
            isLocked={isLocked}
          />
        )}
      />
      <Form onSubmit={onSubmit}>
        <OptionsLock
          options={options.map(option => ({
            ...option,
            disabled: !Boolean(user.qrcode) || option.disabled,
          }))}
          type="qrcode"
        />
        <FormSubmitButton
          className="w-full"
          disabled={isLocked && !currentMember}
        />
      </Form>
    </>
  )
}

export default QrCode
