import * as React from 'react'
const SvgClaimUnlocked = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 39})`}>
      <ellipse cx={19.488} cy={17} fill="#BDBDBD" rx={19.088} ry={17} />
      <ellipse cx={19.488} cy={14.614} fill="#E0E0E0" rx={19.088} ry={14.614} />
      <path
        fill="#EEE"
        d="M17.937 4.543c0-.972-.789-1.768-1.757-1.681-1.95.175-3.842.64-5.571 1.372-2.245.952-4.143 2.326-5.516 3.994-1.372 1.669-2.177 3.578-2.338 5.55a9.2 9.2 0 0 0 .632 4.158c.377.953 1.478 1.322 2.441.973 1.279-.465 1.773-2.003 1.548-3.345a6.4 6.4 0 0 1-.066-1.565c.113-1.38.676-2.717 1.637-3.885.96-1.168 2.289-2.13 3.86-2.796a12.9 12.9 0 0 1 3.376-.903c.963-.124 1.754-.9 1.754-1.872"
      />
      <path
        fill="#3C3F42"
        fillRule="evenodd"
        d="M17.199 13.082h4.67c1.375 0 2.49 1.09 2.49 2.435v2.88c0 1.346-1.116 2.437-2.49 2.437H16.93c-1.374 0-2.49-1.09-2.49-2.436v-2.88c0-1.107.76-2.03 1.792-2.327l-.065.007v-.889c.012-1.732 1.453-3.142 3.22-3.142 1.38 0 2.601.853 3.052 2.116a.5.5 0 0 1-.024.389.528.528 0 0 1-.96-.057 2.17 2.17 0 0 0-2.056-1.433c-1.21 0-2.188.951-2.194 2.116v.783zm2.198 5.027c.29 0 .522-.227.522-.51V16.31a.51.51 0 0 0-.522-.504.51.51 0 0 0-.515.504v1.287c0 .285.23.51.515.51"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgClaimUnlocked
