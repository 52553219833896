export const shapes = [
  {
    shape: 'shape1',
    className: 'rounded-2xl',
  },
  {
    shape: 'shape2',
    className: 'rounded-full',
  },
  {
    shape: 'shape3',
    className: 'rounded-none',
  },
  {
    shape: 'shape4',
    className: 'rounded-t-md rounded-b-3xl',
  },
]
