const addBusinessCardAnalytics = build =>
  build.mutation({
    query: ({ cardId, data, collection = '' }) => {
      const queryParams = collection ? `?collection=${collection}` : ''
      return {
        url: `/users/business-card/${cardId}/analytics${queryParams}`,
        method: 'POST',
        body: data,
      }
    },
  })

export default addBusinessCardAnalytics
