import { linkItems } from '@/contents'
import styled, { css } from 'styled-components'
import { EditProfileItem } from './EditProfileItem'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import compareRank from '@/Utils/compareRank'
import { Button } from '@/Components/Buttons/Button'
import { LinePlus } from '@/Assets/icons/components'
import { DraggableBloc } from './DraggableBloc'
import PanelAddLink from '@/Components/Panels/ContentPanels/PanelAddLink'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.l} 0;
  `}
`

const LinkItem = ({ item, ...props }) => {
  const content = linkItems.find(linkItem => linkItem.label === item.icon)
  if (!content) {
    return null
  }

  return (
    <EditProfileItem
      icon={content.icon}
      content={item.value}
      label={item.label}
      item={item}
      type="link"
      {...props}
    />
  )
}

const getLinks = (links = []) => {
  return links.filter(link => link.type === 'link').sort(compareRank)
}

const Links = () => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { t } = useTranslation()

  const links = useMemo(() => {
    return getLinks(editCurrentUser.links)
  }, [editCurrentUser.links])

  const onDragAndDrop = useCallback(
    async data => {
      const updatedLinks = data.map((item, index) => ({
        ...item,
        rank: index,
      }))
      setEditCurrentUser({
        ...editCurrentUser,
        links: [
          ...(editCurrentUser.links || []).filter(link => link.type !== 'link'),
          ...updatedLinks,
        ],
      })
    },
    [editCurrentUser, setEditCurrentUser],
  )

  return (
    <Wrapper>
      <PanelAddLink>
        {setIsOpen => (
          <Button
            icon={LinePlus}
            label={t('profilepage.link.add_link')}
            onClick={() => setIsOpen(true)}
            className="w-full"
          />
        )}
      </PanelAddLink>
      <div className="w-full flex-1">
        <DraggableBloc
          title={t('profilepage.link.my_links')}
          list={links}
          item={LinkItem}
          onDragEnd={onDragAndDrop}
        />
      </div>
    </Wrapper>
  )
}

export default Links
