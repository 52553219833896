const explicitImage = build =>
  build.mutation({
    query: data => {
      return {
        url: '/picpurify/explicit-image',
        method: 'POST',
        body: data,
      }
    },
  })

export default explicitImage
