const useLocalStorage = () => {
  const getLocalStorageItem = key => {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }
  const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }
  return {
    getLocalStorageItem,
    setLocalStorageItem,
  }
}

export default useLocalStorage
