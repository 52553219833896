export const infiniteTreesScrollOptions = {
  forceRefetch: ({ currentArg, previousArg }) => {
    return currentArg?.lastDate !== previousArg?.lastDate
  },
  serializeQueryArgs: ({ endpointName }) => {
    return endpointName
  },
  merge: (currentState = [], incomingState = [], { arg }) => {
    const shouldNotKeepPreviousTrees = currentState.some(
      tree => tree.userId !== arg.uid,
    )
    return [
      ...(shouldNotKeepPreviousTrees ? [] : currentState),
      ...incomingState.filter(
        ({ date }) => !currentState.find(p => p.date === date),
      ),
    ].sort((a, b) => b.date - a.date)
  },
}
