import * as React from 'react'

const CertificationIcon = ({ bgColor, ...props }) => {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#certifIcon_svg__certifIcon)">
        <path
          d="M18.5 7.725L20 10l-1.5 2.275.147 2.723-2.423 1.226-1.226 2.423-2.723-.148L10 20l-2.275-1.5-2.723.147-1.226-2.423-2.423-1.226.148-2.723L0 10l1.5-2.275-.147-2.723 2.423-1.226 1.226-2.423 2.723.148L10 0l2.275 1.5 2.723-.147 1.226 2.423 2.423 1.226-.148 2.723z"
          fill={props.color || '#212121'}
        />
      </g>
      <path
        d="M16.064 10a6.064 6.064 0 11-12.128 0 6.064 6.064 0 0112.128 0z"
        fill={bgColor || '#eeeeee'}
      />
      <path
        d="M9.178 12.066a.44.44 0 01-.313-.13L7.06 10.082a.434.434 0 11.622-.605l1.505 1.549 3.136-2.963a.434.434 0 01.596.631l-3.44 3.254a.443.443 0 01-.3.117z"
        fill={props.color || '#212121'}
      />
    </svg>
  )
}

export default CertificationIcon
