const getTokenAuth = build =>
  build.query({
    query: uid => {
      return {
        url: `/users/${uid}/get-token-auth`,
        method: 'GET',
      }
    },
  })

export default getTokenAuth
