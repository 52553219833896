const reward_modal = {
  title: 'Has ganado',
  subtitle: '¡Enhorabuena!',
  daily_seed_reward: 'Has recogido tu {{resource}} diaria',
  daily_quest_reward: 'Has terminado tu misión diaria',
  daily_rewards_title: 'Recursos diarios reclamados',
  daily_rewards_description: 'Acaba de reclamar sus recursos diarios',
}


export default reward_modal
