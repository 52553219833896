import ImageDetails from './ImageDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../ModalWrapper/Modal'
import { useGetFullName } from '@/Hooks/useGetFullName'

const ModalImageFullScreen = ({
  children: button,
  profile = {},
  onRefetch,
  gallery,
  multiple,
  scrollIndex,
}) => {
  const name = useGetFullName(profile)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const formattedGallery = gallery.map(image => {
    const img = {
      id: image.id,
      firstName: image.firstName || profile?.firstName || '',
      lastName: image.lastName || profile?.lastName || '',
      businessName: image.businessName || profile?.businessName || '',
      photoURL: image.photoURL || profile?.photoURL || '',
      src: image.src || '',
      description: image.description || '',
      tags: image.tags || [],
      userId: image.userId || profile?.uid,
      postAt: image.postAt || '',
      customUrl: profile?.customUrl,
      businessMode: profile?.businessMode,
    }
    return img
  })

  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('others.gallery_of', { name })}
        fullscreen
      >
        <div className="flex flex-col flex-1 gap-3">
          {/* {formattedGallery.length && (
            <FlatList
              ref={flatListRef}
              initialNumToRender={formattedGallery.length}
              initialScrollIndex={scrollIndex}
              onScrollToIndexFailed={async info => {
                const wait = new Promise(resolve => setTimeout(resolve, 50))
                wait.then(() => {
                  flatListRef?.current?.scrollToIndex({
                    index: info.index,
                    animated: false,
                  })
                })
              }}
              data={formattedGallery}
              renderItem={({ item }) => {
                return (
                  <ImageDetails
                    image={item}
                    multiple={multiple}
                    setIsOpen={setIsOpen}
                    onRefetch={onRefetch}
                    gallery={gallery}
                  />
                )
              }}
              keyExtractor={item => item.id}
            />
          )} */}
          {formattedGallery.length && (
            <ImageDetails
              image={formattedGallery[scrollIndex]}
              multiple={multiple}
              setIsOpen={setIsOpen}
              onRefetch={onRefetch}
              gallery={gallery}
            />
          )}
        </div>
      </Modal>
    </>
  )
}

export default ModalImageFullScreen
