import React from 'react'
import styled from 'styled-components'

const ratios = {
  spotify: 2.1,
  appleMusic: 2.8,
}

const StyledIFrame = styled.iframe`
  width: 100%;
  aspect-ratio: ${props => props.ratio};
  background-color: transparent;
  position: initial !important;
  z-index: initial !important;
`

export const Iframe = ({ url, platform }) => {
  const ratio = ratios[platform] || 1.78

  return (
    <StyledIFrame
      ratio={ratio}
      id={url}
      title="film"
      src={url}
      frameBorder="0"
      allow={`accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share`}
      allowFullScreen
      width="auto"
      height="auto"
      scrolling="no"
    />
  )
}
