const negative_prompt_explanation = {
  title: 'The negative prompt',
  subtitle: {
    main_text:
      'The negative prompt is a key tool for refining image generation by allowing you to specify what you do not want to see in a generated image.',
  },
  increased_precision_title: 'Increased precision',
  increased_precision_description:
    'Specify unwanted elements in the generated image, ensuring greater accuracy in the results.',

  creative_control_title: 'Creative Control',
  creative_control_description:
    'Keep greater control over image content, clearly defining what should not be present while preserving your creative freedom.',
  elimination_ambiguity_title: 'Elimination of ambiguity',
  elimination_ambiguity_description:
    "Eliminate results that don't meet your expectations, reducing ambiguity and giving you more consistent images.",
  how_use: 'How to use it ?',
  how_use_content:
    'Use <strong>"don\'t"</strong> followed by what you want to exclude.',
  how_use_content_end:
    '<strong>Combine it</strong> with positive prompts for refined results.',
}

export default negative_prompt_explanation
