const plantation_details = {
  title: 'Plantation details',
  posted_by: 'Posted by',
  planted_by: 'Planted by',
  you: 'You',
  see_post: 'See the post',
  planted_on: 'Planted on',
  tree_project: 'Planting project',
  see_more: 'See more',
  learn_more: 'Learn more',
  location: 'Planting location',
  locate: 'Locate',
  participants: 'Participants',
  cost_planting: 'Planting cost',
  no_plantation: 'There are no plantations',
}


export default plantation_details
