import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Expendable from './Expandable'
import { LineArrowBottom } from '@/Assets/icons/components'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const Option = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    margin-bottom: ${theme.space.std};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: pointer;

    &:hover {
      background: ${({ disabled }) =>
        !disabled ? theme.colors.grey1 + '80' : theme.colors.grey1};
    }
  `}
`
const Title = styled.span`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.fontSize.std};
    color: ${theme.colors.black};
    padding: ${theme.space.xxs} ${theme.space.none};
  `}
`

const EditOption = ({ title, component: Component, disabled, ...props }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Option {...props} disabled={disabled}>
      <div
        onClick={() => {
          if (disabled) {
            return null
          }
          setOpen(!open)
        }}
        className={clsx(
          'py-3 px-4 flex flex-row justify-between items-center border-gray-400',
          disabled && 'cursor-not-allowed',
        )}
      >
        <div className="flex flex-row items-center border-red">
          <Title>{t(title)}</Title>
        </div>
        <div className="flex flex-row items-center">
          <LineArrowBottom />
        </div>
      </div>
      <Expendable open={open}>
        <div className="pb-4 px-4 flex flex-col items-center justify-center gap-3">
          {!disabled && <Component disabled={disabled} />}
        </div>
      </Expendable>
    </Option>
  )
}

export const Options = ({ options = [], children = () => null, ...props }) => {
  return (
    <div className="flex flex-col">
      {options.map(option => {
        return (
          <div className="relative">
            <EditOption key={option.title} {...option} {...props} />
            {children(option)}
          </div>
        )
      })}
    </div>
  )
}
