const post_details = {
  title: 'Detalles de la publicación',
  posted_by: 'Publicado por',
  post_history: 'Historial de publicaciones',
  water_this_post: 'Riega esta publicación',
  see_participants: 'Ver {{quantity}} participante{{plurial}}',
  seed_progress: 'Progreso de las semillas',
  help_get_tree: 'Ayuda a obtener un árbol',
  water_this_post_to_plant: 'Riega esta publicación para plantar un árbol',
}


export default post_details
