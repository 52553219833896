import * as React from 'react'
const SvgSocialYoutube = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="5.368 13.434 0.69 0.69"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#fff"
        d="M5.813 13.773q-.082-.044-.162-.085v.17c.057-.032.117-.06.162-.085z"
        style={{
          strokeWidth: 0.0112,
        }}
      />
      <path
        fill="#e8e0e0"
        d="M5.813 13.773c-.055-.03-.162-.085-.162-.085l.143.096z"
        style={{
          strokeWidth: 0.0112,
        }}
      />
      <path
        fill="#cd201f"
        d="M5.661 13.99a1 1 0 0 1-.178-.009.1.1 0 0 1-.041-.02.1.1 0 0 1-.02-.038.3.3 0 0 1-.008-.055 1 1 0 0 1 0-.178c.004-.033.006-.072.03-.095a.1.1 0 0 1 .04-.02c.024-.005.125-.008.23-.008.103 0 .204.004.228.008a.08.08 0 0 1 .047.028c.022.035.023.079.025.113v.126a.3.3 0 0 1-.014.093.1.1 0 0 1-.017.026.1.1 0 0 1-.042.022c-.1.007-.184.009-.28.007m.152-.217-.162-.086v.17z"
        style={{
          strokeWidth: 0.0112,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialYoutube
