import React, { useState } from 'react'
import { Panel } from '../PanelWrapper/Panel'
import AddContentWrapper from '../../Dashboard/Profile/Contents/AddContentWrapper'
import ContentItems from '../../Dashboard/Profile/Contents/ContentItems'
import { contactItems } from '@/contents'
import { useTranslation } from 'react-i18next'

const PanelAddContact = ({ children: button }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      {button(setIsOpen)}
      <Panel
        title={t('edit_profil.contents.add_content_panel_titles.contact')}
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <AddContentWrapper>
          {search => (
            <ContentItems
              items={contactItems}
              search={search}
              type="contact"
              onDismiss={() => setIsOpen(false)}
            />
          )}
        </AddContentWrapper>
      </Panel>
    </>
  )
}

export default PanelAddContact
