import { LinePlus } from '@/Assets/icons/components'
import { Button } from '@/Components/Buttons/Button'
import useCurrentMember from '@/Hooks/useCurrentMember'
import useCurrentUser from '@/Hooks/useCurrentUser'
import styled, { css } from 'styled-components'
import { CreateShopButton } from './CreateShopButton'
import { EnableShopButton } from './EnableShopButton'
import { RedirectShopButton } from './RedirectShopButton'
import { RetryCreationSection } from './RetryCreationSection'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.l} 0;
  `}
`

const ShopRocket = () => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser

  return (
    <Wrapper>
      <div className="flex flex-col gap-3">
        <CreateShopButton user={user} />
        <RedirectShopButton user={user} />
        <EnableShopButton user={user} />
        <RetryCreationSection user={user} />
      </div>
    </Wrapper>
  )
}

export default ShopRocket
