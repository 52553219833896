import * as React from 'react'
const SvgSocialDailymotion = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="13.979 4 3.268 3.267"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#e39c2e"
        d="m16.63 6.816-.213-1.03-.088-.034-.099.038.017.115.07.023.16.912v.005z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m16.205 5.632-.181-.07.023.235.078.018.11.95.036-.007.016.103.005.002.08.022.11-.022-.164-.935-.07-.023z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#e39c2e"
        d="m15.951 5.773.066 1.01.01.18.267-.046-.023-.16-.036.008-.11-.95-.077-.018zm-.721-.397-.05.301-.206-.042-.254 1.214.315-.03.174-.959.058-.035.064-.503-.059-.009z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m15.267 5.825-.005.002-.053.033-.189 1.022.153.066.036-.01-.036.008.13-1.1-.036-.019Z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#e39c2e"
        d="m15.65 4.204-.035.387.061.04z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m14.83 5.884-.23.834-.004.01.121.119.003-.007.254-1.205-.07.065-.034.151z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#e39c2e"
        d="m15.385 5.89-.082-.044-.13 1.102.043-.012.063-.587.096-.313z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m15.38 5.935.007-.044h-.002z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m15.551 4.913-.143.139.005-.038.139-.136zm-.007.139-.157.155.007-.035.153-.158zm-.176-.035-.1.808v.002l.112.061.007.003-.012.146-.096.312-.066.616.247.098.087-1.407.024.007.04-.966-.015-.117-.298.385zm.17.173-.167.16.004-.037.165-.155zm-.005.132-.003.028-.171.139-.005-.005.003-.021.173-.145zm-.007.205-.195.136.005-.033.19-.134z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#aa7734"
        d="M15.551 4.913v-.035l-.138.136-.005.038zm-.164.294.157-.155.003-.038-.153.158zm-.016.143.166-.16.003-.032-.164.155zm.16 0 .002-.028-.003-.005-.173.146-.003.021.005.005zm-.005.146-.19.134-.005.033.195-.136z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#e39c2e"
        d="m15.61 4.697-.04.966.14.042v-.108l.086.035v.153l-.016-.005-.003.057.148.428-.01-.226-.062-.022-.005-.152.06.026-.004-.073-.051-.019-.005-.15.07.018.007.118.026-.012.096.024-.084-.827.094-.092-.46-.298zm.167.294.003.098-.073-.034.002-.1zm-.07-.075v-.104l.063.033.003.106zm.183.096-.061-.03-.002-.106.058.03zm.005.043.002.095-.063-.032V5.02zm.012.237-.068-.031-.005-.103.065.033zm-.123-.024.005.134-.08-.033v-.136zm-.074-.07-.003-.104.073.035.004.104zm0 .352.001-.136.08.033.005.136zm.208-.092-.073-.03-.004-.13.068.034zm0 .047.01.13-.075-.028-.005-.132z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#aa7734"
        d="m15.838 5.261.068.03-.007-.1-.065-.033zm.052-.249-.005-.106-.059-.03.003.106zm-.118-.061-.002-.106-.063-.033v.104Zm.073.477.073.03-.01-.127-.068-.032zm-.012-.31.064.033-.002-.096-.062-.034zm.015.357.004.132.075.028-.009-.13zm.077.31-.007-.118-.07-.018.004.15.052.019v-.021Zm-.077.08.004.153.064.02-.007-.147zm-.14-.667.076.035-.004-.103-.073-.036zm0 .352.088.033-.005-.136-.08-.033zm.072.23.016.005v-.153l-.087-.035v.108l.073.021zm-.071-.41.08.032-.005-.134-.075-.035zm-.003-.316.073.035-.002-.098-.068-.035z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#e39c2e"
        d="m15.782 5.726-.235-.07-.087 1.407.5-.08v-.026h-.002l-.033-.693-.148-.427Z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m15.953 6.859.005.098h.002z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
      <path
        fill="#ffda05"
        d="m15.904 5.797.056 1.16.066.01v-.005l-.002.002-.073-1.19z"
        style={{
          strokeWidth: 0.01562,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialDailymotion
