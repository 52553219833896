const image_style_page = {
  polaroid: 'Polaroid',
  steamPunk: 'SteamPunk',
  mythological: 'Mythological',
  futurist: 'Futurist',
  three_d_render: '3D render',
  mystical: 'Mystical',
  origami: 'Origami',
  graffiti: 'Graffiti',
  cartoon: 'cartoon',
  comic_book: 'Comic book',
  neo: 'Neo',
  epic: 'Epic',
  van_gogh: 'Van Gogh',
  hyper_realistic: 'Hyper realistic',
  oil_painting: 'Oil painting',
  manga_color: 'Manga color',
  search_style: 'Search for a style',
}

export default image_style_page
