const seed_game_explanation_page = {
  title: 'Juego de Semillas',
  subtitle: {
    main_text:
      'Con nuestro juego de semillas, puedes jugar con la comunidad y plantar árboles de forma gratuita.\n¡Cada publicación es una semilla que puedes regar para hacer crecer <color>{{1}}</color>',
    1: 'un nuevo árbol en la vida real!',
  },
  plant_seed_title: 'Planta tu semilla',
  plant_seed_description:
    'Transforma un gesto eco-responsable de plantar árboles en una experiencia divertida, que te permite contribuir activamente a la lucha contra el cambio climático mientras te diviertes.',
  global_movement_title: 'Únete a un movimiento global',
  global_movement_description:
    'Únete a una comunidad global y amplifica tu impacto positivo en el medio ambiente a través de dinámicas sociales virales, inspirando a otros a participar en esta iniciativa significativa.',
  ecological_commitment_title: 'Promociona tu compromiso ecológico',
  ecological_commitment_description:
    'Destaca tu compromiso con el medio ambiente; también recibirás un reconocimiento especial que valora y celebra tu contribución a esta noble causa.',
}

export default seed_game_explanation_page
