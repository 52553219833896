import * as React from 'react'
const SvgSocialMsSkype = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <ellipse cx={10.5} cy={10.75} fill="#184AA9" rx={8.5} ry={8} />
      <circle cx={22} cy={22.75} r={8} fill="#2173F0" />
      <circle cx={16} cy={16.75} r={13} fill="url(#socialMs_skype_svg__a)" />
      <path
        fill="#fff"
        d="M16.069 9.75c2.113 0 4.931 1 4.227 2.333-.963 1.823-2.818 0-4.227 0s-2.114.667-2.114 1.334c0 .666.353 1.333 2.114 2 1.76.666 4.931 1.666 4.931 4.333s-2.466 4-5.636 4c-2.84 0-4.93-1.333-4.227-2.667.81-1.533 2.818.334 4.227.334s2.818-.334 2.818-1.667-2.113-1.667-3.522-2.333c-1.761-.834-3.523-1.667-3.523-4S13.955 9.75 16.07 9.75"
      />
      <defs>
        <linearGradient
          id="socialMs_skype_svg__a"
          x1={5.889}
          x2={25.389}
          y1={8.806}
          y2={25.056}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#297AC0" />
          <stop offset={1} stopColor="#48B0F9" />
        </linearGradient>
      </defs>
    </g>
  </svg>
)
export default SvgSocialMsSkype
