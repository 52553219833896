import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'
import ShapeChoice from '@/Components/ShapeChoice'
import styled, { css } from 'styled-components'

const Preview = styled.div`
  ${({ theme }) => css`
    border-color: ${theme.colors.grey2};
    border-width: 1px;
    width: 80px;
    height: 80px;
    background-color: ${theme.colors.white};
  `}
`

const AvatarShapeChoice = props => {
  const cosmetics = useCosmetics()
  const name = 'shape-avatar'
  return (
    <ShapeChoice
      {...props}
      name={name}
      shapes={cosmetics[name]}
      preview={Preview}
    />
  )
}

export default AvatarShapeChoice
