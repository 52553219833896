const quests_titles = {
  complete_welcome_quests: 'Complete all your welcome quest',
  complete_daily_quests: 'Complete all your daily quest',
  add_post: 'Add {{goal}} post{{plurial}}',
  add_cover_picture: 'Add {{goal}} cover picture{{plurial}}',
  add_profile_picture: 'Add {{goal}} profile picture{{plurial}}',
  add_business_name: 'Add {{goal}} business name{{plurial}}',
  add_job: 'Add your job',
  add_description: 'Add a description',
  add_social_content: 'Add {{goal}} social content{{plurial}}',
  add_contact_content: 'Add {{goal}} contact content{{plurial}}',
  add_following: 'Follow {{goal}} user{{plurial}}',
  add_post_to_bookmark: 'Add {{goal}} post{{plurial}} to bookmarks',
  add_image_to_bookmark:
    'Add {{goal}} image{{plurial}} from a gallery to bookmarks',
  add_drop_to_post: 'Water {{goal}} post{{plurial}}',
  get_view: 'Get {{goal}} view{{plurial}}',
  get_follower: 'Get {{goal}} follower{{plurial}}',
  get_drop_to_post: 'Get your posts watered {{goal}} time{{plurial}}',
  watch_add: 'Watch {{goal}} ad{{plurial}}',
  gain_drop: 'Gain {{goal}} drop{{plurial}}',
  gain_seed: 'Gain {{goal}} seed{{plurial}}',
  gain_coin: 'Gain {{goal}} coin{{plurial}}',
  get_sponsorship: 'Sponsor {{goal}} user{{plurial}}',
  play_game: 'Play a game',
  use_coin: 'Spend {{goal}} coins',
  create_ai_image: 'Create {{goal}} image{{plurial}} with AI',
  verify_account: 'Verify your account',
  claim_daily_resources: 'Claim your daily resources',
}


export default quests_titles
