import { Slide } from '@mui/material'
import { StyledDialog } from './StyledModalContent'
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ModalWrapper = ({
  isOpen,
  onDismiss,
  keepMounted = true,
  width,
  children,
}) => {
  return (
    <StyledDialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
      onClose={onDismiss}
      width={width}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {children}
    </StyledDialog>
  )
}
