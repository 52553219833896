import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineSeed = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="m12.917 8.974.155.966.692-.691A5.98 5.98 0 0 1 17.997 7.5h2.501a1.5 1.5 0 0 1 1.5 1.5v.5a6 6 0 0 1-6 6h-3V20a.5.5 0 0 1-1 0v-7.5h-2.5a6.5 6.5 0 0 1-6.5-6.5V5a1.5 1.5 0 0 1 1.5-1.5h2a6.5 6.5 0 0 1 6.42 5.474ZM12.998 14v.5h3a5 5 0 0 0 5-5 1 1 0 0 0-1-1h-2a5 5 0 0 0-5 5zm-1.5-2.5h.5V10a5.5 5.5 0 0 0-5.5-5.5h-1a1.5 1.5 0 0 0-1.5 1.5 5.5 5.5 0 0 0 5.5 5.5z"
        />
      </g>
    </svg>
  )
}
export default SvgLineSeed
