const getCosmeticsByUid = build =>
  build.query({
    query: ({ uid }) => {
      return {
        url: `/cosmetics/user/${uid}`,
        method: 'GET',
      }
    },
    providesTags: ['Cosmetics'],
  })

export default getCosmeticsByUid
