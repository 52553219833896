import * as React from 'react'
const SvgBolt = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 12})`}>
      <g clipPath="url(#bolt_svg__a)">
        <path
          fill="url(#bolt_svg__b)"
          fillRule="evenodd"
          d="M4.042 1.3a.5.5 0 0 1 .462-.3h3.988c.375 0 .62.395.451.731L7.81 4h1.679c.45 0 .675.544.357.862l-5.977 5.977c-.377.377-1.004-.011-.835-.517L4.306 6.5H2.508a.505.505 0 0 1-.461-.71z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="m3.387 5.157 1.3-3.25a.25.25 0 0 1 .232-.157h.462a.25.25 0 0 1 .232.343l-1.3 3.25a.25.25 0 0 1-.232.157h-.462a.25.25 0 0 1-.232-.343"
        />
      </g>
      <defs>
        <linearGradient
          id="bolt_svg__b"
          x1={9.994}
          x2={0.212}
          y1={10.989}
          y2={8.721}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9400" />
          <stop offset={1} stopColor="#FFAB38" />
        </linearGradient>
        <clipPath id="bolt_svg__a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </g>
  </svg>
)
export default SvgBolt
