const companion_descriptions = {
  'aloe vera':
    'Aloe Vera is a companion originating from desert regions. It has a tender heart and is often described as soothing by other companions.',
  dianthus:
    'Dianthus is delicate and elegant, with colorful and fragrant petals. She uses her charm to calm conflicts and bring peace.',
  nymphaea:
    'Nymphaea is an aquatic companion, allowing it to live and move easily in water. Its aquatic abilities serve as rafts or shields.',
  oak: 'Oak is known for its strength and resilience. With a thick bark and sturdy branches, Oak is a natural protector and a solid pillar for its companions.',
  palmatum:
    'Palmatum is a companion from Japan. Agile and graceful, it uses its sharp leaves like blades to defend and explore its environment.',
}

export default companion_descriptions
