import ThumbnailPreview from '@/Components/ThumbnailPreview'
import { useTranslation } from 'react-i18next'
import InputTitle from './InputTitle'
import SelectPlatform from './SelectPlatform'
import InputValue from './InputValue'
import SwitchIsIntegratedContent from './SwitchIsIntegratedContent'
import InputUrl from './InputUrl'
import SwitchIsFastLink from './SwitchIsFastLink'

const Content = ({
  valueLabel,
  type,
  icon,
  label,
  placeholder,
  thumbnail,
  setThumbnail,
  contentLabel,
  setContentLabel,
  contentURL,
  setContentURL,
  value,
  setValue,
  platform,
  setPlatform,
  isIntegrated,
  setIsIntegrated,
  isFastLink,
  setIsFastLink,
  placeholderTitle,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex-1 mb-20">
      <ThumbnailPreview
        icon={icon}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        label={t(label)}
      />
      <InputTitle
        label={t(placeholderTitle) || t(label)}
        contentLabel={contentLabel}
        setContentLabel={setContentLabel}
        insideModal
      />
      <SelectPlatform
        label={label}
        platform={platform}
        setPlatform={setPlatform}
      />
      <InputValue
        type={type}
        label={label}
        valueLabel={t(valueLabel)}
        placeholder={t(placeholder)}
        value={value}
        setValue={setValue}
      />
      <SwitchIsIntegratedContent
        isIntegrated={isIntegrated}
        setIsIntegrated={setIsIntegrated}
        label={label}
      />
      <InputUrl
        type={type}
        label={label}
        contentURL={contentURL}
        setContentURL={setContentURL}
        insideModal
      />
      <SwitchIsFastLink
        isFastLink={isFastLink}
        setIsFastLink={setIsFastLink}
        type={type}
        label={label}
      />
    </div>
  )
}

export default Content
