import { LineArrowBottom } from '@/Assets/icons/components'
import React from 'react'
import Menu from './Menu'
import { useTheme } from '@/Hooks'

export const Select = ({
  value,
  placeholder = 'Sélectionnez',
  disabled = false,
  options = [],
  onSelect,
  variant = 'primary',
  ...props
}) => {
  const { theme } = useTheme()

  const selectOptions = options.map(option => ({
    ...option,
    onClick: () => {
      onSelect(option)
    },
  }))

  return (
    <Menu
      {...props}
      className="h-12 rounded-[20px] w-full"
      color={variant === 'primary' ? theme.colors.grey1 : theme.colors.white}
      selected={value}
      label={value?.label || placeholder}
      afterIcon={LineArrowBottom}
      options={selectOptions}
    />
  )
}
