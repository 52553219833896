import * as React from 'react'
const SvgSocialSubstack = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        fill="#ff6712"
        d="M2.793 14.68v7.66h26.415v-7.66zM2.793 8.34v3.17h26.415V8.34ZM2.793 2v3.17h26.415V2ZM2.793 22.34h26.415V30L16 22.34 2.793 30Z"
        style={{
          strokeWidth: 0.264151,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialSubstack
