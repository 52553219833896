const getUser = build =>
  build.query({
    query: userId => {
      return {
        url: `/users/${userId}`,
        method: 'GET',
      }
    },
    providesTags: ['User'],
  })

export default getUser
