import * as React from 'react'
const SvgSocialMail = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <path
        fill="#fff"
        d="M20.4 2.75h-8.8c-3.36 0-5.04 0-6.324.654a6 6 0 0 0-2.622 2.622C2 7.31 2 8.99 2 12.35v8.8c0 3.36 0 5.04.654 6.324a6 6 0 0 0 2.622 2.622c1.284.654 2.964.654 6.324.654h8.8c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622C30 26.19 30 24.51 30 21.15v-8.8c0-3.36 0-5.04-.654-6.324a6 6 0 0 0-2.622-2.622C25.44 2.75 23.76 2.75 20.4 2.75"
      />
      <path
        fill="url(#socialMail_svg__a)"
        d="M20.4 2.75h-8.8c-3.36 0-5.04 0-6.324.654a6 6 0 0 0-2.622 2.622C2 7.31 2 8.99 2 12.35v8.8c0 3.36 0 5.04.654 6.324a6 6 0 0 0 2.622 2.622c1.284.654 2.964.654 6.324.654h8.8c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622C30 26.19 30 24.51 30 21.15v-8.8c0-3.36 0-5.04-.654-6.324a6 6 0 0 0-2.622-2.622C25.44 2.75 23.76 2.75 20.4 2.75"
      />
      <path
        fill="url(#socialMail_svg__b)"
        fillRule="evenodd"
        d="m13.69 18.458-1.248-1.249-5.719 5.719q.042.025.086.047c.3.153.691.153 1.475.153h15.431c.785 0 1.177 0 1.476-.153q.044-.022.086-.047l-5.719-5.719-1.248 1.249a3.25 3.25 0 0 1-2.31.957 3.26 3.26 0 0 1-2.31-.957m12.066 3.99.047-.085c.152-.3.152-.691.152-1.475v-8.276c0-.784 0-1.176-.152-1.475a1 1 0 0 0-.062-.11l-5.704 5.704zM25.3 10.588a1 1 0 0 0-.11-.062c-.3-.153-.691-.153-1.476-.153H8.285c-.785 0-1.177 0-1.476.153q-.056.029-.11.062l7.431 7.43a2.63 2.63 0 0 0 2.375.727c.5-.097.978-.339 1.365-.726zm-19.042.44 5.704 5.704-5.719 5.718-.047-.086c-.153-.3-.153-.691-.153-1.475v-8.276c0-.784 0-1.176.153-1.475q.03-.057.062-.11"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="socialMail_svg__a"
          x1={16}
          x2={16}
          y1={2.75}
          y2={30.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A6AE7" />
          <stop offset={1} stopColor="#20C2F4" />
        </linearGradient>
        <linearGradient
          id="socialMail_svg__b"
          x1={16}
          x2={16}
          y1={10.372}
          y2={23.128}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset={1} stopColor="#F9F9F9" />
        </linearGradient>
      </defs>
    </g>
  </svg>
)
export default SvgSocialMail
