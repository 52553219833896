const items = {
  address: 'Su domicilio',
  phone: 'Su teléfono',
  mail: 'Su correo',
  skype: 'Nombre de usuario o número de teléfono',
  discord: 'Su pseudo',
  site: 'Su url',
  placeholder_url: 'https://ejemplo.com/',
  site_title: 'Título del sitio',
  platform: 'Plataforma',
}


export default items
