import { Config } from '@/Config'
import { storage } from '@/Firebase/Storage'
import { useExplicitImageMutation } from '@/Services/modules/explicitImage'
import { isBase64 } from '@/Utils/isBase64'
import { randomId } from '@/Utils/randomId'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useTranslation } from 'react-i18next'

const useUploadImage = () => {
  const [explicitImage] = useExplicitImageMutation()
  const { t } = useTranslation()

  const uploadLegacyImage = async ({ uri, id, path }) => {
    try {
      const response = await fetch(uri)
      const blob = await response.blob()
      const imageRef = ref(storage, path)
      const snapshot = await uploadBytes(imageRef, blob)
      const src = await getDownloadURL(snapshot.ref)
      return { id, src }
    } catch (e) {
      console.error(e)
    }
  }

  const uploadBase64Image = async ({ uri, id, path }) => {
    try {
      const reference = storage.ref(path)
      const task = reference.putString(uri, 'data_url')
      await task
      const src = await reference.getDownloadURL()
      return { id, src }
    } catch (e) {
      console.error(e)
    }
  }

  const uploadRegularImage = async ({ uri, id, path }) => {
    try {
      const baseUrl = Config.API_URL
      const response = await fetch(`${baseUrl}/upload/webp-from-uri`, {
        method: 'POST',
        body: JSON.stringify({ path, uri }),
      })
      const data = await response.json()
      const res = await explicitImage({ url: data })
      if (data.error || res.error) {
        throw new Error(data.error || res.error)
      }
      if (res.data.finalDecision !== 'OK') {
        const imageRef = storage.ref(path)
        await imageRef.delete()
        throw new Error(t('toastExplicitImage.please_upload_an_image'))
      }
      return { id, src: data }
    } catch (error) {
      console.error(error)
    }
  }

  const uploadImage = async (uri, legacy) => {
    const id = randomId(10)
    const path = 'images/' + id
    if (legacy) {
      return uploadLegacyImage({ uri, id, path })
    }

    if (isBase64(uri)) {
      return uploadBase64Image({ uri, id, path })
    }

    return uploadRegularImage({ uri, id, path })
  }

  return {
    uploadImage,
  }
}

export default useUploadImage
