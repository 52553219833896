import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineTurnOff = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M18.364 5.364a9.2 9.2 0 0 1 2.463 4.69 9.3 9.3 0 0 1-.512 5.292A9.13 9.13 0 0 1 17 19.456 8.9 8.9 0 0 1 12 21c-1.78 0-3.52-.537-5-1.544a9.13 9.13 0 0 1-3.315-4.11 9.3 9.3 0 0 1-.512-5.292 9.2 9.2 0 0 1 2.463-4.69M12 3v4.727"
        />
      </g>
    </svg>
  )
}
export default SvgLineTurnOff
