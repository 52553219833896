const publication_rules = {
  posting_rules: 'Posting Rules',
  subtitle: {
    main_text:
      'Posting a publication costs you seeds. Here you will find the details of the costs associated with publishing.',
  },
  upload_a_publication: 'Upload a Publication',
  in_order_to_allow:
    'In order to allow other users to water your publication, you will need 1 seed to upload it.',
  cost: 'Cost: 1 seed',
  write_a_description: 'Write a Description',
  every_100_characters: 'Every 100 characters, you will be charged 1 seed.',
  character_cost: 'Cost: 1 seed / 100 characters',
  add_hashtags: 'Add Hashtags',
  hashtags_allow:
    'Hashtags allow you to highlight yourself on specific words, each hashtag will require 1 seed.',
  hashtag_cost: 'Cost: 1 seed / hashtag',
  add_and_generate: 'Add & Generate Images',
  adding_an_image:
    'Adding an image will cost you 1 seed. To generate an image with our AI, you will need 1 seed per image and coins for the generation.',
  image_cost: 'Cost: 1 seed / image',
}

export default publication_rules
