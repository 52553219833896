import React, { forwardRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { useShareUrl } from '../Panels/PanelShareProfile/ShareUrlContext'
import { Spinner } from '../Spinner'

const options = {
  color: '#363636',
  size: 250,
}

const QRCodePROFILE = forwardRef(
  ({ loading, shareProfileMode, qrcodeImage }, ref) => {
    const { profileUrl } = useShareUrl()
    if (!qrcodeImage || shareProfileMode !== 'PROFILE') {
      return null
    }

    return qrcodeImage ? (
      <img alt="" key={qrcodeImage} className="flex-1" src={qrcodeImage} />
    ) : !loading ? (
      <div ref={ref}>
        <QRCodeCanvas value={profileUrl} {...options} />
      </div>
    ) : null
  },
)

const QRCodeVCARD = forwardRef(({ vcard, shareProfileMode }, ref) => {
  if (!vcard || shareProfileMode !== 'VCARD') {
    return null
  }

  return (
    <div ref={ref}>
      <QRCodeCanvas value={vcard} {...options} />
    </div>
  )
})

export const QRCode = ({
  qrcodeRef,
  vcard,
  qrcodeImage,
  shareProfileMode,
  loading,
}) => {
  return (
    <div className="mt-3 rounded-full h-[250px] w-[250px] items-center justify-center relative flex">
      {loading && <Spinner />}
      <div className="z-10 flex-1 h-[250px] w-[250px] flex">
        <QRCodePROFILE
          qrcodeImage={qrcodeImage}
          shareProfileMode={shareProfileMode}
          loading={loading}
          ref={qrcodeRef}
        />
        <QRCodeVCARD
          ref={qrcodeRef}
          vcard={vcard}
          shareProfileMode={shareProfileMode}
        />
      </div>
    </div>
  )
}
