import addSeedsByUid from './addSeedsByUid'
import addTreeByBuid from './addTreeByBuid'
import getAllTreesCount from './getAllTreesCount'
import getCollectiveTreesByUid from './getCollectiveTreesByUid'
import getDetailsTreesPlantByUser from './getDetailsTreesPlantByUser'
import getDropsByTreeId from './getDropsByTreeId'
import getIndividualTreesByUid from './getIndividualTreesByUid'
import getTreesByUid from './getTreesByUid'
import { api } from '@/Services/api'

export const treesApi = api.injectEndpoints({
  endpoints: build => ({
    addSeedsByUid: addSeedsByUid(build),
    addTreeByBuid: addTreeByBuid(build),
    getDropsByTreeId: getDropsByTreeId(build),
    getAllTreesCount: getAllTreesCount(build),
    getTreesByUid: getTreesByUid(build),
    getDetailsTreesPlantByUser: getDetailsTreesPlantByUser(build),
    getIndividualTreesByUid: getIndividualTreesByUid(build),
    getCollectiveTreesByUid: getCollectiveTreesByUid(build),
  }),
  overrideExisting: true,
})

export const {
  useAddSeedsByUidMutation,
  useAddTreeByBuidMutation,
  useGetDropsByTreeIdQuery,
  useGetAllTreesCountQuery,
  useGetTreesByUidQuery,
  useGetDetailsTreesPlantByUserQuery,
  useGetIndividualTreesByUidQuery,
  useGetCollectiveTreesByUidQuery,
} = treesApi
