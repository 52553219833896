import { Button } from '@/Components/Buttons/Button'
import TextInput from '@/Components/Controls/TextInput'
import { ExpandableBloc } from '@/Components/ExpandableBloc'
import { useActivateProductByCode } from '@/Services/modules/products/useActivateProductByCode'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '../PanelWrapper/Panel'
import { TitleBloc } from '../PanelWrapper/TitleBloc'
import { ProductsSection } from './ProductsSection'

const AddProductButton = profile => {
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const { activateProductByCode, loading } = useActivateProductByCode(profile)
  return (
    <ExpandableBloc
      title="Ajouter un produit"
      subtitle="Entrez le code se situant sur l’emballage du produit"
      className="w-full relative"
    >
      <div className="w-full flex flex-col gap-4">
        <TextInput
          value={code}
          onChange={value => setCode(value.toUpperCase())}
          placeholder={t(
            'parametre.produit_gopop.activer_produit.manuellement.modal.input',
          )}
          variant="secondary"
        />
        <div className="h-12 w-full flex">
          <Button
            label="Enregistrer"
            onClick={() => {
              activateProductByCode(code, () => {
                setCode('')
              })
            }}
            loading={loading}
          />
        </div>
      </div>
    </ExpandableBloc>
  )
}

const PanelProducts = ({ name, children: button, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      {button(setIsOpen)}
      <Panel
        isOpen={isOpen}
        title={t('parametre.produit_gopop.my_products.insight')}
        onDismiss={() => setIsOpen(false)}
        renderCustomFooter={() => <AddProductButton {...props} />}
      >
        <TitleBloc
          title={`Produits de ${name}`}
          subtitle={t('parametre.produit_gopop.my_products.subtitle')}
        />
        <ProductsSection {...props} />
      </Panel>
    </>
  )
}

export default PanelProducts
