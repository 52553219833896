import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import React from 'react'
import styled, { css } from 'styled-components'

const StyledTouchableOpacity = styled(TouchableOpacity)`
  ${({ theme, selected }) => css`
    border: 1px solid ${selected ? theme.colors.primary : 'transparent'};
    border-radius: 16px;
    padding: 8px;
    background-color: ${theme.colors.white};
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`

const OptionItem = ({
  onSelected,
  selected,
  showSelected,
  children,
  ...props
}) => {
  return (
    <StyledTouchableOpacity onClick={onSelected} selected={selected}>
      {children}
    </StyledTouchableOpacity>
  )
}

export default OptionItem
