import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgEyesPswClosed = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 20})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m9.848 5.832 2.863 2.863.013-.15A2.73 2.73 0 0 0 9.998 5.82z"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M9.998 4.001a4.546 4.546 0 0 1 4.544 4.545c0 .586-.118 1.145-.322 1.658l2.658 2.659A10.8 10.8 0 0 0 20 8.546c-1.577-3.99-5.453-6.817-10.002-6.817-1.273 0-2.49.227-3.622.636l1.963 1.959A4.6 4.6 0 0 1 9.998 4M.909 1.524 2.98 3.597l.414.413A10.77 10.77 0 0 0 0 8.546c1.572 3.99 5.453 6.816 9.998 6.816 1.409 0 2.754-.273 3.985-.768l.386.386 2.65 2.654 1.159-1.154L2.068.366zm5.026 5.022L7.339 7.95a3 3 0 0 0-.068.596 2.73 2.73 0 0 0 2.727 2.726c.204 0 .4-.027.59-.068l1.405 1.404c-.605.3-1.277.482-1.995.482a4.546 4.546 0 0 1-4.545-4.544c0-.718.182-1.391.482-2"
        />
      </g>
    </svg>
  )
}
export default SvgEyesPswClosed
