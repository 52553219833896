const tooltip = {
  lock_editing: 'Lock editing',
  lock_editing_tip:
    'By locking this field, your member will no longer be able to edit this information themselves',
  unlock_editing: 'Unlock editing',
  unlock_editing_tip:
    'By unlocking this field, your member will be able to edit this information themselves',
}

export default tooltip
