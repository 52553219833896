import buyCosmetic from './buyCosmetic'
import getCosmeticsByUId from './getCosmeticsByUId'
import { api } from '@/Services/api'

export const cosmeticsApi = api.injectEndpoints({
  endpoints: build => ({
    getCosmeticsByUId: getCosmeticsByUId(build),
    buyCosmetic: buyCosmetic(build),
  }),
  overrideExisting: true,
})

export const { useGetCosmeticsByUIdQuery, useBuyCosmeticMutation } =
  cosmeticsApi
