import React from 'react'
import styled, { css } from 'styled-components'
import { TouchableOpacity } from './Buttons/TouchableOpacity'

const Switch = styled.div`
  ${({ theme, variant, color }) => css`
    width: ${theme.size.l2};
    height: 40px;
    background: ${variant === 'primary'
      ? color || theme.colors.grey1
      : theme.colors.white};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}
`
const StyledIcon = styled(TouchableOpacity)`
  ${({ theme, variant, active, background }) => css`
    width: ${theme.space.xxl};
    height: 38px;
    background: ${active
      ? background || theme.colors.white
      : theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    align-items: center;
    justify-content: center;
    ${variant === 'secondary' &&
    css`
      background: ${active
        ? background || theme.colors.grey1
        : theme.colors.white};
    `}
  `}
`

const IconSwitch = ({ value, left, right, variant = 'primary', color }) => {
  return (
    <Switch variant={variant} color={color}>
      <StyledIcon
        activeOpacity={value === left.condition && 1}
        active={value === left.condition}
        onClick={left.action}
        variant={variant}
      >
        {left.icon}
      </StyledIcon>
      <StyledIcon
        activeOpacity={value === right.condition && 1}
        active={value === right.condition}
        onClick={right.action}
        variant={variant}
        background={right.background}
      >
        {right.icon}
      </StyledIcon>
    </Switch>
  )
}

export default IconSwitch
