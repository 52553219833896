import styled from 'styled-components'

const BackgroundVideo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: opacity 400ms ease 0ms;
  }
  .tiny {
    filter: blur(20px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
  }
`

const VideoBanner = ({ source }) => {
  return (
    <BackgroundVideo>
      <video key={source.uri} autoPlay loop muted playsInline>
        <source src={source.uri} type="video/mp4" />
      </video>
    </BackgroundVideo>
  )
}

export default VideoBanner
