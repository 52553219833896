import * as React from 'react'
const SvgSocialPhone = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        fill="#0f0"
        d="M7.444 30h17.112A5.444 5.444 0 0 0 30 24.556V7.444A5.444 5.444 0 0 0 24.556 2H7.444A5.444 5.444 0 0 0 2 7.444v17.112A5.444 5.444 0 0 0 7.444 30"
        style={{
          strokeWidth: 0.777778,
        }}
      />
      <path
        fill="#fff"
        d="m24.906 21.476-3.588-2.373a1.4 1.4 0 0 0-1.428-.013l-1.538.897a.82.82 0 0 1-.565.113.84.84 0 0 1-.353-.157 25 25 0 0 1-2.886-2.492 25 25 0 0 1-2.493-2.887c-.007-.01-.119-.15-.156-.353a.83.83 0 0 1 .113-.565l.896-1.538c.258-.443.252-.99-.013-1.428l-2.373-3.587a.95.95 0 0 0-1.333-.258s-1.124.769-1.495 1.03c-.594.419-.785 1.055-.786 1.942-.002 1.247 1.073 5.155 5.6 9.683 4.529 4.528 8.436 5.602 9.684 5.6.887 0 1.523-.192 1.941-.786.262-.37 1.03-1.495 1.03-1.495a.95.95 0 0 0-.257-1.333"
        style={{
          strokeWidth: 0.777778,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialPhone
