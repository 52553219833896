const post_details = {
  title: 'Détails du post',
  posted_by: 'Posté par',
  post_history: 'Historique du post',
  water_this_post: 'Arroser ce post',
  see_participants: 'Voir {{quantity}} participant{{plurial}}',
  seed_progress: 'Progression de la graine',
  help_get_tree: 'Aider à obtenir un arbre',
  water_this_post_to_plant: 'Arroser ce post pour planter un nouvel arbre',
}

export default post_details
