import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { ProfileItem } from './ProfileItem'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTranslation } from 'react-i18next'
import SectionTab from './SectionTab'
import { contactItems } from '../../contents'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const ContactItems = styled.div`
  ${({ theme }) => css`
    margin: ${`-${theme.space.xxs}`};
    margin-top: ${theme.space.std};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`

const ContactItem = ({ contact, onAddClick, ...props }) => {
  const { t } = useTranslation()
  const item = contactItems.find(({ type }) => type === contact.type)

  return (
    <ProfileItem
      {...contact}
      label={contact.label}
      icon={contact.thumbnail ? null : item?.icon}
      image={contact.thumbnail ? contact.thumbnail : null}
      item={item}
      format={contact.type === 'phone' ? v => v.code[1] + v.number : undefined}
      onAddClick={() => onAddClick({ contentId: contact.id, type: 'contact' })}
      {...props}
    />
  )
}

const ProfileContacts = memo(({ onAddClick }) => {
  const { profile, canSeeAllContacts } = useProfileContext()
  const { t } = useTranslation()
  return (
    <Wrapper>
      <SectionTab title={t('previewpage.my_contact')}>
        <ContactItems>
          {profile.contacts
            ?.filter(
              contact =>
                contact.enabled &&
                (canSeeAllContacts ? true : !contact.private),
            )
            .sort((a, b) => a.rank - b.rank)
            .map((contact, index) => {
              return (
                <ContactItem
                  key={index}
                  contact={contact}
                  profileDisplay={profile.profileDisplay}
                  onAddClick={onAddClick}
                />
              )
            })}
        </ContactItems>
      </SectionTab>
    </Wrapper>
  )
})

export default ProfileContacts
