import * as React from 'react'
const SvgSocialOnlyfans = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 8.467 8.467"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        d="M4.233 7.938a3.704 3.704 0 1 1 0-7.408 3.704 3.704 0 1 1 0 7.408"
        style={{
          opacity: 1,
          fill: '#00aff0',
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.0823148,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          strokeDasharray: 'none',
        }}
      />
      <path
        d="M3.273 2.477a1.92 1.92 0 1 0 0 3.842 1.92 1.92 0 0 0 0-3.842m0 2.497a.576.576 0 1 1 .002 0z"
        style={{
          opacity: 1,
          fill: '#cceffc',
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.0823148,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          strokeDasharray: 'none',
        }}
      />
      <path
        d="M5.432 3.918c.488.14 1.064 0 1.064 0-.167.73-.697 1.187-1.462 1.242A1.92 1.92 0 0 1 3.273 6.32l.576-1.832c.593-1.882.896-2.01 2.3-2.01h.965c-.161.71-.717 1.254-1.682 1.44"
        style={{
          opacity: 1,
          fill: '#fff',
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.0823148,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          strokeDasharray: 'none',
        }}
      />
    </g>
  </svg>
)
export default SvgSocialOnlyfans
