import { useTheme } from '@/Hooks'
import useEditContent from '@/Hooks/useEditContent'
import { useRef, useState } from 'react'
import { Modal } from './ModalWrapper/Modal'
import { useTranslation } from 'react-i18next'

const ModalImageDeleteConfirmation = ({
  onDelete,
  onDismiss = () => null,
  children: button,
  selectedImage,
}) => {
  const ref = useRef()
  const { theme } = useTheme()
  const { deleteImage } = useEditContent()
  const handleDelete = () => {
    if (onDelete) {
      onDelete()
      return
    }
    deleteImage(selectedImage, ref)
  }
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.imageDeleteConfirmation.title')}
        subtitle={t('modal.imageDeleteConfirmation.subtitle')}
        actions={[
          {
            color: theme.colors.red,
            label: t('modal.btn.cancel'),
            onClick: () => {
              setIsOpen(false)
              onDismiss()
            },
          },
          {
            label: t('modal.btn.confirm'),
            onClick: () => {
              handleDelete()
              setIsOpen(false)
              onDismiss()
            },
            color: theme.colors.primary,
          },
        ]}
      />
    </>
  )
}

export default ModalImageDeleteConfirmation
