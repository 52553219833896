const previewpage = {
  title_tab_bottom: 'Aperçu',
  insight: 'Aperçu du profil',
  my_contact: 'Mes contacts',
  aboutme: 'À propos de moi',
  aboutus: 'À propos de nous',
  activated_account: "Ce profil n'est pas encore activé",
  my_networks: 'Mes réseaux',
  Statistics: 'Statistiques',
  companion: 'Compagnon',
  news_tab_Publications: 'Publications',
  report_title: 'Signaler cet utilisateur',
  report_title_page: 'Signaler',
  no_publications: "Il n'y a pas de publications",
  see_this_profile: {
    seeProfileTitle: "Vous n'êtes pas autorisé à voir ce profil",
    redirectedHomeTitle: "Vous allez être redirigé sur la page d'accueil...",
  },
  blocked_Profile: {
    text: 'Ce profil est bloqué. Si vous souhaitez accéder au contenu complet, veuillez débloquer le profil.',
  },
  follow: {
    pending: 'En attente de validation',
    add_contact: 'Ajouter la fiche contact',
    followed: 'Ajouté',
    no_follow: 'Ajouter',
    following: 'Ajouter à mon réseau',
    title1_page: 'Mon réseau',
    title2_page: 'Réseau',
  },
  newstab: {
    title_tab: 'Actualités',
  },
  preview_stat_tabs: {
    title_tabs1: 'Abonnement',
    title_tabs2: 'Abonné',
    title_tabs3: 'Vue',
  },
  reportModal: {
    title: 'Gérer les interactions avec cet utilisateur',
    subtitle:
      'En signalant cet utilisateur, vous nous aidez à maintenir une communauté respectueuse en nous permettant de modérer ses publications. De plus, si vous souhaitez ne plus voir ses contenus, vous pouvez choisir de le bloquer.',
    blockedTitleBtn1: 'Bloquer cet utilisateur',
    blockedTitleBtn2: 'Débloquer cet utilisateur',
  },
  planted: 'plantés',
}


export default previewpage
