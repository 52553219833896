import TextInput from '@/Components/Controls/TextInput'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Text from '../Texts/Text'
import { TouchableOpacity } from '../Buttons/TouchableOpacity'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.space.xxs};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`

const Tag = styled(TouchableOpacity)`
  ${({ theme }) => css`
    padding: ${theme.space.xs} ${theme.space.std};
    border: 2px solid ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.m};
    display: flex;
    flex-direction: row;
    margin-top: ${theme.space.xs};
    margin-right: ${theme.space.xs};
    align-items: center;
  `}
`

const DeleteTag = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.xs};
    aspect-ratio: 1;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.xs};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}
`

const HashtagList = ({ hashtags = [], onRemove }) => {
  return (
    <Wrapper>
      {hashtags.map((tag, index) => {
        return (
          <Tag
            key={index}
            onClick={e => {
              e.preventDefault()
              onRemove(tag)
            }}
          >
            <Text className="mr-2">{tag}</Text>
            <DeleteTag>
              <Text className="text-center leading-4 pt-0.5">×</Text>
            </DeleteTag>
          </Tag>
        )
      })}
    </Wrapper>
  )
}

const changeSpecialCharacters = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^A-Za-z0-9-]+/g, '')
}

const format = v => {
  const hashtag = changeSpecialCharacters(v)
  const formattedHashtag = `#${hashtag}`
  return formattedHashtag
}

const validate = ({ values, value, hashtags, formattedHashtag, t }) => {
  if (values.length > 3) {
    return t('edit_profil.informations.hashtags.error1')
  }
  if (value.length < 3 || value.length > 12) {
    return t('edit_profil.informations.hashtags.error2')
  }
  if (hashtags.includes(formattedHashtag)) {
    return t('edit_profil.informations.hashtags.error3')
  }
}

const HashtagField = ({ hashtags = [], onChange, children, ...props }) => {
  const [value, setValue] = useState('')
  const { t } = useTranslation()
  const disabled = hashtags.length >= 3

  const handleAddHashTag = useCallback(
    hashtag => {
      const formattedHashtag = format(hashtag)
      const values = [...hashtags, formattedHashtag]
      const error = validate({
        values,
        value,
        hashtags,
        formattedHashtag,
        t,
      })
      if (error) {
        return
        // TODO TOAST
        // Toast.show({
        //   type: 'error',
        //   text1: error,
        //   props: {
        //     closable: true,
        //   },
        // })
      }
      onChange(values)
      setValue('')
    },
    [hashtags, onChange, value, t],
  )

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (value) {
          handleAddHashTag(value)
        }
      }
    },
    [handleAddHashTag, value],
  )

  const handleChange = useCallback(
    (v, { submit } = {}) => {
      if (!v) {
        return setValue('')
      }
      if (v.indexOf(' ') === -1 && !submit) {
        return setValue(v)
      }
      handleAddHashTag(v)
    },
    [handleAddHashTag],
  )

  const handleRemove = tag => {
    onChange(hashtags.filter(item => item !== tag))
  }

  return (
    <div className="relative flex flex-col">
      <TextInput
        placeholder={t('profilepage.galerie.edit_photo.add_hashtag')}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        editable={!disabled}
        blurOnSubmit={false}
        onSubmitEditing={() => handleChange(value, { submit: true })}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {children}
      <HashtagList onRemove={handleRemove} hashtags={hashtags} />
    </div>
  )
}

export default HashtagField
