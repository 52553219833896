const getBookmarkByUserId = build =>
  build.query({
    query: ({ userId }) => {
      return {
        url: `/users/${userId}/bookmark`,
        method: 'GET',
      }
    },
    providesTags: ['Bookmarks'],
  })

export default getBookmarkByUserId
