const share_profil = {
  title: 'Partager mon profil',
  share: 'Partager',
  type_profil: {
    share_private_profil: 'Partager mon profil privé',
    share_publique_profil: 'Partager mon profil public',
    private_profil: 'profil privé',
    public_profil: 'profil public',
    share_profil: 'Partager mon profil',
    share_contact_file: 'Partager ma fiche de contact',
  },
  qr_code: {
    my_qrcode: 'MON QR CODE',
    save_img: 'Sauvegarder en image',
    save_wallet: 'Sauvegarder dans son wallet',
  },
  send: {
    send: 'Envoyer',
    send_msg: 'Envoyer via message',
  },
}


export default share_profil
