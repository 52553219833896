const senCodeVerificationByPhone = build =>
  build.mutation({
    query: ({ uid, phone, code }) => {
      return {
        url: `/users/check-code/${uid}/${phone}/${code}`,
        method: 'PUT',
      }
    },
  })

export default senCodeVerificationByPhone
