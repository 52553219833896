const seed_game_explanation_page = {
  title: 'Seed game',
  subtitle: {
    main_text:
      'With our seed game you can play with the community and plant trees for free.\nEach post is a seed that you can water to grow <color>{{1}}</color>',
    1: 'a new tree in real life!',
  },
  plant_seed_title: 'Plant your seed',
  plant_seed_description:
    'Transform an eco-responsible gesture of planting trees into a fun experience, allowing you to actively contribute to the fight against climate change while having fun.',
  global_movement_title: 'Be part of a global movement',
  global_movement_description:
    'Join a global community and amplify your positive impact on the environment through viral social dynamics, inspiring others to take part in this meaningful initiative.',
  ecological_commitment_title: 'Promote your ecological commitment',
  ecological_commitment_description:
    'Highlight your commitment to the environment, you also receive special recognition that values and celebrates your contribution to this noble cause.',
}

export default seed_game_explanation_page
