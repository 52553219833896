import { Spinner } from '@/Components/Spinner'
import Text from '@/Components/Texts/Text'
import { useGetProductsByUserIdQuery } from '@/Services/modules/products'
import React from 'react'
import OptionProduct from './OptionProduct'

const options = {
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  pollingInterval: 3000,
}

export const ProductsSection = ({ uid }) => {
  const { data: products = [], isLoading } = useGetProductsByUserIdQuery(
    uid,
    options,
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="w-full">
      {!isLoading &&
        (products.length ? (
          products.map(product => (
            <OptionProduct
              key={product.id}
              product={product}
              onClick={() => window.open(window.origin + `/${product.linkId}`)}
            />
          ))
        ) : (
          <Text className="text-2xl text-center w-full flex justify-center">
            Aucun produit trouvé
          </Text>
        ))}
    </div>
  )
}
