import * as React from 'react'
const SvgSocialWechat = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <path
        fill="#31CE6F"
        fillRule="evenodd"
        d="M12.008 5.751C6.542 5.671 2 9.59 2 14.211c0 2.668 1.286 4.983 3.776 6.682 0 0-.913 2.938-.9 2.997s.212.174.28.137c.07-.038 3.3-1.917 3.3-1.917 2.313.775 3.725.584 3.966.552l.033-.004a6 6 0 0 1-.225-2.84c.965-5.42 6.652-7.105 9.769-6.817-.86-3.914-4.6-7.17-9.991-7.25m-3.8 4.448c-.716 0-1.298.567-1.298 1.267s.582 1.267 1.299 1.267 1.298-.567 1.298-1.267S8.926 10.2 8.209 10.2m7.86 0c-.717 0-1.298.567-1.298 1.267s.581 1.267 1.298 1.267 1.299-.567 1.299-1.267-.582-1.267-1.299-1.267"
        clipRule="evenodd"
      />
      <path
        fill="#31CE6F"
        fillRule="evenodd"
        d="M30 20.643c0-3.96-3.925-6.993-8.478-6.993-4.82 0-8.665 3.102-8.665 7.06 0 3.967 3.657 7.25 8.67 7.278 1.01.005 2.513-.248 3.527-.66 0 0 2.423 1.433 2.559 1.422a.22.22 0 0 0 .21-.205c.006-.09-.772-2.328-.772-2.328 2.08-1.59 2.949-3.341 2.949-5.574m-11.65-3.707c-.635 0-1.15.502-1.15 1.122s.515 1.123 1.15 1.123 1.148-.503 1.148-1.123-.514-1.122-1.149-1.122m6.141 0c-.634 0-1.148.502-1.148 1.122s.514 1.123 1.148 1.123c.635 0 1.149-.503 1.149-1.123s-.514-1.122-1.149-1.122"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgSocialWechat
