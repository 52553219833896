import { useTheme } from '@/Hooks'
import React from 'react'
import { styled } from 'styled-components'
import Text from '../Texts/Text'
import { TouchableOpacity } from './TouchableOpacity'

const Button = styled(TouchableOpacity)`
  padding: 24px 16px;
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ButtonBloc = ({ title, subtitle, children, ...props }) => {
  const { theme } = useTheme()

  return (
    <Button {...props}>
      <Text className="text-xl">{title}</Text>
      <Text regular color={theme.colors.grey2}>
        {subtitle}
      </Text>
      {children}
    </Button>
  )
}
