import { useEffect, useState } from 'react'
import useCallbackResizer from './useCallbackResizer'

const useGetElementBoundings = ref => {
  const [boundings, setBoundings] = useState({})
  useEffect(() => {
    if (!ref.current) return

    if (boundings.top && boundings.top > 0) {
      return
    }

    return setBoundings(ref.current.getBoundingClientRect())
  }, [ref, boundings.top])

  useCallbackResizer(() => {
    if (!ref.current) return
    const position = ref.current.getBoundingClientRect()
    if (position.top < 0) {
      return
    }
    return setBoundings(position)
  })

  return boundings
}

export default useGetElementBoundings
