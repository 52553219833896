const plantation_details = {
  title: 'Detalles de plantación',
  posted_by: 'Publicado por',
  planted_by: 'Plantado por',
  you: 'tú',
  see_post: 'Ver publicación',
  planted_on: 'Plantado en',
  tree_project: 'Proyecto de plantado',
  see_more: 'Ver más',
  learn_more: 'Más información',
  location: 'Lugar de plantación',
  locate: 'Localizar',
  participants: 'Participantes',
  cost_planting: 'Costo de plantación',
  no_plantation: 'No hay plantaciones',
}


export default plantation_details
