const share_profil = {
  title: 'Compartir mi perfil',
  share: 'Compartir',
  type_profil: {
    share_private_profil: 'Compartir mi perfil privado',
    share_publique_profil: 'Compartir mi perfil público',
    private_profil: 'perfil privado',
    public_profil: 'perfil público',
    share_profil: 'Compartir mi perfil',
    share_contact_file: 'Compartir mi tarjeta de contacto',
  },
  qr_code: {
    my_qrcode: 'MI CÓDIGO QR',
    save_img: 'Guardar como imagen',
    save_wallet: 'Guarda en tu billetera',
  },
  send: {
    send: 'Enviar a',
    send_msg: 'Enviar por mensaje',
  },
}


export default share_profil
