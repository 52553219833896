import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgEye = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3.907 8.651c3.63-6.201 12.555-6.201 16.186 0a6.63 6.63 0 0 1 0 6.698c-3.63 6.201-12.555 6.201-16.186 0a6.63 6.63 0 0 1 0-6.698"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.557 12.06c0 1.996-1.593 3.613-3.558 3.613s-3.556-1.617-3.556-3.612c0-1.997 1.592-3.614 3.556-3.614 1.965 0 3.558 1.617 3.558 3.614"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
export default SvgEye
