import * as React from 'react'
const SvgFlagFr = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 28})`}>
      <g clipPath="url(#flagFr_svg__a)">
        <rect width={28} height={28} fill="#fff" rx={14} />
        <path
          fill="#1A47B8"
          fillRule="evenodd"
          d="M-5.6 0H7.467v28H-5.6z"
          clipRule="evenodd"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M20.533 0H33.6v28H20.533z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="flagFr_svg__a">
          <rect width={28} height={28} fill="#fff" rx={14} />
        </clipPath>
      </defs>
    </g>
  </svg>
)
export default SvgFlagFr
