const benefitspage = {
  title: 'Mes avantages',
  input: {
    search: 'Rechercher un avantage',
  },
  filter: {
    title: 'Filtre',
    date: {
      title: 'Date',
      all_date: 'Toutes les dates',
      week: 'Cette semaine',
      last_week: 'Semaine dernière',
      last_two_week: 'Les deux dernières semaines',
      last_month: 'Le dernier mois',
      last_three_month: 'Les trois derniers mois',
      last_six_month: 'Les six derniers mois',
      valid: 'Valider',
    },
    categories: {
      title: 'Catégories',
      all: 'Toutes les catégories',
      travel: 'Voyage',
      food: 'Nourriture',
      works: 'Travail',
      no_cate: 'Sans catégorie',
    },
    offers: {
      title: "Types d'offre",
      all: 'Tous les types',
      coupon: 'Coupon',
      offer: 'Offre',
    },
    type: {
      title: 'Type',
    },
  },
  benefits_title: 'Tous nos avantages',
  copy_benefits: 'Avantage copié !',
  benefit_btn: 'En profiter',
  no_result: 'Aucun résultat trouvé',
}


export default benefitspage
