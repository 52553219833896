import React from 'react'
import styled, { css } from 'styled-components'
import TextInput from './TextInput'
import { InputMasked } from './InputMasked'

const StyledInputAdornment = styled.div`
  ${({ theme, adornment, error }) => css`
    padding-left: ${adornment ? theme.space.xl : theme.space.none};
    position: relative;
    background: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    align-items: center;
    height: ${theme.size.m};
    border: ${error
      ? `1px solid ${theme.colors.red}`
      : '1px solid transparent'};

    @media screen and (max-height: 732px) {
      height: 40px;
    }
  `}
`
const Icon = styled.div`
  ${({ theme }) => css`
    left: 16px;
    position: absolute;
  `}
`

export const InputAdornment = ({
  adornment,
  children,
  childrenClassName,
  ...props
}) => {
  const Input = props.mask ? InputMasked : TextInput
  return (
    <StyledInputAdornment
      error={props.error}
      adornment={adornment}
      className={childrenClassName}
    >
      {adornment && <Icon>{adornment()}</Icon>}
      <Input {...props} className="w-full" />
      {children}
    </StyledInputAdornment>
  )
}
