import ProfileTypeSelectorSection from './ProfileTypeSelector'
import ProfileSection from './Profile'
import CompleteProfileSection from './CompleteProfile'
import AboutYouProfileSection from './AboutYouProfile'
import PreviewProfileSection from './PreviewProfile'
import EmailVerificationSection from './EmailVerification'

const formSteps = [
  ProfileTypeSelectorSection,
  ProfileSection,
  CompleteProfileSection,
  AboutYouProfileSection,
  PreviewProfileSection,
  EmailVerificationSection,
]

export default formSteps
