import { Button } from '@/Components/Buttons/Button'
import React from 'react'
import { styled } from 'styled-components'

const Footer = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey1}`};
  width: 100%;
  padding: 16px 40px 24px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
`

export const ModalFooter = ({ renderCustomFooter, actions, loading }) => {
  if (!actions && !renderCustomFooter) return null

  return (
    <Footer>
      {renderCustomFooter?.()}
      <div className="flex w-full gap-2">
        {actions?.map((action, i) => {
          if (action.custom) {
            return action.custom
          }
          return (
            <div key={i} className="h-12 flex flex-1">
              <Button
                key={action.label}
                {...action}
                loading={
                  (loading && i === actions.length - 1) || action.loading
                }
                disabled={action.disabled || loading}
              />
            </div>
          )
        })}
      </div>
    </Footer>
  )
}
