import React, { useState } from 'react'
import { Modal } from './ModalWrapper/Modal'
import ColorPicker from '../ColorPicker'
import { useTranslation } from 'react-i18next'

const ColorPickerModal = ({ value, onChange, children: button }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      {button(setIsOpen)}
      <Modal
        title={t('edit_profil.appearance.select_color')}
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        width="420px"
      >
        <div className="flex justify-center">
          <ColorPicker value={value} onChange={onChange} />
        </div>
      </Modal>
    </>
  )
}

export default ColorPickerModal
