import * as React from 'react'
const SvgSocialLinkedin = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <rect width={28} height={28} x={2} y={2.75} fill="#1275B1" rx={14} />
      <path
        fill="#fff"
        d="M12.619 10.442c0 .935-.81 1.692-1.81 1.692-.999 0-1.809-.757-1.809-1.692s.81-1.692 1.81-1.692c.999 0 1.809.758 1.809 1.692M9.247 13.378h3.093v9.372H9.247zM17.32 13.378h-3.093v9.372h3.093v-4.795c0-1.107.378-2.22 1.886-2.22 1.705 0 1.695 1.45 1.687 2.572-.01 1.467.014 2.965.014 4.443H24v-4.946c-.026-3.159-.85-4.614-3.557-4.614-1.608 0-2.604.73-3.123 1.39z"
      />
    </g>
  </svg>
)
export default SvgSocialLinkedin
