import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import React from 'react'
import styled, { css } from 'styled-components'

const PolygonWrapper = styled(TouchableOpacity)`
  ${({ theme, rotate }) => css`
    width: 89px;
    height: 89px;
    position: relative;
    overflow: hidden;
    transform: rotate(${rotate});
  `}
`

const PolygonTop = styled.div`
  ${({ theme, bg }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    height: 65%;
    border-style: solid;
    border-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    background: ${bg ? bg : theme.colors.white};
    z-index: 1;
  `}
`

const PolygonBottom = styled.div`
  ${({ theme, bg }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 65%;
    height: 65%;
    border-style: solid;
    border-width: 1px;
    border-top-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
    background: ${bg ? bg : theme.colors.white};
    z-index: 1;
  `}
`

const PolygonRight = styled.div`
  ${({ theme, bg }) => css`
    position: absolute;
    bottom: 9px;
    right: 7px;
    width: 55%;
    height: 50%;
    border-top-color: black;
    transform: rotate(135deg);
    border: 1px solid black;
    background: ${bg ? bg : theme.colors.white};
  `}
`

const PolygonLeft = styled.div`
  ${({ theme, bg }) => css`
    position: absolute;
    top: 9px;
    left: 7px;
    width: 55%;
    height: 50%;
    transform: rotate(135deg);
    border: 1px solid transparent;
    border-bottom-color: black;
    background: ${bg ? bg : theme.colors.white};
  `}
`

const Polygon = ({ rotate, bg, ...props }) => {
  return (
    <PolygonWrapper rotate={rotate ? '90deg' : '0deg'} {...props}>
      <PolygonTop bg={bg} />
      <PolygonBottom bg={bg} />
      <PolygonRight bg={bg} />
      <PolygonLeft bg={bg} />
    </PolygonWrapper>
  )
}

export default Polygon
