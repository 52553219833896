const createQrCode = build =>
  build.query({
    query: qrcodeData => {
      return {
        url: `/qrcode`,
        method: 'POST',
        body: qrcodeData,
      }
    },
  })

export default createQrCode
