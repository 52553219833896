const discoverpage = {
  discover: 'Descubrir',
  title: 'Para ti',
  follow: 'Seguir',
  unfollow: 'Dejar de seguir.',
  pending: 'En espera',
  empty_results: {
    no_user_found: 'Usuario no encontrado',
    no_results_matched:
      'No hay resultados que coincidan con tu búsqueda. Por favor, inténtalo de nuevo.',
  },
}


export default discoverpage
