const image_style_page = {
  polaroid: 'Polaroid',
  steamPunk: 'SteamPunk',
  mythological: 'Mitológico',
  futurist: 'Futurista',
  three_d_render: 'Renderizado 3D',
  mystical: 'Místico',
  origami: 'Origami',
  graffiti: 'Graffiti',
  cartoon: 'Dibujo animado',
  comic_book: 'Cómic',
  neo: 'Neo',
  epic: 'Épico',
  van_gogh: 'Van Gogh',
  hyper_realistic: 'Híper realista',
  oil_painting: 'Pintura al óleo',
  manga_color: 'Manga en color',
  search_style: 'Buscar un estilo',
}

export default image_style_page
