const games_page = {
  btn_play: 'Jouer',
  tab_details: 'Détails',
  tab_ranking: 'Classement',
  tab_Historical: 'Historique',
  cost_game_ticket: '1 ticket',
  cost_game_bolt: '25% de vitalité de votre compagnon',
  costs_per_game: 'Coûts par partie',
  winnings_per_game: 'Gains par partie',
  your_best_score: 'Ton meilleur score',
  gain_x2: 'Gain x2',
}

export default games_page
