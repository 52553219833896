const addImageByUid = build =>
  build.mutation({
    query: ({ uid, image }) => {
      return {
        url: `/users/${uid}/image`,
        method: 'POST',
        body: image,
      }
    },
    invalidatesTags: ['ImagesByHashtag'],
  })

export default addImageByUid
