import { useDebouncedState } from '@/Hooks'
import { memo, useCallback, useEffect, useState } from 'react'
import { Modal } from './ModalWrapper/Modal'
import SearchBar from '../Controls/SearchBar'
import { InfiniteScroll } from './ModalImageOptions/ImageOptions/InfiniteScroll'
import { LineClose } from '@/Assets/icons/components'
import IconButton from '../Buttons/IconButton'

const ModalSearchList = memo(
  ({
    isOpen,
    onDismiss,
    onFetch,
    renderItem = () => null,
    specificGetItems,
    children,
    ...props
  }) => {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const [query, setQuery] = useDebouncedState('')
    const [page, setPage] = useState(1)
    const [items, setItems] = useState([])

    const handleDismiss = () => {
      setSearch('')
      setQuery('')
      setPage(1)
      onDismiss()
    }

    const onGetItems = useCallback(async () => {
      setLoading(true)
      setPage(1)
      const newItems = await onFetch({ query, page: 1 })
      setItems(newItems)
      setLoading(false)
    }, [query, onFetch])

    const onPageChange = useCallback(async () => {
      const newItems = await onFetch({ query, page })
      setItems(currentItems => [...currentItems, ...newItems])
      setLoading(false)
    }, [query, onFetch, page])

    useEffect(() => {
      onGetItems()
    }, [onGetItems])

    useEffect(() => {
      if (page !== 1) {
        onPageChange()
      }
    }, [onPageChange, page])

    useEffect(() => {
      setQuery(search)
    }, [search, setQuery])

    return (
      <Modal
        isOpen={isOpen}
        title
        displayCloseButton={false}
        onDismiss={onDismiss}
        header={
          <div className="flex items-center px-10 pt-6 -mb-4">
            <div className="w-full flex items-center mr-2">
              <SearchBar value={search} onChange={setSearch} insideModal />
            </div>
            <IconButton icon={<LineClose />} onClick={onDismiss} />
          </div>
        }
        scrollable={false}
      >
        <div className="h-96">
          <InfiniteScroll
            setPage={setPage}
            loading={loading}
            setLoading={setLoading}
          >
            {items?.map(item =>
              renderItem({ ...item, onDismiss: handleDismiss }),
            )}
          </InfiniteScroll>
        </div>
      </Modal>
    )
  },
)

export default ModalSearchList
