import React, { useMemo, useState } from 'react'
import { useOnboardingContext } from './OnBoardingContext'
import { format } from 'date-fns'
import { parse } from 'date-fns'
import { DatePicker } from '../DatePicker'
import { Popover } from '@mui/material'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const StyledPopover = styled(Popover)`
  ${({ theme }) => css`
    .MuiPaper-root {
      background-color: ${theme.colors.grey1};
    }
  `}
`
export const DatePickerPopoverButton = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const { values, setValues } = useOnboardingContext()
  const setDate = val => {
    if (!val) {
      return setValues({
        ...values,
        birthday: '',
      })
    }
    const formattedDate = format(val, 'dd/MM/yyyy')
    setValues({
      ...values,
      birthday: formattedDate,
    })
  }
  const date = useMemo(
    () =>
      values.birthday.length === 10
        ? parse(values.birthday, 'dd/MM/yyyy', new Date())
        : '',
    [values.birthday],
  )

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <div
        aria-describedby={id}
        className="absolute right-4 cursor-pointer"
        onClick={event => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}
      >
        <Text
          className="font-light text-xs"
          regular
          color={theme.colors.primary}
          aria-describedby={id}
        >
          {t('onboarding.calendar')}
        </Text>
      </div>

      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePicker
          open={anchorEl}
          selectedDate={date}
          setSelectedDate={setDate}
        />
      </StyledPopover>
    </>
  )
}
