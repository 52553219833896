const companion_descriptions = {
  'aloe vera':
    'Aloe Vera es un compañero originario de las regiones desérticas. Posee un corazón tierno y a menudo es descrito como calmante por otros compañeros.',
  dianthus:
    'Dianthus es delicada y elegante, con pétalos coloridos y fragantes. Utiliza su encanto para calmar conflictos y traer paz.',
  nymphaea:
    'Nymphaea es un compañero acuático, lo que le permite vivir y moverse fácilmente en el agua. Sus habilidades acuáticas sirven como balsas o escudos.',
  oak: 'Oak es conocido por su fuerza y resistencia. Con una corteza gruesa y ramas robustas, Oak es un protector natural y un pilar sólido para sus compañeros.',
  palmatum:
    'Palmatum es un compañero originario de Japón. Ágil y grácil, utiliza sus hojas afiladas como cuchillas para defender y explorar su entorno.',
}

export default companion_descriptions
