import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineUserPlus = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M21 12.75h-4m2 2v-4"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M3 19.862c0-2.414 1.697-4.469 4.004-4.85l.208-.034a17.1 17.1 0 0 1 5.576 0l.208.035c2.307.38 4.004 2.435 4.004 4.849 0 1.043-.819 1.888-1.828 1.888H4.828C3.818 21.75 3 20.904 3 19.861ZM14.083 7.688c0 2.174-1.828 3.937-4.083 3.937S5.917 9.862 5.917 7.688 7.745 3.75 10 3.75s4.083 1.763 4.083 3.938Z"
        />
      </g>
    </svg>
  )
}
export default SvgLineUserPlus
