const businessCard = {
  title: 'Retrouvez toutes vos cartes de visite',
  no_business_card_selected: 'Aucune carte de visite sélectionnée',
  subtitle1:
    "Vous n'avez aucune carte de visite. Vous pouvez en ajouter et les personnaliser à votre image afin de les partager avec votre réseau.",
  subtitle2:
    'Retrouvez toutes vos cartes de visite. Personnalisez-les à votre image afin de les partager avec votre réseau.',
  btn_add: 'Ajouter une nouvelle carte',
  left_label: 'Ajouter ma carte',
  title_screen1: 'Ajouter une carte de visite',
  title_screen2: 'Éditer ma carte de visite',
  title_screen3: 'Template',
  title_screen4: 'Informations',
  title_screen5: 'Logo',
  title_screen6: 'Couleur',
  title_screen_preview: 'Carte de visite',
  block_all_fields: 'Bloquer tous les champs.',
  in_multiple_edit_mode:
    'En mode édition multiple, vous ne pouvez ni éditer ni supprimer les cartes de visites',
  create_business_card: 'Créer ma carte de visite',
}

export default businessCard
