import React from 'react'
import { Switch as MUISwitch } from '@mui/material'

const Switch = ({ value, onChange, ...props }) => {
  return (
    <MUISwitch
      onChange={e => onChange(e.target.checked)}
      checked={value}
      {...props}
    />
  )
}

export default Switch
