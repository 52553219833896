import React from 'react'
import IconSwitch from './IconSwitch'
import { LineLock, LineUnlock } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import { useLockedField } from '@/Hooks/useLockedField'
import Text from './Texts/Text'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Tooltip } from './Tooltip'

export const LockedFieldSwitch = ({
  variant = 'primary',
  label,
  isLocked,
  onChange,
  ...props
}) => {
  const { t } = useTranslation()
  const { memberUserId } = useLockedField()
  const { theme } = useTheme()

  if (!memberUserId) return null

  return (
    <div className={clsx(props.className, 'flex justify-between items-center')}>
      {label && (
        <Text color={theme.colors.grey2} className="text-sm font-normal">
          {t(label)}
        </Text>
      )}
      <IconSwitch
        value={isLocked}
        left={{
          icon: (
            <Tooltip
              title={t('tooltip.unlock_editing')}
              placement="top"
              subtitle={t('tooltip.unlock_editing_tip')}
            >
              <LineUnlock color={theme.colors.black} />
            </Tooltip>
          ),
          condition: false,
          action: onChange,
        }}
        right={{
          icon: (
            <Tooltip
              title={t('tooltip.lock_editing')}
              subtitle={t('tooltip.lock_editing_tip')}
            >
              <LineLock
                color={isLocked ? theme.colors.primary : theme.colors.black}
              />
            </Tooltip>
          ),
          condition: true,
          action: onChange,
          background: isLocked
            ? theme.colors.primary + '30'
            : theme.colors.grey1,
        }}
        variant={variant}
      />
    </div>
  )
}
