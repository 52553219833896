import { Option } from '../Option'
import { Modal } from './ModalWrapper/Modal'
import { LineLock, LineUnlock } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import useGetProfileUrl from '@/Hooks/useGetProfileUrl'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ModalProfileMode = ({ children: button, setProfileUrl }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { theme } = useTheme()
  const { currentUser } = useSelector(state => state.user)
  const profileUrlId = useGetProfileUrl(currentUser.id)
  const profileUrlCustomUrl = useGetProfileUrl(currentUser.customUrl)
  const { t } = useTranslation()
  const profileUrlOptions = [
    {
      text: t('share_profil.type_profil.share_publique_profil'),
      icon: <LineUnlock color={theme.colors.black} />,
      onClick: () => {
        setProfileUrl(profileUrlCustomUrl)
        setIsOpen(false)
      },
    },
    {
      text: t('share_profil.type_profil.share_private_profil'),
      icon: <LineLock color={theme.colors.black} />,
      onClick: () => {
        setProfileUrl(profileUrlId)
        setIsOpen(false)
      },
    },
  ]
  return (
    <>
      {button(setIsOpen)}
      <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <div className="flex flex-col gap-3 py-3">
          {profileUrlOptions.map((option, index) => (
            <Option key={index} {...option} />
          ))}
        </div>
      </Modal>
    </>
  )
}

export default ModalProfileMode
