import * as React from 'react'
const SvgSocialViber = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <path
        fill="#BFC8D0"
        fillRule="evenodd"
        d="M30 16.128C30 6.958 26.77 2.75 16 2.75S2 6.957 2 16.128c0 6.528 1.636 10.54 6.462 12.311v3.088c0 1.137 1.426 1.656 2.164.789l2.49-2.925q1.342.116 2.884.116c10.77 0 14-4.207 14-13.379M13.711 27.283q1.07.084 2.289.084c9.112 0 11.846-3.533 11.846-11.237S25.112 4.89 16 4.89 4.154 8.424 4.154 16.128c0 5.446 1.366 8.808 5.384 10.311v4.575c0 .399.5.58.76.276z"
        clipRule="evenodd"
      />
      <path
        fill="#9179EE"
        d="M16 26.605q-1.234 0-2.318-.087l-3.458 4.077a.437.437 0 0 1-.77-.281v-4.652C5.385 24.133 4 20.715 4 15.177 4 7.343 6.77 3.75 16 3.75s12 3.593 12 11.427-2.77 11.428-12 11.428"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M30 15.129C30 5.957 26.77 1.75 16 1.75S2 5.957 2 15.129c0 6.527 1.636 10.54 6.462 12.31v3.088c0 1.137 1.426 1.656 2.164.789l2.49-2.925q1.342.116 2.884.116c10.77 0 14-4.207 14-13.378M13.711 26.282q1.07.084 2.289.084c9.112 0 11.846-3.533 11.846-11.237S25.112 3.89 16 3.89 4.154 7.424 4.154 15.129c0 5.445 1.366 8.807 5.384 10.31v4.575c0 .399.5.58.76.276z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        d="m11.543 12.885-.04.022-.036.03a1.36 1.36 0 0 0-.328 1.633c.302.646.977 1.967 2.162 2.963 1.158.973 2.396 1.496 3.208 1.668l.051.01h.047l.015.003c.02.002.043.006.08.013l.085-.493-.086.493.02.003c.25.044.85.15 1.352-.35.322-.322.617-.657.744-.807a.78.78 0 0 1 .746-.126c.695.235 1.52.765 1.92 1.034.31.208 1.01.71 1.325.937h0c.026.02.088.081.121.167.027.07.041.164-.023.3-.118.255-.48.739-.968 1.163-.498.431-1.016.702-1.43.702h-.005a3 3 0 0 1-.224-.043 9 9 0 0 1-.612-.164 19 19 0 0 1-1.964-.726c-1.565-.673-3.536-1.74-5.381-3.31-1.16-.988-2.256-2.353-3.108-3.632-.86-1.29-1.435-2.432-1.592-2.96l-.01-.036-.017-.035a1.2 1.2 0 0 1-.095-.458.8.8 0 0 1 .175-.53c.42-.503.864-.997 1.845-1.472a1 1 0 0 1 .376-.086c.144-.002.22.034.25.06.375.33.956.91 1.348 1.407a26 26 0 0 1 .912 1.238 4 4 0 0 1 .106.167q.005.008.017.074a1 1 0 0 1 .006.195.5.5 0 0 1-.146.342c-.293.269-.69.517-.846.603Zm4.734-5.619q0 0 0 0a.07.07 0 0 1 .046-.016c3.991 0 7.175 3.038 7.177 6.723l-.003.004a.07.07 0 0 1-.045.015.07.07 0 0 1-.046-.015l-.003-.004c-.001-3.724-3.2-6.69-7.08-6.69a.07.07 0 0 1-.046-.017Zm0 4.13q0 0 0 0v-.002a.07.07 0 0 1 .046-.015c1.569 0 2.788 1.19 2.79 2.594l-.003.004a.07.07 0 0 1-.046.015.07.07 0 0 1-.045-.015l-.003-.004c-.001-1.443-1.236-2.562-2.693-2.562a.07.07 0 0 1-.046-.015Zm0-2.065q0 0 0 0V9.33a.07.07 0 0 1 .046-.015c2.78 0 4.982 2.113 4.983 4.658l-.003.004a.07.07 0 0 1-.045.015.07.07 0 0 1-.045-.015l-.003-.004c-.002-2.583-2.218-4.626-4.887-4.626a.07.07 0 0 1-.046-.015Z"
      />
    </g>
  </svg>
)
export default SvgSocialViber
