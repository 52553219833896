import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgConfidentialyIcon = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m4.653 17.776-.695.283zm-.347-5.629.724.196zm15.388 0-.724.196zm-.347 5.63.695.282zm-5.31 3.815-.116-.741zm-4.073 0 .115-.741zM8.656 8.352l-.15-.735zm6.688 0 .15-.735zm-6.13 13.123-.115.74zm-4.468-3.47.695-.283zm10.04 3.47.115.74zm4.468-3.47-.695-.283zM15.62 8.408l-.15.735zm-7.24 0 .15.735zm-1.486-.227a.75.75 0 1 0 1.5 0zm.75-.303h-.75zm8.712 0h-.75zm-.75.303a.75.75 0 0 0 1.5 0zm-4.754-4.29-.182-.727zm2.296 0 .182-.727zM8.53 9.143l.276-.056-.3-1.47-.275.056zm6.664-.056.276.056.3-1.47-.277-.056zm-.525 11.647-.748.117.231 1.482.75-.117zm-4.59.117-.748-.117-.232 1.482.749.117zm8.573-3.357-.093.228 1.39.565.093-.228zm-13.211.228-.093-.228-1.39.565.093.228zm-.093-.228a8.06 8.06 0 0 1-.318-5.151l-1.448-.392a9.56 9.56 0 0 0 .376 6.108zm13.622-5.151a8.06 8.06 0 0 1-.318 5.15l1.39.566a9.56 9.56 0 0 0 .376-6.108zm-5.05 8.508c-1.271.199-2.569.199-3.84 0l-.232 1.482c1.425.223 2.879.223 4.304 0zM8.807 9.087a16 16 0 0 1 6.388 0l.3-1.47a17.5 17.5 0 0 0-6.987 0zm.525 11.647c-1.775-.278-3.247-1.432-3.89-3.012l-1.39.565c.85 2.088 2.775 3.573 5.048 3.929zm5.57 1.482c2.273-.356 4.198-1.84 5.048-3.929l-1.39-.565c-.643 1.58-2.115 2.734-3.89 3.012zm.57-13.073c1.72.35 3.066 1.6 3.499 3.2l1.448-.392c-.588-2.173-2.398-3.82-4.649-4.278zm-7.24-1.47c-2.251.458-4.06 2.105-4.649 4.278l1.448.392c.433-1.6 1.779-2.85 3.5-3.2zm.163.508v-.303h-1.5v.303zm7.212-.303v.303h1.5v-.303zm-4.572-3.26a4 4 0 0 1 1.932 0l.364-1.454a5.5 5.5 0 0 0-2.66 0zm6.072 3.26c0-2.238-1.571-4.163-3.776-4.714l-.364 1.455c1.574.394 2.64 1.746 2.64 3.259zm-8.712 0c0-1.513 1.066-2.865 2.64-3.26l-.364-1.454c-2.205.551-3.776 2.476-3.776 4.714z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M12 14.25v2"
        />
      </g>
    </svg>
  )
}
export default SvgConfidentialyIcon
