const getUserByUrl = build =>
  build.query({
    query: ({ whitelabel = '', url }) => {
      return {
        url: `/users/url${whitelabel && '?whitelabel=' + whitelabel}`,
        method: 'POST',
        body: { url },
      }
    },
  })

export default getUserByUrl
