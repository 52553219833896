import Text from './Texts/Text'
import React from 'react'
import styled, { css } from 'styled-components'
import { TouchableOpacity } from './Buttons/TouchableOpacity'
import { LineArrowRight } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'

const Option = styled(TouchableOpacity)`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.std} ${theme.space.m};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme.space.std};
    width: 100%;
  `}
`
const Thumbnail = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.s2};
    height: ${theme.size.s2};
    background: ${theme.colors.grey1};
  `}
`
const Title = styled(Text)`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.fontSize.s};
  `}
`
const SubTitle = styled(Text)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.fontSize.xs};
    color: ${theme.colors.grey2} !important;
    margin-top: ${theme.space.xxs};
  `}
`

const ThumbnailOption = ({ title, subtitle, renderThumbnail, ...props }) => {
  const { theme } = useTheme()
  return (
    <Option {...props}>
      <Thumbnail>{renderThumbnail()}</Thumbnail>
      <div className="flex flex-1 flex-row justify-between items-center">
        <div className="flex flex-col ml-3">
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </div>
        <LineArrowRight color={theme.colors.grey2} />
      </div>
    </Option>
  )
}

export default ThumbnailOption
