import { lighten, darken } from 'polished'

export const transformColor = (bg, color1, color2) => {
  const { dark, light } = lightAndDark(color1, color2)
  if (!bg) return '#FFF'
  const result = getG(bg)
  return result ? (result > 186 ? dark || '#000' : light || '#FFF') : '#FFF'
}

export const lightAndDark = (color1, color2) => {
  if (!color1 || !color2) return { dark: '', light: '' }
  const result1 = getG(color1)
  const result2 = getG(color2)

  if (result1 > result2) return { dark: color2, light: color1 }
  else return { dark: color1, light: color2 }
}

export const getG = color => {
  if (!color) return null
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  color = color.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
  return result ? parseInt(result[2], 16) : null
}

export const lightenDarkenColor = (hex = '#FFFFFF', percent) => {
  if (percent > 0) {
    return darken(percent / 100, hex)
  }
  return lighten((percent *= -1 / 100), hex)
}

export const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '')
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100
  }
  return `rgba(${r},${g},${b},${opacity})`
}

export const contrast = (hex1, hex2) => {
  return Math.abs(getG(hex1) - getG(hex2))
}

export const backgroundIsLight = (color, isDarkMode) => {
  if (!color) {
    return null
  }
  const g = getG(color)
  return !isDarkMode ? g > 204 : g < 204
}
