import { useEffect, useMemo, useState } from 'react'
import Colors from './Colors'
import CustomColor from './CustomColor'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from './Texts/Text'

const Title = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.std};
    color: ${theme.colors.black};
    width: 100%;
  `}
`

const ColorSelection = ({
  type,
  colors,
  customColor,
  selectedColor,
  usedColor,
  onAddToCart,
}) => {
  const { t } = useTranslation()
  const isCustomColorSelected = useMemo(
    () =>
      colors.every(
        ({ value: c }) => c.toLowerCase() !== selectedColor.toLowerCase(),
      ),
    [selectedColor, colors],
  )
  const [inputColor, setInputColor] = useState(
    isCustomColorSelected ? selectedColor : customColor.value,
  )

  const handleChange = color => {
    setInputColor(color)
    if (color !== selectedColor && isCustomColorSelected) {
      onAddToCart({
        ...customColor,
        type,
        value: color,
      })
    }
  }

  return (
    <>
      {/* <Title>{t('edit_profil.appearance.color_theme.color')}</Title> */}
      <Colors
        onAddToCart={onAddToCart}
        selectedColor={selectedColor}
        usedColor={usedColor}
        colors={colors}
        isCustomColorSelected={isCustomColorSelected}
        customColor={customColor}
        inputColor={inputColor}
        type={type}
      />
      {isCustomColorSelected && (
        <div className="flex-col w-full flex">
          <div className="mb-3">
            <Title>{t('edit_profil.appearance.color_theme.color_perso')}</Title>
          </div>
          <CustomColor
            isCustomColorSelected={isCustomColorSelected}
            inputColor={inputColor}
            setInputColor={handleChange}
          />
        </div>
      )}
    </>
  )
}

export default ColorSelection
