import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import React from 'react'

export const TitleBloc = ({ title, subtitle }) => {
  const { theme } = useTheme()
  return (
    <div className="flex flex-col mb-10 tablet:mb-5">
      {title && (
        <Text className="text-2xl mb-2 tablet:text-xl tablet:mb-1">
          {title}
        </Text>
      )}
      {subtitle && (
        <Text regular color={theme.colors.grey2}>
          {subtitle}
        </Text>
      )}
    </div>
  )
}
