import React from 'react'
import { useOnboardingContext } from '../OnBoardingContext'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'

import Label from '@/Components/Controls/Label'
import TextArea from '@/Components/Controls/TextArea'
import interests from '@/Utils/interests'
import Interest from '../Interest'
import Text from '@/Components/Texts/Text'
import { useLockedField } from '@/Hooks/useLockedField'
const title = 'onboarding.title'
const subtitle = 'onboarding.step2.help_others'

export const AboutYouProfile = () => {
  const { theme } = useTheme()
  const { values, setValues } = useOnboardingContext()
  const { t } = useTranslation()
  const { isFieldDisabled } = useLockedField()
  const disabled = isFieldDisabled({ fieldName: 'description' })

  return (
    <>
      <div className="mb-6 tablet:mb-3">
        <Label
          name="À propos"
          className="font-light text-grey3 text-sm tablet:text-xs"
        >
          <div className="mb-2">{t('onboarding.about_title')}</div>
          <TextArea
            value={values.description || ''}
            placeholder={t('onboarding.step2.describe_yourself')}
            onChange={value =>
              setValues({
                ...values,
                description: value,
              })
            }
            maxLength={400}
            className="h-40 laptop:h-20"
            resize={false}
            disabled={disabled}
          />
        </Label>
      </div>
      <div className="flex justify-between mb-2">
        <Text
          color={theme.colors.grey3}
          className="font-light text-sm tablet:text-xs"
        >
          {t('onboarding.interests')}*
        </Text>
        <Text
          color={theme.colors.grey3}
          className="font-light text-sm tablet:text-xs"
        >{`${values.tags.length}/3 ${t('onboarding.selected')}`}</Text>
      </div>
      <div className="flex flex-row flex-wrap w-full mb-4 tablet:mb-2">
        {interests.map((interest, index) => {
          const selected = values.tags.includes(interest.value)
          return (
            <Interest
              key={index}
              selected={selected}
              tags={values.tags}
              interest={interest}
              onChange={newTags =>
                setValues(prev => {
                  return { ...prev, tags: newTags }
                })
              }
            />
          )
        })}
      </div>
    </>
  )
}

const AboutYouProfileSection = {
  title,
  subtitle,
  component: AboutYouProfile,
  step: 3,
  getDisabled: values => values.tags?.length < 3,
  section: 1,
}

export default AboutYouProfileSection
