const toasts = {
  toastText: {
    copy_advantage: 'Beneficio copiado!',
    copy_Link: 'Copiado!',
    copy_Code: 'Codigo copiado!',
  },
  toastPsw: {
    title: 'Su contraseña ha sido cambiada !',
    resetTitle:
      'Se le ha enviado un correo electrónico de restablecimiento de contraseña !',
    error1: 'Las contraseñas no son idénticas',
  },
  forgetPsw: {
    title: 'Contraseña olvidada',
    subtitle:
      'Ingrese su dirección de correo electrónico para restablecer su contraseña',
    email: 'Su correo electrónico',
  },
  toastReportPost: {
    option: 'Denuncia del post',
    alreadyReport: 'Este post ya ha sido reportado',
    savedReport: 'Su denuncia fue registrado !',
  },
  toastReportProfile: {
    option: 'Denuncia del perfil',
    alreadyReport: 'Este perfil ya ha sido reportado',
    savedReport: 'Su denuncia fue registrado !',
    blockedsuccess: 'Bloquear con éxito',
    unBlockedsuccess: 'Desbloquear con éxito',
  },
  toastAddContent: {
    error: 'Valor no válido',
  },
  toastModalImage: {
    success: 'La imagen ha sido descargada con éxito!',
  },
  toastImageOriginSelection: {
    success: 'La imagen ha sido descargada con éxito! Considera guardar',
  },
  toastActivateProduct: {
    success: 'Producto activado',
  },
  toastPublicationTime: {
    error: 'Se produjo un error',
  },
  toastWarningBanner: {
    success: 'Se le ha enviado un correo electrónico de confirmación',
  },
  toastQrCode: {
    info_text1: 'QR código guardado !',
    info_text2: 'Su código QR se ha guardado en el rollo de la cámara',
    error: 'Código QR no guardado!',
  },
  toastPersonalInformation: {
    warning_text: 'Por favor, rellene al menos un campo',
  },
  toastErrorDefault: {
    error: 'Se ha producido un error. Inténtelo de nuevo.',
  },
  toastNfcScanner: {
    error1: 'NFC no se puede activar',
    error2: 'Etiqueta no reconocida, inténtelo de nuevo',
  },
  toastTouchId: {
    success: 'Identificación de huellas dactilares activado',
    success1: 'Identificación de huellas dactilares desactivado',
  },
  toastfaceId: {
    success: 'Identificación facial activado',
    success1: 'Identificación facial desactivado',
  },
  toastSendCodeVerification: {
    success: 'Código de verificación enviado exitosamente!',
    error: 'Se produjo un error al enviar.',
  },
  toastCheckCode: {
    success:
      'El código es correcto! Recuerda guardar para confirmar tu verificación.',
    error: 'El código no es correcto!',
  },
  toastOnBoarding: {
    error: 'Nombre de usuario mal formateado',
  },
  toastDropPost: {
    success: 'Felicidades! Este post ha sido regado con éxito.',
  },
  toastUploadImageGallery: {
    you_have_canceled: 'Ha cancelado la selección de imagen',
  },
  toastExplicitImage: {
    please_upload_an_image:
      'Por favor, sube una imagen que cumpla con la política de la aplicación',
  },
  toast_welcome_quest_finished: {
    unlock_feature_complete_quest:
      'Para desbloquear esta función, debes completar las misiones de bienvenida.',
    show_welcome_quest: 'Ver misiones',
  },
}


export default toasts
