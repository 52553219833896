const getQrCodeImageById = build =>
  build.query({
    query: id => {
      return {
        url: `/qrcode/${id}`,
        method: 'GET',
      }
    },
  })

export default getQrCodeImageById
