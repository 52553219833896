import {
  SocialFacebook,
  SocialLinkedin,
  SocialMail,
  SocialTelegram,
  SocialTwitter,
  SocialWhatsapp,
} from '@/Assets/icons/components'
import { useGetBaseUrl } from '@/Hooks'
import useCurrentUser from '@/Hooks/useCurrentUser'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const useSocialMediaOptions = () => {
  const { slug: customUrl } = useParams()
  const { currentUser } = useCurrentUser()
  const baseUrl = useGetBaseUrl()
  const { t } = useTranslation()
  const profileUrl = useMemo(
    () => `${baseUrl}/${customUrl || currentUser?.customUrl}`,
    [baseUrl, currentUser?.customUrl, customUrl],
  )
  const options = useMemo(
    () => [
      {
        label: t('modal.socialMedia.share_on_facebook'),
        icon: SocialFacebook,
        onClick: () =>
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(profileUrl)}`,
            '_blank',
          ),
      },
      {
        label: t('modal.socialMedia.share_on_linkedin'),
        icon: SocialLinkedin,
        onClick: () =>
          window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(profileUrl)}`,
            '_blank',
          ),
      },
      {
        label: t('modal.socialMedia.share_on_twitter'),
        icon: () => <SocialTwitter size={20} className="mr-3" />,
        onClick: () =>
          window.open(
            `https://twitter.com/intent/tweet?url=${encodeURIComponent(profileUrl)}`,
            '_blank',
          ),
      },
      {
        label: t('modal.socialMedia.share_on_whatsapp'),
        icon: SocialWhatsapp,
        onClick: () =>
          window.open(
            `https://wa.me/?text=${encodeURIComponent(profileUrl)}`,
            '_blank',
          ),
      },
      {
        label: t('modal.socialMedia.share_on_telegram'),
        icon: SocialTelegram,
        onClick: () =>
          window.open(
            `https://t.me/share/url?url=${encodeURIComponent(profileUrl)}`,
            '_blank',
          ),
      },
      {
        label: t('modal.socialMedia.share_via_email'),
        icon: SocialMail,
        onClick: () =>
          window.open(
            `mailto:?subject=Check%20this%20out&body=${encodeURIComponent(profileUrl)}`,
            '_blank',
          ),
      },
    ],

    [profileUrl, t],
  )
  return { options, profileUrl }
}

export default useSocialMediaOptions
