import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.space.l};
    padding-bottom: ${theme.space.m};
  `}
`
const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${theme.space.xxs};
    height: ${theme.size.s2};
  `}
`
const TitleText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.std};
    font-weight: ${theme.fontWeight.semibold};
    color: ${theme.colors.grey3};
  `}
`

const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.space.s};
  `}
`

const SectionTab = ({ icon: Icon, title, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Title>
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        <TitleText>{title}</TitleText>
      </Title>
      {children}
    </Wrapper>
  )
}
export default SectionTab
