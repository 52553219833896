import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'member',
  initialState: {
    currentMember: null,
    checkedAllMemberUid: [],
  },
  reducers: {
    updateCurrentMember(state, { payload }) {
      const currentMember = state.currentMember
      state.currentMember = { ...currentMember, ...payload }
    },
    setCurrentMember(state, { payload }) {
      state.currentMember = payload
    },
    setCheckedAllMemberUid(state, { payload }) {
      state.checkedAllMemberUid = payload
    },
  },
})

export const { updateCurrentMember, setCurrentMember, setCheckedAllMemberUid } =
  slice.actions

export default slice.reducer
