import { getFieldValues } from '@/Components/BusinessCardPreview/templates/utils'
import { useEditBusinessCard } from '../../Contexts/EditBusinessCardContext'
import { useTranslation } from 'react-i18next'
import TextInput from '@/Components/Controls/TextInput'

const orderedKeys = [
  'fullName',
  'email',
  'phone',
  'website',
  'job',
  'businessName',
  'address',
  'addressComplement',
  'city',
  'state',
  'zipCode',
]

export const InformationBusinessCard = () => {
  const { editBusinessCard, setEditBusinessCard } = useEditBusinessCard()
  const placeholders = getFieldValues()
  const { t } = useTranslation()

  return (
    <div className="pb-12">
      <div className="px-4 pt-3 pb-6">
        {orderedKeys.map(name => {
          const value = editBusinessCard[name]
          const placeholder = placeholders[name]
          if (!placeholder) {
            return null
          }

          return (
            <TextInput
              key={name}
              className="mb-3"
              placeholder={t(placeholder)}
              value={value || ''}
              onChange={text =>
                setEditBusinessCard({
                  ...editBusinessCard,
                  [name]: text,
                })
              }
              variant="secondary"
            />
          )
        })}
      </div>
    </div>
  )
}
