import * as React from 'react'
const SvgSearch = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <path
        fill="#8E8E95"
        fillRule="evenodd"
        d="M11.525 1.6C6.044 1.6 1.6 6.044 1.6 11.525s4.444 9.925 9.925 9.925a9.925 9.925 0 1 0 0-19.85m0 1.8a8.125 8.125 0 1 1 0 16.25 8.125 8.125 0 0 1 0-16.25m8.62 15.483a.9.9 0 0 0-1.181 1.354l1.9 1.9.092.08a.9.9 0 0 0 1.18-1.353l-1.9-1.9z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgSearch
