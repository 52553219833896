import React from 'react'
import { ContentWrapper, ItemLabel, Wrapper } from './styles'
import { Iframe } from './Iframe'

const appendPlaysInlineParam = (url = '') => {
  if (url.includes('?')) {
    return `${url}&playsinline=1&fs=0`
  } else {
    return `${url}?playsinline=1&fs=0`
  }
}

const IntegratedPreviewItem = ({
  profileDisplay,
  type,
  styles,
  shape,
  label,
  URL,
  platform,
}) => {
  return (
    <Wrapper
      className={profileDisplay === 'icon' && 'aspect-square'}
      profileDisplay={profileDisplay}
    >
      <ContentWrapper
        profileDisplay={'row'}
        type={type}
        styles={styles}
        className={shape.className + ' overflow-hidden'}
      >
        <div className="flex flex-col flex-1">
          <ItemLabel className="p-3">{label}</ItemLabel>
          <Iframe url={appendPlaysInlineParam(URL)} platform={platform} />
        </div>
      </ContentWrapper>
    </Wrapper>
  )
}

export default IntegratedPreviewItem
