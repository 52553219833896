import { ProTextInput } from './ProTextInput'

const InputTitle = ({ contentLabel, setContentLabel, label, ...props }) => {
  return (
    <div className="mt-6 mb-2">
      <ProTextInput
        {...props}
        value={contentLabel}
        onChange={setContentLabel}
        label="Title"
        placeholder={label}
      />
    </div>
  )
}

export default InputTitle
