import SearchBar from '@/Components/Controls/SearchBar'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const AddContentWrapper = ({ searchbar = true, children }) => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation()
  return (
    <Wrapper>
      {searchbar && (
        <SearchBar
          placeholder={t('profilepage.social.search_network')}
          value={search}
          onChange={setSearch}
        />
      )}
      {children(search)}
    </Wrapper>
  )
}

export default AddContentWrapper
