const removeImageByUid = build =>
  build.mutation({
    query: ({ uid, imageId }) => {
      return {
        url: `/users/${uid}/image/${imageId}`,
        method: 'DELETE',
      }
    },
    invalidatesTags: ['ImagesByHashtag'],
  })

export default removeImageByUid
