// import { LineArrowRight } from '@/Assets/Icons/components'
import React from 'react'
import { useProfileContext } from '../Contexts/ProfileContext'
import IntegratedPreviewItem from './IntegratedPreviewItem'
import { NotIntegratedPreviewItem } from './NotIntegratedPreviewItem'
import { shapes } from '@/Utils/shapes'

export const ProfileItem = ({
  image,
  icon,
  label = '',
  value = '',
  type,
  item,
  url,
  ...props
}) => {
  const {
    profile: { profileDisplay = 'row', hideContentsSubtitle = false, styles },
  } = useProfileContext()

  if (!item) {
    return null
  }

  const formattedValue =
    item.formatValue && value
      ? item.formatValue(value)
      : props.platform
        ? value
        : url?.toLowerCase() || value

  const formattedUri = item.formatUri(value)

  const URL = url || formattedUri.web
  const shape = shapes.find(
    btnShape => btnShape.shape === (styles.btnShape || 'shape1'),
  )

  const properties = {
    profileDisplay,
    type,
    styles,
    shape,
    icon,
    image,
    label,
    hideContentsSubtitle,
    formattedValue,
    URL,
  }

  if (props.isIntegrated && profileDisplay === 'row') {
    return <IntegratedPreviewItem {...props} {...properties} />
  }

  return <NotIntegratedPreviewItem {...props} {...properties} />
}
