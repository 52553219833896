import { infiniteScrollOptions } from '../infiniteScrollOptions'

const getDiscoverPosts = build =>
  build.query({
    query: ({ lastDate, userId }) => {
      return {
        url: `/posts/${lastDate}?&userId=${userId}&discover=true`,
        method: 'GET',
      }
    },
    ...infiniteScrollOptions,
  })

export default getDiscoverPosts
