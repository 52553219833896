const activity_stats = {
  level: 'Level',
  rankings: 'Rankings',
  by_exp: 'by experience',
  by_earned_seeds: 'by earned seeds',
  best_contributor: 'best contributor',
  days: 'days',
  current_streak: 'Current streak',
  best_streak: 'Best streak',
  trees_planted: 'Trees planted',
  seeds_earned: 'Seeds earned',
  total_exp_earned: 'Total EXP earned',
  stats: 'Stats',
}

export default activity_stats
