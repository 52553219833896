import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgProfile = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M5 19.112c0-2.414 1.697-4.469 4.004-4.85l.208-.034a17.1 17.1 0 0 1 5.576 0l.208.035c2.307.38 4.004 2.435 4.004 4.849C19 20.155 18.181 21 17.172 21H6.828C5.818 21 5 20.154 5 19.111ZM16.083 6.938c0 2.174-1.828 3.937-4.083 3.937S7.917 9.112 7.917 6.938 9.745 3 12 3s4.083 1.763 4.083 3.938Z"
        />
      </g>
    </svg>
  )
}
export default SvgProfile
