import addMultipleResourceGainsByUid from './addMultipleResourceGainsByUid'
import addResourceGainsByUid from './addResourceGainsByUid'
import getUserProgressDetails from './getUserProgressDetails'
import getUserResourcesGainedByType from './getUserResourcesGainedByType'
import { api } from '@/Services/api'

export const resourceGainsApi = api.injectEndpoints({
  endpoints: build => ({
    addResourceGainsByUid: addResourceGainsByUid(build),
    getUserResourcesGainedByType: getUserResourcesGainedByType(build),
    getUserProgressDetails: getUserProgressDetails(build),
    addMultipleResourceGainsByUid: addMultipleResourceGainsByUid(build),
  }),
  overrideExisting: true,
})

export const {
  useAddResourceGainsByUidMutation,
  useAddMultipleResourceGainsByUidMutation,
  useGetUserResourcesGainedByTypeQuery,
  useGetUserProgressDetailsQuery,
} = resourceGainsApi
