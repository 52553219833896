const actvity_stats = {
  level: 'Nivel',
  rankings: 'Clasificaciones',
  by_exp: 'por experiencia',
  by_earned_seeds: 'por semillas ganadas',
  best_contributor: 'mejor contribuyente',
  days: 'días',
  current_streak: 'Racha actual',
  best_streak: 'Mejor racha',
  trees_planted: 'Árboles plantados',
  seeds_earned: 'Semillas ganadas',
  total_exp_earned: 'Total de experiencia ganada',
  stats: 'Estadísticas',
}

export default actvity_stats
