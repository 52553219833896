import * as React from 'react'
const SvgSocialDribble = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 12.8 12.8"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#100000"
        d="M.8 6.4q0-1.523.75-2.811A5.56 5.56 0 0 1 3.59 1.55Q4.877.8 6.4.8t2.811.75A5.56 5.56 0 0 1 11.25 3.59Q12 4.877 12 6.4t-.75 2.811A5.56 5.56 0 0 1 9.21 11.25Q7.923 12 6.4 12a5.5 5.5 0 0 1-2.811-.75A5.56 5.56 0 0 1 1.55 9.21 5.5 5.5 0 0 1 .8 6.4m.93 0q0 1.747 1.176 3.08.537-1.053 1.702-2.005t2.274-1.198q-.168-.393-.325-.706-1.926.615-4.167.616-.435 0-.65-.011 0 .045-.005.112a1 1 0 0 0-.005.112m.145-1.154q.246.023.728.023 1.87 0 3.55-.504-.85-1.512-1.87-2.52a4.55 4.55 0 0 0-1.517 1.243 4.8 4.8 0 0 0-.89 1.758m1.669 4.839a4.53 4.53 0 0 0 2.856.985q.829 0 1.646-.313a16.5 16.5 0 0 0-.873-3.707q-1.03.224-2.078 1.13-1.047.908-1.551 1.905m1.714-8.199q.985 1.02 1.825 2.543Q8.607 3.79 9.38 2.805A4.54 4.54 0 0 0 6.4 1.73q-.571 0-1.142.156m2.228 3.338q.169.358.381.907a19 19 0 0 1 1.803-.078q.695 0 1.378.033a4.5 4.5 0 0 0-1.098-2.71q-.727 1.086-2.464 1.848m.661 1.691q.572 1.658.773 3.405.884-.57 1.445-1.467a4.5 4.5 0 0 0 .672-1.938 22 22 0 0 0-1.49-.056q-.615 0-1.4.056"
        style={{
          strokeWidth: 0.0112,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialDribble
