const businessCard_placeholder = {
  businessName: 'Business Name',
  fullName: 'Full Name',
  job: 'Your job',
  phone: '+1234567890',
  email: 'your@mail.com',
  website: 'yourdomain.com',
  address: 'Address',
  addressComplement: 'Address complement',
  zipCode: 'ZIP / Postal code',
  city: 'City',
  state: 'State',
  your_logo: 'YOUR LOGO',
}


export default businessCard_placeholder
