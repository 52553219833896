import React, { useEffect, useState } from 'react'
import { Options } from '../Options'
import { LockedFieldSwitch } from '../LockedFieldSwitch'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useLockedField } from '@/Hooks/useLockedField'
const LockedSwitch = ({ name, type }) => {
  const { editCurrentUser } = useEditCurrentUser()
  const { onLocked } = useLockedField()
  const [isLocked, setIsLocked] = useState(
    editCurrentUser.lockedFields?.[type]?.includes(name) || false,
  )
  useEffect(() => {
    setIsLocked(editCurrentUser.lockedFields?.[type]?.includes(name))
  }, [editCurrentUser.lockedFields, type, name])
  return (
    <div className="absolute right-12 top-2 cursor-pointer">
      <LockedFieldSwitch
        variant="secondary"
        isLocked={isLocked}
        onChange={e =>
          onLocked({
            e: e,
            setIsLocked: setIsLocked,
            type: type,
            fieldName: name,
          })
        }
      />
    </div>
  )
}

export const OptionsLock = ({ options, type = 'user', ...props }) => {
  return (
    <Options options={options} {...props}>
      {props => <LockedSwitch name={props.name} type={type} />}
    </Options>
  )
}
