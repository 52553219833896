const companion_yields = {
  obtained: 'to harvest',
  yield: 'Yield',
  claim_my_yields: 'Claim my yields',
  no_claim_yields: 'Next harvest in ',
  received_at: 'Received at ',
  yield_history: 'Yield history',
  rewarded: "Your companion's yields have been collected!",
  rewarded_description: 'You just collected all your yields!',
  reminder: 'Come back every day to get your daily yields',
  great: 'Great!',
  choose_active_resources: 'Choose 3 active resources',
  resources_can_be_changed:
    'The chosen resources can be changed according to your wishes.',
  active: 'active',
  disabled: 'disabled',
  validate_selected_resources: 'Validate my selection',
  error_claim_yields_before_selecting_it:
    'Cannot modify resources to yield when there are yields to claim. Claim them first.',
}

export default companion_yields
