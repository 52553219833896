const homelogin = {
  account: 'Ya tienes una cuenta ?',
  login: 'Conectarse',
  start: 'Comenzar',
  slide: 'ARRASTRA PARA APRENDER MÁS',
  frame0: {
    title: 'La última de tus tarjetas de visita',
  },
  frame1: {
    title: 'Tu perfil digital en tu bolsillo',
  },
  frame2: {
    title: 'Planta árboles con tus publicaciones',
  },
  frame3: {
    title: 'Convierte tu actividad en regalos reales',
    members: 'socios',
    voucher: 'cupón',
    reservation: 'en la reserva',
    discount: 'de ahorro',
  },
  frame4: {
    title: 'Centraliza tus redes sociales',
  },
}


export default homelogin
