const plantation_details = {
  title: 'Détails de la plantation',
  posted_by: 'Posté par',
  planted_by: 'Planté par',
  you: 'Vous',
  see_post: 'Voir le post',
  planted_on: 'Planté le',
  tree_project: 'Projet de la plantation',
  see_more: 'Voir plus',
  learn_more: 'En savoir plus',
  location: 'Lieu de plantation',
  locate: 'Localiser',
  participants: 'Participants',
  cost_planting: 'Coût de la plantation',
  no_plantation: "Il n'y a pas de plantations",
}


export default plantation_details
