import * as React from 'react'
const SvgSocialSignal = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        fill="#2196f3"
        d="M4.333 2h23.334A2.333 2.333 0 0 1 30 4.333v23.334A2.333 2.333 0 0 1 27.667 30H4.333A2.333 2.333 0 0 1 2 27.667V4.333A2.333 2.333 0 0 1 4.333 2"
        style={{
          strokeWidth: 0.777778,
        }}
      />
      <path
        fill="#b3e5fc"
        d="M8.222 28.056a.391.391 0 0 1-.383-.321l-.34-1.916.766-.135.265 1.497 1.468-.395.202.752-1.877.504a.4.4 0 0 1-.1.014M7.21 24.476a6.8 6.8 0 0 0-1.024-2.097q-.326-.444-.609-.912l.667-.401q.263.437.567.851c.501.679.886 1.465 1.145 2.338zm-2.308-4.31a11.3 11.3 0 0 1-.912-3.536l.774-.07a10.5 10.5 0 0 0 .85 3.292zM16.467 27.27l-.03-.778a11.6 11.6 0 0 0 3.355-.624l.254.735a12.4 12.4 0 0 1-3.58.666m-1.463-.032a12 12 0 0 1-.686-.075c-.987-.135-1.904-.126-2.738.028l-.142-.765q1.377-.253 2.985-.033.32.045.643.07zm6.393-1.194-.34-.699a11.3 11.3 0 0 0 2.809-1.933l.533.567c-.889.835-1.9 1.53-3.002 2.065m4.005-3.131-.6-.498a10.8 10.8 0 0 0 1.74-2.925l.723.286a11.5 11.5 0 0 1-1.863 3.137m2.314-4.533-.756-.19a10.5 10.5 0 0 0 .285-3.387l.775-.06q.034.438.035.885c0 .929-.114 1.854-.34 2.752M4.732 15.189l-.778-.032a11.2 11.2 0 0 1 .741-3.576l.726.279a10.4 10.4 0 0 0-.69 3.329m22.32-1.737a10.6 10.6 0 0 0-1.233-3.169l.67-.394a11.3 11.3 0 0 1 1.323 3.402zM5.99 10.62l-.684-.37a11.7 11.7 0 0 1 2.215-2.897l.538.562a11 11 0 0 0-2.07 2.705m19.062-1.47a11.2 11.2 0 0 0-2.472-2.346l.443-.639c1.011.702 1.9 1.547 2.643 2.511zM9.104 7.03l-.465-.623a12.2 12.2 0 0 1 3.212-1.719l.262.733A11.4 11.4 0 0 0 9.104 7.03m12.307-.939a11.4 11.4 0 0 0-3.22-1.13l.146-.763c1.203.23 2.36.635 3.439 1.206Zm-7.983-1.05-.172-.759a12.6 12.6 0 0 1 3.631-.273l-.054.775a11.9 11.9 0 0 0-3.405.257"
        style={{
          strokeWidth: 0.777778,
        }}
      />
      <path
        fill="#fff"
        d="M16 6.7c-5.154 0-9.333 3.997-9.333 8.927 0 2.641 1.205 5.007 3.11 6.641v3.065l2.807-1.403a9.7 9.7 0 0 0 3.416.626c5.154 0 9.333-3.997 9.333-8.928 0-4.93-4.179-8.928-9.333-8.928"
        style={{
          strokeWidth: 0.777778,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialSignal
