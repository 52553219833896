import * as React from 'react'
const SvgAdd = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 56})`}>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M22 28h12m-6-6v12"
      />
    </g>
  </svg>
)
export default SvgAdd
