const getBusinessCardsByUid = build =>
  build.query({
    query: ({ userId }) => {
      return {
        url: `/users/${userId}/business-cards`,
        method: 'GET',
      }
    },
    providesTags: ['BusinessCards'],
  })

export default getBusinessCardsByUid
