import { LineArrowBottom, LineArrowTop } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import clsx from 'clsx'
import React, { useState } from 'react'
import { ButtonBloc } from './Buttons/ButtonBloc'
import Expendable from './Expandable'

export const ExpandableBloc = ({ children, ...props }) => {
  const { theme } = useTheme()
  const [open, setOpen] = useState(false)
  const Icon = !open ? LineArrowBottom : LineArrowTop
  return (
    <ButtonBloc
      {...props}
      className={clsx(props.className, open && 'opacity-100')}
      onClick={() => setOpen(!open)}
    >
      <Icon className="absolute top-6 right-4" color={theme.colors.grey2} />
      <Expendable open={open}>
        <div className="pt-4" onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </Expendable>
    </ButtonBloc>
  )
}
