import styled from 'styled-components'

const Phone = styled.div`
  height: 88%;
  aspect-ratio: 0.4825;
  max-height: 659px;
  border: 8px solid black;
  border-radius: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;

  @media screen and (max-height: 896px) {
    aspect-ratio: 0.51;
    margin-bottom: 10px !important;
  }
  @media screen and (max-height: 732px) {
    height: 90%;
    aspect-ratio: 0.51;
    margin-bottom: 4px !important;
  }
`

export default Phone
