import addProductActivation from './addProductActivation'
import getProductsByUserId from './getProductsByUserId'
import { api } from '@/Services/api'

export const productsApi = api.injectEndpoints({
  endpoints: build => ({
    getProductsByUserId: getProductsByUserId(build),
    addProductActivation: addProductActivation(build),
  }),
  overrideExisting: true,
})

export const { useGetProductsByUserIdQuery, useLazyAddProductActivationQuery } =
  productsApi
