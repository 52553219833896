import React from 'react'
import { styled } from 'styled-components'

const Styled = styled.button`
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`

export const TouchableOpacity = ({ type = 'button', ...props }) => {
  return <Styled {...props} type={type} />
}
