import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgRotate = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21.5 12.153c0 4.886-4.03 8.847-9 8.847s-9-3.961-9-8.847c0-4.887 4-8.848 9-8.848 6 0 9 4.915 9 4.915m0 0V3m0 5.22h-4.655"
        />
      </g>
    </svg>
  )
}
export default SvgRotate
