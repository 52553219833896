import getStreaks from './getStreaks'
import { api } from '@/Services/api'

export const streaksApi = api.injectEndpoints({
  endpoints: build => ({
    getStreaks: getStreaks(build),
  }),
  overrideExisting: true,
})

export const { useGetStreaksQuery } = streaksApi
