export default build =>
  build.query({
    query: uid => {
      return {
        url: `/users/${uid}/products`,
        method: 'GET',
      }
    },
    providesTags: ['UserProducts'],
  })
