import { useEffect } from 'react'

const useCallbackResizer = callback => {
  useEffect(() => {
    window.addEventListener('resize', callback, { passive: true })

    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [callback])

  return null
}

export default useCallbackResizer
