const statistics = {
  title: 'Mis estadísticas',
  active_profiles: 'Perfiles activos',
  unique_views: 'Vistas únicas',
  clicks: 'Clics',
  ctr: 'Tasa de clics (CTR)',
  ctr_graph: 'CTR (tasa de clics)',
  views: 'Vistas',
  views_by_device: 'Vistas por dispositivo',
  views_by_country: 'Vistas por país',
  top_links: 'Mis enlaces principales',
  top_contacts: 'Mis contactos principales',
  top_networks: 'Mis redes principales',
  change_vs_last_month: 'vs el mes pasado',
  filter_week: 'Esta semana',
  filter_month: 'Este mes',
  filter_last_three_month: 'Los últimos tres meses',
  filter_last_six_month: 'Los últimos seis meses',
  filter_year: 'Este año',
  title_links: 'Título',
  graph: 'Gráfico',
  views_graph: 'vistas',
  unique_views_graph: 'vistas únicas',
  member_statistics: 'Estadísticas',
}

export default statistics
