const prompt_suggestion_page = {
  suggestion: 'Sugerencias',
  landscape: {
    label: 'Paisaje',
    a_lush_forest: 'Un bosque exuberante bañado por la luz del crepúsculo.',
    a_deserted_beach: 'Una playa desierta con suaves olas.',
    a_starry_sky: 'Un cielo estrellado sobre una montaña nevada.',
    a_field_colorful: 'Un campo de flores coloridas bajo un cielo de verano.',
  },
  animals: {
    label: 'Animales',
    playful_kitten: 'Un gatito jugando con ovillos de lana.',
    elephant_at_sunset: 'Un elefante caminando en la sabana al atardecer.',
    penguins_on_iceberg: 'Un grupo de pingüinos en un iceberg.',
    horse_in_wildflowers:
      'Un caballo galopando en un campo de flores silvestres.',
  },
  architecture: {
    label: 'Arquitectura',
    medieval_castle_lake: 'Un castillo medieval al borde de un lago.',
    futuristic_skyscrapers: 'Rascacielos futuristas con jardines colgantes.',
    country_house_vineyards: 'Una casa de campo rodeada de viñedos.',
    ancient_temples_jungle: 'Templos antiguos en una jungla exuberante.',
  },
  scifi: {
    label: 'Ciencia Ficción',
    spaceship_landing:
      'Una nave espacial aterrizando en un planeta alienígena.',
    dystopian_city: 'Una ciudad distópica con robots y luces de neón.',
    ancient_tech_explorers:
      'Exploradores descubriendo tecnología antigua en una luna desierta.',
    contemplative_cyborg: 'Un cyborg contemplativo en un jardín cibernético.',
  },
  gastronomy: {
    label: 'Gastronomía',
    french_pastries:
      'Un surtido de repostería francesa en una panadería vintage.',
    festive_table_world_cuisine:
      'Una mesa festiva con platos tradicionales del mundo.',
    chef_decorating_cake: 'Un chef decorando meticulosamente un pastel.',
    colorful_cocktails: 'Cócteles coloridos en un bar de playa al atardecer.',
  },
  fashion: {
    label: 'Moda',
    couture_fashion_show: 'Un desfile de moda de alta costura en un castillo.',
    vintage_outfit_photoshoot:
      'Una sesión de fotos con atuendos vintage en una calle de París.',
    exotic_fashion_accessories:
      'Accesorios de moda exóticos con fondo de jungla.',
    sewing_workshop: 'Un taller de costura con telas y bocetos de diseños.',
  },
  nature_space: {
    label: 'Naturaleza y Espacio',
    aurora_glass_igloo: 'La aurora boreal vista desde un iglú de cristal.',
    hidden_waterfall: 'Una cascada oculta en un bosque encantado.',
    solar_system_mars_view:
      'El sistema solar visto desde la superficie de Marte.',
    grand_canyon_storm:
      'Una vista del Gran Cañón durante una tormenta dramática.',
  },
}

export default prompt_suggestion_page
