import * as React from 'react'
const SvgSocialBehance = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <circle
        cx={16}
        cy={16}
        r={14}
        style={{
          fill: '#1769ff',
          strokeWidth: 0.0112,
        }}
      />
      <path
        d="M11.89 9.656a8 8 0 0 1 1.654.16 3.6 3.6 0 0 1 1.279.525q.532.364.826.968.294.605.294 1.493 0 .96-.436 1.6T14.22 15.45q1.173.338 1.75 1.182.579.843.579 2.035 0 .96-.373 1.662t-1.005 1.146q-.63.444-1.44.657a6.5 6.5 0 0 1-1.66.213H5.92V9.656Zm-.354 5.136q.746-.001 1.226-.356t.48-1.154q0-.444-.16-.728a1.2 1.2 0 0 0-.427-.445 1.8 1.8 0 0 0-.613-.222 4 4 0 0 0-.72-.061H8.71v2.968zm.16 5.383q.396.002.782-.08.373-.079.657-.266.284-.186.454-.506.169-.32.169-.818 0-.976-.551-1.394-.552-.418-1.457-.418H8.71v3.483zm8.362-.266q.568.55 1.635.55.764 0 1.315-.381t.675-.809h2.221q-.532 1.653-1.635 2.363-1.102.711-2.666.712-1.084 0-1.954-.346a4.1 4.1 0 0 1-1.475-.987 4.4 4.4 0 0 1-.933-1.528 5.6 5.6 0 0 1-.328-1.954q0-1.03.337-1.919a4.5 4.5 0 0 1 .96-1.536q.622-.649 1.484-1.022a4.8 4.8 0 0 1 1.91-.373q1.173 0 2.044.454a4.1 4.1 0 0 1 1.43 1.217q.561.764.81 1.742.248.978.177 2.044h-6.628q.053 1.223.62 1.773m2.852-4.833q-.453-.498-1.377-.497-.605 0-1.004.204-.4.204-.64.506-.24.301-.336.64a3 3 0 0 0-.116.603h4.105q-.18-.957-.632-1.456M19.005 10.3h5.128v1.423h-5.128z"
        style={{
          fill: '#fff',
          strokeWidth: 0.0112,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialBehance
