import { LineMenu2 } from '@/Assets/icons/components'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { TouchableOpacity } from './TouchableOpacity'
import { OptionsMenuPopover } from '../Profile/OptionsMenuPopover'

const StyledButton = styled(TouchableOpacity)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.xxl};
    overflow: hidden;
    padding: ${theme.space.xxs};
  `}
`
const AccountPanelPopover = ({ children: button, ...props }) => {
  const open = Boolean(props.anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      {button({ id })}
      <OptionsMenuPopover {...props} id={id} open={open} />
    </>
  )
}

const AccountPanelButton = () => {
  const { currentUser } = useSelector(state => state.user)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AccountPanelPopover onClose={handleClose} anchorEl={anchorEl}>
      {({ id }) => (
        <StyledButton aria-describedby={id} onClick={handleClick}>
          <div className="px-1">
            <LineMenu2 />
          </div>
          <img
            className="h-10 w-10 rounded-full bg-grey1"
            src={currentUser?.photoURL || require('@/Assets/images/avatar.png')}
            alt=""
          />
        </StyledButton>
      )}
    </AccountPanelPopover>
  )
}

export default AccountPanelButton
