import explicitImage from './explicitImage'
import { api } from '@/Services/api'

export const expliciteImageApi = api.injectEndpoints({
  endpoints: build => ({
    explicitImage: explicitImage(build),
  }),
  overrideExisting: true,
})

export const { useExplicitImageMutation } = expliciteImageApi
