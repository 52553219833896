import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTop = () => {
  const [ready, setReady] = useState(false)
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    setReady(false)
    const handleScrollToTop = () => {
      setTimeout(() => {
        window.scrollTo(0, 0)
        setReady(true)
      }, 100)
    }

    handleScrollToTop()
  }, [pathname])

  return ready
}

export default useScrollToTop
