const createUserByUid = build =>
  build.mutation({
    query: ({ userUid, linkId, dataUser, dataLink }) => {
      return {
        url: `/users/${userUid}`,
        method: 'POST',
        body: {
          link: linkId,
          dataUser,
          dataLink,
        },
      }
    },
    invalidatesTags: ['UsersByHashtag', 'Whitelabel'],
  })

export default createUserByUid
