const shop = {
  screen_name: 'Boutique',
  free_ressources: 'Offres gratuites',
  seeds_packages: 'Packs de graines',
  coins_packages: 'Packs de pièces',
  drops_packages: 'Packs de gouttes',
  tickets_packages: 'Packs de tickets',
  pack_title: 'Obtenez {{quantity}} ',
  seed: 'graine',
  coin: 'pièce',
  drop: 'goutte',
  ticket: 'ticket',
  chest: 'coffre',
  free_subtitle: 'Regardez une pub pour gagner votre {{resource}} gratuitement',
  resources_subtitle:
    'Obtenez des {{ resource }} pour planter des arbres dans la vraie vie.',
  subscriptions_subtitle: 'Fonctionnalités Budly',
  free_seed_button: 'Regarder une pub',
  already_recovered: 'Déjà récupérée',
  remaining: 'restante{{plurial}}',
  five_seeds_pack_subtitle:
    "Chaque achat augmente l'argent reversé aux projets...",
  fifty_seeds_pack_subtitle: "Et aide l'équipe Budly à se développer ...",
  two_hundred_seeds_pack_subtitle:
    'Plein de nouvelles fonctionnalités pour vous.',
  ticket_pack_subtitle: 'Pour jouer à nos mini-jeux.',
  chest_pack_subtitle: 'Et gagne des accessoires pour ton compagnon.',
  tab_all: 'Tout',
  tab_free: 'Gratuit',
  tab_coins: 'Pièces',
  tab_resources: 'Ressources',
  tab_products: 'Produits',
  tab_subscriptions: 'Abonnements',
  delivery: 'Livraison',
  order: 'Commander',
  select: 'Sélectionner',
  subscribe: "S'abonner",
  price: '{{ price }}€',
  resources_price: 'À partir de {{ price }}€',
  products_price: '{{ price }}€ / {{ product }}',
  duration: 'mois',
  subscriptions_price: '{{ price }}€ / {{ duration }}',
  subscriptions_price_subtitle: '+ {{ price }}€ une seule fois',
  thanks_for_purchase_title: 'Merci pour votre achat !',
  thanks_for_purchase_description:
    'Votre achat a bien été effectué, faites-en bon usage !',
  thanks_for_watching_add_description:
    'Votre récompense vous a bien été attribuée !',
  available_soon: 'Bientôt disponible',
  shop_creation_success: 'Votre shop a bien été créé',
  cart: 'Panier',
  add_to_cart: 'Ajouter au panier',
  cancel: 'Annuler',
  quantity: 'Quantité',
  total: 'Total de dépense',
  pay_and_save_btn: 'Payer et sauvegarder',
  monthly_subscription: 'Abonnement mensuel',
  monthly_benefits: 'Avantages mensuels',
  badge_text: '€ de produits',
  physical_products: 'de produits physique',
  physical_products_offered: 'Produits physique offert',
  product_added_success: 'Le produit à bien été ajouté au panier!',
  sponsor: 'Parrain',
  subscription_mandatory: 'Abonnement obligatoire',
  already_subscribed: 'Abonnement déjà en cours',
}

export default shop
