import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { ProfileItem } from './ProfileItem'
import { useTranslation } from 'react-i18next'
import SectionTab from './SectionTab'
import { useProfileContext } from './Contexts/ProfileContext'
import { socialItems } from '../../contents'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const ItemsWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${`-${theme.space.xxs}`};
    margin-top: ${theme.space.std};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`

const SocialItem = ({ link, onAddClick, ...props }) => {
  const item = socialItems.find(({ label }) => label === link.icon)
  return (
    <ProfileItem
      {...link}
      item={item}
      icon={link.thumbnail ? null : item?.icon}
      image={link.thumbnail ? link.thumbnail : null}
      onAddClick={() => onAddClick({ contentId: link.id, type: 'social' })}
      {...props}
    />
  )
}

const ProfileSocials = memo(({ onAddClick }) => {
  const { profile } = useProfileContext()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <SectionTab title={t('previewpage.my_networks')}>
        <ItemsWrapper>
          {profile.links
            ?.filter(link => link.type === 'social' && link.enabled)
            .map((link, index) => {
              return (
                <SocialItem
                  key={index}
                  link={link}
                  profileDisplay={profile.profileDisplay}
                  onAddClick={onAddClick}
                />
              )
            })}
        </ItemsWrapper>
      </SectionTab>
    </Wrapper>
  )
})

export default ProfileSocials
