import React from 'react'
import { ModalWrapper } from './ModalWrapper'
import { ModalContent } from './ModalContent'

export const Modal = ({
  isOpen,
  onDismiss,
  onReady,
  children,
  fullScreen,
  width,
  ...props
}) => {
  return (
    <ModalWrapper isOpen={isOpen} onDismiss={onDismiss} width={width}>
      <ModalContent onDismiss={onDismiss} {...props}>
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}
