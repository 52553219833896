import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineDragVertical = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12 14.5V21m0 0 2.693-2.75M12 21l-2.693-2.75M6 14.5h12m-6-5V3m0 6.5h6m-6 0H6M12 3 9.307 5.75M12 3l2.693 2.75"
        />
      </g>
    </svg>
  )
}
export default SvgLineDragVertical
