const formatFollowingsQuery = followings => {
  if (followings.length === 0) {
    return ''
  }
  const followingString = followings.join(',')
  return `&followings=${followingString}`
}

const getFeedPosts = build =>
  build.mutation({
    query: ({ lastDate, feed = '', userId, followings = [] }) => {
      const followingsQuery = formatFollowingsQuery(followings)
      return {
        url: `/posts/${lastDate}?feed=${feed}&userId=${userId}${followingsQuery}`,
        method: 'GET',
      }
    },
  })

export default getFeedPosts
