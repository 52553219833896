const activity_stats = {
  level: 'Niveau',
  rankings: 'Classements',
  by_exp: 'par expérience',
  by_earned_seeds: 'par graines obtenues',
  best_contributor: 'meilleur contributeur',
  days: 'jours',
  current_streak: 'Série en cours',
  best_streak: 'Meilleure série',
  trees_planted: 'Arbres plantés',
  seeds_earned: 'Graines gagnées',
  total_exp_earned: 'EXP totale gagnée',
  stats: 'Statistiques',
}


export default activity_stats
