import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineSetting2 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M13.752 5.443c0-.835-.61-1.544-1.436-1.67a2.1 2.1 0 0 0-.632 0 1.69 1.69 0 0 0-1.436 1.67v1.181q-.708.204-1.34.556l-.836-.835a1.69 1.69 0 0 0-2.196-.166q-.256.19-.447.447a1.69 1.69 0 0 0 .166 2.196l.835.835a6.3 6.3 0 0 0-.556 1.341h-1.18c-.836 0-1.545.61-1.67 1.436q-.048.317 0 .632a1.69 1.69 0 0 0 1.67 1.436h1.18q.204.708.556 1.34l-.835.836c-.59.59-.66 1.523-.166 2.196q.19.256.447.447a1.69 1.69 0 0 0 2.196-.166l.835-.835c.42.234.87.421 1.341.556v1.18c0 .836.61 1.545 1.436 1.67q.317.048.632 0a1.69 1.69 0 0 0 1.436-1.67v-1.18a6.3 6.3 0 0 0 1.34-.556l.836.835c.59.59 1.523.66 2.196.166a2.1 2.1 0 0 0 .447-.447 1.69 1.69 0 0 0-.166-2.196l-.835-.835c.234-.42.421-.87.556-1.341h1.18c.836 0 1.545-.61 1.67-1.436q.048-.317 0-.632a1.69 1.69 0 0 0-1.67-1.436h-1.18a6.3 6.3 0 0 0-.556-1.34l.835-.836c.59-.59.66-1.524.166-2.196a2.1 2.1 0 0 0-.447-.447 1.69 1.69 0 0 0-2.196.166l-.835.835a6.3 6.3 0 0 0-1.341-.556z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M9.5 12.75a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
        />
      </g>
    </svg>
  )
}
export default SvgLineSetting2
