const accountPage = {
  subtitle: 'Información visible sólo para usted.',
  paymentInformationTitle: 'Información de pago',
  subTitlePayment: 'Acceder a la información sobre la tarjeta registrada',
  titleManage: 'Stripe Portal',
  buttonTextStripe: 'Gestione',
  dangerZoneTitle: 'Zona de peligro',
  passwordSubtitle: 'Cambiar la contraseña actual',
  resetPassword: 'Restablecer mi contraseña',
}

export default accountPage
