import * as React from 'react'
const SvgSocialStrava = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 0.205 0.205"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        fill="#fc4c02"
        d="M.013.013h.179v.179h-.18Z"
        style={{
          strokeWidth: 0.0112,
        }}
      />
      <g fill="#fff" fillRule="evenodd">
        <path d="m.09.111.028.05.027-.05H.128l-.01.02L.107.11z" opacity={0.6} />
        <path d="m.093.04.035.071h-.07Zm0 .043.014.028H.079Z" />
      </g>
    </g>
  </svg>
)
export default SvgSocialStrava
