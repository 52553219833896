import React from 'react'
import styled from 'styled-components'

const StyledExpendable = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;

  &.open {
    grid-template-rows: 1fr;
  }

  .inner {
    width: 100%;
    overflow: ${({ open }) => (!open ? 'hidden' : 'none')};
  }
`

const Expendable = ({ open, children }) => {
  return (
    <StyledExpendable className={open && 'open'} open={open}>
      <div className="inner">{children}</div>
    </StyledExpendable>
  )
}

export default Expendable
