const getBusinessCardTemplatesByWhitelabel = build =>
  build.query({
    query: whitelabel => {
      return {
        url: `/business-card-templates/${whitelabel}`,
        method: 'GET',
      }
    },
  })

export default getBusinessCardTemplatesByWhitelabel
