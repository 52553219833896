const updateBusinessCardById = build =>
  build.mutation({
    query: ({ cardId, businessCard }) => {
      return {
        url: `/users/business-card/${cardId}`,
        method: 'PUT',
        body: businessCard,
      }
    },
    invalidatesTags: ['BusinessCards'],
  })

export default updateBusinessCardById
