const negative_prompt_explanation = {
  title: 'El mensaje negativo',
  subtitle: {
    main_text:
      'El mensaje negativo es una herramienta clave para ajustar la generación de imágenes al permitirle especificar lo que no desea ver en una imagen generada.',
  },
  increased_precision_title: 'Mayor precisión ',
  increased_precision_description:
    'Especificar elementos no deseados en la imagen generada, asegurando mayor precisión en los resultados.',

  creative_control_title: 'control creativo',
  creative_control_description:
    'Mantenga un mayor control sobre el contenido de la imagen, definiendo claramente lo que no debe estar presente y preservando su libertad creativa.',
  elimination_ambiguity_title: 'Eliminación de la ambigüedad',
  elimination_ambiguity_description:
    'Elimine los resultados que no cumplan con sus expectativas, reduciendo la ambigüedad y brindándole imágenes más consistentes.',
  how_use: '¿Cómo usarlo?',
  cómo_usar_contenido:
    'Utilice <strong>"no"</strong> seguido de lo que desea excluir.',
  how_use_content_end:
    '<strong>Combínalo</strong> con indicaciones positivas para obtener resultados refinados.',
}

export default negative_prompt_explanation
