import getSubscriptionsByUid from './getSubscriptionsByUid'
import { api } from '@/Services/api'

export const subscriptionsApi = api.injectEndpoints({
  endpoints: build => ({
    getSubscriptionsByUid: getSubscriptionsByUid(build),
  }),
  overrideExisting: true,
})

export const { useGetSubscriptionsByUidQuery } = subscriptionsApi
