const follow = {
  fllw: 'Follow',
  un_flw: 'Unfollow',
  followers: 'Followers',
  followed: 'Followed',
  contacts: 'Contacts',
}


export default follow
