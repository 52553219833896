import { LineEdit, LineGallery, LineTrash } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalDeleteBusinesscard from './ModalDeleteBusinessCard'
import { Modal } from './ModalWrapper/Modal'
import { Option } from '../Option'
import { useEditBusinessCard } from '../Dashboard/Contexts/EditBusinessCardContext'
import { DefaultParent } from '../DefaultParent'

const styles = {
  option: {
    marginBottom: 16,
  },
}

const ModalBusinessCardOptions = ({ children: button, businessCard }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { setSelectedBusinessCard } = useEditBusinessCard()

  const businessCardOptions = [
    {
      text: t('modal.businessCardOption.option1'),
      icon: <LineEdit color={theme.colors.black} />,
      onClick: () => {
        setSelectedBusinessCard(businessCard)
        setIsOpen(false)
      },
      color: theme.colors.grey1,
    },
    {
      text: t('modal.businessCardOption.option2'),
      icon: <LineGallery color={theme.colors.black} />,
      onClick: () => {
        window.open(window.origin + `/c/${businessCard.id}`)
        setIsOpen(false)
      },
      color: theme.colors.grey1,
    },
    {
      text: t('modal.businessCardOption.option3'),
      icon: <LineTrash color="#FFFFFF" />,
      color: theme.colors.red,
      parent: ModalDeleteBusinesscard,
      onClick: setIsModalDeleteOpen => {
        setIsModalDeleteOpen(true)
      },
    },
  ]
  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.businessCardOption.title')}
      >
        <div className="flex flex-col py-3">
          {businessCardOptions.map(
            ({ parent: Parent = DefaultParent, ...option }, index) => {
              return (
                <Parent
                  key={index}
                  businessCardId={businessCard.id}
                  onDismiss={() => setIsOpen(false)}
                >
                  {setIsModalDeleteOpen => (
                    <Option
                      {...option}
                      onClick={() => option.onClick(setIsModalDeleteOpen)}
                      style={styles.option}
                    />
                  )}
                </Parent>
              )
            },
          )}
        </div>
      </Modal>
    </>
  )
}

export default ModalBusinessCardOptions
