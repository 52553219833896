import React from 'react'
import styled, { css } from 'styled-components'
import { getFieldValues } from './utils'
import { TemplateLogo } from './components/TemplateLogo'
import { Contact } from './styles'
import { LineMessage5, LinePhone } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import OptionItem from './OptionItem'

const title = 'Template 1'
const slug = 'template1'

const AddressText = styled.span`
  font-size: 11px;
  color: #3c3f42;
`

const BusinessName = styled.span`
  ${({ color }) => css`
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: ${color};
  `}
`

const Website = styled.span`
  ${({ color }) => css`
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 500;
    color: ${color};
  `}
`

const BorderBottom = styled.div`
  ${({ theme, color }) => css`
    position: relative;
    background: #ffffff;
    flex: 1;
    border-bottom-width: ${theme.space.xs};
    border-color: ${color};
    border-style: solid;
    display: flex;
  `}
`

const SelectTemplate = ({ item, onSelected, selected }) => {
  return (
    <OptionItem
      onSelected={() => onSelected(item)}
      selected={selected}
      showSelected
    >
      <Template />
    </OptionItem>
  )
}

const Template = ({ businessCard, lang = 'en' }) => {
  const { theme } = useTheme()
  const {
    businessName,
    fullName,
    job,
    phone,
    email,
    website,
    address,
    addressComplement,
    zipCode,
    city,
    state,
    color = theme.colors.primary,
    logo,
  } = getFieldValues({ businessCard, lang })

  return (
    <div className="border border-grey2 w-[350px] h-[220px]">
      <BorderBottom
        color={color}
        className="flex relative bg-white h-full flex-row"
      >
        <div className="absolute w-1/2 h-[82%] bg-grey1 bottom-0 rounded-tr-3xl" />
        <div className="flex flex-row flex-1 py-2 px-3">
          <div className="w-[55%] h-full pt-10 justify-between flex-col flex z-10">
            <div className="flex flex-col">
              {businessName && (
                <BusinessName color={color}>{businessName}</BusinessName>
              )}
              {fullName && (
                <span className="text-grey3 text-xs">{fullName}</span>
              )}
              {job && <span className="text-grey3 text-xs">{job}</span>}
            </div>
            <div className="flex flex-col">
              {phone && (
                <div className="flex flex-row items-center mb-1">
                  <div className="w-4 h-4 mr-1">
                    <LinePhone
                      color={color}
                      className="w-full h-full"
                      size={16}
                    />
                  </div>
                  <Contact
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    className="text-grey3"
                  >
                    {phone}
                  </Contact>
                </div>
              )}
              {email && (
                <div className="flex flex-row items-center">
                  <div className="w-4 h-4 mr-1">
                    <LineMessage5
                      color={color}
                      className="w-full h-full"
                      size={16}
                    />
                  </div>
                  <Contact
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    className="text-grey3"
                  >
                    {email}
                  </Contact>
                </div>
              )}
            </div>
            {website && <Website color={color}>{website}</Website>}
          </div>
          <div className="w-[45%] h-full justify-between flex flex-col pb-3">
            <div className="h-[35%] w-full justify-end flex">
              <TemplateLogo
                businessCard={businessCard}
                clsxClassName="h-full w-full"
              />
            </div>
            <div className="flex flex-col">
              {address && <AddressText>{address}</AddressText>}
              {addressComplement && (
                <AddressText>{addressComplement}</AddressText>
              )}
              {(city || zipCode) && (
                <AddressText>
                  {city} {zipCode}
                </AddressText>
              )}
              {state && <AddressText>{state}</AddressText>}
            </div>
          </div>
        </div>
      </BorderBottom>
    </div>
  )
}

const ThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    height: ${theme.size.s};
    background: #ffffff;
    border: 0.5px solid #8e8e95;
    overflow: hidden;
  `}
`

const Thumbnail = ({ color }) => {
  return (
    <ThumbnailWrapper>
      <BorderBottom className="border-b-2" color={color}>
        <div className="absolute w-1/2 h-[72%] bg-grey1 bottom-0 rounded-tr-md" />
      </BorderBottom>
    </ThumbnailWrapper>
  )
}

export const template1 = {
  title,
  slug,
  component: Template,
  thumbnail: Thumbnail,
  selectTemplate: SelectTemplate,
}
