import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useProfileContext } from './Contexts/ProfileContext'
import SectionTab from './SectionTab'
import { useTranslation } from 'react-i18next'
import Gallery from '@/Components/Gallery'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const ProfileGallery = memo(() => {
  const { profile, setCurrentProfile } = useProfileContext()
  const { t } = useTranslation()
  return (
    <Wrapper>
      <SectionTab title={t('profilepage.galerie.galerie')}>
        <Gallery
          gallery={profile.gallery}
          profile={profile}
          onRefetch={value => setCurrentProfile(value)}
        />
      </SectionTab>
    </Wrapper>
  )
})

export default ProfileGallery
