import * as React from 'react'
const SvgSocialMym = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        fill="#c0392b"
        d="M16.01 15.27 22.452 2h5.385l-9.385 17.692V30h-4.885V19.692L4.164 2h5.403z"
        style={{
          strokeWidth: 0.492308,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialMym
