import algoliasearch from 'algoliasearch/lite'
const algoliaApplicationID = '2ZGAOZ9S66'
const algoliaSearchOnlyAPIKey = '02d6cdee5cf99c52b4264f2175f8b35a'
export const searchClient = algoliasearch(
  algoliaApplicationID,
  algoliaSearchOnlyAPIKey,
)

export const indexes = {
  users: searchClient.initIndex('users'),
}