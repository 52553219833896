import React, { useEffect, useRef } from 'react'
import { TopBar } from '@/Components'
import styled, { css } from 'styled-components'
import { Outlet, useNavigate } from 'react-router-dom'
import { useGetElementBoundings } from '@/Hooks'
import { DashboardLayoutProvider } from './Contexts/DashboardLayoutContext'
import { useSelector } from 'react-redux'

const DashboardContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  ${({ topBarHeight }) => css`
    padding-top: ${topBarHeight}px;
    min-height: calc(100vh - ${topBarHeight}px);
  `}
`
const StyledContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
  `}
`

const DEFAULT_TOPBAR_HEIGHT = 80

const DashboardLayout = () => {
  const { currentUser } = useSelector(state => state.user)
  const navigate = useNavigate()
  const topBarRef = useRef()
  const topBarBoundings = useGetElementBoundings(topBarRef)
  const topBarHeight = topBarBoundings?.height || DEFAULT_TOPBAR_HEIGHT

  useEffect(() => {
    if (!currentUser) {
      navigate('/sign-in')
    }
    if (currentUser?.status === 'creation') {
      navigate('/onboarding')
    }
  }, [currentUser, navigate])

  if (!currentUser) return null

  return (
    <DashboardLayoutProvider topBarHeight={topBarHeight}>
      <StyledContainer className="w-full min-h-full ">
        <TopBar ref={topBarRef} />
        <DashboardContent topBarHeight={topBarHeight}>
          <Outlet />
        </DashboardContent>
      </StyledContainer>
    </DashboardLayoutProvider>
  )
}

export default DashboardLayout
