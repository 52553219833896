import * as React from 'react'
const SvgMusic = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        d="M1.24 9.923a8.683 8.683 0 1 1 17.366 0 8.683 8.683 0 0 1-17.366 0"
        style={{
          fill: '#337180',
          strokeWidth: 0.035,
        }}
      />
      <path
        d="M14.764 5.732a.15.15 0 0 0-.12-.043l-7.437.478a.154.154 0 0 0-.142.148l-.222 5.12a1.9 1.9 0 0 0-.632-.107q-.16 0-.322.024c-.47.064-.86.252-1.16.559-.312.318-.44.678-.379 1.07.06.388.285.698.667.923q.434.252.946.253.147 0 .306-.022a2 2 0 0 0 1.052-.47c.288-.255.454-.559.494-.903v-.066c.013-.184.225-4.59.225-4.59l5.762-.463-.207 3.802a1.9 1.9 0 0 0-.702-.132q-.153 0-.31.022c-.47.064-.858.255-1.153.57-.304.323-.43.684-.377 1.073.054.39.276.7.66.918.285.162.604.244.948.244q.155 0 .32-.022a2 2 0 0 0 1.063-.49q.444-.405.466-.928c.012-.195.111-2.436.3-6.85a.15.15 0 0 0-.046-.118"
        style={{
          fill: '#b5e3ea',
          strokeWidth: 0.035,
        }}
      />
    </g>
  </svg>
)
export default SvgMusic
