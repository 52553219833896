import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgPlantedParticipants = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 12})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M.917 10.148c0-1.407.99-2.606 2.335-2.828l.122-.02a10 10 0 0 1 3.253 0l.12.02c1.346.222 2.336 1.42 2.336 2.828 0 .609-.477 1.102-1.066 1.102H1.983c-.589 0-1.066-.493-1.066-1.102ZM7.382 3.047c0 1.268-1.066 2.297-2.382 2.297-1.315 0-2.382-1.029-2.382-2.297S3.685.75 5 .75s2.382 1.028 2.382 2.297Z"
        />
      </g>
    </svg>
  )
}
export default SvgPlantedParticipants
