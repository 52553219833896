import React from 'react'
import styled, { css } from 'styled-components'
import { getFieldValues } from './utils'
import { TemplateLogo } from './components/TemplateLogo'
import { Contact } from './styles'
import { LineMessage5, LinePhone } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import OptionItem from './OptionItem'

const title = 'Template 3'
const slug = 'template3'

const FullName = styled.span`
  ${({ color }) => css`
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
    color: ${color};
    text-align: center;
  `}
`

const BusinessName = styled.span`
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 500;
  color: #3c3f42;
`

const Website = styled.span`
  color: #3c3f42;
  font-size: 11px;
  padding: 4px;
  font-family: 'Poppins';
  font-weight: 500;
`

const LineTop = styled.div`
  ${({ color, lineSize }) => css`
    width: 200%;
    height: ${lineSize || '48px'};
    left: -50%;
    background: ${color + '20'};
    position: absolute;
    bottom: 25%;
    transform: rotate(30deg);
  `}
`
const LineBottom = styled.div`
  ${({ color, lineSize }) => css`
    width: 200%;
    height: ${lineSize || '48px'};
    left: -50%;
    background: ${color + '20'};
    position: absolute;
    bottom: 0%;
    transform: rotate(30deg);
  `}
`

const SelectTemplate = ({ item, onSelected, selected }) => {
  return (
    <OptionItem
      onSelected={() => onSelected(item)}
      selected={selected}
      showSelected
      ratio={0.75}
    >
      <Template />
    </OptionItem>
  )
}

const Template = ({ businessCard, lang = 'en' }) => {
  const { theme } = useTheme()
  const {
    businessName,
    fullName,
    job,
    phone,
    email,
    website,
    address,
    addressComplement,
    zipCode,
    city,
    state,
    color = theme.colors.primary,
    logo,
  } = getFieldValues({ businessCard, lang })

  return (
    <div className="border border-grey2 w-[220px] h-[350px] rounded-lg bg-white relative overflow-hidden flex flex-col">
      <LineTop color={color} />
      <LineBottom color={color} />
      <div className="flex flex-col flex-1 py-3 items-center justify-center">
        <div className="flex flex-col items-center px-3 flex-1 justify-around">
          {businessName && (
            <BusinessName className="text-grey3">{businessName}</BusinessName>
          )}
          <div className="h-1/4 py-2">
            <TemplateLogo
              businessCard={businessCard}
              clsxClassName="h-full w-full"
            />
          </div>
          <div className="flex flex-col">
            {fullName && <FullName color={color}>{fullName}</FullName>}
            {job && <span className="text-grey3 text-center">{job}</span>}
          </div>
          <div className="flex flex-col px-2 py-0">
            {phone && (
              <div className="flex py-1 flex-row items-center">
                <div className="w-3 h-3 mr-1">
                  <LinePhone
                    color={color}
                    className="w-full h-full"
                    size={12}
                  />
                </div>
                <Contact className="text-center">{phone}</Contact>
              </div>
            )}
            {email && (
              <div className="flex flex-row items-center">
                <div className="w-3 h-3 mr-1">
                  <LineMessage5
                    color={color}
                    className="w-full h-full"
                    size={12}
                  />
                </div>
                <Contact className="text-center">{email}</Contact>
              </div>
            )}
          </div>
          {website && <Website>{website}</Website>}
          <div className="flex flex-col px-3">
            {address && <Contact className="text-center">{address}</Contact>}
            {addressComplement && (
              <Contact className="text-center">{addressComplement}</Contact>
            )}
            {(city || zipCode) && (
              <Contact className="text-center">
                {city} {zipCode}
              </Contact>
            )}
            {state && <Contact className="text-center">{state}</Contact>}
          </div>
        </div>
      </div>
    </div>
  )
}

const ThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.s};
    height: ${theme.size.fill};
    background: #ffffff;
    border: 0.5px solid #8e8e95;
    overflow: hidden;
    position: relative;
  `}
`

const Thumbnail = ({ color }) => {
  return (
    <ThumbnailWrapper>
      <LineTop lineSize="4px" color={color} />
      <LineBottom lineSize="4px" color={color} />
    </ThumbnailWrapper>
  )
}

export const template3 = {
  title,
  slug,
  component: Template,
  thumbnail: Thumbnail,
  selectTemplate: SelectTemplate,
}
