const discoverpage = {
  discover: 'Discover',
  title: 'For you',
  follow: 'Follow',
  unfollow: 'Unfollow',
  pending: 'Pending',
  empty_results: {
    no_user_found: 'No user found',
    no_results_matched: 'No results match your search. Please try again.',
  },
}


export default discoverpage
