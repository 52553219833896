import * as React from 'react'
const SvgLogoReview = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 50})`}>
      <rect width={50} height={50} fill="#C1C7D0" rx={8} />
      <path
        fill="#F3F3F3"
        d="m38.358 31.427-4.173-9.76c-.76-1.787-1.893-2.8-3.187-2.867-1.28-.066-2.52.827-3.466 2.534l-2.534 4.546c-.533.96-1.293 1.534-2.12 1.6-.84.08-1.68-.36-2.36-1.226l-.293-.374c-.947-1.186-2.12-1.76-3.32-1.64s-2.227.947-2.907 2.294l-2.306 4.6a5.3 5.3 0 0 0 .226 5.173 5.29 5.29 0 0 0 4.52 2.52h17.014a5.32 5.32 0 0 0 4.44-2.387 5.22 5.22 0 0 0 .466-5.013M18.292 20.174a4.507 4.507 0 1 0 0-9.014 4.507 4.507 0 0 0 0 9.014"
      />
    </g>
  </svg>
)
export default SvgLogoReview
