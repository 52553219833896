import * as React from 'react'
const SvgRightArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 64})`}>
      <path
        fill="#fff"
        d="M21.332 31.635a1 1 0 0 1 .864-.99l.136-.01 17.579.001-6.351-6.325a1 1 0 0 1 1.299-1.514l.112.097 8.067 8.032a1 1 0 0 1 .294.71v.038l-.005.059.005-.098a1 1 0 0 1-.197.596l-.008.011a1 1 0 0 1-.088.1l-.001.001-8.067 8.033a1 1 0 0 1-1.508-1.305l.097-.112 6.348-6.323H22.332a1 1 0 0 1-1-1"
      />
      <rect
        width={62.5}
        height={62.5}
        x={0.75}
        y={0.75}
        stroke="#fff"
        strokeWidth={1.5}
        rx={31.25}
      />
    </g>
  </svg>
)
export default SvgRightArrow
