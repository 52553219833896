const toasts = {
  toastText: {
    copy_advantage: 'Avantage copié !',
    copy_Link: 'Copié !',
    copy_Code: 'Code copié !',
  },
  toastPsw: {
    title: 'Votre mot de passe a bien été modifié !',
    resetTitle:
      'Un e-mail de réinitialisation de mot de passe vous a été envoyé !',
    error1: 'Les mots de passe ne sont pas identiques',
  },
  toastReportPost: {
    option: 'Signalement du post',
    alreadyReport: 'Ce post a déjà été signalé',
    savedReport: 'Votre signalement a bien été enregistré !',
  },
  toastReportProfile: {
    option: 'Signalement du profil',
    alreadyReport: 'Ce profil a déjà été signalé',
    savedReport: 'Votre signalement a bien été enregistré !',
    blockedsuccess: 'Bloqué avec succès',
    unBlockedsuccess: 'Débloqué avec succès',
  },
  toastAddContent: {
    error: 'Valeur non valide',
  },
  toastModalImage: {
    success: "L'image a été téléchargée avec succès !",
  },
  toastImageOriginSelection: {
    success: "L'image a été téléchargée avec succès ! Pensez à sauvegarder",
  },
  toastActivateProduct: {
    success: 'Produit Activé',
  },
  toastPublicationTime: {
    error: "Une erreur s'est produite",
  },
  toastWarningBanner: {
    success: 'Un email de confirmation vous a été envoyé',
  },
  toastQrCode: {
    info_text1: 'QR code enregistré !',
    info_text2: 'Votre code QR a été enregistré dans vos fichiers de caméra',
    error: 'QR code non enregistré !',
  },
  toastPersonalInformation: {
    warning_text: 'Veuillez remplir au moins un champ',
  },
  toastErrorDefault: {
    error: 'Une erreur s’est produite. Veuillez réessayer.',
  },
  toastNfcScanner: {
    error1: 'NFC ne peut pas être activé',
    error2: 'Étiquette non reconnue, réessayer',
  },
  toastTouchId: {
    success: "Identification d'empreinte digitale activée",
    success1: "Identification d'empreinte digitale désactivée",
  },
  toastfaceId: {
    success: 'Face ID activé',
    success1: 'Face ID désactivé',
  },
  toastSendCodeVerification: {
    success: 'Le code de vérification a été envoyé avec succès !',
    error: "Une erreur s'est produite lors de l'envoi.",
  },
  toastCheckCode: {
    success:
      'Le code est correct ! Pensez à enregistrer pour confirmer votre vérification.',
    error: "Le code n'est pas correct !",
  },
  toastOnBoarding: {
    error: "Nom d'utilisateur mal formaté",
  },
  toastDropPost: {
    success: 'Félicitations ! Ce post a été arrosé avec succès.',
  },
  toastUploadImageGallery: {
    you_have_canceled: "Vous avez annulé la sélection d'image",
  },
  toastExplicitImage: {
    please_upload_an_image:
      "Veuillez télécharger une image qui respecte la politique de l'application",
  },
  toast_welcome_quest_finished: {
    unlock_feature_complete_quest:
      'Pour débloquer cette fonctionnalité, vous devez terminer les quêtes de bienvenue.',
    show_welcome_quest: 'Voir les quêtes',
  },
}


export default toasts
