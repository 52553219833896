import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineSend2 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="m5.359 12.016-1.212 4.03c-.766 2.547 1.72 4.821 3.965 3.627l9.319-4.958c2.09-1.112 2.092-4.29.004-5.406L8.11 4.329C5.867 3.131 3.377 5.402 4.14 7.951zm0 0h2.97"
        />
      </g>
    </svg>
  )
}
export default SvgLineSend2
