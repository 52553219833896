import { LineExport } from '@/Assets/icons/components'
import { Config } from '@/Config'
import useUploadImage from '@/Hooks/useUploadImage'
import { PickerOverlay } from 'filestack-react'

const type = 'gallery'
const originType = 'image'
const title = 'modal.imageOriginSelection.choice1.title'
const subtitle = 'modal.imageOriginSelection.choice1.subtitle'
const icon = LineExport

const useGetOnClick = ({ setIsParentOpen }) => {
  return () => setIsParentOpen(true)
}

const ModalGallery = ({
  isOpen,
  onDismiss,
  destination,
  onChange,
  format = { width: 500, height: 500 },
}) => {
  const { uploadImage } = useUploadImage()

  const onUploadDone = async (res, type) => {
    if (res.filesUploaded[0]?.url) {
      const { src } = await uploadImage(res.filesUploaded[0].url, false)
      if (destination === 'photo') {
        onChange({ photoURL: src })
      }
      if (destination === 'banner') {
        onChange({ banner: { type: 'image', value: src } })
      }
      if (destination === 'logo') {
        onChange({ logoURL: src })
      }
      if (destination === 'gallery') {
        onChange(src)
      }
      onDismiss()
    }
  }
  return (
    <>
      {isOpen && (
        <PickerOverlay
          apikey={Config.FILESTACK_API_KEY}
          onUploadDone={onUploadDone}
          pickerOptions={{
            maxFiles: 1,
            accept: 'image/*',
            uploadInBackground: false,
            fromSources: ['local_file_system', 'url'],
            transformations: {
              crop: {
                aspectRatio: format.width / format.height,
                force: true,
              },
              circle: false,
            },
            onClose: () => {
              onDismiss()
            },
          }}
        />
      )}
    </>
  )
}

export const gallery = {
  type,
  originType,
  title,
  subtitle,
  icon,
  useGetOnClick,
  parent: ModalGallery,
}
