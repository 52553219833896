import moment from 'moment'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useGetUserName } from '@/Hooks/useGetUserName'
import { useTheme } from '@/Hooks'
import { useGetUserQuery } from '@/Services/modules/users'
import { LineArrowRight } from '@/Assets/icons/components'
import { Avatar } from '../Profile/Avatar'
import { useTranslation } from 'react-i18next'
import { Config } from '@/Config'

const StyledDrop = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: ${theme.space.m} ${theme.space.none};
    border-bottom: 1px solid ${theme.colors.grey1};
    color: ${theme.colors.black};
    cursor: pointer;
    &:hover {
      background: ${theme.colors.grey1};
      border-radius: ${theme.space.m};
      text-decoration: none;
      color: ${theme.colors.black};
    }
  `}
`

const Drop = ({ drop, mine }) => {
  const { data: profile } = useGetUserQuery(drop.userId)
  const userName = useGetUserName(profile || {})

  const { theme } = useTheme()

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dateFormat = useMemo(() => {
    if (language === 'fr') {
      return '[Le] DD MMMM YYYY [à] HH[h]mm'
    }
    if (language === 'es') {
      return '[El] DD [de] MMMM [de] YYYY [a las] HH[h]mm'
    }
    return 'MMMM DD YYYY [at] hh[h]mm A'
  }, [language])
  return (
    <StyledDrop href={window.origin + `/${profile?.customUrl}`}>
      <Avatar
        shape={profile?.styles?.['shape-avatar']}
        size={theme.size.m}
        showEmptyLogo={false}
        profile={profile || {}}
      />
      <div className="px-4 flex-1">
        <span>{moment(drop.date).format(dateFormat)}</span>
        <div className="shrink">
          <span color={theme.colors.grey2}>
            {userName || 'Anonyme'}{' '}
            {mine
              ? t('others.watered_your_seed')
              : t('others.watered_the_seed')}
          </span>
        </div>
      </div>
      <LineArrowRight size={16} />
    </StyledDrop>
  )
}

export default Drop
