const security = {
  skip: 'Saltar',
  title: 'Seguridad',
  pin_code: 'Código PIN',
  use: 'Utilizar',
  or: 'o',
  to_disable: 'para desactivar',
  to_unlock: 'para desbloquear',
  wrong_code: 'Código incorrecto',
  set_pin_code: 'Establecer código PIN',
  pin_code_not_match: 'Los códigos PIN no coinciden',
  confirm_pin_code: 'Confirmar código PIN',
  sentence: 'Feliz de verte de nuevo',
  try_later: 'Demasiados intentos. Por favor, inténtelo de nuevo más tarde.',
  codepin_modal: {
    forgot: 'Olvidar',
    delete: 'Borrar',
    reset: {
      title: 'Restablece tu código pin',
      subtitle:
        'Al confirmar, recibirá un correo electrónico que le permitirá restablecer su código PIN.',
      success_message: 'Se le ha enviado un correo electrónico',
    },
  },
  touchIDModal: {
    title: 'Identificar',
    title2: 'Activar la identificación de huellas dactilares ',
  },
  faceIDModal: {
    title: "S'identifier",
    title2: 'Activar identificación por reconocimiento facial',
  },
}


export default security
