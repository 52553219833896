import React, { createContext, useContext, useMemo } from 'react'
import { Config } from '@/Config'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'

const ProfileContext = createContext()

export const ProfileProvider = ({ config, children }) => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser

  const value = useMemo(() => {
    return {
      ...config,
      isMine: Boolean(user?.uid && user?.uid === config?.profile?.uid),
      isAdmin: Boolean(config?.profile?.uid === Config.ADMIN_UID),
      isVisitor: !user?.uid,
    }
  }, [user?.uid, config])
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export function useProfileContext() {
  return useContext(ProfileContext) || {}
}
