const discover_companions = {
  title: 'Découvrir les compagnons',
  choose_companion_resources: 'Sélectionner ses ressources',
  no_companion: 'Aucun compagnon',
  choose_companion: 'Choisissez votre compagnon',
  choose_companion_subtitle:
    'Gagnez quotidiennement des ressources supplémentaires avec votre compagnon.',
  explanation_title_screen: 'Compagnons',
  explanation_title: {
    main_text: 'Gagnez des <color>{{1}}</color>, avec votre compagnon',
    1: 'ressources',
  },
  explanation_description:
    'Les compagnons permettent de gagner des ressources quotidiennement. Il vous accompagnera aussi dans les mini-jeux pour vous aider à récolter encore plus de récompenses !',
  explanations: {
    title_reward: 'Encore plus de récompenses',
    description_reward:
      'Votre compagnon vous accompagne au quotidien dans votre geste éco-responsable en vous rapportant plus de ressources.',
    title_gains: 'Doublez vos gains en jouant',
    description_gains:
      'Activez votre compagnon durant vos parties vous permettra de doubler le gain de toutes les ressources récoltées !',
    title_evolve: 'Faite-le évoluer',
    description_evolve:
      "En faisant évoluer votre compagnon, vous augmentez les récompenses ! Vous pouvez l'améliorer avec vos coins",
  },
  get_your_companion: 'Obtenez votre compagnon',
  monthly: 'Mensuel',
  yearly: 'Annuel',
  by_month: '/mois',
  by_year: '/an',
}

export default discover_companions
