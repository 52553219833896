import { api } from '@/Services/api'

const updateCachePost = (state, patch) => {
  state = state.map(post => {
    if (post.id === patch.postId) {
      post.drops = [...post.drops, { userId: patch.userId, date: Date.now() }]
    }
    return post
  })
}

const addDrop = build =>
  build.mutation({
    query: ({ postId, userId }) => {
      return {
        url: `/posts/${userId}/add-drop/${postId}`,
        method: 'POST',
      }
    },
    async onQueryStarted(patch, { dispatch, queryFulfilled }) {
      const patchPosts = dispatch(
        api.util.updateQueryData('getPosts', patch.postId, state =>
          updateCachePost(state, patch),
        ),
      )
      const patchDiscoverPosts = dispatch(
        api.util.updateQueryData('getDiscoverPosts', patch.postId, state =>
          updateCachePost(state, patch),
        ),
      )
      const patchUserPosts = dispatch(
        api.util.updateQueryData('getPostsByUserId', patch.postId, state =>
          updateCachePost(state, patch),
        ),
      )
      try {
        await queryFulfilled
      } catch {
        patchPosts.undo()
        patchUserPosts.undo()
        patchDiscoverPosts.undo()
      }
    },
    invalidatesTags: [
      'PostsByHashtag',
      'Bookmarks',
      'AvailableResources',
      'Progress',
      'Streaks',
    ],
  })

export default addDrop
