import Text from '../Texts/Text'
import { useTheme } from '@/Hooks'

const { default: styled, css } = require('styled-components')

const StyledTextArea = styled.div`
  ${({ theme, resize }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: 25.76px;
    padding: 16px;
    width: 100%;
    position: relative;
    textarea {
      background-color: transparent;
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      outline: none;
      resize: ${resize ? 'vertical' : 'none'};
      color: ${theme.colors.black};
    }
  `}
`
export const TextArea = ({ value, onChange, resize = true, ...props }) => {
  const { theme } = useTheme()
  return (
    <StyledTextArea resize={resize}>
      <textarea
        value={value}
        onChange={e => onChange(e.target.value)}
        {...props}
      />
      {props.maxLength && (
        <div className="flex flex-row justify-end">
          <Text color={theme.colors.grey2} className="text-xs">
            {value.length || 0}/{props.maxLength}
          </Text>
        </div>
      )}
    </StyledTextArea>
  )
}

export default TextArea
