import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineBusinessCard = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M20 20H4c-1.654 0-3-1.346-3-3V7c0-1.654 1.346-3 3-3h16c1.654 0 3 1.346 3 3v10c0 1.654-1.346 3-3 3M4 6c-.551 0-1 .449-1 1v10c0 .551.449 1 1 1h16c.551 0 1-.449 1-1V7c0-.551-.449-1-1-1zm6 9H6a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2m0-4H6a1 1 0 0 1 0-2h4a1 1 0 1 1 0 2"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M16 12.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4M16 13.356c-1.562 0-2.5.715-2.5 1.429 0 .357.938.715 2.5.715 1.466 0 2.5-.357 2.5-.715 0-.714-.98-1.429-2.5-1.429"
        />
      </g>
    </svg>
  )
}
export default SvgLineBusinessCard
