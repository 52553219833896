import ContentCopy from './ContentCopy'
import { useShareUrl } from './Panels/PanelShareProfile/ShareUrlContext'
import React from 'react'

const CopyLink = () => {
  const { url } = useShareUrl()

  return <ContentCopy text={url.replace('https://', '')} />
}

export default CopyLink
