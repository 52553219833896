const feedpage = {
  feed: 'Feed',
  title_tab_bottom: 'Home',
  insight: 'Home',
  fav: 'My favorites',
  add_post_to_bookmarks: 'Add post to bookmarks',
  remove_post_from_bookmarks: 'Remove post from bookmarks',
  search: 'Search',
  scan: {
    qrcode: 'Scan by QRCode',
    nfc: 'Scan by NFC',
  },
  notification: {
    title: 'My activities',
    all: 'All',
    requests: 'Requests',
    add: 'Add',
    added: 'Added',
    unfollow: 'Unfollow',
    follow: 'Follow',
    empty_resource: 'The resource no longer exists',
    people_watering_post: 'People have watered your post',
    no_notification_title: 'No notifications found',
    no_notification_subtitle:
      'At the moment there is no activity on your account. Come back later!',
  },
  modify_post: {
    modify: 'Edit this post',
    delete: 'Delete this post',
    edit_btn: 'Edit',
  },
  bookmark_images: 'Your saved images',
  bookmark_posts: 'Your saved posts',
}


export default feedpage
