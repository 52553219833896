const shop = {
  screen_name: 'Tienda',
  free_ressources: 'Ofertas gratuitas',
  seeds_packages: 'Paquetes de semillas',
  coins_packages: 'Paquetes de monedas',
  drops_packages: 'Paquetes de gotas',
  tickets_packages: 'Paquetes de boletos',
  pack_title: 'Recibe {{quantity}} ',
  seed: 'semilla',
  coin: 'moneda',
  drop: 'gota',
  ticket: 'ticket',
  chest: 'cofre',
  free_subtitle: 'Mira un anuncio para ganar una {{resource}} gratuita',
  resources_subtitle:
    'Obtén {{ resource }} para plantar árboles en la vida real.',
  subscriptions_subtitle: 'Funciones de Budly',
  free_seed_button: 'Mira un anuncio',
  already_recovered: 'Ya recuperado',
  remaining: 'restante{{plurial}}',
  five_seeds_pack_subtitle:
    'Cada compra aumenta el dinero donado a proyectos...',
  fifty_seeds_pack_subtitle: 'Y ayuda al equipo de Budly a desarrollar...',
  two_hundred_seeds_pack_subtitle: 'Muchas nuevas características para ti.',
  ticket_pack_subtitle: 'Para jugar a nuestros mini-juegos.',
  chest_pack_subtitle: 'Y gana accesorios para tu compañero.',
  tab_all: 'Todo',
  tab_free: 'Gratuito',
  tab_coins: 'Monedas',
  tab_resources: 'Recursos',
  tab_products: 'Productos',
  tab_subscriptions: 'Suscripciones',
  delivery: 'Entrega',
  order: 'Ordenar',
  select: 'Seleccionar',
  subscribe: 'Suscribirse',
  price: '{{ price }}€',
  resources_price: 'Desde {{ price }}€',
  products_price: '{{ price }}€ / {{ product }}',
  duration: 'mes',
  subscriptions_price: '{{ price }}€ / {{ duration }}',
  subscriptions_price_subtitle: '+ {{ price }}€ solo una vez',
  thanks_for_purchase_title: '¡Gracias por su compra!',
  thanks_for_purchase_description: 'Tu compra ha sido realizada, ¡aprovéchala!',
  thanks_for_watching_add_description: '¡Tu recompensa te ha sido otorgada!',
  // available_soon: "Bientôt disponible",
  available_soon: 'Pronto disponible',
  shop_creation_success: 'Tu tienda ha sido creada con éxito',
  cart: 'Carrito',
  add_to_cart: 'Añadir al carrito',
  cancel: 'Annular',
  quantity: 'Cantitad',
  total: 'Total de gasto',
  pay_and_save_btn: 'Pagar y guardar',
  monthly_subscription: 'Suscripción mensual',
  monthly_benefits: 'Beneficios mensuales',
  badge_text: '€ de productos',
  physical_products: 'de productos físicos',
  physical_products_offered: 'Productos físicos ofrecidos',
  product_added_success: '¡El producto ha sido añadido al carrito con éxito!',
  sponsor: 'Padrino',
  subscription_mandatory: 'Suscripción obligatoria',
  already_subscribed: 'Suscripción ya en curso',
}

export default shop
