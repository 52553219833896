const getPostById = build =>
  build.query({
    query: id => {
      return {
        url: `/posts/post/${id}`,
        method: 'GET',
      }
    },
    providesTags: (result, error, id) => {
      return [{ type: 'Posts', id }]
    },
  })

export default getPostById
