import { useTheme } from '@/Hooks'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { SiAddthis } from 'react-icons/si'
import { useTranslation } from 'react-i18next'

const Logo = styled.img`
  ${({ ratio }) => css`
    height: auto;
    transform: scale(${ratio});
  `}
`
const WrapperLogo = styled.div`
  height: 60px;
  width: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const NotLogo = styled.div`
  height: 60px;
  width: 60px;
  flex-direction: column;
  align-items: center;
  display: flex;
`

export const TemplateLogo = ({
  businessCard,
  clsxClassName,
  logoClassName = 'items-center',
}) => {
  const [ratio, setRatio] = useState(1)
  const { theme } = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    if (businessCard?.logo) {
      setRatio(businessCard.logoScale || 1)
    }
  }, [])

  if (!businessCard) {
    return (
      <NotLogo>
        <span className="text-grey2 text-xs text-center">
          {t('businessCard_placeholder.your_logo')}
        </span>
        <SiAddthis style={{ color: theme.colors.grey2 }} />
      </NotLogo>
    )
  }

  if (businessCard.logo) {
    return (
      <WrapperLogo>
        <Logo
          ratio={businessCard.logoScale || ratio}
          src={businessCard.logo}
          resizeMode="contain"
        />
      </WrapperLogo>
    )
  }

  return null
}
