import TextInput from './TextInput'
import { LineClose, Search as SearchIcon } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import Text from '../Texts/Text'

const Search = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    padding-left: ${theme.space.std};
    padding-right: ${theme.space.std};
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    min-height: ${theme.size.m};
  `}
`

const WrapperClose = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
  `}
`

const FakeTextInput = styled(Text)`
  ${({ theme }) => css`
    padding: ${theme.space.std} ${theme.space.m};
    color: ${theme.colors.grey2};
  `}
`

export const FakeSearchBar = memo(({ placeholder }) => {
  return (
    <Search>
      <SearchIcon />
      <FakeTextInput className="pl-2 grow">{placeholder}</FakeTextInput>
    </Search>
  )
})

const SearchBar = ({
  placeholder,
  value,
  onChange,
  autoFocus = false,
  insideModal = false,
}) => {
  return (
    <Search>
      <SearchIcon />
      <TextInput
        className="p-0 pl-2 grow w-auto"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        insideModal={insideModal}
      />
      <ResetButton value={value} onChange={onChange} />
    </Search>
  )
}
const ResetButton = ({ value, onChange }) => {
  const { theme } = useTheme()

  if (!value) {
    return null
  }

  return (
    <div onClick={() => onChange('')} className="mr-3 cursor-pointer">
      <WrapperClose className="rounded-xl">
        <LineClose color={theme.colors.grey2} />
      </WrapperClose>
    </div>
  )
}

export default SearchBar
