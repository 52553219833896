import * as React from 'react'
const SvgSocialCalendly = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 522})`}>
      <path
        fill="#016AFF"
        d="M351.299 338.5c-16.6 14.7-37.2 33-74.8 33h-22.5c-27.2 0-51.9-9.9-69.6-27.8-17.3-17.5-26.799-41.4-26.799-67.4v-30.7c0-26 9.499-49.9 26.799-67.4 17.7-17.9 42.4-27.8 69.6-27.8h22.4c37.6 0 58.201 18.3 74.801 33 17.2 15.2 32.099 28.4 71.599 28.4 6.2 0 12.2-.5 18-1.4 0-.1-.099-.2-.099-.3-2.4-5.8-5.101-11.6-8.301-17.1l-26.5-45.8c-24.3-42.1-69.199-68-117.699-68h-52.901c-48.6 0-93.499 25.9-117.699 68L91.1 193c-24.3 42.1-24.3 93.9 0 136l26.5 45.8c24.3 42.1 69.199 68 117.699 68H288.2c48.6 0 93.499-25.9 117.699-68l26.5-45.8c3.2-5.5 6.001-11.3 8.301-17.1 0-.1.099-.2.099-.3-5.9-.9-11.9-1.4-18-1.4-39.5-.1-54.3 13-71.5 28.3"
      />
      <path
        fill="#016AFF"
        d="M276.5 178.3H254c-41.3 0-68.5 29.5-68.5 67.3v30.7c0 37.8 27.2 67.3 68.5 67.3h22.399c60.2 0 55.5-61.4 146.5-61.4 8.7 0 17.301.8 25.701 2.4 2.8-15.6 2.8-31.6 0-47.2-8.4 1.6-17.001 2.4-25.701 2.4-90.9-.1-86.199-61.5-146.399-61.5"
      />
      <path
        fill="#016AFF"
        d="M500.899 307.1c-15.5-11.5-33.399-18.9-52.299-22.5 0 .2 0 .3-.1.5-1.6 9-4.101 17.9-7.601 26.5 15.9 2.6 30.601 8.6 43.201 17.8 0 .1-.1.3-.1.4-7.201 23.5-18.2 45.7-32.4 65.9-14.1 19.9-31.201 37.5-50.701 52.1-40.6 30.4-88.899 46.5-139.799 46.5-31.5 0-62.001-6.2-90.801-18.3-27.8-11.7-52.699-28.6-74.1-50s-38.2-46.4-50-74.1c-12.2-28.7-18.3-59.3-18.3-90.8s6.2-62 18.3-90.8c11.7-27.8 28.6-52.7 50-74.1 21.401-21.4 46.4-38.2 74.1-50C198.999 34 229.6 27.9 261.1 27.9c50.9 0 99.199 16.1 139.799 46.5 19.5 14.7 36.601 32.2 50.701 52.1 14.3 20.2 25.199 42.3 32.4 65.9 0 .1.1.3.1.4-12.6 9.2-27.301 15.2-43.201 17.8 3.4 8.6 6.001 17.5 7.601 26.5 0 .1 0 .3.1.4 18.9-3.5 36.699-11 52.299-22.5 14.9-11 12.001-23.5 9.701-30.9C477.8 77.5 378.5 0 261.1 0 116.9 0 .1 116.9.1 261s116.9 261 261 261c117.4 0 216.7-77.5 249.5-184.1 2.3-7.4 5.199-19.8-9.701-30.8"
      />
    </g>
  </svg>
)
export default SvgSocialCalendly
