const reward_modal = {
  title: 'You have won',
  subtitle: 'Congratulation!',
  daily_seed_reward: 'You have collected your daily {{resource}}',
  daily_quest_reward: 'You have finished your daily quest',
  daily_rewards_title: 'Daily resources collected',
  daily_rewards_description: 'You have just collected your daily resources',
}


export default reward_modal
