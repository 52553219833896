import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import { Modal } from './ModalWrapper/Modal'
import { useNavigate } from 'react-router-dom'

const ModalImageToFeed = ({ isOpen, onDismiss, image, setImage }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleDismiss = () => {
    onDismiss()
    navigate('PublishPost', {
      screen: 'Publier un post',
      params: {
        initialPost: {
          images: [image.src],
          tags: image.tags,
          content: image.description,
        },
      },
    })
    setImage({
      src: '',
      description: '',
      tags: [],
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={t('modal.publish_gallery_in_post')}
      actions={[
        {
          label: t('publication_modal.publish'),
          onClick: handleDismiss,
          color: theme.colors.primary,
        },
        {
          color: theme.colors.grey1,
          label: t('modal.btn.cancel'),
          onClick: onDismiss,
        },
      ]}
    />
  )
}

export default ModalImageToFeed
