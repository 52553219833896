import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineMobile = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={2}
          d="M12 17.75v.023"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M13.25 7.75h-2.5M19.3 14.837H4.7m10.216 6.535c-1.911.504-3.92.504-5.832 0a6.43 6.43 0 0 1-4.653-4.907l-.095-.456a16 16 0 0 1 0-6.518l.095-.456a6.43 6.43 0 0 1 4.653-4.906 11.4 11.4 0 0 1 5.832 0 6.43 6.43 0 0 1 4.653 4.906l.095.456c.448 2.15.448 4.369 0 6.518l-.095.456a6.43 6.43 0 0 1-4.653 4.907Z"
        />
      </g>
    </svg>
  )
}
export default SvgLineMobile
