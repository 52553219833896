import { Tabs } from '@/Components/Controls/Tabs'
import useGetTabs from '@/Components/Dashboard/Profile/Contents/useGetTabs'
import Form from '@/Components/Form/Form'
import Headings from '@/Components/Dashboard/Profile/Headings'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import FormSubmitButton from '@/Components/Form/FormSubmitButton'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'

const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`

const TITLE = 'edit_profil.contents.title'
const SUBTITLE = 'edit_profil.contents.subtitle'

const ManageContents = () => {
  const tabs = useGetTabs()
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser

  const [selectedTab, setSelectedTab] = useState(tabs[0].title)
  const { onSubmit } = useEditCurrentUser()

  if (!user) {
    return null
  }

  return (
    <Form onSubmit={onSubmit}>
      <Headings title={TITLE} subtitle={SUBTITLE} />
      <Wrapper>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="min-w-20 pl-0"
        />
        {tabs.map(tab => {
          const Component = tab.component
          if (tab.title === selectedTab) {
            return (
              <>
                <Component key={tab.name} />
                {tab.submit && <FormSubmitButton className="w-full" />}
              </>
            )
          }
          return null
        })}
      </Wrapper>
    </Form>
  )
}
export default ManageContents
