import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgVersionIcon = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M6 15.212h10.19c1.417 0 2.282-1.688 1.536-2.995l-.49-.858a2.81 2.81 0 0 1 0-2.756l.49-.858c.746-1.307-.12-2.995-1.535-2.995H6zm0 0v5.538"
        />
      </g>
    </svg>
  )
}
export default SvgVersionIcon
