const businessCard = {
  title: 'Find all your business cards',
  no_business_card_selected: 'No business card selected',
  subtitle1:
    'You have no business card, you can add and customize them to your image to share with your network.',
  subtitle2:
    'Find all your business card, customize them to your image in order to share them with your network.',
  btn_add: 'Add a new card',
  left_label: 'Add my card',
  title_screen1: 'Add a business card',
  title_screen2: 'Edit my business card',
  title_screen3: 'Template',
  title_screen4: 'Information',
  title_screen5: 'Logo',
  title_screen6: 'Color',
  title_screen_preview: 'Business card',
  block_all_fields: 'Block all fields.',
  in_multiple_edit_mode:
    'In multiple edit mode, you cannot edit or delete business cards',
  create_business_card: 'Create my business card',
}

export default businessCard
