import * as React from 'react'
const SvgLineFaq = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <path
        stroke="#292929"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 4.001h16v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z"
      />
      <path
        stroke="#292929"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M12 16h.01v.01H12z"
      />
      <path
        stroke="#292929"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10.586 7.586c.39-.39.9-.585 1.41-.586.513-.001 1.027.194 1.418.586.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 0 1-1.418.586L12 12"
      />
    </g>
  </svg>
)
export default SvgLineFaq
