import LabeledSwitch from '@/Components/Controls/LabeledSwitch'
import { ProLabel } from './ProLabel'
import { useTranslation } from 'react-i18next'

const SwitchIsFastLink = ({ isFastLink, setIsFastLink, type }) => {
  const { t } = useTranslation()
  const visible = type === 'social' || type === 'link'
  if (!visible) {
    return null
  }

  return (
    <div className="mt-2">
      <ProLabel label={t('profilepage.advanced_options')} />
      <LabeledSwitch
        title={t('profilepage.instant_redirection')}
        value={isFastLink}
        onChange={setIsFastLink}
      />
    </div>
  )
}

export default SwitchIsFastLink
