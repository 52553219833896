const getAllTreesCount = build =>
  build.query({
    query: () => {
      return {
        url: '/trees/count',
        method: 'GET',
      }
    },
  })

export default getAllTreesCount
