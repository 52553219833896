import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineNotification = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 20})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M9 1v1.005M6.01 15.903c1.987.224 3.993.224 5.98 0a3.5 3.5 0 0 1-.09.574l-.082.334a2.87 2.87 0 0 1-2.114 2.106c-.463.11-.945.11-1.408 0a2.87 2.87 0 0 1-2.114-2.106l-.082-.334a3.5 3.5 0 0 1-.09-.574Zm-2.982-9.27a6.3 6.3 0 0 1 4.13-4.33c1.235-.4 2.577-.402 3.81.005a6.15 6.15 0 0 1 4.011 4.28l.584 2.223.467 2.117c.144.651.34 1.322.443 1.981q.027.173.027.351a2.24 2.24 0 0 1-1.788 2.197l-.367.075a26.8 26.8 0 0 1-10.69 0l-.367-.075a2.24 2.24 0 0 1-1.76-2.548c.102-.66.298-1.33.442-1.982l.468-2.116z"
        />
      </g>
    </svg>
  )
}
export default SvgLineNotification
