import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineMultipleBubble = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M7.926 7.142a6.22 6.22 0 0 0-4.634 4.634c-.39 1.66-.39 3.388 0 5.048a6.22 6.22 0 0 0 4.634 4.634c1.66.39 3.388.39 5.048 0a6.22 6.22 0 0 0 4.634-4.634M7.926 7.142c1.66-.39 3.388-.39 5.048 0a6.22 6.22 0 0 1 4.634 4.634c.39 1.66.39 3.388 0 5.048M7.926 7.142a6.2 6.2 0 0 0-1.145.39 6.22 6.22 0 0 1 4.245-3.49c1.66-.39 3.388-.39 5.048 0a6.22 6.22 0 0 1 4.634 4.634c.39 1.66.39 3.388 0 5.048a6.22 6.22 0 0 1-3.49 4.245 6.2 6.2 0 0 0 .39-1.145"
        />
      </g>
    </svg>
  )
}
export default SvgLineMultipleBubble
