import React from 'react'
import { Modal } from './ModalWrapper/Modal'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router-dom'
const budlyPresentationImage = require('@/Assets/images/budly-presentation.webp')

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    border-radius: ${theme.borderRadius.m};
    margin-top: ${theme.space.l};
    max-width: 360px;
  `}
`

const ModalDownloadApp = React.memo(
  ({ isOpen, onDismiss, loading, title, subtitle, btnLabel }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
      <Modal
        title={title || t('modal_download_app.title')}
        subtitle={subtitle || t('modal_download_app.subtitle')}
        isOpen={isOpen}
        loading={loading}
        onDismiss={onDismiss}
        actions={[
          {
            label: btnLabel || t('modal_download_app.btn_label'),
            onClick: () => {
              navigate('/dl-app')
              onDismiss()
            },
          },
        ]}
      >
        <div className="flex items-center justify-center">
          <Image src={budlyPresentationImage} alt="app presentation" />
        </div>
      </Modal>
    )
  },
)

export default ModalDownloadApp
