const getUserByCustomFieldForWhitelabel = build =>
  build.query({
    query: ({ customUrl, whitelabel }) => {
      return {
        url: `/users/customUrl/where/${customUrl}/and/${whitelabel}`,
        method: 'GET',
      }
    },
  })

export default getUserByCustomFieldForWhitelabel
