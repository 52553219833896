import { useTranslation } from 'react-i18next'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { LabeledSwitchLock } from '@/Components/ControlsLocked/LabeledSwitchLock'

function PrivateProfileSwitch({ name, disabled = false }) {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { t } = useTranslation()
  return (
    <LabeledSwitchLock
      className="mt-3 mb-3"
      title={t('edit_profil.informations.advanced_options.private_mode')}
      subtitle={t('edit_profil.informations.advanced_options.private_mode')}
      value={editCurrentUser.isPrivateMode || false}
      onChange={value =>
        setEditCurrentUser({ ...editCurrentUser, isPrivateMode: value })
      }
      disabled={disabled}
      name={name}
    />
  )
}

export default PrivateProfileSwitch
