import { infiniteScrollOptions } from '../infiniteScrollOptions'

const getPosts = build =>
  build.query({
    query: ({ lastDate, feed = '', userId }) => {
      return {
        url: `/posts/${lastDate}?feed=${feed}&userId=${userId}`,
        method: 'GET',
      }
    },
    ...infiniteScrollOptions,
  })

export default getPosts
