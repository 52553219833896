const addAnalytics = build =>
  build.mutation({
    query: ({ userUid, body, collection = null }) => {
      const queryParam = collection ? `?collection=${collection}` : ''
      return {
        url: `/users/${userUid}/analytics${queryParam}`,
        method: 'POST',
        body,
      }
    },
  })

export default addAnalytics
