import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineMoreVertical = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M10 19c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2M10 5c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2M10 12c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2"
        />
      </g>
    </svg>
  )
}
export default SvgLineMoreVertical
