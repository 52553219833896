import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineScan2 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m15.577 21.344.147.735zm-.561.112-.148-.735zm-5.866.011-.145.736zm-.722-.142.144-.736zm-4.678-6.43a.75.75 0 0 0-1.5 0zm18 0a.75.75 0 0 0-1.5 0zM8.424 4.155l-.148-.735zm.56-.112.148.736zm5.866-.01-.145.736zm.722.142.145-.736zm4.678 6.43a.75.75 0 0 0 1.5 0zm-18 0a.75.75 0 0 0 1.5 0zm4.383 2.192a.75.75 0 1 0 .61 1.37zm1.255.263.304.685zm8.224 0-.304.685zm.646 1.108a.75.75 0 0 0 .61-1.37zm-1.329 6.44-.56.113.294 1.47.56-.111zm-6.134.123-.723-.142-.29 1.472.723.142zm5.573-.01a14.3 14.3 0 0 1-5.573.01l-.29 1.472c2.033.4 4.127.396 6.158-.011zM2.25 14.895c0 3.495 2.54 6.478 6.033 7.166l.29-1.472C5.75 20.034 3.75 17.64 3.75 14.895zm18 0c0 2.749-1.997 5.148-4.821 5.714l.295 1.47c3.492-.7 6.026-3.688 6.026-7.184zM8.57 4.89l.562-.112-.295-1.47-.56.111zm6.135-.122.723.142.29-1.472-.723-.142zm-5.573.01a14.3 14.3 0 0 1 5.573-.01l.29-1.472a15.8 15.8 0 0 0-6.158.011zm12.618 5.826c0-3.495-2.54-6.478-6.033-7.166l-.29 1.472c2.823.555 4.823 2.949 4.823 5.694zm-18 0c0-2.749 1.997-5.148 4.82-5.714l-.294-1.47c-3.492.7-6.026 3.688-6.026 7.184zm3.492 3.563.95-.422-.609-1.371-.95.422zm8.566-.422.95.422.61-1.37-.951-.423zm-7.616 0a9.38 9.38 0 0 1 7.616 0l.609-1.371a10.88 10.88 0 0 0-8.834 0z"
        />
      </g>
    </svg>
  )
}
export default SvgLineScan2
