import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgConditionsIcon = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m3.353 15.562.73-.175zm0-5.979.73.175zm17.294 0-.73.175zm0 5.979-.73-.175zm-5.597 5.487-.168-.731zm-6.1 0-.168.73zm0-16.953.168.73zm6.1 0-.168.73zM4.082 15.387a12.1 12.1 0 0 1 0-5.63l-1.459-.349a13.6 13.6 0 0 0 0 6.328zm15.836-5.63a12.1 12.1 0 0 1 0 5.63l1.459.35a13.6 13.6 0 0 0 0-6.329zm-5.036 10.56a12.9 12.9 0 0 1-5.764 0l-.336 1.463c2.117.486 4.32.486 6.436 0zM9.118 4.828a12.9 12.9 0 0 1 5.764 0l.336-1.462a14.4 14.4 0 0 0-6.436 0zm0 15.49c-2.506-.575-4.452-2.49-5.036-4.93l-1.459.35c.72 3.005 3.11 5.342 6.16 6.043zm6.1 1.463c3.048-.701 5.44-3.038 6.159-6.044l-1.46-.349c-.583 2.44-2.53 4.355-5.036 4.93zm-.336-16.953c2.506.576 4.452 2.491 5.036 4.93l1.459-.349c-.72-3.005-3.11-5.342-6.16-6.043zm-6.1-1.462c-3.048.701-5.44 3.038-6.159 6.043l1.46.35c.583-2.44 2.53-4.355 5.035-4.931zm6.049 18.385c0-1.464.001-2.485.107-3.255.102-.747.29-1.146.582-1.432l-1.05-1.071c-.623.61-.892 1.38-1.018 2.3-.123.895-.121 2.037-.121 3.458zm5.491-6.868c-1.45 0-2.612-.002-3.522.118-.93.123-1.71.384-2.33.992l1.05 1.071c.293-.288.706-.474 1.476-.576.79-.104 1.834-.105 3.326-.105z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M9 9.75h3m-3 3h5"
        />
      </g>
    </svg>
  )
}
export default SvgConditionsIcon
