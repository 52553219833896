const sendEmailMember = build =>
  build.mutation({
    query: ({ uid, ...body }) => {
      return {
        url: `members/send/email/${uid}`,
        method: 'POST',
        body,
      }
    },
    invalidatesTags: ['TeamMembers'],
  })

export default sendEmailMember
