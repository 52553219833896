import * as Sentry from '@sentry/browser'
const errorsMessageToIgnore = ['ECONNRESET']

export const handleError = error => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(error)
    return
  }
  if (errorsMessageToIgnore.includes(error.message)) {
    return
  }
  Sentry.captureException(error)
}
