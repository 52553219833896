const accountPage = {
  subtitle: 'Informations visibles seulement par vous.',
  paymentInformationTitle: 'Informations de paiement',
  subTitlePayment: 'Accédez aux informations concernant la carte enregistrée',
  titleManage: 'Stripe Portal',
  buttonTextStripe: 'Manage',
  dangerZoneTitle: 'Zone de danger',
  passwordSubtitle: 'Modifiez votre mot de passe actuel',
  resetPassword: 'Réinitialiser mon mot de passe',
}

export default accountPage
