const post_details = {
  title: 'Post details',
  posted_by: 'Posted by',
  post_history: 'Post history',
  water_this_post: 'Water this post',
  see_participants: 'See {{quantity}} participant{{plurial}}',
  seed_progress: 'Seed progress',
  help_get_tree: 'Help get a tree',
  water_this_post_to_plant: 'Water this post to plant a new tree',
}


export default post_details
