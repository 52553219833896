import React from 'react'

const FlatList = ({
  data,
  renderItem,
  keyExtractor = item => null,
  listHeaderComponent,
  listFooterComponent,
}) => {
  return (
    <>
      {listHeaderComponent && listHeaderComponent}
      {data.map((item, index) => {
        return renderItem({ item, key: keyExtractor(item) })
      })}
      {listFooterComponent && listFooterComponent}
    </>
  )
}

export default FlatList
