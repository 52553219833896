const downloadVcardById = build =>
  build.query({
    query: ({ cardId = '' }) => {
      return {
        url: `/users/addContact/businessVCard?businessCardID=${cardId}`,
        method: 'GET',
        responseHandler: 'text',
      }
    },
  })

export default downloadVcardById
