import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineWarning = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M3.353 9.7A7.51 7.51 0 0 1 8.95 4.103c2.006-.47 4.094-.47 6.1 0A7.51 7.51 0 0 1 20.647 9.7c.47 2.006.47 4.094 0 6.1a7.51 7.51 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0A7.51 7.51 0 0 1 3.353 15.8a13.35 13.35 0 0 1 0-6.1Z"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M12 14.5c-.41 0-.75-.34-.75-.75V8.5c0-.41.34-.75.75-.75s.75.34.75.75v5.25c0 .41-.34.75-.75.75M12 18a1 1 0 0 1-.71-.29c-.09-.1-.16-.21-.22-.33A1 1 0 0 1 11 17c0-.26.11-.52.29-.71.37-.37 1.05-.37 1.42 0 .18.19.29.45.29.71 0 .13-.03.26-.08.38q-.075.18-.21.33A1 1 0 0 1 12 18"
        />
      </g>
    </svg>
  )
}
export default SvgLineWarning
