import app from './Config'
import { getFirestore } from 'firebase/firestore'
// import 'firebase/storage'
// import 'firebase/functions'
// import firebase from 'firebase'
// import { getCurrentUser, deleteUser } from './Auth'

export const db = getFirestore(app)
// export let storage
// export let functions

// if (typeof window !== 'undefined') {
//   db = firebase.firestore()
//   db.settings({
//     ignoreUndefinedProperties: true,
//   })
//   storage = firebase.storage()
//   functions = firebase.functions()
// }

// export const readDataLink = id => {
//   return db.collection('links').doc(id).get()
// }
// export const readDataUser = id => {
//   return db.collection('users').doc(id).get()
// }
// export const readDataUserWithId = id => {
//   return db.collection('users').where('id', '==', id).get()
// }

// export const read = collection => {
//   return new Promise((resolve, reject) => {
//     db.collection(collection)
//       .get()
//       .then(querySnapshot => {
//         resolve(querySnapshot)
//       })
//       .catch(err => reject(err))
//   })
// }

// export const write = (collection, items) => {
//   const user = getCurrentUser()

//   db.collection(collection)
//     .doc(user)
//     .set(items)
//     .then(() => {
//       console.log('Document successfully written!')
//     })
//     .catch(error => {
//       console.error('Error writing document: ', error)
//     })
// }

// export const generateLinks = (id, items, action) => {
//   return new Promise((resolve, reject) => {
//     db.collection('links')
//       .doc(id)
//       .set(items)
//       .then(() => {
//         resolve(true)
//       })
//       .catch(err => {
//         console.log(err)
//         reject(err)
//       })
//   })
// }

// export const verifUserExist = id => {
//   return new Promise((resolve, reject) => {
//     db.collection('users')
//       .doc(id)
//       .get()
//       .then(doc => {
//         if (doc.exists) {
//           resolve(true)
//         } else {
//           reject(false)
//         }
//       })
//       .catch(err => {
//         console.log(err)
//         reject(false)
//       })
//   })
// }

// export const deleteAccount = async collection => {
//   const userDb = getCurrentUser()
//   await deleteUser()
//     .then(() => {
//       return db.collection(collection).doc(userDb).delete()
//     })
//     .catch(err => console.log(err))
// }

// export const update = (collection, items, doc) => {
//   if (doc === undefined) {
//     const user = getCurrentUser()
//     db.collection(collection)
//       .doc(user)
//       .update(items)
//       .then(() => {
//         console.log('Document successfully written!')
//       })
//       .catch(error => {
//         console.error('Error writing document: ', error)
//       })
//   } else {
//     db.collection(collection)
//       .doc(doc)
//       .update(items)
//       .then(() => {
//         console.log('Document successfully written!')
//       })
//       .catch(error => {
//         console.error('Error writing document: ', error)
//       })
//   }
// }

// export const addEmail = newEmail => {
//   db.collection('emailCapture')
//     .add(newEmail)
//     .then(() => {
//       console.log('Document successfully written!')
//     })
//     .catch(error => {
//       console.error('Error writing document: ', error)
//     })
// }

// export const addStorageYLC = (uid, collection, value) => {
//   db.collection('users')
//     .doc(uid)
//     .collection(collection)
//     .add(value)
//     .then(() => {
//       console.log('Document successfully written!')
//     })
//     .catch(error => {
//       console.error('Error writing document: ', error)
//     })
// }
