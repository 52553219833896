import getFollowings from './getFollowings'
import { api } from '@/Services/api'
import getFollowingsList from './getFollowingsList'

export const followingsApi = api.injectEndpoints({
  endpoints: build => ({
    getFollowings: getFollowings(build),
    getFollowingsList: getFollowingsList(build),
  }),
  overrideExisting: true,
})

export const { useGetFollowingsQuery, useLazyGetFollowingsListQuery } =
  followingsApi
