import { useMemo } from 'react'
import Text from '../Texts/Text'
import ReactPhoneInput2 from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled, { css } from 'styled-components'

const StyledPhoneInputWrapper = styled.div`
  ${({ theme }) => css`
    .react-tel-input .country-list {
      border-radius: ${theme.borderRadius.m};
    }
    .react-tel-input .country-list .country {
      padding: 12px 16px;
      border-bottom: 1px solid ${theme.colors.grey1};
    }
    .container {
      background-color: ${theme.colors.grey1};
      border-radius: ${theme.borderRadius.l};
      padding: ${theme.space.xs} ${theme.space.m};
      border: none;
    }
    input {
      background-color: transparent !important;
      border: none !important;
      color: ${theme.colors.black} !important;
      font-size: ${theme.fontSize.std} !important;
    }
    .selected-flag,
    .react-tel-input .flag-dropdown.open {
      background: transparent !important;
    }
    .button {
      border: none !important;
    }
    button:hover {
      background-color: transparent !important;
    }
  `}
`

const PhoneInput = ({
  label = 'Valeur',
  value = { number: '', code: ['FR', '+33'] },
  onChange,
  ...props
}) => {
  const formattedValue = useMemo(() => {
    const formattedCode = value.code[1].replace('+', '')
    const formattedNumber = value.number.slice(1)

    return `${formattedCode}${formattedNumber}`
  }, [value])

  return (
    <>
      <div className="mb-3">
        <Text className="font-medium">{label}</Text>
      </div>
      <StyledPhoneInputWrapper>
        <ReactPhoneInput2
          {...props}
          containerClass="container"
          buttonClass="button"
          countryCodeEditable={false}
          country="fr"
          value={formattedValue}
          onChange={(number, country) => {
            onChange({
              number: `0${number.slice(country.dialCode.length)}`,
              code: [country.countryCode.toUpperCase(), `+${country.dialCode}`],
            })
          }}
        />
      </StyledPhoneInputWrapper>
    </>
  )
}

export default PhoneInput
