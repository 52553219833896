import { OptionItem } from '../../../OptionItem'
import React from 'react'
import ModalSearchList from '../../ModalSearchList'
import { LineGallery } from '@/Assets/icons/components'
import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'
import { bgAnimated } from '@/Utils/bgAnimated'
import DynamicBackgroundAnimated from '@/Utils/DynamicBackgroundAnimated'
const type = 'animatedBackground'
const originType = 'animatedBackground'
const icon = LineGallery
const title = 'modal.imageOriginSelection.choice3.title'
const subtitle = 'modal.imageOriginSelection.choice3.subtitle'

const useGetOnClick = ({ setIsParentOpen }) => {
  return () => setIsParentOpen(true)
}
const onFetch = async ({ query, page }) => {
  try {
    if (query) {
      const filteredData = bgAnimated.filter(item =>
        item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
      )
      const paginatedData = filteredData.slice((page - 1) * 10, page * 10)
      return paginatedData
    }

    const paginatedData = bgAnimated.slice((page - 1) * 10, page * 10)
    return paginatedData
  } catch (error) {
    console.warn(error)
    return []
  }
}

const AnimatedBackgroundItem = ({
  onDismiss,
  onSelected,
  cosmetic,
  ...item
}) => {
  return (
    <OptionItem
      onSelected={() => {
        onSelected(originType, item.name, item.name)
        onDismiss()
      }}
      cosmetic={cosmetic}
      id={item.name}
    >
      <DynamicBackgroundAnimated name={item.name} />
    </OptionItem>
  )
}

const ModalAnimatedBackground = ({ onSelected, ...props }) => {
  const { banner = [] } = useCosmetics()
  const cosmetic = banner.find(b => b.label === type)
  return (
    <ModalSearchList
      {...props}
      renderItem={params => (
        <AnimatedBackgroundItem
          {...params}
          onSelected={onSelected}
          cosmetic={cosmetic}
        />
      )}
      onFetch={onFetch}
    />
  )
}

export const animatedBackground = {
  type,
  originType,
  title,
  subtitle,
  icon,
  useGetOnClick,
  parent: ModalAnimatedBackground,
}
