import React from 'react'
import styled, { css } from 'styled-components'
import { useTheme } from '@/Hooks'
import DynamicBackgroundAnimated from '@/Utils/DynamicBackgroundAnimated'
import VideoBanner from './VideoBanner'
import { LineGallery } from '@/Assets/icons/components'
import ModalEditOrDelete from '../Modals/ModalEditOrDelete'
import ButtonEditable from '../Buttons/ButtonEditable'

const BannerWrapper = styled(ButtonEditable)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.l};
    width: ${theme.size.fill};
    aspect-ratio: 1.93;
    overflow: hidden;
    background-color: ${theme.colors.grey1};
  `}
`

const NoBanner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ noBannerStyle }) => noBannerStyle};
  height: 100%;
`

const Banner = ({ banner, className }) => {
  const { theme } = useTheme()
  return (
    <ModalEditOrDelete
      type="banner"
      additionalOptions={{
        unsplash: true,
        animatedBackground: true,
        coverr: true,
        canva: true,
        imageAI: true,
      }}
    >
      {props => (
        <BannerWrapper
          {...props}
          fieldName="banner"
          className={className}
          banner={banner}
        >
          {banner ? (
            <>
              {banner.type === 'animatedBackground' && (
                <DynamicBackgroundAnimated name={banner.value} />
              )}
              {banner.type === 'image' && (
                <img
                  className="w-full h-full object-cover"
                  src={banner.value}
                  alt="banner"
                />
              )}
              {banner.type === 'video' && (
                <VideoBanner
                  className="flex-1"
                  source={{ uri: banner.value }}
                />
              )}
            </>
          ) : (
            <NoBanner noBannerStyle={theme.colors.grey1}>
              <LineGallery color={theme.colors.grey2} />
            </NoBanner>
          )}
        </BannerWrapper>
      )}
    </ModalEditOrDelete>
  )
}

export default Banner
