import * as React from 'react'
const SvgVideo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 8.467 8.467"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path
        d="M4.23.53c-.2.005-.404.003-.6.046-.079.001-.152.034-.23.041-.132.033-.261.075-.39.112-.185.07-.369.15-.544.243-.08.047-.166.088-.241.144a3.7 3.7 0 0 0-1.19 1.242c-.106.186-.2.38-.274.581-.01.038-.035.072-.038.113a2 2 0 0 0-.09.318c-.026.066-.026.14-.049.208A3.9 3.9 0 0 0 .625 5.07c.02.067.03.136.056.202a.7.7 0 0 0 .049.152c.01.074.058.136.072.208.11.251.237.497.397.721.344.495.813.905 1.352 1.176q.216.11.444.192c.038.014.076.018.112.039.378.116.773.175 1.168.178.152-.007.301-.025.451-.035.137-.03.277-.041.41-.082.128-.03.255-.072.377-.116a3.703 3.703 0 0 0 1.798-1.41c.193-.295.354-.616.456-.953.027-.056.024-.12.053-.174.03-.135.066-.27.078-.408.038-.162.031-.333.039-.5a4 4 0 0 0-.064-.733c-.02-.072-.023-.15-.052-.22-.03-.118-.068-.233-.104-.347a3.7 3.7 0 0 0-1.33-1.746 3.8 3.8 0 0 0-.81-.442 5 5 0 0 0-.484-.149A3.3 3.3 0 0 0 4.266.53zm-.593 2.325c.334.004.67-.01 1.003.011a.79.79 0 0 1 .64.61c.032.187.013.379.02.567-.004.34.01.681-.013 1.018a.79.79 0 0 1-.656.65c-.494.017-.99.008-1.484.01-.226-.011-.463.028-.68-.058a.8.8 0 0 1-.48-.58c-.016-.426-.006-.853-.008-1.28.005-.174-.015-.356.079-.511a.78.78 0 0 1 .44-.387c.156-.07.329-.04.496-.049zm2.675.43c.14 0 .269.09.32.22.041.08.01.177.02.265-.003.406.013.815-.008 1.22a.315.315 0 0 1-.334.295c-.133.002-.241-.092-.355-.146-.12-.069-.244-.13-.363-.198-.037-.1-.016-.213-.023-.319.005-.308-.008-.617.007-.925-.003-.047.028-.082.073-.093.198-.099.383-.226.59-.306a.3.3 0 0 1 .073-.013z"
        style={{
          fill: '#2ca089',
          stroke: '#3b7844',
          strokeWidth: 0,
          strokeOpacity: 0.473684,
        }}
      />
    </g>
  </svg>
)
export default SvgVideo
