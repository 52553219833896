import * as React from 'react'
const SvgSocialTiktok = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 32})`}>
      <path
        fill="#EE1D52"
        d="M8.451 19.793c.156-1.294.687-2.019 1.687-2.761 1.43-1.006 3.218-.437 3.218-.437V13.22q.654-.017 1.3.077v4.343s-1.788-.569-3.219.438c-.999.742-1.53 1.467-1.686 2.76-.005.703.126 1.621.734 2.416a6 6 0 0 1-.46-.264c-1.337-.898-1.58-2.245-1.574-3.197M22.035 6.979c-.984-1.079-1.356-2.168-1.49-2.933h1.237s-.247 2.006 1.553 3.979l.025.026a7 7 0 0 1-1.325-1.072M28 10.037v4.256s-1.58-.062-2.75-.36c-1.632-.415-2.681-1.053-2.681-1.053s-.725-.456-.784-.487v8.789c0 .49-.134 1.711-.543 2.73-.533 1.334-1.356 2.21-1.508 2.388 0 0-1 1.183-2.767 1.98-1.592.719-2.99.7-3.407.719 0 0-2.417.095-4.59-1.318a9 9 0 0 1-1.312-1.053l.011.008c2.175 1.413 4.59 1.317 4.59 1.317.419-.018 1.817 0 3.408-.719 1.765-.797 2.767-1.98 2.767-1.98.15-.179.977-1.054 1.508-2.388.408-1.019.543-2.241.543-2.73v-8.788c.059.032.783.487.783.487s1.05.638 2.683 1.054c1.17.297 2.749.36 2.749.36V9.912c.54.121 1.001.154 1.3.124"
      />
      <path
        fill="#000"
        d="M26.7 9.913v3.334s-1.579-.062-2.748-.36c-1.633-.415-2.683-1.053-2.683-1.053s-.725-.455-.783-.488v8.79c0 .49-.134 1.712-.543 2.731-.533 1.334-1.356 2.21-1.508 2.388 0 0-1.001 1.183-2.767 1.98-1.591.719-2.99.7-3.408.719 0 0-2.415.096-4.59-1.317l-.01-.008a8 8 0 0 1-.648-.703c-.694-.846-1.12-1.847-1.227-2.133v-.003c-.172-.496-.533-1.688-.484-2.842.088-2.036.804-3.285.993-3.599a8.6 8.6 0 0 1 1.928-2.257 8.1 8.1 0 0 1 2.291-1.3 8 8 0 0 1 2.844-.572v3.375s-1.788-.567-3.218.437c-1 .742-1.53 1.467-1.687 2.76-.007.953.237 2.3 1.573 3.199q.235.148.46.263c.233.304.517.568.84.783 1.306.826 2.4.884 3.799.347.932-.359 1.635-1.167 1.96-2.063.205-.56.202-1.123.202-1.706V4.046h3.256c.134.765.506 1.854 1.49 2.933.396.407.84.767 1.325 1.072.144.149.876.881 1.816 1.331.487.233 1 .41 1.528.531"
      />
      <path
        fill="#69C9D0"
        d="M4.49 22.757v.002l.08.22c-.01-.026-.04-.104-.08-.222"
      />
      <path
        fill="#69C9D0"
        d="M10.513 13.792a8.1 8.1 0 0 0-2.291 1.3 8.6 8.6 0 0 0-1.927 2.262c-.19.312-.906 1.563-.993 3.599-.05 1.154.312 2.345.484 2.841v.004c.108.283.532 1.284 1.226 2.132q.303.37.647.703a8.9 8.9 0 0 1-1.947-1.75c-.688-.838-1.112-1.828-1.223-2.12v-.01c-.172-.496-.535-1.688-.484-2.843.087-2.036.803-3.286.993-3.6a8.5 8.5 0 0 1 1.927-2.261 8.1 8.1 0 0 1 2.291-1.3 8 8 0 0 1 1.616-.456 8.4 8.4 0 0 1 2.527-.035v.962a8 8 0 0 0-2.846.572"
      />
      <path
        fill="#69C9D0"
        d="M20.544 4.046h-3.256v16.57c0 .582 0 1.144-.202 1.705-.328.896-1.028 1.704-1.96 2.063-1.4.539-2.493.48-3.798-.347a3.5 3.5 0 0 1-.844-.78c1.112.568 2.107.558 3.34.084.932-.359 1.632-1.168 1.96-2.064.205-.56.202-1.122.202-1.704V3h4.496s-.05.412.062 1.046M26.7 8.991v.922a7.3 7.3 0 0 1-1.524-.53c-.94-.45-1.673-1.183-1.817-1.332q.251.157.517.285c1.143.547 2.27.71 2.824.655"
      />
    </g>
  </svg>
)
export default SvgSocialTiktok
