import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineMessage5 = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="m4.302 7.25 5.78 4.13c1.147.82 2.688.82 3.835 0l5.78-4.13M2.886 15.901a13.1 13.1 0 0 1 0-6.302 7.35 7.35 0 0 1 5.546-5.407l.453-.101a14.4 14.4 0 0 1 6.232 0l.453.1A7.35 7.35 0 0 1 21.115 9.6c.514 2.07.514 4.233 0 6.302a7.35 7.35 0 0 1-5.546 5.407l-.453.101a14.4 14.4 0 0 1-6.232 0l-.453-.1A7.35 7.35 0 0 1 2.885 15.9Z"
        />
      </g>
    </svg>
  )
}
export default SvgLineMessage5
