import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineWallet = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M8 9h4"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m2.885 15.151.728-.18zm0-6.302.728.18zm18.23 0 .728-.181zm0 6.302-.728-.18zm-6 5.508-.162-.732zm-6.23 0 .162-.732zm0-17.318.162.732zm6.23 0 .163-.732zM8.432 20.558l-.163.733zm7.138 0 .163.733zm0-17.116-.162.732zm-7.138 0-.163-.733zm10.262 11.062-.134.738zm-.058-.011.134-.738zm0-4.986.134.738zm.058-.01-.134-.738zm2.307.99a.75.75 0 0 0 .601-1.374zm.601 4.4A.75.75 0 1 0 21 13.513zm-5.023-2.259-.721.206zm0-1.256.721.206zM8.593 4.174l.454-.1-.325-1.465-.454.1zm6.36-.1.454.1.325-1.465-.454-.1zm.454 15.752-.454.1.325 1.465.454-.1zm-6.36.1-.454-.1-.325 1.465.454.1zm-5.434-4.955a12.3 12.3 0 0 1 0-5.942l-1.455-.361a13.8 13.8 0 0 0 0 6.664zM20.387 9.03c.484 1.95.484 3.99 0 5.94l1.456.362a13.8 13.8 0 0 0 0-6.664zm-5.434 10.897a13.65 13.65 0 0 1-5.906 0l-.325 1.464c2.16.479 4.397.479 6.556 0zM9.047 4.073a13.65 13.65 0 0 1 5.906 0l.325-1.464a15.15 15.15 0 0 0-6.556 0zm-.454 15.753a6.6 6.6 0 0 1-4.98-4.856l-1.455.362a8.1 8.1 0 0 0 6.11 5.959zm7.139 1.465a8.1 8.1 0 0 0 6.11-5.959l-1.455-.362a6.6 6.6 0 0 1-4.98 4.856zm-.325-17.117a6.6 6.6 0 0 1 4.98 4.856l1.456-.362a8.1 8.1 0 0 0-6.111-5.959zM8.268 2.709a8.1 8.1 0 0 0-6.11 5.959l1.455.361a6.6 6.6 0 0 1 4.98-4.855zm10.56 11.057-.059-.01-.269 1.475.059.01zm-.059-3.521.059-.01-.27-1.476-.058.01zm.059-.01a3.74 3.74 0 0 1 2.172.252l.601-1.374a5.24 5.24 0 0 0-3.042-.354zm-.27 5.007a5.24 5.24 0 0 0 3.043-.355L21 13.513a3.74 3.74 0 0 1-2.172.253zm-1.259-2.82a1.54 1.54 0 0 1 0-.844l-1.442-.412a3.04 3.04 0 0 0 0 1.668zM18.5 8.77a3.38 3.38 0 0 0-2.643 2.397l1.442.412a1.88 1.88 0 0 1 1.47-1.333zm.27 4.986a1.88 1.88 0 0 1-1.47-1.333l-1.443.412a3.38 3.38 0 0 0 2.643 2.397z"
        />
      </g>
    </svg>
  )
}
export default SvgLineWallet
