import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from './ModalWrapper/Modal'
import { Button } from '../Buttons/Button'
import { useTheme } from '@/Hooks'
import { LineExport } from '@/Assets/icons/components'
import ModalSocialMedia from './ModalSocialMedia/ModalSocialMedia'
import ContentCopy from '../ContentCopy'
import useSocialMediaOptions from './ModalSocialMedia/useSocialMediaOptions'

const ModalAddToContact = ({ children: button }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { theme } = useTheme()
  const { profileUrl } = useSocialMediaOptions()

  const handleOpenModalSocialMedia = openModal => {
    openModal(true)
    setIsOpen(false)
  }
  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.addToContact.share_this_profile')}
        renderCustomFooter={() => (
          <ContentCopy text={profileUrl} className="w-full" />
        )}
      >
        <ModalSocialMedia>
          {modalSocialSetIsOpen => (
            <Button
              label={t('modal.addToContact.share_this_profile')}
              color={theme.colors.grey1}
              className="w-full justify-start"
              icon={() => <LineExport size={18} className="mr-2" />}
              onClick={() => handleOpenModalSocialMedia(modalSocialSetIsOpen)}
            />
          )}
        </ModalSocialMedia>
      </Modal>
    </>
  )
}

export default ModalAddToContact
