import * as React from 'react'
const SvgSocialSoundcloud = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 32 31.993"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <linearGradient
        id="socialSoundcloud_svg__a"
        x1={932.951}
        x2={932.951}
        y1={50.663}
        y2={1824.753}
        gradientTransform="matrix(.01504 0 0 .00834 2 8.231)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f80" />
        <stop offset={1} stopColor="#f30" />
      </linearGradient>
      <path
        fill="url(#socialSoundcloud_svg__a)"
        d="M2 20.974q0 .52.378.788.378.266.808.189.404-.078.566-.287.163-.209.163-.69v-3.779a.93.93 0 0 0-.28-.684.93.93 0 0 0-.684-.28.92.92 0 0 0-.67.28.93.93 0 0 0-.281.684zm2.997 1.615q0 .378.267.567t.684.189q.43 0 .697-.189t.267-.567v-8.808a.92.92 0 0 0-.28-.67.93.93 0 0 0-.684-.28.92.92 0 0 0-.671.28.92.92 0 0 0-.28.67zm2.983.417q0 .378.274.567.273.189.704.189.417 0 .684-.189t.267-.567v-8.039q0-.405-.28-.69a.9.9 0 0 0-.671-.287q-.405 0-.69.287a.94.94 0 0 0-.288.69zm2.997.04q0 .716.964.716t.964-.717V10.016q0-1.094-.664-1.238-.43-.105-.847.248-.417.351-.417.99zm3.05.377V9.247q0-.676.403-.808a7.632 7.632 0 0 1 5.446.73 7.65 7.65 0 0 1 2.782 2.56 7.63 7.63 0 0 1 1.231 3.577 4.3 4.3 0 0 1 1.694-.339q1.824 0 3.12 1.29T30 19.358q0 1.824-1.296 3.114t-3.108 1.29l-11.335-.013a.3.3 0 0 1-.176-.143.4.4 0 0 1-.059-.183"
        style={{
          fill: 'url(#socialSoundcloud_svg__a)',
          strokeWidth: 0.0112,
        }}
      />
    </g>
  </svg>
)
export default SvgSocialSoundcloud
