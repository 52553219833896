const updateQrCodeById = build =>
  build.mutation({
    query: ({ id, qrcodeData }) => {
      return {
        url: `/qrcode/${id}`,
        method: 'PUT',
        body: qrcodeData,
      }
    },
  })

export default updateQrCodeById
