import { useTheme } from '@/Hooks'
import clsx from 'clsx'
import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

export const Spinner = ({ color, size = 40, loading, className, ...props }) => {
  const { theme } = useTheme()
  return (
    <div
      className={clsx(className, 'flex justify-center items-center')}
      {...props}
    >
      <ClipLoader
        color={color || theme.colors.primary}
        loading={loading}
        size={size}
      />
    </div>
  )
}
