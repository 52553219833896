const referral = {
  title: 'Affiliation',
  overview: "Vue d'ensemble",
  stats: 'Statistiques',
  bonus: 'Bonus',
  portfolio: 'Portefeuille',
  copy: 'Copier',
  overviewData: {
    bonusPool: 'Bonus Pool',
    personnalCustomers: 'Ventes actives personnelles',
    lastMonthRevenue: 'Revenus du mois en cours',
    totalRevenue: 'Revenus totaux',
    salesThisMonth: 'Ventes ce mois',
    levelOne: 'Niveau 1',
    levelTwo: 'Niveau 2',
  },
  statsData: {
    newMonthlySell: 'Nouvelles ventes mensuelles',
    monthlyActiveCustomer: 'Clients actifs mensuels',
    monthlyRevenue: 'Revenus mensuels',
  },
  myTransactions: 'Mes transactions',
  noTransactions: 'Aucune transaction à afficher',
  mySales: 'Mes ventes',
  date: 'Date',
  user: 'Utilisateur',
  sellBonus: 'Bonus de vente',
  profit: 'Profit',
  myPortfolio: 'Mon portefeuille',
  connectMetamask: 'Connectez vous avec Metamask',
  receivedAt: 'Recu le',
  collected: 'Récolté',
  fromYourSales: 'Venant des ventes réalisées',
  fromPoolBonusSilver: 'Venant du bonus pool argent',
  fromPoolBonusGold: 'Venant du bonus pool or',
  fromPoolBonusPlatinum: 'Venant du bonus pool platine',
  connectSequence: 'Connectez vous avec Sequence',
  connectWalletConnect: 'Connectez vous avec WalletConnect',
  connected: 'Connecté',
  actionsBalance: 'Solde actions Budly',
  salesBalance: 'Solde des ventes',
  disconnect: 'Déconnecter',
  myClients: 'Mes clients',
  bonusData: {
    qualificationRequirements: 'Critères de qualification',
    qualificationRequirementsDescription:
      "Chaque mois, vous pouvez vous qualifier pour les 3 pools en réalisant des nouvelles ventes. Lorsque le bonus pool est envoyé, le montant sera divisé par le nombre d'utilisateurs qualifiés.",
    make: 'Réaliser',
    newSalesCurrentMonth: 'nouvelles ventes ce mois',
    missingSales: 'ventes restantes',
    validated: 'validé',
    bronze: 'Bronze',
    silver: 'Argent',
    gold: 'Or',
    platinum: 'Platine',
    unqualified: 'Non qualifié',
  },
  sellBonusText1:
    'Votre pourcentage de commission augmente avec le nombre de vos ventes mensuelles actives. Une vente est active si elle est payée dans le mois en cours.',
  sellBonusText2:
    'Les abonnements annuels sont considérés comme actifs pendant 12 mois, tandis que les abonnements mensuels prolongent leur activité à chaque renouvellement.',
  sellBonusText3: 'Maximisez vos revenus en fidélisant vos clients.',
  paymentPercentage: 'Pourcentage de paiement réel',
  directActive: 'Actifs directs',
  bud_monney: "Vos BUDs vous rapporte de l'argent !",
  affiliate_dashboard: 'Dashboard affilié',
  become_ambassador: 'Devenir ambassadeur pour 99€/an',
  nextPaymentIn: "Prochain paiement dans",
  days: "jours",
  hours: "heures",
  minutes: "minutes",
}

export default referral
