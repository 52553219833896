import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'

const Mention = ({ children = '' }) => {
  const { theme } = useTheme()

  return (
    <Text
      color={theme.colors.primary}
      onClick={() =>
        window.open(window.origin + `/${children.replace('@', '')}`)
      }
      className="cursor-pointer"
    >
      {children}
    </Text>
  )
}

const interaction = {
  check: string => string.startsWith('@'),
  component: Mention,
}

export default interaction
