import useEditContent from '@/Hooks/useEditContent'
import formatContentUrl from '@/Utils/formatContentUrl'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '../PanelWrapper/Panel'
import Content from './components/Content'
import formatContentValue from '@/Utils/formatContentValue'
//import IconSwitch from '@/Components/IconSwitch'
import { isValidUrl } from '@/Utils/validators'

const PanelAddContent = ({ item, type, onDismiss, children: button }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [contentLabel, setContentLabel] = useState('')
  const [contentURL, setContentURL] = useState('')
  const [value, setValue] = useState(
    item.type === 'phone' ? { number: '', code: ['FR', '+33'] } : '',
  )
  const [platform, setPlatform] = useState('')
  const [isIntegrated, setIsIntegrated] = useState(false)
  const { t } = useTranslation()
  const {
    addContact,
    addLink,
    setIsFastLink,
    isFastLink,
    setThumbnail,
    thumbnail,
  } = useEditContent()

  const newContact = useMemo(() => {
    return {
      type: item.type || item.label,
      rank: null,
      id: Date.now(),
      label: contentLabel,
      private: false,
      value,
      enabled: true,
    }
  }, [contentLabel, item.label, item.type, value])
  const newLink = useMemo(() => {
    if (typeof value === 'string') {
      return {
        type,
        icon: item.label,
        rank: null,
        id: Date.now(),
        label: contentLabel,
        value: value.trim(),
        url: formatContentUrl(contentURL, value, platform, item),
        enabled: true,
        ...(item.label === 'Music' || item.label === 'Video'
          ? { platform, isIntegrated }
          : {}),
        ...((type === 'social' || type === 'link') && { isFastLink }),
      }
    }
  }, [
    contentLabel,
    contentURL,
    isFastLink,
    isIntegrated,
    item,
    platform,
    type,
    value,
  ])

  const handleDismiss = () => {
    setContentLabel('')
    setContentURL('')
    setThumbnail(null)
    setValue(item.type === 'phone' ? { number: '', code: ['FR', '+33'] } : '')
    setIsOpen(false)
    onDismiss()
  }

  const onSubmit = async () => {
    const isValid = item.validate ? item.validate(value) : true

    if ((!isValid && !contentURL) || (contentURL && !isValidUrl(contentURL))) {
      // dispatch(setLoading(false))
      // TODO TOAST
      // return Toast.show({
      //   type: 'error',
      //   text1: t('toastAddContent.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
    if (type === 'contact') {
      await addContact(newContact)
    }
    if (type === 'social' || type === 'link') {
      await addLink(newLink)
    }
    handleDismiss()
  }

  return (
    <>
      {button(setIsOpen)}
      <Panel
        title={t(`edit_profil.contents.add_content_panel_titles.${type}`)}
        isOpen={isOpen}
        onDismiss={handleDismiss}
        fullscreen
        childrenClassName="flex-1"
        actions={[
          {
            label: t('profilepage.galerie.edit_photo.save'),
            onClick: onSubmit,
            disabled: !contentLabel || (!value && !contentURL),
          },
        ]}
      >
        <Content
          valueLabel={item.valueLabel}
          type={item.type}
          icon={item.icon}
          label={item.label}
          placeholderTitle={item.placeholderTitle}
          placeholder={item.placeholder}
          thumbnail={thumbnail}
          setThumbnail={setThumbnail}
          contentLabel={contentLabel}
          setContentLabel={setContentLabel}
          contentURL={contentURL}
          setContentURL={setContentURL}
          value={value}
          setValue={v => setValue(formatContentValue(v, type))}
          platform={platform}
          setPlatform={setPlatform}
          isIntegrated={isIntegrated}
          setIsIntegrated={setIsIntegrated}
          isFastLink={isFastLink}
          setIsFastLink={setIsFastLink}
        />
      </Panel>
    </>
  )
}

export default PanelAddContent
