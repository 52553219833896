import { useGetSubscriptionsByUidQuery } from '@/Services/modules/subscriptions'

const COMPANION_SUBSCRIPTIONS = [
  'ABO-COMPAGNON-EUR-Monthly',
  'ABO-COMPAGNON-EUR-Yearly',
]

export const useGetSubscriptions = (uid, config = {}) => {
  const { data = [] } = useGetSubscriptionsByUidQuery(uid, {
    ...config,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    skip: !uid,
  })

  return {
    hasCompanionSubscription: data.some(subscriptionID =>
      COMPANION_SUBSCRIPTIONS.includes(subscriptionID),
    ),
    hasAmbassadorSubscription: data.includes('AMBASSADOR-LICENCE-EUR-Yearly'),
  }
}
