import React, { memo, useCallback, useEffect, useState } from 'react'
import { useOnboardingContext } from '../OnBoardingContext'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import Text from '@/Components/Texts/Text'
import CodeInput from '@/Components/Controls/CodeInput'
import useCurrentUser from '@/Hooks/useCurrentUser'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import { useSendWelcomeEmailMutation } from '@/Services/modules/users'
import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const title = 'onboarding.verification_code.verification'
const subtitle = 'onboarding.verification_code.subtitle'
const skip = 'skip'

const Resend = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`
export const EmailVerification = memo(() => {
  const { theme } = useTheme()
  const { values, setValues, isVerificationEmailByUser } =
    useOnboardingContext()
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const [initialMailSent, setInitialMailSent] = useState(false)
  const [sendWelcomeEmail] = useSendWelcomeEmailMutation({})
  const [resendLoading, setResendLoading] = useState(false)
  const navigate = useNavigate()
  const { lang } = useSelector(state => state.language)

  const handleSendEmail = useCallback(async () => {
    setResendLoading(true)
    if (!initialMailSent) {
      setInitialMailSent(true)
    }
    try {
      await sendWelcomeEmail({ userId: currentUser?.uid, lang }).unwrap()
      toast.success(t('toastSendCodeVerification.success'))
    } catch (error) {
      console.log(error)
      toast.error(t('toastErrorDefault.error'))
    } finally {
      setResendLoading(false)
    }
  }, [initialMailSent, sendWelcomeEmail, currentUser?.uid, lang, t])

  useEffect(() => {
    if (!currentUser?.needToActiveAccount) {
      navigate('/dashboard/profile/informations')
      return
    }
    if (!isVerificationEmailByUser) {
      toast.success(t('toastSendCodeVerification.success'))
    }
  }, [
    currentUser?.needToActiveAccount,
    navigate,
    handleSendEmail,
    resendLoading,
    initialMailSent,
    isVerificationEmailByUser,
    t,
  ])

  if (!currentUser?.needToActiveAccount) {
    return null
  }

  return (
    <>
      <div className="mb-6 ">
        <div className="mb-2">
          <Text
            color={theme.colors.grey3}
            regular
            className="font-light text-sm"
          >
            {t('onboarding.verification_code.enter_your_verification')}
          </Text>
        </div>
        <CodeInput
          code={values.code}
          onChange={value =>
            setValues(prev => {
              return { ...prev, code: value }
            })
          }
        />
        <div className="flex flex-row items-center">
          <Text color={theme.colors.grey2} regular className="text-xs mr-1">
            {t('onboarding.verification_code.info')}
          </Text>
          <Text className="text-xs">{currentUser?.email}.</Text>
        </div>
        <TouchableOpacity onClick={handleSendEmail} disabled={resendLoading}>
          <Resend className="text-xs">
            {t('onboarding.verification_code.resend')}
          </Resend>
        </TouchableOpacity>
      </div>
    </>
  )
})

const EmailVerificationSection = {
  title,
  subtitle,
  skip,
  component: EmailVerification,
  step: 5,
  section: 2,
  getDisabled: values => values?.code?.length < 6,
  onSubmit: async ({
    verifyEmailCode,
    currentUser,
    values,
    handleNavigate,
    setLoading,
    t,
  }) => {
    setLoading(true)
    try {
      await verifyEmailCode({
        userId: currentUser.uid,
        code: values.code,
      }).unwrap()

      toast.success(t('verification.success'))
      handleNavigate()
    } catch (error) {
      toast.error(t('toastErrorDefault.error'))
      return { error: 'The code is not correct' }
    } finally {
      setLoading(false)
    }
  },
}

export default EmailVerificationSection
