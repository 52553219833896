const security = {
  skip: 'Skip',
  title: 'Security',
  pin_code: 'PIN code',
  use: 'Use',
  or: 'or',
  to_disable: 'to disable',
  to_unlock: 'to unlock',
  wrong_code: 'Wrong code',
  set_pin_code: 'Set PIN code',
  pin_code_not_match: 'PIN codes do not match',
  confirm_pin_code: 'Confirm PIN code',
  sentence: 'Happy to see you again',
  try_later: 'Too many attempts. Please try again later.',
  codepin_modal: {
    forgot: 'Forgotten',
    delete: 'Delete',
    reset: {
      title: 'Reset your pin code',
      subtitle:
        'By confirming, you will receive an email that will allow you to reset your PIN code.',
      success_message: 'An email has been sent to you',
    },
  },
  touchIDModal: {
    title: 'Sign in',
    title2: 'Activate Fingerprint identification ',
  },
  faceIDModal: {
    title: 'Sign in',
    title2: 'Activate identification by facial recognition',
  },
}


export default security
