const deleteBusinessCardById = build =>
  build.mutation({
    query: ({ cardId }) => {
      return {
        url: `/users/business-card/${cardId}`,
        method: 'DELETE',
      }
    },
    invalidatesTags: ['BusinessCards'],
  })

export default deleteBusinessCardById
