import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Ellipsis = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: ${props => 100 - props.index * 15 + '%'};
    aspect-ratio: 1;
    border-radius: 999999px;
    bottom: ${props => -75 + props.index * 8};
    right: 0;
    background-color: ${theme.colors.primary};
    opacity: 0.1;
  `}
`

const EllipsisBackGround = () => {
  return (
    <Wrapper>
      {[1, 2, 3, 4, 5].map((item, index) => {
        return <Ellipsis key={index} index={index} />
      })}
    </Wrapper>
  )
}

export default EllipsisBackGround
