const sendWelcomeEmail = build =>
  build.mutation({
    query: ({ userId, lang }) => {
      return {
        url: `/users/verify/${userId}/send-welcome-email?lang=${lang}`,
        method: 'POST',
      }
    },
  })

export default sendWelcomeEmail
