import { formatPhoneNumber } from '@/Utils/formaters'
import { contactItems } from '@/contents'
import styled, { css } from 'styled-components'
import { EditProfileItem } from './EditProfileItem'
import compareRank from '@/Utils/compareRank'
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/Components/Buttons/Button'
import { LineLock, LinePlus, LineUnlock } from '@/Assets/icons/components'
import { DraggableBloc } from './DraggableBloc'
import Text from '@/Components/Texts/Text'
import PanelAddContact from '@/Components/Panels/ContentPanels/PanelAddContact'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.l} 0;
  `}
`
const Contact = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
  `}
`
const formatContactValue = contact => (
  <Contact className="text-xs">
    {contact.type === 'phone'
      ? formatPhoneNumber(contact.value)
      : contact.value}
  </Contact>
)

const ContactItem = ({ item, ...props }) => {
  const content = contactItems.find(
    contactItem => contactItem.type === item.type,
  )
  if (!content) {
    return null
  }
  return (
    <EditProfileItem
      icon={content.icon}
      content={formatContactValue(item)}
      label={item.label}
      type="contact"
      item={item}
      {...props}
    />
  )
}

const getPublicContacts = (contacts = []) =>
  contacts?.filter(contact => !contact.private).sort(compareRank)

const getPrivateContacts = (contacts = []) =>
  contacts?.filter(contact => contact.private).sort(compareRank)

const Contacts = memo(() => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { t } = useTranslation()
  const publicContacts = useMemo(() => {
    return getPublicContacts(editCurrentUser.contacts)
  }, [editCurrentUser.contacts])
  const privateContacts = useMemo(() => {
    return getPrivateContacts(editCurrentUser.contacts)
  }, [editCurrentUser.contacts])

  const onDragAndDrop = useCallback(
    async data => {
      const updatedContacts = data.map((item, index) => ({
        ...item,
        rank: index,
      }))
      setEditCurrentUser({
        ...editCurrentUser,
        contacts: [
          ...editCurrentUser.contacts.filter(
            contact => contact.private !== updatedContacts[0].private,
          ),
          ...updatedContacts,
        ],
      })
    },
    [editCurrentUser, setEditCurrentUser],
  )

  return (
    <Wrapper>
      <PanelAddContact>
        {setIsOpen => (
          <Button
            icon={LinePlus}
            label={t('profilepage.contact.top.addcontact')}
            onClick={() => {
              setIsOpen(true)
            }}
            className="w-full"
          />
        )}
      </PanelAddContact>
      <div className="w-full flex-1">
        <DraggableBloc
          title={t('profilepage.contact.bottom.visible_all')}
          icon={LineUnlock}
          list={publicContacts}
          item={ContactItem}
          onDragEnd={onDragAndDrop}
        />
        <DraggableBloc
          title={t('profilepage.contact.bottom.visible_contact_only')}
          icon={LineLock}
          list={privateContacts}
          item={ContactItem}
          onDragEnd={onDragAndDrop}
        />
      </div>
    </Wrapper>
  )
})

export default Contacts
