const getUserProgressDetails = build =>
  build.query({
    query: uid => {
      return {
        url: `/progress/${uid}/details`,
        method: 'GET',
      }
    },
    providesTags: ['Progress'],
  })

export default getUserProgressDetails
