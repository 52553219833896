import { infiniteScrollOptions } from '../infiniteScrollOptions'

const getPostsByUserId = build =>
  build.query({
    query: ({ lastDate = Date.now(), userId = '' }) => {
      return {
        url: `/posts/${lastDate}?userId=${userId}`,
        method: 'GET',
      }
    },
    ...infiniteScrollOptions,
  })

export default getPostsByUserId
