import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineStatus = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m3.353 15.05-.73.17zm0-6.1-.73-.17zm17.294 0-.73.172zm0 6.1-.73-.172zm-5.597 5.597-.172-.73zm-6.1 0 .172-.73zm0-17.294.172.73zm6.1 0-.172.73zm-7.193 8.87a.75.75 0 0 0 1.004 1.115zm2.209-.979.501.558zm1.561.197.624-.417zm.746 1.118-.624.417zm1.561.197-.501-.558zm2.209-.979a.75.75 0 0 0-1.004-1.115zm-3.068 1.51-.094.745zm-2.15-2.575-.093.745zm-6.842 4.166a12.6 12.6 0 0 1 0-5.756l-1.46-.343a14.1 14.1 0 0 0 0 6.442zm15.834-5.756a12.6 12.6 0 0 1 0 5.756l1.46.343a14.1 14.1 0 0 0 0-6.442zm-5.039 10.795a12.6 12.6 0 0 1-5.756 0l-.343 1.46c2.119.497 4.323.497 6.442 0zM9.122 4.083a12.6 12.6 0 0 1 5.756 0l.343-1.46a14.1 14.1 0 0 0-6.442 0zm0 15.834a6.76 6.76 0 0 1-5.039-5.039l-1.46.343a8.26 8.26 0 0 0 6.156 6.156zm6.099 1.46a8.26 8.26 0 0 0 6.156-6.156l-1.46-.343a6.76 6.76 0 0 1-5.039 5.039zm-.343-17.294a6.76 6.76 0 0 1 5.039 5.039l1.46-.343a8.26 8.26 0 0 0-6.156-6.156zM8.78 2.623a8.26 8.26 0 0 0-6.156 6.156l1.46.343a6.76 6.76 0 0 1 5.039-5.039zm.082 10.715 1.706-1.536-1.003-1.115-1.707 1.536zm2.142-1.481.746 1.119 1.248-.833-.746-1.119zm3.433 1.456 1.707-1.536-1.004-1.115-1.706 1.536zm-2.687-.338c.143.214.292.442.443.611.165.185.415.399.79.446l.186-1.489a.4.4 0 0 1 .142.05c.026.016.027.024.001-.005a2 2 0 0 1-.118-.155 14 14 0 0 1-.196-.29zm1.684-.777c-.106.096-.19.17-.262.232-.073.063-.12.1-.153.121-.032.021-.029.015 0 .005a.4.4 0 0 1 .15-.013l-.187 1.489c.374.047.67-.099.875-.237.188-.126.39-.31.58-.482zm-2.866-.396c.106-.096.19-.17.262-.232.073-.063.12-.1.153-.121.032-.021.029-.015 0-.005a.4.4 0 0 1-.15.013l.187-1.489c-.374-.047-.67.099-.875.237-.188.126-.39.31-.58.482zm1.684-.777c-.143-.214-.292-.442-.443-.611-.165-.185-.415-.399-.79-.446l-.186 1.489a.4.4 0 0 1-.142-.05c-.026-.016-.027-.024-.001.005s.062.076.118.155c.055.078.117.17.196.29z"
        />
      </g>
    </svg>
  )
}
export default SvgLineStatus
