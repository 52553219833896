const follow = {
  fllw: 'Seguir',
  un_flw: 'Dejar de seguir',
  // followers: 'Followers',
  // followed: 'Followed',
  followers: 'Seguidores',
  followed: 'Seguidos',
  contacts: 'Contactos',
}


export default follow
