import { api } from '@/Services/api'

const addTreeByBuid = build =>
  build.mutation({
    query: uid => {
      return {
        url: `/trees/${uid}`,
        method: 'POST',
      }
    },
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      const res = await queryFulfilled
      const newTree = res.data.tree
      dispatch(
        api.util.updateQueryData(
          'getTreesByUid',
          newTree.id,
          state => (state = [newTree, ...state]),
        ),
      )
    },
    invalidatesTags: ['Trees', 'DetailsTrees', 'CurrentTreeSeeds'],
  })

export default addTreeByBuid
