import styled, { css } from 'styled-components'

export const Pill = styled.div`
  ${({ theme, onClick }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.s} ${theme.space.std};
  `}
`
