import { generateId } from '@/Utils/generateId'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth'
import app from './Config'
import { createdataUser } from './Functions'

export const auth = getAuth(app)
export const googleAuthProvider = new GoogleAuthProvider()
export const appleAuthProvider = new OAuthProvider('apple.com')

export const signIn = credentials => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, credentials.email, credentials.psw)
      .then(user => {
        resolve(user)
      })
      .catch(err => {
        console.log('errr', err)
        reject(err)
      })
  })
}

export const signUp = identifiants => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, identifiants.email, identifiants.psw)
      .then(user => {
        resolve(user)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const signInWithToken = token => {
  return new Promise((resolve, reject) => {
    signInWithCustomToken(auth, token)
      .then(user => {
        resolve(user)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const signOutUser = async () => {
  try {
    await signOut(auth)
    return true
  } catch (err) {
    throw err
  }
}

export const resetPassword = email => {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        resolve(true)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const signUpCurrentUser = async (
  credentials,
  dataLink,
  createUserByUid,
  linkId = generateId(),
  slug,
) => {
  try {
    const { user } = await signUp(credentials)

    await createUserByUid({
      userUid: user.uid,
      linkId,
      dataUser: createdataUser(credentials, linkId, slug),
      dataLink: dataLink,
    })
    await signIn(credentials)
    return Promise.resolve(user.uid)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const userBrowserPersistence = async isPersist => {
  if (isPersist) {
    await setPersistence(auth, indexedDBLocalPersistence)
  } else {
    await setPersistence(auth, browserSessionPersistence)
  }
}
