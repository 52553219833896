import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgPlantedLocation = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 14})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M11.667 6.519c0 2.634-2.09 5.731-4.667 5.731S2.333 9.153 2.333 6.519 4.423 1.75 7 1.75s4.667 2.135 4.667 4.769Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M5.542 6.125a1.458 1.458 0 1 1 2.916 0 1.458 1.458 0 0 1-2.916 0Z"
        />
      </g>
    </svg>
  )
}
export default SvgPlantedLocation
