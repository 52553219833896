export const formatColors = ({ colors, defaultColor }) => {
  return [...colors]
    .sort((a, b) => {
      if (a.label === 'default') {
        return -1
      }
      if (b.label === 'default') {
        return 1
      }
      return 0
    })
    .map(item =>
      item.label === 'default' ? { ...item, value: defaultColor } : item,
    )
}
