const tooltip = {
  lock_editing: "Bloquer l'édition",
  lock_editing_tip:
    'En bloquant ce champ, votre membre ne pourra plus éditer cette information de lui-même',
  unlock_editing: "Débloquer l'édition",
  unlock_editing_tip:
    'En débloquant ce champ, votre membre pourra éditer cette information de lui-même',
}

export default tooltip
