import { useRemoveImageByUidMutation } from '@/Services/modules/users'
import useCurrentUser from './useCurrentUser'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateCurrentUser } from '@/Store/User'
import useCurrentMember from './useCurrentMember'

const useEditContent = () => {
  const { currentUser, setCurrentUser } = useCurrentUser()
  const { currentMember, setCurrentMember } = useCurrentMember()
  const [user, setUser] = [
    currentMember || currentUser,
    setCurrentMember || setCurrentUser,
  ]
  const [removeImageByUid] = useRemoveImageByUidMutation()
  const [isFastLink, setIsFastLink] = useState(false)
  const [thumbnail, setThumbnail] = useState(null)
  const dispatch = useDispatch()

  const addThumbnailIfExist = async dataToUpdate => {
    if (!thumbnail && dataToUpdate.thumbnail) {
      return (dataToUpdate.thumbnail = null)
    }
    if (thumbnail) {
      return (dataToUpdate.thumbnail = thumbnail)
    }
    return dataToUpdate
  }

  const addContact = async newContact => {
    await addThumbnailIfExist(newContact)
    await setUser({
      contacts: user.contacts ? [newContact, ...user.contacts] : [newContact],
    })
  }

  const updateContact = async updatedContact => {
    await addThumbnailIfExist(updatedContact)
    await setUser({
      contacts: user.contacts
        ? user.contacts.map(contact => {
            return contact.id === updatedContact.id ? updatedContact : contact
          })
        : [updatedContact],
    })
  }

  const addLink = async newLink => {
    try {
      await addThumbnailIfExist(newLink)
      const fastLinkExist =
        isFastLink && user.links.some(link => link.isFastLink)
      const actualLinks = user.links || []
      const links = actualLinks.map(link => {
        if (fastLinkExist && link.isFastLink) {
          return { ...link, isFastLink: false }
        }

        return link
      })

      await setUser({
        links: user.links ? [newLink, ...links] : [newLink],
      })
    } catch (err) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }
  const updateLink = async newlink => {
    const updatedLink = {
      ...newlink,
      thumbnail,
    }
    await addThumbnailIfExist(updatedLink)
    const fastLinkExist = isFastLink && user.links.some(link => link.isFastLink)
    const actualLinks = user.links || []
    const links = actualLinks.map(link => {
      if (fastLinkExist && link.isFastLink) {
        return { ...link, isFastLink: false }
      }

      return link.id === updatedLink.id ? updatedLink : link
    })

    await setUser({
      links,
    })
  }

  const deleteContent = async ({ type, contentId }) => {
    if (type === 'social' || type === 'link') {
      const links = user.links.filter(link => link.id !== contentId)
      try {
        await setUser({ links })
      } catch (error) {
        console.warn(error)
      }
    }
    if (type === 'contact') {
      const contacts = user.contacts.filter(contact => contact.id !== contentId)
      try {
        await setUser({ contacts })
      } catch (error) {
        console.warn(error)
      }
    }
  }

  const deleteImage = async selectedImage => {
    try {
      await removeImageByUid({
        uid: user.uid,
        imageId: selectedImage.id,
      })
      const gallery = [
        ...user.gallery.filter(img => img.src !== selectedImage.src),
      ]
      dispatch(updateCurrentUser({ gallery }))
    } catch (error) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: error.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }

  return {
    deleteContent,
    deleteImage,
    updateContact,
    updateLink,
    addContact,
    addLink,
    isFastLink,
    setIsFastLink,
    thumbnail,
    setThumbnail,
  }
}
export default useEditContent
