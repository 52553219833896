const fields = [
  {
    name: 'businessName',
    placeholder: 'Business Name',
  },
  {
    name: 'fullName',
    placeholder: 'Full Name',
  },
  {
    name: 'job',
    placeholder: 'Your job',
  },
  {
    name: 'phone',
    placeholder: '+1234567890',
  },
  {
    name: 'email',
    placeholder: 'your@mail.com',
  },
  {
    name: 'website',
    placeholder: 'yourdomain.com',
  },
  {
    name: 'address',
    placeholder: 'Address',
  },
  {
    name: 'addressComplement',
    placeholder: 'Address complement',
  },
  {
    name: 'zipCode',
    placeholder: 'ZIP / Postal code',
  },
  {
    name: 'city',
    placeholder: 'City',
  },
  {
    name: 'state',
    placeholder: 'State',
  },
]

export const getFieldValues = ({ businessCard, lang } = {}) => {
  if (!businessCard) {
    return fields.reduce((values, field) => {
      return {
        ...values,
        [field.name]: field.placeholder,
      }
    }, {})
  }

  const getInformationValues = () => {
    return fields.reduce((values, field) => {
      if (lang === 'en') {
        return {
          ...values,
          [field.name]: businessCard[field.name] || '',
        }
      }

      return {
        ...values,
        [field.name]: businessCard[lang]?.[field.name] || '',
      }
    }, {})
  }

  return {
    ...getInformationValues(),
    color: businessCard.color,
    logo: businessCard.logo,
  }
}

export const getFontsizeByLength = (length = 24) => {
  if (length <= 24) {
    return '9px'
  }
  const baseFontSize = 9 // 9px as a base
  const divider = 14 // experimentally determined
  const fontSize = Math.max(baseFontSize - Math.floor(length / divider), 1)

  return `${fontSize}px`
}
