import React from 'react'
import ProfileContentIcon from '../ProfileContentIcon'
import { CustomThumbnail } from '@/Components/Profile/CustomThumbnail'
import {
  ContentWrapper,
  ItemInfos,
  ItemLabel,
  ItemValue,
  PaddingStd,
  Wrapper,
} from './styles'

export const NotIntegratedPreviewItem = ({
  profileDisplay,
  type,
  styles,
  shape,
  icon,
  image,
  label,
  hideContentsSubtitle,
  formattedValue,
  URL,
  onAddClick,
  ...props
}) => {
  return (
    <Wrapper
      className={profileDisplay === 'icon' && 'aspect-square'}
      profileDisplay={profileDisplay}
    >
      <ContentWrapper
        rel="noopener noreferrer"
        target="_blank"
        href={URL || ''}
        profileDisplay={profileDisplay}
        type={type}
        styles={styles}
        className={shape.className}
        onClick={onAddClick}
      >
        <>
          {icon && <PaddingStd>{icon}</PaddingStd>}
          {image && (
            <PaddingStd>
              <CustomThumbnail src={image} />
            </PaddingStd>
          )}
          {profileDisplay === 'row' && (
            <>
              <ItemInfos>
                <ItemLabel>{label}</ItemLabel>
                {!hideContentsSubtitle && (
                  <ItemValue>{formattedValue}</ItemValue>
                )}
              </ItemInfos>
              <PaddingStd>
                <ProfileContentIcon
                  share={props.platform ? props.value : URL || formattedValue}
                />
              </PaddingStd>
            </>
          )}
        </>
      </ContentWrapper>
    </Wrapper>
  )
}
