import React from 'react'
import { useProfileContext } from './Contexts/ProfileContext'
import { ButtonReport } from '@/Components/Buttons/ButtonReport'

export const ProfileReport = () => {
  const { profile } = useProfileContext()

  return (
    <div className="w-full flex justify-end items-end px-3 flex-1 py-2">
      <ButtonReport currentProfile={profile} />
    </div>
  )
}
