const getPostsDetailsById = build =>
  build.query({
    query: id => {
      return {
        url: `/posts/details/${id}`,
        method: 'GET',
      }
    },
  })

export default getPostsDetailsById
