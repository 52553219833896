import * as React from 'react'
const SvgTripadvisor = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 34})`}>
      <path
        d="M16.98 6.82c-4.425 0-8.425 1.117-11.578 3.176l-5.308.008s1.254 1.668 1.66 3.355L15.18 23.664l1.804 2.691 1.82-2.714 13.458-10.29c.402-1.695 1.668-3.39 1.668-3.39l-5.657-.004C25.105 7.91 21.25 6.82 16.98 6.82m0 0"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#000a12',
          fillOpacity: 1,
        }}
      />
      <path
        d="M25.191 9.934a8.427 8.427 0 0 0-7.43 4.922 8.5 8.5 0 0 0-.745 2.894c-.317-4.328-3.887-7.762-8.274-7.852 2.426-1.035 5.215-1.597 8.235-1.597 3.043 0 5.789.547 8.214 1.633"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#fcc40f',
          fillOpacity: 1,
        }}
      />
      <path
        d="M85.198 39.395c0 13.887-11.247 25.135-25.122 25.135-13.887 0-25.135-11.248-25.135-25.135 0-13.875 11.248-25.135 25.135-25.135 13.875 0 25.122 11.26 25.122 25.135Zm0 0"
        style={{
          fillRule: 'nonzero',
          fill: '#fff',
          fillOpacity: 1,
          strokeWidth: 5.847,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: '#000a12',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        transform="matrix(.3018 0 0 .3018 -9.663 6.821)"
      />
      <path
        d="M71.375 39.395c0 6.238-5.06 11.299-11.299 11.299-6.251 0-11.299-5.06-11.299-11.3 0-6.238 5.048-11.298 11.3-11.298 6.238 0 11.298 5.06 11.298 11.299Zm0 0"
        style={{
          fill: 'none',
          strokeWidth: 5.102,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: '#000a12',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        transform="matrix(.3018 0 0 .3018 -9.663 6.821)"
      />
      <path
        d="M9.875 18.71a1.409 1.409 0 1 1-2.817.004 1.409 1.409 0 0 1 2.817-.003m0 0"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#ef6a45',
          fillOpacity: 1,
        }}
      />
      <path
        d="M141.745 39.343c0 13.888-11.247 25.135-25.135 25.135-13.874 0-25.122-11.247-25.122-25.135 0-13.875 11.248-25.135 25.122-25.135 13.888 0 25.135 11.26 25.135 25.135Zm0 0"
        style={{
          fillRule: 'nonzero',
          fill: '#fff',
          fillOpacity: 1,
          strokeWidth: 5.847,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: '#000a12',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        transform="matrix(.3018 0 0 .3018 -9.663 6.821)"
      />
      <path
        d="M127.91 39.343c0 6.238-5.048 11.299-11.3 11.299-6.238 0-11.299-5.06-11.299-11.3 0-6.237 5.06-11.298 11.3-11.298 6.25 0 11.298 5.06 11.298 11.299Zm0 0"
        style={{
          fill: 'none',
          strokeWidth: 5.102,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: '#000a12',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        transform="matrix(.3018 0 0 .3018 -9.663 6.821)"
      />
      <path
        d="M26.941 18.695a1.409 1.409 0 1 1-2.815.003 1.409 1.409 0 0 1 2.815-.003m0 0"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#00b087',
          fillOpacity: 1,
        }}
      />
    </g>
  </svg>
)
export default SvgTripadvisor
