import { css, styled } from 'styled-components'

export const Layout = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.white};
    /* opacity: 0;
transition: opacity 0.2s ease-in;
&.loaded {
  transition: opacity 0.2s ease-in;
  opacity: 1;
} */
  `}
`
