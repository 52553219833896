import BadgeFollow from '../Post/BadgeFollow'
import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components'
import { Avatar } from './Avatar'
import { useGetUserName } from '@/Hooks/useGetUserName'
import CertificationIcon from '@/Assets/icons/CertificationIcon'
import { useTheme } from '@/Hooks'
import Text from '@/Components/Texts/Text'

const HeaderInfos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: ${theme.space.std};
    flex-shrink: 1;
    padding-right: ${theme.space.xl};
  `}
`

const HeaderIdentity = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const HeaderFullName = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.size.xs};
    font-weight: ${theme.fontWeight.semibold};
    margin-right: ${theme.space.xxs};
    flex-shrink: 1;
  `}
`
const HeaderBusiness = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`
const HeaderDate = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
  `}
`

export const TopbarIdentity = ({ resource, discover = false }) => {
  const { theme } = useTheme()
  const fullName = useGetUserName(resource)
  return (
    <div className={clsx('flex', !discover && 'items-center')}>
      <Avatar
        profile={{ photoURL: resource.photoURL }}
        size={theme.size.m}
        border={false}
      />
      <HeaderInfos>
        <HeaderIdentity>
          {fullName.trim() && (
            <div className="flex  items-center">
              <HeaderFullName>{fullName}</HeaderFullName>
              {resource.isCertified && (
                <CertificationIcon
                  color={
                    resource?.styles?.['background-color'] ||
                    theme.colors.primary
                  }
                />
              )}
            </div>
          )}
          <HeaderBusiness>
            {resource.businessName ? `${resource.businessName}` : ''}
          </HeaderBusiness>
        </HeaderIdentity>
        <HeaderDate>
          {moment(resource.createdAt || resource.postAt).calendar()}
        </HeaderDate>
      </HeaderInfos>
      {discover && <BadgeFollow resourceUserId={resource.userId} />}
    </div>
  )
}
