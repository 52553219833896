const activity_companion = {
  your_companion: 'Ton compagnon',
  evolution: 'niveau {{evolution}}',
  vitality: '{{energyPercentage}}% de vitalité',
  inactiv: 'Inactif',
  evolving: 'En cours d’évolution...',
  empty: 'Pas de compagnon trouvé',
  description:
    'Augmentez les performances de votre compagnon en l’améliorant continuellement.',
  upgrade: 'Améliorer',
  evolve: 'Évoluer {{name}}',
  evolve_now: 'Faire évoluer maintenant',
  pending_evolve: 'En cours d’évolution',
  description_evolving:
    'Votre compagnon est inactif pendant son évolution. Payez pour passer le temps restant.',
  remaining_time: 'Temps restant avant évolution : {{time}} minute{{plurial}}',
  details_button: 'Détails de votre compagnon',
  vitality_title: 'Énergie',
  yield: 'Rendement',
  yield_description: 'Le rendement de votre compagnon est optimal.',
  yield_empty_description:
    'Votre compagnon n’a plus d’énergie. Il n’est plus en capacité de vous rapporter de rendement.',
  yield_description_75:
    'Rechargez l’énergie de votre compagnon en l’arrosant pour garder son rendement optimal.',
  yield_description_50:
    'Le rendement de votre compagnon est divisé par 2. Rechargez son énergie en l’arrosant pour garder son rendement optimal.',
  yield_description_25:
    'L’énergie de votre compagnon est presque épuisée. Arrosez-le pour continuer à avoir un rendement.',
  yield_claim: 'Réclamer mes rendements',
  yield_claim_empty: 'Aucun rendement en attente',
  yield_history: 'Historique de rendement',
  learn_more: 'En savoir plus',
  yield_received: 'Reçu le {{date}}',
  yield_select_resources: 'Sélectionner de nouvelles ressources',
  yield_select_resources_cost: 'coût :',
  obtained_coins_title: 'Coins',
  obtained_exp_title: 'Exp',
  obtained_ticket_title: 'Ticket',
  obtained_resource_subtitle: 'obtenus',
  obtained_resources: '{{quantity}} {{resource}} obtenus',
  water_companion: 'Arroser votre compagnon',
  level: {
    evolution: 'Coût d’évolution : ',
    time_evolution: 'Temps d’évolution : ',
    level2: 'Niveau ',
  },
  modal: {
    title: 'Détails des prochains rendements de votre compagnon',
  },
  modal_activity: {
    owner: 'Propriétaire',
    close: 'Fermer',
  },
  minute: 'minute',
  inactiv_title: 'Votre compagnon est inactif',
  inactiv_description:
    'Votre compagnon est désactivé. Il n’est plus en capacité de vous rapporter de rendement.',
  inactiv_button: 'Réactiver mon compagnon',
}

export default activity_companion
