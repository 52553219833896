import { useTheme } from '@/Hooks'
import Text from '../Texts/Text'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const Label = ({
  name,
  color,
  children,
  childrenClassName,
  optional = false,
  ...props
}) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  return (
    <label
      htmlFor={name}
      className={clsx(
        'font-normal mb-2',
        'tablet:text-sm tablet:mb-1',
        childrenClassName,
        optional && 'flex justify-between',
      )}
    >
      <Text color={color || theme.colors.grey2} {...props}>
        {children}
      </Text>
      {optional && (
        <Text
          className="font-light text-sm tablet:text-xs"
          color={color || theme.colors.grey2}
        >
          {t('onboarding.optional')}
        </Text>
      )}
    </label>
  )
}

export default Label
