import { api } from '@/Services/api'

const createPost = build =>
  build.mutation({
    query: ({ post }) => {
      return {
        url: '/posts/',
        method: 'POST',
        body: post,
      }
    },
    async onQueryStarted({ post, user }, { dispatch, queryFulfilled }) {
      const newPost = {
        ...post,
        ...user,
        seeds: [],
        trees: [],
      }
      const patchPosts = dispatch(
        api.util.updateQueryData(
          'getPosts',
          post.id,
          state => (state = [newPost, ...state]),
        ),
      )
      const patchUserPosts = dispatch(
        api.util.updateQueryData(
          'getPostsByUserId',
          post.id,
          state => (state = [newPost, ...state]),
        ),
      )

      try {
        await queryFulfilled
      } catch {
        patchPosts.undo()
        patchUserPosts.undo()
      }
    },
    invalidatesTags: [
      'PostsByHashtag',
      'AvailableResources',
      'Progress',
      'Streaks',
    ],
  })

export default createPost
