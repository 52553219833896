import * as React from 'react'
const SvgQrCode = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 125})`}>
      <path fill="#000" d="M13.417 7.75H8.25v5.167h5.167z" />
      <path
        fill="#000"
        d="M18.583 7.75h-5.166v5.167h5.166zM23.75 7.75h-5.167v5.167h5.167zM28.917 7.75H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 7.75h-5.166v5.167h5.166zM39.25 7.75h-5.167v5.167h5.167zM44.417 7.75H39.25v5.167h5.167zM54.75 7.75h-5.167v5.167h5.167zM59.917 7.75H54.75v5.167h5.167zM65.083 7.75h-5.166v5.167h5.166zM70.25 7.75h-5.167v5.167h5.167zM85.75 7.75h-5.167v5.167h5.167zM90.917 7.75H85.75v5.167h5.167zM96.083 7.75h-5.166v5.167h5.166zM101.25 7.75h-5.167v5.167h5.167zM106.417 7.75h-5.167v5.167h5.167zM111.583 7.75h-5.166v5.167h5.166zM116.75 7.75h-5.167v5.167h5.167zM13.417 12.917H8.25v5.166h5.167zM44.417 12.917H39.25v5.166h5.167zM59.917 12.917H54.75v5.166h5.167zM70.25 12.917h-5.167v5.166h5.167zM75.417 12.917H70.25v5.166h5.167zM85.75 12.917h-5.167v5.166h5.167zM116.75 12.917h-5.167v5.166h5.167zM13.417 18.083H8.25v5.167h5.167zM23.75 18.083h-5.167v5.167h5.167zM28.917 18.083H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 18.083h-5.166v5.167h5.166zM44.417 18.083H39.25v5.167h5.167zM54.75 18.083h-5.167v5.167h5.167zM65.083 18.083h-5.166v5.167h5.166zM70.25 18.083h-5.167v5.167h5.167zM85.75 18.083h-5.167v5.167h5.167zM96.083 18.083h-5.166v5.167h5.166zM101.25 18.083h-5.167v5.167h5.167zM106.417 18.083h-5.167v5.167h5.167zM116.75 18.083h-5.167v5.167h5.167zM13.417 23.25H8.25v5.167h5.167zM23.75 23.25h-5.167v5.167h5.167zM28.917 23.25H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 23.25h-5.166v5.167h5.166zM44.417 23.25H39.25v5.167h5.167zM70.25 23.25h-5.167v5.167h5.167zM85.75 23.25h-5.167v5.167h5.167zM96.083 23.25h-5.166v5.167h5.166zM101.25 23.25h-5.167v5.167h5.167zM106.417 23.25h-5.167v5.167h5.167zM116.75 23.25h-5.167v5.167h5.167zM13.417 28.417H8.25v5.166h5.167zM23.75 28.417h-5.167v5.166h5.167zM28.917 28.417H23.75v5.166h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 28.417h-5.166v5.166h5.166zM44.417 28.417H39.25v5.166h5.167zM54.75 28.417h-5.167v5.166h5.167zM59.917 28.417H54.75v5.166h5.167zM70.25 28.417h-5.167v5.166h5.167zM75.417 28.417H70.25v5.166h5.167zM85.75 28.417h-5.167v5.166h5.167zM96.083 28.417h-5.166v5.166h5.166zM101.25 28.417h-5.167v5.166h5.167zM106.417 28.417h-5.167v5.166h5.167zM116.75 28.417h-5.167v5.166h5.167zM13.417 33.583H8.25v5.167h5.167zM44.417 33.583H39.25v5.167h5.167zM59.917 33.583H54.75v5.167h5.167zM75.417 33.583H70.25v5.167h5.167zM85.75 33.583h-5.167v5.167h5.167zM116.75 33.583h-5.167v5.167h5.167zM13.417 38.75H8.25v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M18.583 38.75h-5.166v5.167h5.166zM23.75 38.75h-5.167v5.167h5.167zM28.917 38.75H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 38.75h-5.166v5.167h5.166zM39.25 38.75h-5.167v5.167h5.167zM44.417 38.75H39.25v5.167h5.167zM54.75 38.75h-5.167v5.167h5.167zM65.083 38.75h-5.166v5.167h5.166zM75.417 38.75H70.25v5.167h5.167zM85.75 38.75h-5.167v5.167h5.167zM90.917 38.75H85.75v5.167h5.167zM96.083 38.75h-5.166v5.167h5.166zM101.25 38.75h-5.167v5.167h5.167zM106.417 38.75h-5.167v5.167h5.167zM111.583 38.75h-5.166v5.167h5.166zM116.75 38.75h-5.167v5.167h5.167zM59.917 43.917H54.75v5.166h5.167zM65.083 43.917h-5.166v5.166h5.166zM70.25 43.917h-5.167v5.166h5.167zM18.583 49.083h-5.166v5.167h5.166zM34.083 49.083h-5.166v5.167h5.166zM44.417 49.083H39.25v5.167h5.167zM59.917 49.083H54.75v5.167h5.167zM65.083 49.083h-5.166v5.167h5.166zM80.583 49.083h-5.166v5.167h5.166zM96.083 49.083h-5.166v5.167h5.166zM106.417 49.083h-5.167v5.167h5.167zM116.75 49.083h-5.167v5.167h5.167zM13.417 54.25H8.25v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M18.583 54.25h-5.166v5.167h5.166zM23.75 54.25h-5.167v5.167h5.167zM28.917 54.25H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 54.25h-5.166v5.167h5.166zM65.083 54.25h-5.166v5.167h5.166zM70.25 54.25h-5.167v5.167h5.167zM75.417 54.25H70.25v5.167h5.167zM85.75 54.25h-5.167v5.167h5.167zM101.25 54.25h-5.167v5.167h5.167zM111.583 54.25h-5.166v5.167h5.166zM34.083 59.417h-5.166v5.166h5.166zM39.25 59.417h-5.167v5.166h5.167zM44.417 59.417H39.25v5.166h5.167zM49.583 59.417h-5.166v5.166h5.166zM59.917 59.417H54.75v5.166h5.167zM75.417 59.417H70.25v5.166h5.167zM80.583 59.417h-5.166v5.166h5.166zM85.75 59.417h-5.167v5.166h5.167zM90.917 59.417H85.75v5.166h5.167zM96.083 59.417h-5.166v5.166h5.166zM101.25 59.417h-5.167v5.166h5.167zM111.583 59.417h-5.166v5.166h5.166zM116.75 59.417h-5.167v5.166h5.167zM13.417 64.583H8.25v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M18.583 64.583h-5.166v5.167h5.166zM23.75 64.583h-5.167v5.167h5.167zM49.583 64.583h-5.166v5.167h5.166zM54.75 64.583h-5.167v5.167h5.167zM80.583 64.583h-5.166v5.167h5.166zM85.75 64.583h-5.167v5.167h5.167zM90.917 64.583H85.75v5.167h5.167zM96.083 64.583h-5.166v5.167h5.166zM13.417 69.75H8.25v5.167h5.167zM34.083 69.75h-5.166v5.167h5.166zM39.25 69.75h-5.167v5.167h5.167zM44.417 69.75H39.25v5.167h5.167zM49.583 69.75h-5.166v5.167h5.166zM65.083 69.75h-5.166v5.167h5.166zM75.417 69.75H70.25v5.167h5.167zM90.917 69.75H85.75v5.167h5.167zM106.417 69.75h-5.167v5.167h5.167zM116.75 69.75h-5.167v5.167h5.167zM59.917 74.917H54.75v5.166h5.167zM65.083 74.917h-5.166v5.166h5.166zM70.25 74.917h-5.167v5.166h5.167zM85.75 74.917h-5.167v5.166h5.167zM90.917 74.917H85.75v5.166h5.167zM13.417 80.083H8.25v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M18.583 80.083h-5.166v5.167h5.166zM23.75 80.083h-5.167v5.167h5.167zM28.917 80.083H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 80.083h-5.166v5.167h5.166zM39.25 80.083h-5.167v5.167h5.167zM44.417 80.083H39.25v5.167h5.167zM59.917 80.083H54.75v5.167h5.167zM80.583 80.083h-5.166v5.167h5.166zM96.083 80.083h-5.166v5.167h5.166zM101.25 80.083h-5.167v5.167h5.167zM111.583 80.083h-5.166v5.167h5.166zM116.75 80.083h-5.167v5.167h5.167zM13.417 85.25H8.25v5.167h5.167zM44.417 85.25H39.25v5.167h5.167zM59.917 85.25H54.75v5.167h5.167zM65.083 85.25h-5.166v5.167h5.166zM70.25 85.25h-5.167v5.167h5.167zM75.417 85.25H70.25v5.167h5.167zM80.583 85.25h-5.166v5.167h5.166zM85.75 85.25h-5.167v5.167h5.167zM101.25 85.25h-5.167v5.167h5.167zM106.417 85.25h-5.167v5.167h5.167zM111.583 85.25h-5.166v5.167h5.166zM116.75 85.25h-5.167v5.167h5.167zM13.417 90.417H8.25v5.166h5.167zM23.75 90.417h-5.167v5.166h5.167zM28.917 90.417H23.75v5.166h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 90.417h-5.166v5.166h5.166zM44.417 90.417H39.25v5.166h5.167zM54.75 90.417h-5.167v5.166h5.167zM59.917 90.417H54.75v5.166h5.167zM65.083 90.417h-5.166v5.166h5.166zM70.25 90.417h-5.167v5.166h5.167zM80.583 90.417h-5.166v5.166h5.166zM85.75 90.417h-5.167v5.166h5.167zM101.25 90.417h-5.167v5.166h5.167zM106.417 90.417h-5.167v5.166h5.167zM13.417 95.583H8.25v5.167h5.167zM23.75 95.583h-5.167v5.167h5.167zM28.917 95.583H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 95.583h-5.166v5.167h5.166zM44.417 95.583H39.25v5.167h5.167zM59.917 95.583H54.75v5.167h5.167zM85.75 95.583h-5.167v5.167h5.167zM90.917 95.583H85.75v5.167h5.167zM13.417 100.75H8.25v5.167h5.167zM23.75 100.75h-5.167v5.167h5.167zM28.917 100.75H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 100.75h-5.166v5.167h5.166zM44.417 100.75H39.25v5.167h5.167zM65.083 100.75h-5.166v5.167h5.166zM80.583 100.75h-5.166v5.167h5.166zM85.75 100.75h-5.167v5.167h5.167zM101.25 100.75h-5.167v5.167h5.167zM13.417 105.917H8.25v5.166h5.167zM44.417 105.917H39.25v5.166h5.167zM54.75 105.917h-5.167v5.166h5.167zM65.083 105.917h-5.166v5.166h5.166zM75.417 105.917H70.25v5.166h5.167zM80.583 105.917h-5.166v5.166h5.166zM85.75 105.917h-5.167v5.166h5.167zM101.25 105.917h-5.167v5.166h5.167zM111.583 105.917h-5.166v5.166h5.166zM116.75 105.917h-5.167v5.166h5.167zM13.417 111.083H8.25v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M18.583 111.083h-5.166v5.167h5.166zM23.75 111.083h-5.167v5.167h5.167zM28.917 111.083H23.75v5.167h5.167z"
      />
      <path
        fill="#000"
        d="M34.083 111.083h-5.166v5.167h5.166zM39.25 111.083h-5.167v5.167h5.167zM44.417 111.083H39.25v5.167h5.167zM59.917 111.083H54.75v5.167h5.167zM65.083 111.083h-5.166v5.167h5.166zM70.25 111.083h-5.167v5.167h5.167zM75.417 111.083H70.25v5.167h5.167zM80.583 111.083h-5.166v5.167h5.166zM85.75 111.083h-5.167v5.167h5.167zM106.417 111.083h-5.167v5.167h5.167zM116.75 111.083h-5.167v5.167h5.167z"
      />
    </g>
  </svg>
)
export default SvgQrCode
