const interests = [
  { icon: '🏢', value: 'Business' },
  { icon: '🎨', value: 'Creatives' },
  { icon: '📚', value: 'Education' },
  {
    icon: '🎶',
    value: 'Entertainment',
  },
  { icon: '👗', value: 'Fashion' },
  {
    icon: '⚖️',
    value: 'Government_Politics',
  },
  {
    icon: '🍔',
    value: 'Food_Beverages',
  },
  {
    icon: '🍏',
    value: 'Health_Wellness',
  },
  { icon: '🖥', value: 'Tech' },
  {
    icon: '🛫',
    value: 'Travel_Tourism',
  },
  { icon: '❔', value: 'Other' },
]

export default interests
