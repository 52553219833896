import React from 'react'
import { bgAnimated } from './bgAnimated'

const DynamicBackgroundAnimated = ({ name }) => {
  const background = bgAnimated.find(svg => svg.name === name)
  if (!background) {
    return null
  }

  return (
    <img src={background.url} alt="animated background" className="w-full" />
  )
}

export default DynamicBackgroundAnimated
