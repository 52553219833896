import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineWater = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="M18.75 15c0 4.142-2.608 6.75-6.75 6.75S5.25 19.142 5.25 15c0-4.445 4.839-10.446 6.323-12.182a.562.562 0 0 1 .854 0C13.912 4.554 18.75 10.555 18.75 15Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M16.125 15.375a3.375 3.375 0 0 1-3.375 3.375"
        />
      </g>
    </svg>
  )
}
export default SvgLineWater
