import styled, { css } from 'styled-components'
import Text from '../Texts/Text'
import Switch from '@/Components/Controls/Switch'
import { useTheme } from '@/Hooks'

const Options = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.std} ${theme.space.m};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: ${theme.borderRadius.l};
    background-color: ${props =>
      props.variant === 'secondary' ? theme.colors.white : theme.colors.grey1};
  `}
`

const LabeledSwitch = ({
  title,
  subtitle,
  value,
  onChange,
  disabled = false,
  ...props
}) => {
  const { theme } = useTheme()
  return (
    <Options {...props}>
      <div className="grow flex flex-col">
        {title && (
          <Text regular color={theme.colors.grey2}>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text regular className="text-xs" color={theme.colors.grey2}>
            {subtitle}
          </Text>
        )}
      </div>
      <Switch
        value={value}
        onChange={onChange}
        disabled={disabled}
        color="primary"
      />
    </Options>
  )
}

export default LabeledSwitch
