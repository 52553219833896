import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../ModalWrapper/Modal'
import { Button } from '../../Buttons/Button'
import { useTheme } from '@/Hooks'
import ContentCopy from '../../ContentCopy'
import useSocialMediaOptions from './useSocialMediaOptions'

const ModalSocialMedia = ({ children: button }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { theme } = useTheme()
  const { options, profileUrl } = useSocialMediaOptions()
  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.socialMedia.share_on_your_favorite')}
        renderCustomFooter={() => (
          <ContentCopy text={profileUrl} className="w-full" />
        )}
      >
        {options.map(option => (
          <div className="mb-3" key={option.label}>
            <Button
              {...option}
              color={theme.colors.grey1}
              className="w-full justify-start"
            />
          </div>
        ))}
      </Modal>
    </>
  )
}

export default ModalSocialMedia
