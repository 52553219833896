const homelogin = {
  account: 'Already have an account?',
  login: 'Log in',
  start: 'Start',
  slide: 'SLIDE TO LEARN MORE',
  frame0: {
    title: 'The last of your business cards',
  },
  frame1: {
    title: 'Your digital profile in your pocket',
  },
  frame2: {
    title: 'Plant trees with your posts',
  },
  frame3: {
    title: 'Turn your activity into real gifts',
    members: 'members',
    voucher: 'coupon',
    reservation: 'on reservation',
    discount: 'saving',
  },
  frame4: {
    title: 'Centralize your social networks',
  },
}


export default homelogin
