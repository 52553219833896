const toasts = {
  toastText: {
    copy_advantage: 'Benefit copied!',
    copy_Link: 'Copied!',
    copy_Code: 'Code copied!',
  },
  toastPsw: {
    title: 'Your password has been changed !',
    resetTitle: 'A password reset email has been sent to you!',
    error1: 'Passwords are not identical',
  },
  toastReportPost: {
    option: 'Post reporting',
    alreadyReport: 'This post has already been reported',
    savedReport: 'Your report has been recorded !',
  },
  toastReportProfile: {
    option: 'Profile Reporting',
    alreadyReport: 'This profile has already been reported',
    savedReport: 'Your report has been recorded !',
    blockedsuccess: 'Successfully block',
    unBlockedsuccess: 'Unlock successfully',
  },
  toastAddContent: {
    error: 'Invalid value',
  },
  toastModalImage: {
    success: 'The image has been successfully uploaded!',
  },
  toastImageOriginSelection: {
    success: 'The image was successfully uploaded! Remember to save',
  },
  toastActivateProduct: {
    success: 'Product activated',
  },
  toastPublicationTime: {
    error: 'An error occurred',
  },
  toastWarningBanner: {
    success: 'A confirmation email has been sent to you',
  },
  toastQrCode: {
    info_text1: 'QR Code saved !',
    info_text2: 'Your QR Code has been save to your camera roll',
    error: 'QR Code not saved!',
  },
  toastPersonalInformation: {
    warning_text: 'Please fill in at least one field',
  },
  toastErrorDefault: {
    error: 'An error occurred. Please try again.',
  },
  toastNfcScanner: {
    error1: "NFC can't be activated",
    error2: 'Tag not recognized, try again',
  },
  toastTouchId: {
    success: 'Fingerprint identification activated',
    success1: 'Fingerprint identification disabled',
  },
  toastfaceId: {
    success: 'Face ID enabled',
    success1: 'Face ID disabled',
  },
  toastSendCodeVerification: {
    success: 'Verification code sent successfully!',
    error: 'An error occurred while sending.',
  },
  toastCheckCode: {
    success:
      'The code is correct! Remember to save to confirm your verification.',
    error: 'Code is not correct!',
  },
  toastOnBoarding: {
    error: 'Badly formatted username',
  },
  toastDropPost: {
    success: 'Congratulations! This post has been watered successfully.',
  },
  toastUploadImageGallery: {
    you_have_canceled: 'You have canceled image selection',
  },
  toastExplicitImage: {
    please_upload_an_image:
      "Please upload an image that complies with the application's policy",
  },
  toast_welcome_quest_finished: {
    unlock_feature_complete_quest:
      'To unlock this feature, you must complete the welcome quests.',
    show_welcome_quest: 'View quests',
  },
}


export default toasts
