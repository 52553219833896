import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineBuilding = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12.5 22H4.08c-1.16 0-2.11-.93-2.11-2.07V5.09c0-2.62 1.95-3.81 4.34-2.64l4.44 2.18c.96.47 1.75 1.72 1.75 2.78zM21.97 15.06v3.78c0 2.16-1 3.16-3.16 3.16H12.5V10.42l.47.1 4.5 1.01 2.03.45c1.32.29 2.4.97 2.46 2.89.01.06.01.12.01.19M5.5 9h3.47M5.5 13h3.47"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M17.47 11.53v3.22c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25v-4.23zM21.96 14.87A2.25 2.25 0 0 1 19.72 17c-1.24 0-2.25-1.01-2.25-2.25v-3.22l2.03.45c1.32.29 2.4.97 2.46 2.89"
        />
      </g>
    </svg>
  )
}
export default SvgLineBuilding
