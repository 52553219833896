import templates from '@/Components/BusinessCardPreview/templates'
import ThumbnailOption from '@/Components/ThumbnailOption'

const BusinessCardOption = ({ businessCard, ...props }) => {
  const { fullName, businessName, job, email, color } = businessCard

  const Thumbnail = templates.find(
    template => template.slug === businessCard.template,
  )?.thumbnail

  if (!Thumbnail) {
    return null
  }

  return (
    <ThumbnailOption
      {...props}
      title={fullName || businessName}
      subtitle={job || email}
      renderThumbnail={() => <Thumbnail color={color} />}
      className="w-full"
    />
  )
}

export default BusinessCardOption
