import React, { useMemo, memo } from 'react'
import styled, { css } from 'styled-components'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTranslation } from 'react-i18next'
import { ProfileStats } from './ProfileStats'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.m} ${theme.space.m};
    display: flex;
    flex-direction: column;
  `}
`

const FullName = styled.span`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: ${theme.fontWeight.semibold};
    color: ${theme.colors.black};
  `}
`

export const ProfileIdentity = memo(
  ({ user, statsVisible = true, ...props }) => {
    const { profile = {} } = useProfileContext()
    const { t } = useTranslation()
    const subtitle = useMemo(() => {
      const { businessName, job } = user || profile
      if (job && businessName) {
        return `@${job} ${t('profilepage.at_home_bar')} ${businessName}`
      }
      return job || businessName
    }, [profile, user, t])
    if (profile.businessMode || user?.businessMode) {
      return (
        <Wrapper {...props}>
          <FullName>{user?.businessName || profile.businessName}</FullName>
        </Wrapper>
      )
    }

    return (
      <Wrapper {...props}>
        <FullName>
          {user?.firstName || profile.firstName}{' '}
          {user?.lastName || profile.lastName}
        </FullName>
        <span className="text-grey2 text-sm">{subtitle}</span>
        {statsVisible && <ProfileStats />}
      </Wrapper>
    )
  },
)
