import React, { useMemo, useRef, useState } from 'react'
import { FormProvider } from './FormProvider'
// import { useForm } from 'react-hook-form'

const Form = ({ onSubmit, children, ...props }) => {
  const formRef = useRef()
  // const form = useForm()

  const [saveStatus, setSaveStatus] = useState('saved')

  const config = useMemo(() => {
    return {
      // ...form,
      formRef,
      onSubmit,
      saveStatus,
      setSaveStatus,
    }
  }, [onSubmit, saveStatus])

  if (!config) {
    return null
  }

  return (
    <FormProvider config={config}>
      <form ref={formRef} onSubmit={onSubmit} {...props}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
