import { normalize } from '@/Utils/normalize'

const useFilteredUsers = (list = [], search = '') => {
  const result = list.filter(user => {
    if (!search) {
      return true
    }
    if (!user.firstName) {
      return false
    }

    const fullName = `${user.firstName} ${user.lastName || ''}`

    const value = fullName
    return (
      normalize(value).indexOf(normalize(search)) !== -1 ||
      normalize(user.job || '').indexOf(normalize(search)) !== -1 ||
      normalize(user.email || '').indexOf(normalize(search)) !== -1
    )
  })

  return result
}

export default useFilteredUsers
