import * as React from 'react'
const SvgFlagEn = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 28})`}>
      <g clipPath="url(#flagEn_svg__a)">
        <rect width={28} height={28} fill="#1A47B8" rx={14} />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M-1.43 0H-5.6v4.667L29.407 28H33.6v-4.667z"
          clipRule="evenodd"
        />
        <path fill="#F93939" d="M-4.21 0 33.6 25.266V28h-1.358L-5.6 2.708V0z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M29.867 0H33.6v4.667S9.352 20.212-1.867 28H-5.6v-4.667z"
          clipRule="evenodd"
        />
        <path
          fill="#F93939"
          d="M33.6 0h-1.266L-5.6 25.288V28h1.39L33.6 2.728z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M8.656 0h10.72v8.637H33.6v10.72H19.377V28H8.656v-8.642H-5.6V8.637H8.656z"
          clipRule="evenodd"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M10.905 0h6.19v10.77H33.6v6.46H17.095V28h-6.19V17.23H-5.6v-6.46h16.505z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="flagEn_svg__a">
          <rect width={28} height={28} fill="#fff" rx={14} />
        </clipPath>
      </defs>
    </g>
  </svg>
)
export default SvgFlagEn
