const companion_yields = {
  obtained: 'por cosechar',
  yield: 'Rendimiento',
  claim_my_yields: 'Reclamar mis rendimientos',
  no_claim_yields: 'Próxima cosecha en ',
  received_at: 'Recibido el ',
  yield_history: 'Historial de rendimiento',
  rewarded: '¡Los rendimientos de tu compañero han sido recogidos!',
  rewarded_description: '¡Acabas de recoger todos tus rendimientos!',
  reminder: 'Vuelve cada día para obtener tus rendimientos diarios',
  great: 'Genial!',
  choose_active_resources: 'Elige 3 recursos activos',
  resources_can_be_changed:
    'Los recursos elegidos se pueden cambiar según sus deseos.',
  active: 'activo',
  disabled: 'deshabilitado',
  validate_selected_resources: 'Validar mi selección',
  error_claim_yields_before_selecting_it:
    'Imposible modificar recursos cuando hay rendimientos que recuperar. Recójalos primero.',
}

export default companion_yields
