const quests_titles = {
  complete_welcome_quests: 'Terminez toutes vos quêtes de bienvenue',
  complete_daily_quests: 'Terminez toutes vos quêtes quotidiennes',
  add_post: 'Ajoutez {{goal}} publication{{plurial}}',
  add_cover_picture: 'Ajoutez {{goal}} image{{plurial}} de couverture',
  add_profile_picture: 'Ajoutez {{goal}} image{{plurial}} de profil',
  add_business_name: 'Ajoutez {{goal}} nom{{plurial}} de votre entreprise',
  add_job: 'Ajoutez {{goal}} emploi{{plurial}}',
  add_description: 'Ajoutez {{goal}} description{{plurial}}',
  add_social_content: 'Ajoutez {{goal}} contenu{{plurial}} social',
  add_contact_content: 'Ajoutez {{goal}} contenu{{plurial}} de contact',
  add_following: 'Suivez {{goal}} utilisateur{{plurial}}',
  add_post_to_bookmark: 'Ajoutez {{goal}} publication{{plurial}} à vos favoris',
  add_image_to_bookmark:
    "Ajoutez {{goal}} image{{plurial}} d'une galerie à vos favoris",
  add_drop_to_post: 'Arrosez {{goal}} post{{plurial}}',
  get_view: 'Obtenez {{goal}} vue{{plurial}}',
  get_follower: 'Obtenez {{goal}} abonné{{plurial}}',
  get_drop_to_post: 'Faites arroser vos posts {{goal}} fois',
  watch_add: 'Regardez {{goal}} publicité{{plurial}}',
  gain_drop: 'Gagnez {{goal}} goutte{{plurial}}',
  gain_seed: 'Gagnez {{goal}} graine{{plurial}}',
  gain_coin: 'Gagnez {{goal}} pièce{{plurial}}',
  get_sponsorship: 'Parrainez {{goal}} personne{{plurial}}',
  play_game: 'Faire une partie',
  use_coin: 'Dépenser {{goal}} pièces',
  create_ai_image: "Créez {{goal}} image{{plurial}} avec l'IA",
  verify_account: 'Vérifiez votre compte',
  claim_daily_resources: 'Récupérez vos ressources journalières',
}


export default quests_titles
