// import { WelcomeQuestsBanner } from '@/Components/Activity/components/WelcomeQuestsBanner'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import useWelcomeQuestsFinished from '@/Hooks/useWelcomeQuestsFinished'
import { useTranslation } from 'react-i18next'
import { LabeledSwitchLock } from '@/Components/ControlsLocked/LabeledSwitchLock'

export const EditProfileBusinessSwitch = ({ name, disabled = false }) => {
  const { t } = useTranslation()
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const welcomeQuestsFinished = useWelcomeQuestsFinished()

  const handleChangeProfileType = value => {
    const cleanedFields = value
      ? {
          firstName: '',
          lastName: '',
          job: '',
        }
      : {}

    setEditCurrentUser({
      ...editCurrentUser,
      ...cleanedFields,
      [name]: !editCurrentUser[name],
    })
  }

  return (
    <>
      {/* <WelcomeQuestsBanner
        onClick={() =>
          Navigate('Activity', {
            tab: 'quests',
          })
        }
        subtitle={t('games.subtitle_welcome_quests_banner_business')}
      /> */}
      <LabeledSwitchLock
        title={t('edit_profil.informations.advanced_options.business_profile')}
        subtitle={t(
          'edit_profil.informations.advanced_options.business_profile',
        )}
        value={editCurrentUser[name]}
        onChange={handleChangeProfileType}
        disabled={disabled || !welcomeQuestsFinished}
        name={name}
      />
    </>
  )
}
