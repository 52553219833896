import * as React from 'react'
const SvgSeedHello = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 60})`}>
      <path
        fill="#3C3F42"
        d="M17.137 59.45a.54.54 0 0 1-.535-.48c-.107-.96.475-1.431.54-1.483a.55.55 0 0 1 .377-.108c2.506.233 2.958-.317 3.036-.49.288-.63-.823-2.113-1.665-2.914a.541.541 0 0 1 .745-.783c.441.42 2.608 2.593 1.904 4.143-.421.927-1.673 1.302-3.822 1.144a.74.74 0 0 0-.043.374.54.54 0 0 1-.477.595q-.03.004-.06.003M8.265 58.678a.54.54 0 0 1-.29-.996.7.7 0 0 0 .281-.301c-.997-1.408-1.348-2.742-1.043-3.967.457-1.84 2.228-2.694 2.303-2.729a.54.54 0 0 1 .461.976c-.02.01-1.39.687-1.718 2.021-.234.951.105 2.046 1.006 3.25a.54.54 0 0 1 .107.31c.003.083-.001.831-.816 1.35a.53.53 0 0 1-.29.086M4.58 37.13a.54.54 0 0 1-.431-.212c-5.191-6.823-3.14-13.652-3.05-13.94a.539.539 0 1 1 1.03.322c-.019.065-1.962 6.6 2.88 12.964a.541.541 0 0 1-.43.866"
      />
      <path
        fill="#3C3F42"
        d="M2.273 24.284c-.326 0-.71-.14-1.122-.508C.506 23.2.32 22.47.654 21.823c.362-.697 1.274-1.098 2.078-.915.445.103.79.458.901.926.096.405.006.838-.244 1.236a1.34 1.34 0 0 1-.33.872c-.181.197-.454.342-.786.342M32.147 53.534q-.048 0-.094-.003c-1.526-.091-2.387-2.233-2.556-6.363a.539.539 0 1 1 1.078-.045c.16 3.876.93 5.294 1.543 5.331h.03c.791 0 2.077-1.843 2.424-3.979a.54.54 0 0 1 1.066.174c-.39 2.391-1.874 4.885-3.49 4.885"
      />
      <path
        fill="#3C3F42"
        d="M35.336 49.362q-.22 0-.462-.047c-1.095-.219-1.373-.904-1.277-1.425.055-.308.244-.586.498-.788.168-.439.46-.77.842-.938.44-.193.93-.131 1.28.164.63.532.858 1.504.517 2.21-.256.532-.762.824-1.398.824"
      />
      <path
        fill="green"
        d="M39.59 6.88c-.453-2-2.335-4.418-5.273-4.957-3.119-.574-6.18.565-7.87 3.963-.4.81-.693 1.671-.867 2.56-.943-1.574-2.756-3.741-5.687-4.035-4.174-.414-6.629 2.018-7.317 4.03-.943 2.748-.074 5.003 1.947 5.303s3.457-.963 4.763-1.649c1.891-.995 5.107-1.077 5.785.818q.143.755.248 1.513c.004.684-.97 3.923-1.283 4.523-.426.818-.853 1.338-.484 1.79.313.382.909.518 1.448.518 1.701 0 1.97-.247 2.187-.518.26-.33 1.482-4.334.124-7.718-.462-2.465 2.463-2.94 3.292-2.961 1.67-.039 4.228.548 5.831.755 3.635.469 3.484-2.482 3.156-3.934"
      />
      <path
        fill="#A4B46B"
        d="M17.875 8.182q.282-.471.643-.887c.252-.294.826-.604.848-1.032.032-.574-.866-.516-1.22-.49q-.346.022-.688.087c-.41.075-.812.194-1.191.36a4.96 4.96 0 0 0-1.712 1.235c-.345.388-.643.841-.812 1.336-.397 1.148-.386 2.236.022 2.907.227.373.566.596 1.008.66 1.85.274 2.238-1.787 2.672-3.136.112-.352.24-.72.43-1.04m9.863-1.82c-.105.374-.138.782.056 1.06.14.199.505.302.74.244.696-.17 1.19-1.11 1.535-1.67.268-.436.609-.853.967-1.218.225-.229.523-.49.6-.816.143-.602-.777-.362-1.049-.295-1.062.267-2.08 1.111-2.594 2.072-.09.168-.188.388-.255.623"
      />
      <path
        fill="#A37B65"
        d="M32.567 43.91c-2.27 7.724-10.37 12.143-18.093 9.873-7.722-2.27-12.143-10.37-9.873-18.093 2.707-9.208 14.024-18.316 19.425-22.238a2.762 2.762 0 0 1 4.24 1.246c2.42 6.22 7.008 20.004 4.3 29.212"
      />
      <path
        fill="#7E5847"
        d="M15.442 52.567c-5.586-1.343-9.28-5.91-8.249-10.198 1.032-4.29 6.398-6.676 11.985-5.332s9.281 5.91 8.25 10.199-6.399 6.676-11.986 5.331"
      />
      <path
        fill="#fff"
        d="M22.49 17.355c.023-.123-.104-.243-.22-.248-.694-.031-1.394.7-1.918 1.1q-1.048.801-2.041 1.672a53 53 0 0 0-1.945 1.782c-.535.52-1.498 1.183-1.662 1.932-.078.358.172.794.598.688.778-.197 1.36-1.072 1.92-1.62.63-.616 1.256-1.235 1.898-1.835a638 638 0 0 1 1.931-1.803c.502-.465 1.309-.963 1.439-1.668M13.808 24.813c-.321-.22-.69-.103-.923.17l-.395.463a3.4 3.4 0 0 0-.529.621.66.66 0 0 0 .166.899c.319.218.664.095.899-.167l.952-1.063c.24-.267.097-.739-.17-.923M9.601 36.7a3.92 3.92 0 0 0 2.358 5.015 3.92 3.92 0 0 0 5.019-2.35 3.92 3.92 0 0 0-2.36-5.017 3.917 3.917 0 0 0-5.017 2.351"
      />
      <path
        fill="#3C3F42"
        d="M14.327 40.099c-.327.161-.703.072-.838-.2s.022-.623.35-.786c.328-.161.703-.073.838.2.135.271-.022.623-.35.786m-1.24-2.45c-.568.28-1.215.125-1.45-.345-.232-.47.038-1.079.605-1.358.568-.28 1.216-.126 1.449.345.233.469-.037 1.077-.605 1.357m2.74 1.3a2.7 2.7 0 0 0-1.623-3.456 2.7 2.7 0 0 0-3.458 1.62 2.7 2.7 0 0 0 1.625 3.454 2.697 2.697 0 0 0 3.456-1.619"
      />
      <path
        fill="#fff"
        d="M22.644 42.389a3.92 3.92 0 0 0 2.9 4.723 3.92 3.92 0 0 0 4.727-2.893 3.92 3.92 0 0 0-2.9-4.723 3.92 3.92 0 0 0-4.727 2.893"
      />
      <path
        fill="#3C3F42"
        d="M27.92 45.198c-.308.199-.69.15-.855-.104-.164-.256-.047-.623.26-.82.308-.198.692-.15.856.105.164.256.048.623-.261.82m-1.627-2.394c-.572.272-1.218.108-1.443-.365-.227-.474.053-1.078.624-1.35.57-.271 1.217-.108 1.443.365.226.475-.053 1.079-.624 1.35m2.789 1.13a2.7 2.7 0 0 0-1.997-3.254 2.7 2.7 0 0 0-3.257 1.993 2.7 2.7 0 0 0 1.999 3.253 2.697 2.697 0 0 0 3.255-1.992M25.619 36.088c-.107.685 1.092 1.115 2.443 1.295 1.208.162 2.371.428 2.67-.445.614-1.795-4.791-2.914-5.113-.85M13.88 30.386c-.306.87.771 1.383 1.821 2.006 1.172.695 2.378 1.1 2.72.497 1.026-1.82-3.909-4.292-4.54-2.503"
      />
      <path
        fill="#543B2F"
        d="M29.15 49.222c-.138.484-.983.666-1.887.407-.906-.26-1.527-.86-1.388-1.344.138-.483.983-.666 1.889-.407.904.258 1.524.861 1.386 1.344M10.35 42.174c-.192.465-1.052.552-1.922.195s-1.421-1.025-1.23-1.49 1.052-.553 1.923-.195c.87.358 1.42 1.026 1.229 1.49"
      />
      <path
        fill="#AB1700"
        d="M20.144 47.016c.396 1.1-.853 2.435-3.16 1.607-2.304-.828-2.257-2.739-1.411-3.25.486-.29 1.188.194 2.358.615 1.173.42 2.016.483 2.213 1.028"
      />
      <path
        fill="#fff"
        d="M19.761 47.052c-.069.193-.973.045-2.02-.331-1.045-.376-1.837-.837-1.768-1.03.07-.193.901.159 1.946.534 1.047.377 1.912.634 1.842.827"
      />
      <path
        fill="#FD6C6C"
        d="M15.652 47.421c.278.35.74.689 1.438.94.784.281 1.415.308 1.877.187-.872-1.77-2.385-1.51-3.315-1.127"
      />
    </g>
  </svg>
)
export default SvgSeedHello
