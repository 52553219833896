import { useTheme } from '@/Hooks'
import ModalContentDeleteConfirmation from '../../Modals/ModalContentDeleteConfirmation'
import IconButton from '../../Buttons/IconButton'
import { LineLock, LineTrash, LineUnlock } from '@/Assets/icons/components'
import Switch from '@/Components/Controls/Switch'
import { contactItems, linkItems, socialItems } from '@/contents'
import { useEffect, useMemo, useRef, useState } from 'react'
import useEditContent from '@/Hooks/useEditContent'
import formatContentUrl from '@/Utils/formatContentUrl'
import { isValidUrl } from '@/Utils/validators'
import Content from './components/Content'
import formatContentValue from '@/Utils/formatContentValue'
import { Panel } from '../PanelWrapper/Panel'
import IconSwitch from '@/Components/IconSwitch'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const Header = ({
  deleteContent,
  type,
  contentId,
  isPrivate,
  setIsPrivate,
  enabled,
  setEnabled,
  onDismiss,
}) => {
  const { theme } = useTheme()
  return (
    <div className="flex flex-row items-center grow">
      <ModalContentDeleteConfirmation
        onDismiss={onDismiss}
        deleteContent={deleteContent}
        type={type}
        contentId={contentId}
      >
        {setIsOpen => (
          <IconButton
            onClick={() => setIsOpen(true)}
            icon={<LineTrash color={theme.colors.black} />}
            className="mr-3"
          />
        )}
      </ModalContentDeleteConfirmation>
      {type === 'contact' && (
        <IconSwitch
          value={isPrivate}
          left={{
            icon: <LineLock color={theme.colors.black} />,
            condition: true,
            action: () => setIsPrivate(true),
          }}
          right={{
            icon: <LineUnlock color={theme.colors.black} />,
            condition: false,
            action: () => setIsPrivate(false),
          }}
        />
      )}
      <div className="grow flex flex-row justify-end mr-3">
        <Switch value={enabled} onChange={setEnabled} />
      </div>
    </div>
  )
}
const contents = {
  contact: contactItems,
  social: socialItems,
  link: linkItems,
}
const PanelEditContent = ({ item, type, children: button }) => {
  const ref = useRef()
  const { t } = useTranslation()
  const contentItem = contents[type].find(
    content => content.type === item.type || content.label === item.icon,
  )
  const {
    updateContact,
    updateLink,
    thumbnail,
    setThumbnail,
    isFastLink,
    setIsFastLink,
  } = useEditContent()
  const [value, setValue] = useState(item.value ?? '')
  const [label, setLabel] = useState(item.label ?? '')
  const [contentURL, setContentURL] = useState(item.url ?? '')
  const [isPrivate, setIsPrivate] = useState(item.private)
  const [enabled, setEnabled] = useState(item.enabled)
  const [platform, setPlatform] = useState(item.platform || null)
  const [isIntegrated, setIsIntegrated] = useState(item.isIntegrated || false)
  const [isOpen, setIsOpen] = useState(false)
  const updatedContact = useMemo(() => {
    return { ...item, label, private: isPrivate, value, enabled, thumbnail }
  }, [enabled, isPrivate, item, label, thumbnail, value])
  const updatedLink = useMemo(() => {
    if (typeof value === 'string') {
      return {
        ...item,
        label,
        private: isPrivate,
        value: value.trim().toLowerCase(),
        enabled,
        url: formatContentUrl(contentURL, value, platform, contentItem),
        thumbnail,
        ...(contentItem.label === 'Music' || contentItem.label === 'Video'
          ? { platform, isIntegrated }
          : {}),
        ...((type === 'social' || type === 'link') && { isFastLink }),
      }
    }
  }, [
    contentItem,
    contentURL,
    enabled,
    isFastLink,
    isIntegrated,
    isPrivate,
    item,
    label,
    platform,
    thumbnail,
    type,
    value,
  ])
  const onSubmit = async () => {
    const isValid = contentItem.validate ? contentItem.validate(value) : true
    if ((!isValid && !contentURL) || (contentURL && !isValidUrl(contentURL))) {
      return toast.error(t('toastAddContent.error'))
    }
    if (type === 'contact') {
      await updateContact(updatedContact, ref)
    }
    if (type === 'social' || type === 'link') {
      await updateLink(updatedLink, ref)
    }
    setIsOpen(false)
  }
  useEffect(() => {
    setLabel(item.label)
    setContentURL(
      (!type && contentItem?.label !== 'Site') || type === 'social'
        ? item.url
        : '',
    )
    setValue(item.value)
    setEnabled(item.enabled)
    setIsPrivate(item.private || false)
  }, [item, type, contentItem?.label])
  useEffect(() => {
    setThumbnail(item.thumbnail || null)
    setIsFastLink(item.isFastLink || false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {button(setIsOpen)}
      <Panel
        title={t(`edit_profil.contents.edit_content_panel_titles.${type}`)}
        isOpen={isOpen}
        onDismiss={() => {
          setValue(item.value)
          setLabel(item.label)
          setEnabled(item.enabled)
          setIsPrivate(item.private || false)
          setIsOpen(false)
        }}
        fullscreen
        childrenClassName="flex-1"
        actions={[
          {
            label: t('profilepage.galerie.edit_photo.save'),
            onClick: onSubmit,
          },
        ]}
      >
        <Header
          type={type}
          contentId={item.id}
          isPrivate={isPrivate}
          setIsPrivate={setIsPrivate}
          enabled={enabled}
          setEnabled={setEnabled}
          onDismiss={() => setIsOpen(false)}
        />
        <Content
          valueLabel={contentItem.valueLabel}
          type={item.type}
          icon={contentItem.icon}
          label={contentItem.label}
          placeholderTitle={contentItem.placeholderTitle}
          placeholder={contentItem.placeholder}
          thumbnail={thumbnail}
          setThumbnail={setThumbnail}
          contentLabel={label}
          setContentLabel={setLabel}
          contentURL={contentURL}
          setContentURL={setContentURL}
          value={value}
          setValue={v => setValue(formatContentValue(v, type))}
          platform={platform}
          setPlatform={setPlatform}
          isIntegrated={isIntegrated}
          setIsIntegrated={setIsIntegrated}
          isFastLink={isFastLink}
          setIsFastLink={setIsFastLink}
        />
      </Panel>
    </>
  )
}
export default PanelEditContent
