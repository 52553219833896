const yield_explanation_page = {
  yield_explanation_companion: 'Fonctionnement du compagnon',
  title: {
    main_text:
      'Gagnez des <color>{{1}}</color> et des <color>{{2}}</color> avec votre compagnon',
    1: 'ressources',
    2: 'tickets',
  },
  subtitle:
    'Augmentez le niveau de votre compagnon pour gagner toujours plus de ressources et de tickets !',
  explanations: {
    title_drop: 'Prenez soin de votre compagnon',
    description_drop:
      "Lorsque vous jouez à des jeux, votre compagnon utilise de l'énergie. Vous pouvez l'arroser pour qu'il puisse continuer à jouer.",
    title_gains: 'Votre compagnon travaille pendant votre absence',
    description_gains:
      "Chaque jour, votre compagnon travaille pour vous. Vous pouvez récolter les récompenses qu'il a gagnées.",
    title_evolve: 'Faites évoluer votre compagnon',
    description_evolve:
      'En faisant évoluer votre compagnon, vous augmentez ses récompenses. Vous pouvez faire évoluer votre compagnon en lui donnant des pièces.',
  },
}


export default yield_explanation_page
