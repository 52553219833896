const publication_modal = {
  title: 'Publication',
  title2: 'Edit a publication',
  write_publish: 'Write a post...',
  hashtags: 'Add a hashtag',
  publish: 'Publish',
  description_price: 'Description cost',
  confirm: 'Confirm',
}


export default publication_modal
