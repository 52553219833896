const tooltip = {
  lock_editing: 'Bloquear edición',
  lock_editing_tip:
    'Al bloquear este campo, su miembro ya no podrá editar esta información por sí mismo',
  unlock_editing: 'Desbloquear edición',
  unlock_editing_tip:
    'Al desbloquear este campo, su miembro podrá editar esta información por sí mismo',
}

export default tooltip
