export const infiniteHistoryScrollOptions = {
  forceRefetch: ({ currentArg, previousArg }) => {
    return currentArg?.lastDate !== previousArg?.lastDate
  },
  serializeQueryArgs: ({ endpointName }) => {
    return endpointName
  },
  merge: (currentState = [], incomingState = [], { arg }) => {
    const shouldNotKeepPreviousHistoricals = currentState.some(
      historical => historical.gameId !== arg.gameId,
    )
    return [
      ...(shouldNotKeepPreviousHistoricals ? [] : currentState),
      ...incomingState.filter(
        ({ date }) => !currentState.find(p => p.date === date),
      ),
    ].sort((a, b) => b.date - a.date)
  },
}
