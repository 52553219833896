import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineHome = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M20.04 9.719a.75.75 0 0 0-1.5 0zm-14.58 0a.75.75 0 1 0-1.5 0zm9.053 10.988-.172-.73zm-5.026 0 .172-.73zm5.341-15.693-.532.529zm5.64 6.744a.75.75 0 1 0 1.064-1.057zM9.172 5.014l.532.529zm-6.704 5.687a.75.75 0 1 0 1.064 1.057zm7.25 7.62-.737-.14zm.02-.104.737.139zm4.524 0-.737.139zm.02.103.737-.138zm-.29 2.232-.677-.322zm-.794-.077a.75.75 0 0 0 1.354.645zm-3.19.077-.677.322zm-.56.568a.75.75 0 0 0 1.354-.645zm1.913-4.677-.2-.723zm1.278 0 .2-.723zm5.901-6.724v4.918h1.5V9.72zM5.46 14.637V9.72h-1.5v4.918zm8.88 5.34a10.2 10.2 0 0 1-4.68 0l-.346 1.46a11.7 11.7 0 0 0 5.372 0zm-4.68 0c-2.457-.58-4.2-2.79-4.2-5.34h-1.5c0 3.24 2.214 6.058 5.354 6.8zm5.026 1.46c3.14-.742 5.354-3.56 5.354-6.8h-1.5c0 2.55-1.743 4.76-4.2 5.34zm-.39-15.894 6.172 6.215 1.064-1.057-6.171-6.215zM8.64 4.486 2.468 10.7l1.064 1.057 6.172-6.215zm6.722 0c-.652-.657-1.193-1.204-1.68-1.577-.502-.387-1.035-.659-1.681-.659v1.5c.183 0 .397.064.768.348.387.298.847.758 1.528 1.445zM9.704 5.543c.681-.687 1.14-1.147 1.528-1.445.37-.284.585-.348.768-.348v-1.5c-.646 0-1.178.272-1.682.659-.486.373-1.027.92-1.679 1.577zm.752 12.916.019-.103L9 18.079l-.02.103zm3.07-.103.018.103 1.475-.277-.02-.103zm-.211 1.874-.117.245 1.354.645.117-.246zm-3.984.644.117.246 1.354-.645-.117-.245zm4.214-2.415c.112.6.031 1.22-.23 1.77l1.354.645c.399-.837.52-1.78.35-2.692zm-4.564-.277a4.4 4.4 0 0 0 .35 2.692l1.354-.644a2.9 2.9 0 0 1-.23-1.771zm2.58-1.017c.287-.08.59-.08.877 0l.401-1.445a3.14 3.14 0 0 0-1.678 0zM15 18.08a3.02 3.02 0 0 0-2.16-2.36l-.4 1.446c.554.154.978.614 1.086 1.19zm-4.524.277a1.52 1.52 0 0 1 1.087-1.19l-.401-1.446A3.02 3.02 0 0 0 9 18.079z"
        />
      </g>
    </svg>
  )
}
export default SvgLineHome
