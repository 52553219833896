import { LineMultipleBubble } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import styled, { css } from 'styled-components'
import ModalImageDeleteConfirmation from './Modals/ModalImageDeleteConfirmation'
import ModalImageFullScreen from './Modals/ModalImageFullScreen/ModalImageFullScreen'
import { TouchableOpacity } from './Buttons/TouchableOpacity'
import PanelAddImageToGallery from './Panels/PanelAddImageToGallery/PanelAddImageToGallery'

const Images = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${'-' + theme.space.xxs};
    margin-top: ${theme.space.xs};
  `}
`

const ImageWrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    width: 33.333%;
    aspect-ratio: 1;
    padding: ${theme.space.xxs};
    position: relative;
  `}
`

const StyledImage = styled.img`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    aspect-ratio: 1;
    border-radius: ${theme.space.std};
    background: ${theme.colors.grey1};
  `}
`

const RNView = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
  `}
`

const ImagePreview = ({ image, ...props }) => {
  const { theme } = useTheme()
  const isArray = typeof image.src !== 'string'
  const multiple = isArray && image.src?.length > 1
  return (
    <ImageWrapper {...props}>
      {multiple && (
        <RNView className="absolute top-2 right-2 rounded-full z-10 p-1 opacity-90">
          <LineMultipleBubble color={theme.colors.grey2} />
        </RNView>
      )}
      <StyledImage src={isArray ? image.src?.[0] : image.src} />
    </ImageWrapper>
  )
}

const Gallery = ({
  gallery = [],
  editable = false,
  onRefetch = () => null,
  profile,
}) => {
  return (
    <Images>
      {gallery.map((image, index) => {
        return editable ? (
          <ModalImageDeleteConfirmation selectedImage={image}>
            {setIsodalImageDeleteOpen => (
              <PanelAddImageToGallery key={image.id} selectedImage={image}>
                {setIsPanelAddImageToGalleryOpen => (
                  <ImagePreview
                    image={image}
                    onClick={() => setIsPanelAddImageToGalleryOpen(true)}
                    onLongPress={() => setIsodalImageDeleteOpen(true)}
                  />
                )}
              </PanelAddImageToGallery>
            )}
          </ModalImageDeleteConfirmation>
        ) : (
          <ModalImageFullScreen
            key={image.id}
            scrollIndex={index.toString()}
            profile={profile}
            gallery={gallery}
            onRefetch={onRefetch}
            multiple={typeof image.src !== 'string'}
          >
            {setIsOpen => (
              <ImagePreview image={image} onClick={() => setIsOpen(true)} />
            )}
          </ModalImageFullScreen>
        )
      })}
    </Images>
  )
}

export default Gallery
