const addMultipleResourceGainsByUid = build =>
  build.mutation({
    query: ({ uid, resourceGains, quest = [] }) => {
      const queryParams = quest.length ? `?quest=${quest.join('-')}` : ''
      return {
        url: `/resource-gains/${uid}/multiple${queryParams}`,
        method: 'POST',
        body: { resourceGains },
      }
    },
    invalidatesTags: ['AvailableResources', 'ResourcesGained', 'Progress'],
  })

export default addMultipleResourceGainsByUid
