const previewpage = {
  title_tab_bottom: 'Preview',
  insight: 'Profile preview',
  my_contact: 'My contacts',
  aboutme: 'About me',
  aboutus: 'About us',
  activated_account: 'This profile is not yet activated',
  my_networks: 'My networks',
  Statistics: 'Statistics',
  companion: 'Companion',
  news_tab_Publications: 'Publications',
  report_title: 'Report this user',
  report_title_page: 'Report',
  no_publications: 'No publications',
  see_this_profile: {
    seeProfileTitle: 'You are not allowed to see this profile',
    redirectedHomeTitle: 'You will be redirected to the home page...',
  },
  blocked_Profile: {
    text: 'This profile is blocked, If you want to access the full content, please unlock the profile.',
  },
  follow: {
    pending: 'Pending',
    add_contact: 'Add contact card',
    followed: 'Added',
    no_follow: 'Add',
    following: 'Add to my network',
    title1_page: 'My network',
    title2_page: 'Network',
  },
  newstab: {
    title_tab: 'News',
  },
  preview_stat_tabs: {
    title_tabs1: 'followed',
    title_tabs2: 'follower',
    title_tabs3: 'view',
  },
  reportModal: {
    title: 'Manage interactions with this user',
    subtitle:
      'By reporting this user, you help us maintain a respectful community by allowing us to moderate its publications. Also, if you want to stop seeing its contents, you can choose to block it.',
    blockedTitleBtn1: 'Block this user',
    blockedTitleBtn2: 'Unblock this user',
  },
  planted: 'planted',
}


export default previewpage
