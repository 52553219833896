import React from 'react'
import styled, { css } from 'styled-components'
import { getFieldValues } from './utils'
import { TemplateLogo } from './components/TemplateLogo'
import { Contact } from './styles'
import { LineMessage5, LinePhone } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import OptionItem from './OptionItem'

const title = 'Template 2'
const slug = 'template2'

const FullName = styled.span`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
`

const Website = styled.span`
  font-size: 9px;
  color: #fff;
  position: absolute;
  bottom: 0;
  margin-bottom: 6px;
`
const LeftCard = styled.div`
  ${({ color }) => css`
    background-color: ${color};
  `}
`
const VerticalBar = styled.div`
  ${({ color }) => css`
    background-color: ${color};
  `}
`

const SelectTemplate = ({ item, onSelected, selected }) => {
  return (
    <OptionItem
      onSelected={() => onSelected(item)}
      selected={selected}
      showSelected
    >
      <Template />
    </OptionItem>
  )
}

const Template = ({ businessCard, lang = 'en' }) => {
  const { theme } = useTheme()
  const {
    businessName,
    fullName,
    job,
    phone,
    email,
    website,
    address,
    addressComplement,
    zipCode,
    city,
    state,
    color = theme.colors.primary,
    logo,
  } = getFieldValues({ businessCard, lang })

  const renderCity = () => {
    if (city && state) {
      return `${city} | ${state}`
    }
    return city || state
  }

  return (
    <div className="border border-grey2 w-[350px] h-[220px]">
      <div className="flex relative bg-white h-full flex-row">
        <LeftCard
          color={color}
          className="relative h-full flex w-[35%] items-center justify-center"
        >
          <VerticalBar
            color={color}
            className="absolute -right-2 w-0.5 h-full"
          />
          <div className="h-[35%] items-center justify-center flex w-full p-2">
            <TemplateLogo
              businessCard={businessCard}
              clsxClassName="h-full w-full"
            />
          </div>
          {website && <Website>{website}</Website>}
        </LeftCard>
        <div className="flex-1 flex flex-col">
          <div className="p-4 flex flex-col">
            {fullName && <FullName>{fullName.toUpperCase()}</FullName>}
            {businessName && (
              <span className="text-grey3 text-xs">{businessName}</span>
            )}
            {job && <span className="text-grey3 text-xs">{job}</span>}
            <div className="mt-3 flex flex-col">
              {address && <Contact>{address}</Contact>}
              {addressComplement && <Contact>{addressComplement}</Contact>}
              {(city || state || zipCode) && (
                <Contact>
                  {renderCity()}
                  <br /> {zipCode}
                </Contact>
              )}
            </div>
          </div>
          <div className="mt-auto px-4 pb-1 w-full justify-around">
            {phone && (
              <div className="flex flex-row items-center">
                <div className="w-3 h-3 mr-1">
                  <LinePhone
                    className="w-full h-full"
                    color={color}
                    size={12}
                  />
                </div>
                <Contact>{phone}</Contact>
              </div>
            )}
            {email && (
              <div className="flex flex-row items-center">
                <div className="w-3 h-3 mr-1">
                  <LineMessage5
                    className="w-full h-full"
                    color={color}
                    size={12}
                  />
                </div>
                <Contact>{email}</Contact>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    height: ${theme.size.s};
    background: #ffffff;
    border: 0.5px solid ${theme.colors.grey2};
    overflow: hidden;
  `}
`

const Thumbnail = ({ color }) => {
  return (
    <ThumbnailWrapper>
      <LeftCard
        color={color}
        className="relative h-full w-[35%] items-center justify-center"
      >
        <VerticalBar
          color={color}
          className="absolute -right-1.5 w-0.5 h-full"
        />
      </LeftCard>
    </ThumbnailWrapper>
  )
}

export const template2 = {
  title,
  slug,
  component: Template,
  thumbnail: Thumbnail,
  selectTemplate: SelectTemplate,
}
