import { IconLabel } from '@/Components/Onboarding/Steps'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import React from 'react'
import { QRCodeButtons } from './QRCodeButtons'
import { useTranslation } from 'react-i18next'

const Header = () => {
  return (
    <div className="w-full flex tablet:static lg:absolute">
      <IconLabel childrenClassName="py-7 lg:pl-6" />
    </div>
  )
}
export const DownloadContent = props => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <Header />
      <div className="flex-1 flex flex-col justify-center minwtablet:px-16 xl:px-32">
        <div className="mb-4">
          <Text
            className=" text-2xl lg:text-4xl  xl:text-5xl"
            color={theme.colors.black}
          >
            {t('signin.img.create_your_digital')}
          </Text>
        </div>
        <Text
          regular
          className="mb-4 lg:mb-16 text-base lg:text-1xl xl:text-2xl"
          color={theme.colors.grey2}
        >
          {t('download_app.enjoy_a_better_mobile')}
        </Text>
        <QRCodeButtons {...props} />
      </div>
    </>
  )
}
