import * as React from 'react'
const SvgSubtract = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 14})`}>
      <path
        fill="#8E8E95"
        fillRule="evenodd"
        d="M13.667 7A6.667 6.667 0 1 1 .334 7a6.667 6.667 0 0 1 13.333 0m-6.666-.666c.368 0 .666.298.666.667v3.334a.667.667 0 0 1-1.333 0V7c0-.369.298-.667.667-.667m0-1.333a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgSubtract
