import { infiniteTreesScrollOptions } from '../infiniteTreesScrollOptions'

const getIndividualTreesByUid = build =>
  build.query({
    query: ({ uid, lastDate = '' }) => {
      return {
        url: `/trees/${uid}/get-trees?lastDate=${lastDate}&filter=individual`,
        method: 'GET',
      }
    },
    providesTags: ['IndividualTrees'],
    ...infiniteTreesScrollOptions,
  })

export default getIndividualTreesByUid
