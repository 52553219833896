import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineCloudOff = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M16.737 20h-10C4.12 20 2 17.851 2 15.2a4.8 4.8 0 0 1 3.164-4.529 6.018 6.018 0 0 1 .3-2.171M6.87 6.166C8.123 4.838 9.957 4 12 4c3.549 0 6.467 2.527 6.809 5.762A5.34 5.34 0 0 1 22 14.667c0 1.83-.91 3.444-2.296 4.405"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M3.53 2.47a.75.75 0 0 0-1.06 1.06zm16.94 19.06a.75.75 0 1 0 1.06-1.06zm-18-18 18 18 1.06-1.06-18-18z"
        />
      </g>
    </svg>
  )
}
export default SvgLineCloudOff
