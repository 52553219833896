import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

const CartShopContext = createContext()

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])
  const [isCartOpen, setIsCartOpen] = useState(false)

  const addItemToCart = useCallback(item => {
    setCart(currentCart => {
      const existingItem = currentCart.find(i => i.title === item.title)

      if (existingItem) {
        return currentCart.map(i =>
          i.title === item.title
            ? { ...i, quantity: i.quantity + item.quantity }
            : i,
        )
      } else {
        return [...currentCart, { ...item, quantity: item.quantity }]
      }
    })
  }, [])

  const removeItemFromCart = useCallback(title => {
    setCart(currentCart => currentCart.filter(i => i.title !== title))
  }, [])

  const clearCart = useCallback(() => {
    setCart([])
  }, [])

  const totalItems = useMemo(() => {
    return cart.reduce((total, item) => total + item.quantity, 0)
  }, [cart])

  const totalPrice = useMemo(() => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0)
  }, [cart])

  const value = useMemo(() => {
    return {
      cart,
      isCartOpen,
      setIsCartOpen,
      addItemToCart,
      removeItemFromCart,
      clearCart,
      totalItems,
      totalPrice,
    }
  }, [
    cart,
    isCartOpen,
    setIsCartOpen,
    addItemToCart,
    removeItemFromCart,
    clearCart,
    totalItems,
    totalPrice,
  ])

  return (
    <CartShopContext.Provider value={value}>
      {children}
    </CartShopContext.Provider>
  )
}

export function useCartShopContext() {
  return useContext(CartShopContext)
}
