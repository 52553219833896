const formatContentUrl = (contentURL, value, platform, item) => {
  const urlPattern = /^((http|https):\/\/)/
  const formattedContentURL = contentURL
    ? urlPattern.test(contentURL)
      ? contentURL
      : `https://${contentURL}`
    : null
  return item.label === 'Music' || item.label === 'Video'
    ? item.formatUri(platform || 'spotify', value.trim()).web
    : formattedContentURL || item.formatUri(value.toLowerCase().trim()).web
}

export default formatContentUrl
