const statistics = {
  title: 'Mes statistiques',
  active_profiles: 'Profils actifs',
  unique_views: 'Vues uniques',
  clicks: 'Clics',
  ctr: 'Taux de clics (CTR)',
  ctr_graph: 'CTR (taux de clics)',
  views: 'Vues',
  views_by_device: 'Vues par appareil',
  views_by_country: 'Vues par pays',
  top_links: 'Mon Top Liens',
  top_contacts: 'Mon Top Contacts',
  top_networks: 'Mon Top Réseaux',
  change_vs_last_month: 'vs le mois dernier',
  filter_week: 'Cette semaine',
  filter_month: 'Ce mois',
  filter_last_three_month: 'Les trois derniers mois',
  filter_last_six_month: 'Les six derniers mois',
  filter_year: 'Cette année',
  title_links: 'Titre',
  graph: 'Graphique',
  views_graph: 'vues',
  unique_views_graph: 'vues uniques',
  member_statistics: 'Statistiques',
}

export default statistics
