const verifyUserByUid = build =>
  build.mutation({
    query: uid => {
      return {
        url: `/users/verify/${uid}`,
        method: 'PUT',
      }
    },
  })

export default verifyUserByUid
