import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgPlayStore = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m4.137 3.434 8.74 8.571-8.675 8.65a2.1 2.1 0 0 1-.326-.613 2.5 2.5 0 0 1 0-.755V4.567c-.026-.395.065-.79.26-1.133m12.506 4.833-2.853 2.826L5.153 2.6c.28-.097.58-.124.873-.078.46.126.899.32 1.302.573l7.816 4.325c.508.273 1.003.56 1.498.847M13.79 12.93l2.839 2.788-2.058 1.146-6.28 3.49c-.52.287-1.041.561-1.55.874a1.8 1.8 0 0 1-1.471.195zm7.36-.925a1.91 1.91 0 0 1-.99 1.72l-2.346 1.302-3.087-3.022 3.1-3.074c.795.443 1.577.886 2.358 1.303a1.89 1.89 0 0 1 .965 1.771"
        />
      </g>
    </svg>
  )
}
export default SvgPlayStore
