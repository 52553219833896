const addSeedsByUid = build =>
  build.mutation({
    query: ({ uid, body }) => {
      return {
        url: `/trees/add-seeds/${uid}`,
        method: 'PUT',
        body,
      }
    },

    invalidatesTags: ['AvailableResources', 'CurrentTreeSeeds'],
  })

export default addSeedsByUid
