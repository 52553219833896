import styled from 'styled-components'

export const StyleOptionReport = styled.div`
  width: 400px;
  max-width: 100%;
  background: #ffffff;
  padding: 16px 0px;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(45, 45, 41, 0.12);
  margin: 5px auto;
  cursor: pointer;
  &:hover {
    background: rgba(159, 159, 159, 0.188);
    color: #080808;
  }
  span,
  button,
  textarea {
    font-family: 'work sans', sans-serif;
  }
`
export const WrapperReportText = styled.div`
  width: 400px;
  max-width: 100%;
  text-align: end;
  span,
  button,
  textarea,
  h4 {
    font-family: 'work sans', sans-serif;
  }
  h4 {
    text-align: center;
    margin-bottom: 16px;
  }
  textarea {
    width: 400px;
    height: 176px;
    border: 2px solid #f4f5f9;
    border-radius: 12px;
    padding: 12px 10px 0;
    resize: none;
    color: #1d3548;
  }
  button {
    background: #080808;
    padding: 12px;
    border-radius: 8px;
    color: #ffffff;
  }
`
