const accountPage = {
  subtitle: 'Information visible only to you.',
  paymentInformationTitle: 'Payment information',
  subTitlePayment: 'Access information about the registered card',
  titleManage: 'Stripe Portal',
  buttonTextStripe: 'Manage',
  dangerZoneTitle: 'Danger zone',
  passwordSubtitle: 'Change your current password',
  resetPassword: 'Reset my password',
}

export default accountPage
