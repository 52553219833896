const publication_modal = {
  title: 'Publication',
  title2: 'Modifier une publication',
  write_publish: 'Écrire une publication...',
  hashtags: 'Ajouter un hashtag',
  publish: 'Publier',
  description_price: 'Coût de la description',
  confirm: 'Confirmer',
}


export default publication_modal
