import { useSelector } from 'react-redux'
import { useEditBusinessCard } from '../../Contexts/EditBusinessCardContext'
import { useMemo, useState } from 'react'
import { Slider } from '@mui/material'
import ThumbnailPreview from '@/Components/ThumbnailPreview'
import { useTranslation } from 'react-i18next'

export const LogoBusinessCard = () => {
  const { currentUser } = useSelector(state => state.user)
  const {
    editBusinessCard,
    setEditBusinessCard,
    initialLogo: businessCardInitialLogo,
  } = useEditBusinessCard()
  const { t } = useTranslation()

  const initialLogo = useMemo(() => {
    return currentUser?.logoURL || businessCardInitialLogo || ''
  }, [currentUser?.logoURL, businessCardInitialLogo])

  const logoScale = useMemo(() => {
    return editBusinessCard.logoScale || 1
  }, [editBusinessCard])

  const [sliderValue, setSliderValue] = useState(Number(logoScale))

  return (
    <div className="w-full">
      <div className="flex-1 px-4">
        <div className="mb-6">
          <ThumbnailPreview
            width={200}
            height={200}
            thumbnail={editBusinessCard.logo}
            setThumbnail={value => {
              setEditBusinessCard({
                ...editBusinessCard,
                logo: value,
              })
            }}
            label={t('edit_profil.appearance.logo.preview_text')}
            initialValue={initialLogo}
            variant="secondary"
          />
        </div>
        <Slider
          title={t('edit_profil.appearance.logo.label_slider')}
          min={0.3}
          max={1}
          value={sliderValue}
          step={0.1}
          onChange={(e, value) => {
            setSliderValue(value)
            setEditBusinessCard({
              ...editBusinessCard,
              logoScale: value,
            })
          }}
        />
      </div>
    </div>
  )
}
