export default build =>
  build.query({
    query: ({ uid, code, body }) => {
      return {
        url: `/users/${uid}/activation/${code}`,
        method: 'POST',
        body,
      }
    },
    invalidatesTags: ['UserProducts'],
  })
