const hashtagsPage = {
  profiles: 'Profiles',
  images: 'Images',
  posts: 'Posts',
  nothing: 'There is nothing to display',
  result: ' result',
  results: ' results',
}


export default hashtagsPage
