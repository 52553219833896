import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgFingerPrint = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill={props.color || theme?.colors?.black || '#212121'}
      stroke={props.color || theme?.colors?.black || '#212121'}
      strokeWidth={0}
      viewBox="-2.4 -2.4 28.8 28.8"
      {...props}
    >
      <g transform={`scale(${1})`}>
        <g stroke="none">
          <path d="M4.16 20.176a.475.475 0 0 1-.439-.294 9.43 9.43 0 0 1 5-12.11.475.475 0 0 1 .364.875A8.464 8.464 0 0 0 4.6 19.521a.474.474 0 0 1-.259.62.5.5 0 0 1-.18.035zm14.544-2.648c1.52-.571 2.17-2.01 1.74-3.853-.686-2.943-4.361-6.932-9.215-6.447a.475.475 0 1 0 .094.944 8.02 8.02 0 0 1 8.198 5.72 2.143 2.143 0 0 1-1.15 2.747c-.853.32-1.816-.386-2.99-1.343a.474.474 0 1 0-.599.735c.911.743 2.005 1.636 3.158 1.636a2.2 2.2 0 0 0 .764-.14zm-3.785 4.917a.475.475 0 0 0-.237-.627c-3.015-1.361-5.06-4.272-5.078-6.135a1.35 1.35 0 0 1 .754-1.358 2.58 2.58 0 0 1 2.614.342.474.474 0 1 0 .493-.811 3.52 3.52 0 0 0-3.514-.389 2.29 2.29 0 0 0-1.296 2.225c.02 2.147 2.181 5.431 5.636 6.99a.475.475 0 0 0 .628-.237m4.019-1.766a.475.475 0 0 0-.344-.576c-2.603-.658-5.336-2.514-6.357-4.318a.475.475 0 1 0-.826.468c1.307 2.309 4.486 4.147 6.95 4.77a.5.5 0 0 0 .117.014.475.475 0 0 0 .46-.358m-9.97 2.22a.475.475 0 0 0 .141-.656c-3.359-5.215-2.254-8.739-.287-10.172 1.93-1.407 5.336-1.247 7.848 1.813a.474.474 0 1 0 .733-.601c-2.88-3.512-6.858-3.64-9.14-1.978-2.3 1.675-3.668 5.68.049 11.452a.474.474 0 0 0 .655.142zM4.85 4.397c1.323-1.234 8.372-4.568 13.677-.33a.474.474 0 1 0 .592-.74c-5.494-4.39-12.897-1.51-14.916.377a.474.474 0 1 0 .647.693m17.347 8.67a.475.475 0 0 0 .378-.555 10.525 10.525 0 0 0-9.397-8.332 10.52 10.52 0 0 0-11.054 6.63.475.475 0 0 0 .87.38c1.872-4.3 5.64-6.57 10.078-6.067a9.58 9.58 0 0 1 8.57 7.565.475.475 0 0 0 .466.387.5.5 0 0 0 .089-.009z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}
export default SvgFingerPrint
