import { parsePhoneNumber } from 'libphonenumber-js'

export const capitalize = string => {
  return string[0].toUpperCase() + string.slice(1)
}

export const formatNumber = input => {
  if (input == null) {
    return '-'
  }
  const number = Number(input)
  if (number > 1000000000) {
    return Math.round(number / 100000000) / 10 + 'Bn'
  }
  if (number > 1000000) {
    return Math.round(number / 100000) / 10 + 'M'
  }
  if (number > 1000) {
    return Math.round(number / 100) / 10 + 'K'
  }
  return number
}

export const formatPhoneNumber = phoneObj => {
  const code = phoneObj.code || ['FR', '+33']
  const country = code[0]
  const phoneNumber = phoneObj.number || '10000000'
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, country)

  return parsedPhoneNumber.formatInternational()
}
