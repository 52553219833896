const prompt_suggestion_page = {
  suggestion: 'Suggestions',
  landscape: {
    label: 'Paysage',
    a_lush_forest: 'Une forêt luxuriante baignée par la lumière du crépuscule.',
    a_deserted_beach: 'Une plage déserte avec des vagues douces.',
    a_starry_sky: "Un ciel étoilé au-dessus d'une montagne enneigée.",
    a_field_colorful: "Un champ de fleurs colorées sous un ciel d'été.",
  },
  animals: {
    label: 'Animaux',
    playful_kitten: 'Un chaton jouant avec des pelotes de laine.',
    elephant_at_sunset:
      'Un éléphant marchant dans la savane au coucher du soleil.',
    penguins_on_iceberg: 'Un groupe de pingouins sur un iceberg.',
    horse_in_wildflowers:
      'Un cheval galopant dans un champ de fleurs sauvages.',
  },
  architecture: {
    label: 'Architecture',
    medieval_castle_lake: 'Un château médiéval au bord d’un lac.',
    futuristic_skyscrapers:
      'Des gratte-ciel futuristes avec des jardins suspendus.',
    country_house_vineyards: 'Une maison de campagne entourée de vignobles.',
    ancient_temples_jungle: 'Des temples anciens dans une jungle luxuriante.',
  },
  scifi: {
    label: 'Science-fiction',
    spaceship_landing:
      'Un vaisseau spatial atterrissant sur une planète extraterrestre.',
    dystopian_city: 'Une ville dystopique avec des robots et des néons.',
    ancient_tech_explorers:
      'Des explorateurs découvrant une technologie ancienne sur une lune déserte.',
    contemplative_cyborg: 'Un cyborg contemplatif dans un jardin cybernétique.',
  },
  gastronomy: {
    label: 'Gastronomie',
    french_pastries:
      'Un assortiment de pâtisseries françaises dans une boulangerie vintage.',
    festive_table_world_cuisine:
      'Une table de fête avec des plats traditionnels du monde.',
    chef_decorating_cake: 'Un chef décorant minutieusement un gâteau.',
    colorful_cocktails:
      'Des cocktails colorés sur un bar de plage au coucher du soleil.',
  },
  fashion: {
    label: 'Mode',
    couture_fashion_show:
      'Un défilé de mode sur le thème de la haute couture dans un château.',
    vintage_outfit_photoshoot:
      'Une séance photo avec des tenues vintage dans une rue de Paris.',
    exotic_fashion_accessories:
      'Des accessoires de mode exotiques sur fond de jungle.',
    sewing_workshop:
      'Un atelier de couture avec des tissus et des esquisses de designs.',
  },
  nature_space: {
    label: 'Nature et Espace',
    aurora_glass_igloo: 'Une aurore boréale vue depuis un igloo en verre.',
    hidden_waterfall: 'Une cascade cachée dans une forêt enchantée.',
    solar_system_mars_view: 'Le système solaire vu depuis la surface de Mars.',
    grand_canyon_storm: 'Une vue du Grand Canyon pendant un orage dramatique.',
  },
}

export default prompt_suggestion_page
