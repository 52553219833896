import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLinePhone = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M15.165 21.634a7.7 7.7 0 0 0 2.67 0c1.417-.25 2.558-1.201 2.951-2.46l.083-.267q.13-.419.131-.854c0-1.272-1.138-2.303-2.541-2.303H14.54c-1.403 0-2.541 1.031-2.541 2.303q0 .435.13.854l.084.267c.393 1.259 1.534 2.21 2.951 2.46Zm0 0A15.04 15.04 0 0 1 3.117 9.584m0 0a7.7 7.7 0 0 1 0-2.669c.25-1.417 1.2-2.558 2.46-2.951l.266-.083a2.9 2.9 0 0 1 .854-.131C7.969 3.75 9 4.888 9 6.291v3.918c0 1.403-1.031 2.541-2.303 2.541q-.435 0-.854-.13l-.267-.084c-1.259-.393-2.21-1.534-2.46-2.951Z"
        />
      </g>
    </svg>
  )
}
export default SvgLinePhone
