import { useTranslation } from 'react-i18next'
import { ProLabel } from './ProLabel'
import LabeledSwitch from '@/Components/Controls/LabeledSwitch'

const SwitchIsIntegratedContent = ({
  isIntegrated,
  setIsIntegrated,
  label,
}) => {
  const { t } = useTranslation()
  return label !== 'Music' && label !== 'Video' ? null : (
    <>
      <ProLabel label="Integrer" />
      <LabeledSwitch
        text={t('profilepage.link.video_tab_show_on_profile')}
        value={isIntegrated}
        onChange={setIsIntegrated}
      />
    </>
  )
}

export default SwitchIsIntegratedContent
