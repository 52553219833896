import React, { createContext, useContext, useMemo } from 'react'

const FormContext = createContext()

export const FormProvider = ({ config, children }) => {
  const values = useMemo(() => {
    return config
  }, [config])

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>
}

export function useFormContext() {
  return useContext(FormContext)
}
