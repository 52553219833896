import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineAward = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M17.07 7.26V4.63q0-3-3-3h-5q-3 0-3 3v2.56m6.3 3.8.57.89c.09.14.29.28.44.32l1.02.26c.63.16.8.7.39 1.2l-.67.81c-.1.13-.18.36-.17.52l.06 1.05c.04.65-.42.98-1.02.74l-.98-.39a.86.86 0 0 0-.55 0l-.98.39c-.6.24-1.06-.1-1.02-.74l.06-1.05c.01-.16-.07-.4-.17-.52l-.67-.81c-.41-.5-.24-1.04.39-1.2l1.02-.26c.16-.04.36-.19.44-.32l.57-.89c.36-.54.92-.54 1.27 0M7.167 6.582l.166-.096C8.853 5.61 9.613 5.172 10.419 5a5.2 5.2 0 0 1 2.162 0c.806.172 1.566.61 3.086 1.486l.166.096c1.416.816 2.124 1.225 2.656 1.784a5.5 5.5 0 0 1 1.296 2.272c.215.75.215 1.583.215 3.248s0 2.498-.215 3.248a5.5 5.5 0 0 1-1.296 2.272c-.532.56-1.24.968-2.656 1.784l-.166.095c-1.52.877-2.28 1.315-3.086 1.487a5.2 5.2 0 0 1-2.162 0c-.806-.172-1.566-.61-3.086-1.486l-.166-.096c-1.416-.816-2.124-1.225-2.656-1.784a5.5 5.5 0 0 1-1.296-2.272C3 16.384 3 15.551 3 13.886s0-2.498.215-3.248A5.5 5.5 0 0 1 4.51 8.366c.532-.56 1.24-.968 2.656-1.784"
        />
      </g>
    </svg>
  )
}
export default SvgLineAward
