import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineRefresh = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M20 12c0 4.416-3.584 8-8 8s-7.112-4.448-7.112-4.448m0 0h3.616m-3.616 0v4M4 12c0-4.416 3.552-8 8-8 5.336 0 8 4.448 8 4.448m0 0v-4m0 4h-3.552"
        />
      </g>
    </svg>
  )
}
export default SvgLineRefresh
