import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: ${props => (props.profileDisplay === 'row' ? '100%' : '25%')};
    padding: ${theme.space.xxs};
  `}
`

export const ContentWrapper = styled.a`
  ${({ theme }) => css`
    flex: 1;
    border-color: ${props =>
      props.styles?.showBtnOutline
        ? props.styles?.btnOutlineColor || '#000000'
        : theme.colors.grey1};
    border-width: 1px;
    background-color: ${props =>
      props.styles?.hideBtnBackground ? 'transparent' : theme.colors.grey1};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    outline: none;
    color: ${theme.colors.grey3};

    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: none;
      opacity: 0.8;
      color: ${theme.colors.grey3};
    }
  `}
`

export const PaddingStd = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.std};
    flex-shrink: 0;
  `}
`

export const ItemInfos = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ItemLabel = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.semibold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${theme.colors.black};
  `}
`
export const ItemValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`
