import React from 'react'
import { useOnboardingContext } from '../OnBoardingContext'
import { useTheme } from '@/Hooks'
import { BannerAvatar } from '@/Components/Profile/BannerAvatar'
import { ProfileDescription } from '@/Components/Profile/ProfileDescription'
import { ProfileIdentity } from '@/Components/Profile/ProfileIdentity'
import { ModalImageTypeSelectionButton } from '@/Components/Modals/ModalImageTypeSelectionButton'
import { toast } from 'react-toastify'

const title = 'onboarding.step2.your_profile_is'
const subtitle = 'onboarding.step2.select_a_photo_and_a_banner'

export const PreviewProfile = () => {
  const { theme } = useTheme()
  const { values, setValues } = useOnboardingContext()

  return (
    <>
      <div>
        <BannerAvatar
          profile={values}
          avatarSize={theme.size.xl2}
          showEmptyLogo
          className="laptop:aspect-[3.20]"
          hideTreeBadge
        />
      </div>
      <div className="w-full flex justify-end mb-16 tablet:mb-12">
        <div>
          <ModalImageTypeSelectionButton
            editCurrentUser={values}
            setEditCurrentUser={setValues}
            onboarding
          />
        </div>
      </div>
      <ProfileIdentity user={values} className="px-0" statsVisible={false} />
      <ProfileDescription user={values} className="px-0 tablet:-mt-4" />
    </>
  )
}

const PreviewProfileSection = {
  title,
  subtitle,
  component: PreviewProfile,
  step: 4,
  section: 1,
  onSubmit: async ({ values, setCurrentUser, setLoading, t }) => {
    setLoading(true)
    try {
      const data = {
        businessMode: values.businessMode,
        about: values.about,
        firstName: values.firstName,
        lastName: values.lastName,
        birthday: new Date(values.birthday).getTime(),
        tags: values.tags,
        description: values.description,
        photoURL: values.photoURL,
        logoURL: values.logoURL,
        banner: values.banner,
        hashtags: values.hashtags,
        status: 'enabled',
      }
      await setCurrentUser(data)
    } catch (err) {
      console.error(err.message)
      toast.error(t('toastErrorDefault.error'))
      return { error: err }
    } finally {
      setLoading(false)
    }
  },
}

export default PreviewProfileSection
