import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineCoins = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 14})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.25 4.667c0-.967-1.828-1.75-4.083-1.75s-4.084.783-4.084 1.75m8.167 0V7c0 .595-.692 1.12-1.75 1.436-.662.199-1.466.314-2.333.314s-1.672-.116-2.334-.314C4.776 8.12 4.083 7.595 4.083 7V4.667m8.167 0c0 .595-.692 1.12-1.75 1.436-.662.198-1.466.314-2.333.314S6.495 6.3 5.833 6.103c-1.057-.316-1.75-.841-1.75-1.436"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.75 7v2.333c0 .595.692 1.12 1.75 1.437.662.198 1.466.313 2.333.313s1.672-.116 2.334-.313c1.057-.317 1.75-.842 1.75-1.437V8.75M1.75 7c0-.698.954-1.3 2.333-1.581M1.75 7c0 .595.692 1.12 1.75 1.436.662.198 1.466.314 2.333.314q.61 0 1.167-.072"
        />
      </g>
    </svg>
  )
}
export default SvgLineCoins
