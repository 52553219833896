import * as React from 'react'
const SvgSampleUserCardGrey = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 150})`}>
      <rect width={108} height={150} x={0.667} fill="#F4F4F4" rx={16} />
      <circle cx={54.266} cy={55} r={20} fill="#D9D9D9" />
      <ellipse cx={54.266} cy={99} fill="#D9D9D9" rx={32} ry={20} />
    </g>
  </svg>
)
export default SvgSampleUserCardGrey
