import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'
import { OptionItem } from '@/Components/OptionItem'
import { memo, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ModalSearchList from '../../ModalSearchList'

const styles = {
  coverImage: { height: 40, width: 40 },
}

const RNVideo = styled.video`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
  `}
`

const type = 'coverr'
const originType = 'video'
const title = 'modal.imageOriginSelection.choice5.title'
const subtitle = 'modal.imageOriginSelection.choice5.subtitle'

const icon = () => (
  <img
    style={styles.coverImage}
    src={require('@/Assets/images/coverr.webp')}
    alt="coverr"
  />
)

const useGetOnClick = ({ setIsParentOpen }) => {
  return () => setIsParentOpen(true)
}

const COVERR_API_KEY = '928e1aaa48781d717e301617deee1e5e'

const onFetch = async ({ query, page }) => {
  try {
    const url = `https://api.coverr.co/videos?api_key=${COVERR_API_KEY}&query=${query}&page=${page}&page_size=10&urls=${true}`
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(response.status)
    }
    const responseObject = await response.json()
    const results = responseObject.hits.map(hit => {
      return {
        thumbnail: hit.thumbnail,
        url: hit.urls.mp4,
        preview: hit.urls.mp4_preview,
      }
    })
    return results
  } catch (error) {
    console.warn(error)
    return []
  }
}

const getId = item => {
  const splittedUrl = item?.url.split('/') || []
  const slug = splittedUrl[splittedUrl.length - 2]
  const splittedSlug = slug.split('-')
  return splittedSlug[splittedSlug.length - 1]
}

const CoverrItem = ({
  index,
  focusedIndex,
  onDismiss,
  onSelected,
  cosmetic,
  ...item
}) => {
  const [loading, setLoading] = useState(false)
  const onLoadedData = () => {
    if (loading) {
      setLoading(false)
    }
  }
  return (
    <OptionItem
      onSelected={() => {
        onSelected(originType, item.preview, getId(item))
        onDismiss()
      }}
      cosmetic={cosmetic}
      id={getId(item)}
    >
      {loading && (
        <div className="absolute w-full h-full items-center justify-center bg-transparent z-20">
          <span>loading...</span>
        </div>
      )}

      <RNVideo
        className="h-full w-full"
        source={{ uri: item.preview }}
        poster={item.thumbnail}
        volume={0}
        resizeMode="cover"
        repeat
        muted
        onLoadedData={onLoadedData}
        onMouseEnter={async e => {
          try {
            await e.target.play()
          } catch (error) {
            console.error(error)
          }
        }}
        onMouseLeave={e => e.target.pause()}
        preload="none"
        loop
        playsInline
      >
        <source src={item.preview} type="video/mp4" />
      </RNVideo>
    </OptionItem>
  )
}

const ModalCoverr = memo(({ onSelected, ...props }) => {
  const { banner = [] } = useCosmetics()
  const cosmetic = banner.find(b => b.label === type)
  const [focusedIndex, setFocusedIndex] = useState(0)
  const onViewableItemsChanged = useCallback(({ viewableItems }) => {
    setFocusedIndex(viewableItems?.[0]?.index || 0)
  }, [])

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 100,
    minimumViewTime: 500,
  })

  return (
    <ModalSearchList
      {...props}
      onViewableItemsChanged={onViewableItemsChanged}
      viewabilityConfig={viewabilityConfig.current}
      renderItem={params => (
        <CoverrItem
          {...params}
          focusedIndex={focusedIndex}
          onSelected={onSelected}
          cosmetic={cosmetic}
        />
      )}
      onFetch={onFetch}
    />
  )
})

export const coverr = {
  type,
  originType,
  title,
  subtitle,
  icon,
  useGetOnClick,
  parent: ModalCoverr,
}
