import React from 'react'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'

const StyledSketchPicker = styled(SketchPicker)`
  padding: 0px !important;
  box-shadow: 0px 0px 0px !important;
  .flexbox-fix:nth-last-child(-n + 2) {
    padding: 0px !important;
    border-top: 0px !important;
    div {
      display: none;
    }
  }
`
const ColorPicker = ({ value, onChange }) => {
  return (
    <StyledSketchPicker color={value} onChange={color => onChange(color.hex)} />
  )
}

export default ColorPicker
