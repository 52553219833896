import styled, { css } from 'styled-components'
import { TouchableOpacity } from './Buttons/TouchableOpacity'
import Text from './Texts/Text'
import { backgroundIsLight } from '@/Utils/transformColor'
import { useTheme } from '@/Hooks'
import { LineArrowRight } from '@/Assets/icons/components'

const commonStyles = css`
  ${({ theme, bgColor, borderBottom, disabled, borderRadius }) => `
    border-bottom-color: rgba(142, 142, 149, 0.1);
    border-bottom-width: ${borderBottom ? borderBottom : '0px'};
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-radius: ${borderRadius ? borderRadius : theme.borderRadius.m};
    background: ${bgColor};
    opacity: ${disabled ? 0.5 : 1};
    display: flex;
  `}
`

const WrapperOption = styled(TouchableOpacity)`
  ${commonStyles}
`

const WrapperLinkOption = styled.a`
  text-decoration: none;
  ${commonStyles}
`

const WrapperTextIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin: 8px 16px;
    gap: ${theme.space.xs};
    padding: 8px 0;
  `}
`
const OptionText = styled(Text)`
  ${({ theme, bgColor, isDarkMode }) => css`
    font-weight: 400;
    font-size: ${theme.fontSize.std};
    color: ${backgroundIsLight(bgColor) || isDarkMode
      ? theme.colors.black
      : theme.colors.white};
  `}
`

export const Option = ({
  text,
  icon,
  toggle,
  arrow,
  color,
  type,
  ...props
}) => {
  const { theme, isDarkMode } = useTheme()
  const bgColor = color || theme.colors.grey1
  const Option = type === 'link' ? WrapperLinkOption : WrapperOption

  return (
    <Option {...props} bgColor={bgColor}>
      <WrapperTextIcon>
        {icon}
        <OptionText
          isDarkMode={isDarkMode}
          bgColor={bgColor}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {text}
        </OptionText>
        {toggle ? toggle : null}
        {arrow && (
          <div className="flex-row grow items-center justify-end self-center">
            <LineArrowRight size={12} />
          </div>
        )}
      </WrapperTextIcon>
    </Option>
  )
}
