import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineMore = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M5 10.75c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M19 10.75c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M12 10.75c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"
        />
      </g>
    </svg>
  )
}
export default SvgLineMore
