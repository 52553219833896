import React from 'react'
import { Button } from '@/Components/Buttons/Button'
import { useTranslation } from 'react-i18next'
import ModalImageTypeSelection from '@/Components/Modals/ModalImageTypeSelection'

export const ModalImageTypeSelectionButton = props => {
  const { editCurrentUser } = props
  const { t } = useTranslation()

  return (
    <ModalImageTypeSelection {...props}>
      {setIsOpen => (
        <Button
          className="self-end mt-3 h-12"
          label={
            editCurrentUser.photoURL &&
            editCurrentUser.banner &&
            editCurrentUser.logoURL
              ? t('edit_profil.informations.modify_photos')
              : t('edit_profil.informations.add_photos')
          }
          onClick={() => setIsOpen(true)}
        />
      )}
    </ModalImageTypeSelection>
  )
}
