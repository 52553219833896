import CertificationIcon from '@/Assets/icons/CertificationIcon'
import { useTheme } from '@/Hooks'
import { useGetFullName } from '@/Hooks/useGetFullName'
import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { TouchableOpacity } from './Buttons/TouchableOpacity'
import { Avatar } from './Profile/Avatar'
import Text from './Texts/Text'

const Wrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.std};
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-shrink: 0;
    align-items: center;
  `}
`

const InfosWrapper = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 ${theme.space.std};
    flex-shrink: 0;
  `}
`
const ProfileName = styled(Text)`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.semibold};
    margin-right: ${theme.space.xxs};
    text-overflow: ellipsis;
  `}
`

const EditIconWrapper = styled.div`
  ${({ theme }) => css`
    height: ${theme.size.std};
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const HeaderProfile = ({ icon: Icon, children, ...props }) => {
  const { currentUser } = useSelector(state => state.user)
  const { theme } = useTheme()
  const name = useGetFullName()

  return (
    <Wrapper {...props}>
      <Avatar profile={currentUser} size={theme.size.std} border={false} />
      <InfosWrapper>
        <div className="flex flex-row">
          <ProfileName>{name}</ProfileName>
          {currentUser.isCertified && (
            <CertificationIcon color={theme.colors.primary} />
          )}
        </div>
        {children}
      </InfosWrapper>
      <EditIconWrapper>
        <Icon color={theme.colors.black} />
      </EditIconWrapper>
    </Wrapper>
  )
}

export default HeaderProfile
