import React, { useState } from 'react'
import { Panel } from '../PanelWrapper/Panel'
import AddContentWrapper from '../../Dashboard/Profile/Contents/AddContentWrapper'
import ContentItems from '../../Dashboard/Profile/Contents/ContentItems'
import { socialItems } from '@/contents'
import { useTranslation } from 'react-i18next'

const PanelAddSocial = ({ children: button }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      {button(setIsOpen)}
      <Panel
        title={t('edit_profil.contents.add_content_panel_titles.social')}
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <AddContentWrapper>
          {search => (
            <ContentItems
              items={socialItems}
              search={search}
              type="social"
              onDismiss={() => setIsOpen(false)}
            />
          )}
        </AddContentWrapper>
      </Panel>
    </>
  )
}

export default PanelAddSocial
