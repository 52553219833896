const discover_companions = {
  title: 'Descubre los compañeros',
  no_companion: 'Ningún compañero',
  choose_companion: 'Elige tu compañero',
  choose_companion_subtitle:
    'Obtén recursos adicionales diariamente con tu compañero.',
  choose_companion_resources: 'Selecciona tus recursos',
  explanation_title_screen: 'Compañeros',
  explanation_title: {
    main_text: 'Gana <color>{{1}}</color> con tu compañero',
    1: 'recursos',
  },
  explanation_description:
    'Los compañeros te permiten ganar recursos diariamente. ¡También te acompañarán en mini-juegos para ayudarte a recolectar aún más recompensas!',
  explanations: {
    title_reward: 'Aún más recompensas',
    description_reward:
      'Tu compañero te acompaña diariamente en tus acciones ecológicas proporcionándote más recursos.',
    title_gains: 'Dobla tus ganancias jugando',
    description_gains:
      'Activar a tu compañero durante tus partidas te permitirá doblar la ganancia de todos los recursos recolectados!',
    title_evolve: 'Hazlo evolucionar',
    description_evolve:
      'Al hacer evolucionar a tu compañero, ¡aumentas las recompensas! Puedes mejorarlo con tus monedas',
  },
  get_your_companion: 'Obtén tu compañero',
  monthly: 'Mensual',
  yearly: 'Anual',
  by_month: '/mes',
  by_year: '/año',
}

export default discover_companions
