import { LineGallery } from '@/Assets/icons/components'
import InteractiveText from '@/Components/Controls/InteractiveText/InteractiveText'
import { CarouselPost } from '@/Components/Post/CarouselPost'
import { ToggleBookmark } from '@/Components/Post/ToggleBookmark'
import { useProfileContext } from '@/Components/Profile/Contexts/ProfileContext'
import { TopbarIdentity } from '@/Components/Profile/TopbarIdentity'
import Tags from '@/Components/Tags'
import { useTheme } from '@/Hooks'
import styled, { css } from 'styled-components'

const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    aspect-ratio: 1;
    border-radius: ${theme.borderRadius.l};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.space.std};
    margin-bottom: ${theme.space.std};
  `}
`
const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.space.xl};
  `}
`
const Image = styled.img`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    aspect-ratio: 1;
    border-radius: ${theme.borderRadius.s};
    border: 2px solid ${theme.colors.grey1};
    background: ${theme.colors.grey1};
  `}
`

const ImageFullScreen = ({ src, multiple }) => {
  const { theme } = useTheme()
  if (!src) {
    return <LineGallery color={theme.colors.black} />
  }
  if (multiple) {
    return <CarouselPost images={src} />
  }
  return <Image src={src} />
}

const ImageDetails = ({ image, multiple, setIsOpen }) => {
  const { isVisitor } = useProfileContext()

  return (
    <>
      <TopbarIdentity resource={image} />
      <ImageWrapper>
        <div className="relative">
          <ImageFullScreen src={image.src} multiple={multiple} />
          {!isVisitor && (
            <div className="absolute top-2 right-2">
              <ToggleBookmark data={image} type="picture" />
            </div>
          )}
        </div>
      </ImageWrapper>
      <Wrapper>
        <InteractiveText numberOfLines={2}>{image.description}</InteractiveText>
        <Tags
          tags={image?.tags}
          type="images"
          onDismiss={() => setIsOpen(false)}
        />
      </Wrapper>
    </>
  )
}

export default ImageDetails
