const activity_companion = {
  your_companion: 'Your companion',
  evolution: 'level {{evolution}}',
  vitality: '{{energyPercentage}}% vitality',
  inactiv: 'Inactiv',
  evolving: 'Currently evolving ...',
  empty: 'No companion found',
  description:
    "Increase your companion's performance by continually improving it.",
  upgrade: 'Improve',
  evolve: 'Evolve {{name}}',
  evolve_now: 'Evolve now',
  pending_evolve: 'Evolution in progress',
  description_evolving:
    'Your companion is inactive during its evolution. Pay to skip the remaining time',
  remaining_time:
    'Time remaining before evolution : {{time}} minute{{plurial}}',
  details_button: "Your companion's details",
  vitality_title: 'Energy',
  yield: 'Yield',
  yield_description: "Your companion's yield is optimal",
  yield_empty_description:
    'Your companion no longer has any energy. It is no longer able to bring you any yield',
  yield_description_75:
    'Recharge your companion by watering it to keep its yield optimal',
  yield_description_50:
    'Your companion yield is divided by 2. Recharge its energy by watering it to keep its yield optimal',
  yield_description_25:
    'Your companion energy is almost exhausted. Water it to continue to have a yield',
  yield_claim: 'Claim my yields',
  yield_claim_empty: 'No yield pending',
  yield_history: 'Yield history',
  learn_more: 'Learn more',
  yield_received: 'Received on {{date}}',
  yield_select_resources: 'Select new resources',
  yield_select_resources_cost: 'price :',
  obtained_coins_title: 'Coins',
  obtained_exp_title: 'Exp',
  obtained_ticket_title: 'Ticket',
  obtained_resource_subtitle: 'obtained',
  obtained_resources: '{{quantity}} {{resource}} obtained',
  water_companion: 'Water your companion',
  level: {
    evolution: 'Evolution cost: ',
    time_evolution: 'Evolution time: ',
    level2: 'Level ',
  },
  modal: {
    title: "Details of your companion's upcoming yields",
  },
  modal_activity: {
    owner: 'Owner',
    close: 'Close',
  },
  minute: 'minute',
  inactiv_title: 'Your companion is inactive',
  inactiv_description:
    'Your companion is deactivated. He is no longer able to bring you any yields.',
  inactiv_button: 'Reactivate my companion',
}

export default activity_companion
