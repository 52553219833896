import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgPlantedDrop = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 14})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeMiterlimit={10}
          d="M10.938 8.75c0 2.416-1.522 3.938-3.938 3.938S3.063 11.166 3.063 8.75c0-2.593 2.822-6.093 3.688-7.106a.328.328 0 0 1 .498 0c.866 1.013 3.689 4.513 3.689 7.106Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.406 8.969a1.97 1.97 0 0 1-1.969 1.969"
        />
      </g>
    </svg>
  )
}
export default SvgPlantedDrop
