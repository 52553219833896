import React, { useEffect, useState } from 'react'
import LabeledSwitch from '../Controls/LabeledSwitch'
import { LockedFieldSwitch } from '../LockedFieldSwitch'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useLockedField } from '@/Hooks/useLockedField'

export const LabeledSwitchLock = ({
  name,
  title,
  subtitle,
  value,
  onChange,
  disabled,
  type = 'user',
  ...props
}) => {
  const { editCurrentUser } = useEditCurrentUser()
  const { onLocked } = useLockedField()
  const [isLocked, setIsLocked] = useState(
    editCurrentUser.lockedFields?.[type]?.includes(name) || false,
  )
  useEffect(() => {
    setIsLocked(editCurrentUser.lockedFields?.[type]?.includes(name))
  }, [editCurrentUser.lockedFields, type, name])
  return (
    <div className="relative flex flex-col justify-center">
      <LabeledSwitch
        title={title}
        subtitle={subtitle}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <div className="absolute right-20 cursor-pointer">
        <LockedFieldSwitch
          variant="secondary"
          isLocked={isLocked}
          onChange={e =>
            onLocked({
              e: e,
              setIsLocked: setIsLocked,
              type: type,
              fieldName: name,
            })
          }
        />
      </div>
    </div>
  )
}
