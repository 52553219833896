import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLinestarIa = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12.877 16.168c.152-.414.76-.414.913 0l.417 1.134a.48.48 0 0 0 .294.285l1.176.401c.43.147.43.734 0 .88l-1.176.403a.48.48 0 0 0-.294.284l-.417 1.134c-.152.415-.76.415-.913 0l-.417-1.134a.48.48 0 0 0-.295-.284l-1.176-.402a.462.462 0 0 1 0-.88l1.176-.402a.48.48 0 0 0 .295-.285zM14.64 3.473c.231-.63 1.156-.63 1.387 0l.794 2.16c.074.202.239.36.448.432l2.24.766a.702.702 0 0 1 0 1.338l-2.24.766a.72.72 0 0 0-.448.432l-.794 2.16c-.231.63-1.156.63-1.388 0l-.793-2.16a.72.72 0 0 0-.448-.432l-2.241-.766a.702.702 0 0 1 0-1.338l2.24-.766a.72.72 0 0 0 .448-.432zM6.486 9.363a.545.545 0 0 1 1.028 0l.468 1.323a.55.55 0 0 0 .332.332l1.323.468a.545.545 0 0 1 0 1.028l-1.323.468a.55.55 0 0 0-.332.332l-.468 1.323a.545.545 0 0 1-1.028 0l-.468-1.323a.55.55 0 0 0-.332-.332l-1.323-.468a.545.545 0 0 1 0-1.028l1.323-.468a.55.55 0 0 0 .332-.332z"
        />
      </g>
    </svg>
  )
}
export default SvgLinestarIa
