import GifLevel1 from '@/Assets/Gifs/net-levelegif1.gif'
import GifLevel2 from '@/Assets/Gifs/net-levelegif2.gif'
import GifLevel3 from '@/Assets/Gifs/net-levelegif3.gif'
import GifLevel4 from '@/Assets/Gifs/net-levelegif4.gif'
import GifLevel5 from '@/Assets/Gifs/net-levelegif5.gif'
import NftLevel1 from '../../../Assets/images/NFT/net-levelicon1.webp'
import NftLevel2 from '../../../Assets/images/NFT/net-levelicon2.webp'
import NftLevel3 from '../../../Assets/images/NFT/net-levelicon3.webp'
import NftLevel4 from '../../../Assets/images/NFT/net-levelicon4.webp'
import NftLevel5 from '../../../Assets/images/NFT/net-levelicon5.webp'

const companions = {
  aloeVera: {
    name: 'Aloe Vera',
    avatar: NftLevel1,
    animated: GifLevel1,
    background: '#F6F1D7',
  },
  dianthus: {
    name: 'Dianthus',
    avatar: NftLevel2,
    animated: GifLevel2,
    background: '#F1DDEE',
  },
  nymphaea: {
    name: 'Nymphaea',
    avatar: NftLevel3,
    animated: GifLevel3,
    background: '#E0F2F4',
  },
  oak: {
    name: 'Oak',
    avatar: NftLevel4,
    animated: GifLevel4,
    background: '#F1DFDD',
  },
  palmatum: {
    name: 'Palmatun',
    avatar: NftLevel5,
    animated: GifLevel5,
    background: '#E1F3DB',
  },
}

export default companions
