import ColorPickerField from './ColorPickerField'

const CustomColor = ({ inputColor, setInputColor }) => {
  return (
    <div className="w-full">
      <ColorPickerField value={inputColor} onChange={setInputColor} />
    </div>
  )
}

export default CustomColor
