import * as React from 'react'
const SvgLineExit = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <g
        fill="#fff"
        clipPath="url(#lineExit_svg__a)"
        filter="url(#lineExit_svg__b)"
      >
        <path
          fillRule="evenodd"
          d="M10.109 4.326c-.539.502-.539 1.42-.539 3.253v8.837c0 1.834 0 2.752.539 3.253.538.502 1.362.351 3.009.05l1.774-.325c1.824-.334 2.736-.502 3.277-1.204.542-.703.542-1.719.542-3.751V9.557c0-2.032 0-3.048-.54-3.751-.543-.702-1.456-.87-3.28-1.203l-1.773-.325c-1.646-.302-2.47-.452-3.008.05zm1.746 6.207c.316 0 .572.28.572.627v1.675c0 .346-.256.627-.572.627s-.57-.28-.57-.627V11.16c0-.346.255-.627.57-.627"
          clipRule="evenodd"
        />
        <path d="M8.464 5.999c-1.568.002-2.385.038-2.906.585C5 7.17 5 8.112 5 9.998v4c0 1.885 0 2.827.558 3.413.52.546 1.338.583 2.906.586-.036-.5-.036-1.075-.036-1.698V7.697c0-.624 0-1.2.036-1.698" />
      </g>
      <defs>
        <clipPath id="lineExit_svg__a">
          <path fill="#fff" d="M24 0H0v24h24z" />
        </clipPath>
        <filter
          id="lineExit_svg__b"
          width={17.711}
          height={20.996}
          x={3}
          y={4}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0.778597 0 0 0 0 0.133834 0 0 0 0 0.138161 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3791_32091"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3791_32091"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.941176 0 0 0 0 0.564706 0 0 0 0 0.564706 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_3791_32091" />
        </filter>
      </defs>
    </g>
  </svg>
)
export default SvgLineExit
