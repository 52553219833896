import { useCallback, useRef } from 'react'
import useLiveRef from './useLiveRef'

const useRefHandler = effect => {
  const effectRef = useLiveRef(effect)
  const cleanupRef = useRef()
  const valueRef = useRef()
  const setup = useCallback(
    value => {
      if (Object.is(value, valueRef.current)) return
      if (typeof cleanupRef.current === 'function') cleanupRef.current()
      valueRef.current = value
      cleanupRef.current = effectRef.current(value)
    },
    [effectRef],
  )
  return setup
}

export default useRefHandler
