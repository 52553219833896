import { Spinner } from '@/Components/Spinner'
import { throttle } from 'lodash'
import React from 'react'

export const InfiniteScroll = ({ setPage, loading, setLoading, children }) => {
  const handleScroll = throttle(e => {
    if (loading) {
      return
    }

    const { scrollHeight, scrollTop, clientHeight } = e.target
    const offset = 200
    const topRange = scrollHeight - (scrollTop + offset)
    const bottomRange = scrollHeight - scrollTop
    const bottom = clientHeight >= topRange && clientHeight <= bottomRange
    if (!bottom) {
      return
    }
    setLoading(true)
    setPage(prev => prev + 1)
  }, 200)

  return (
    <div className="overflow-scroll h-full px-3" onScroll={handleScroll}>
      {children}
      {loading && <Spinner />}
    </div>
  )
}
