import { Option } from '@/Components/Option'
import Text from '@/Components/Texts/Text'
import React from 'react'
import styled, { css } from 'styled-components'

const Send = styled.div`
  ${({ theme }) => css`
    margin-top: ${({ title }) => (title ? theme.space.l : theme.space.std)};
  `}
`
const Title = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
    margin-left: ${theme.space.xs};
    margin-bottom: ${theme.space.s};
    height: ${theme.size.xs};
    font-weight: 500;
    font-size: ${theme.fontSize.xs};
    line-height: ${theme.fontSize.std};
    letter-spacing: 1px;
    display: flex;
  `}
`
const Options = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.secondary};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `}
`

const ShareBloc = ({ title, options }) => {
  return (
    <Send title={title}>
      {title && <Title>{title}</Title>}
      <Options>
        {options.map(({ text, ...props }, index) => (
          <Option
            key={text}
            text={text}
            borderBottom={options.length === index + 1 ? 0 : 1}
            borderRadius={0}
            {...props}
          />
        ))}
      </Options>
    </Send>
  )
}

export default ShareBloc
