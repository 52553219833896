import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineDownload = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="M4 12.798c0 4.42 3 8 8 8s8-3.58 8-8"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="m14.44 12.418-2.56 2.56-2.56-2.56m2.56-7.68v10.17"
        />
      </g>
    </svg>
  )
}
export default SvgLineDownload
