const homelogin = {
  account: 'Vous avez déjà un compte ?',
  login: 'Se connecter',
  start: 'Commencer',
  slide: 'FAITES GLISSER POUR EN SAVOIR PLUS',
  frame0: {
    title: 'La dernière de vos cartes de visite',
  },
  frame1: {
    title: 'Votre profil digital dans votre poche',
  },
  frame2: {
    title: 'Plantez des arbres grâce à vos publications',
  },
  frame3: {
    title: 'Transformez votre activité en cadeaux réels',
    voucher: 'coupon',
    members: 'membres',
    reservation: 'sur réservation',
    discount: "d'économie",
  },
  frame4: {
    title: 'Centralisez vos réseaux sociaux',
  },
}


export default homelogin
