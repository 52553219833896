import * as React from 'react'
const SvgFlagEs = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 28})`}>
      <g clipPath="url(#flagEs_svg__a)">
        <rect width={28} height={28} fill="#F93939" rx={14} />
        <path
          fill="#F93939"
          d="M35.467 0H3.733A3.733 3.733 0 0 0 0 3.733v20.534A3.733 3.733 0 0 0 3.733 28h31.734a3.733 3.733 0 0 0 3.733-3.733V3.733A3.733 3.733 0 0 0 35.467 0"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M0 7.467h39.2v13.067H0z"
          clipRule="evenodd"
        />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M16.8 11.615v4.704c0 1.306-1.255 2.352-2.8 2.352h-3.733c-1.542-.004-2.8-1.055-2.8-2.356V11.61c0-1.068.836-1.96 1.986-2.25.347-.97 1.415-.1 2.68-.1 1.273 0 2.334-.865 2.68.102 1.147.297 1.987 1.191 1.987 2.252"
          clipRule="evenodd"
        />
        <path
          fill="#CBCBCB"
          fillRule="evenodd"
          d="M16.8 13.066h1.867v5.6H16.8zm-11.2 0h1.867v5.6H5.6z"
          clipRule="evenodd"
        />
        <path
          fill="#1A47B8"
          fillRule="evenodd"
          d="M16.8 16.8h1.867v1.866H16.8zm-11.2 0h1.867v1.866H5.6z"
          clipRule="evenodd"
        />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M16.8 11.2h1.867v1.867H16.8zm-11.2 0h1.867v1.867H5.6z"
          clipRule="evenodd"
        />
        <path
          fill="#AF010D"
          fillRule="evenodd"
          d="M9.333 11.2H11.2V14H9.333zm3.734 3.734h1.866v2.8h-1.866z"
          clipRule="evenodd"
        />
        <path
          fill="#AE6A3E"
          fillRule="evenodd"
          d="M13.067 11.2h1.866V14h-1.866z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M9.333 14.934H11.2v2.8H9.333z"
          clipRule="evenodd"
        />
        <path
          fill="#AF010D"
          fillRule="evenodd"
          d="M11.2 11.2 9.333 9.333h5.6L13.067 11.2z"
          clipRule="evenodd"
        />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M11.2 7.467h1.867v1.866H11.2z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="flagEs_svg__a">
          <rect width={28} height={28} fill="#fff" rx={14} />
        </clipPath>
      </defs>
    </g>
  </svg>
)
export default SvgFlagEs
