import React from 'react'
import styled, { css } from 'styled-components'
import EllipsisBackGround from './EllipsisBackground'

export const Title = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.back};
`

export const Subtitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
    font-size: 13px;
  `}
`

const StyledCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.l} ${theme.space.m};
    overflow: hidden;
  `}
`

export const Card = ({ bg = 'empty', children, ...props }) => {
  return (
    <StyledCard {...props}>
      {bg === 'ellipsis' && <EllipsisBackGround />}
      {children}
    </StyledCard>
  )
}

export const BadgeResourceQuantity = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.m};
    border: 1px solid ${theme.colors.grey2};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px 4px 8px;
    align-self: flex-start;
  `}
`

export const OutlinedCard = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.std};
  `}
`
