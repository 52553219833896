import React from 'react'
import styled, { css } from 'styled-components'

const BackgroundIcon = styled.div`
  ${({ theme, color }) => css`
    width: ${theme.size.std};
    height: ${theme.size.std};
    background: ${color || theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.l};
    overflow: hidden;
    cursor: pointer;
  `}
`

const IconButton = ({ onClick, icon, ...props }) => {
  return (
    <div onClick={onClick} {...props}>
      <BackgroundIcon color={props.color}>{icon}</BackgroundIcon>
    </div>
  )
}

export default IconButton
