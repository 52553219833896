const quests_titles = {
  complete_welcome_quests: 'Completa todas tus misiones de bienvenida',
  complete_daily_quests: 'Completa todas tus misiones diarias',
  add_post: 'Añadir {{goal}} publicación{{es}}',
  add_cover_picture: 'Añadir {{goal}} imagen{{es}} de portada',
  add_profile_picture: 'Añadir {{goal}} imagen{{es}} de perfil',
  add_business_name: 'Añadir {{goal}} nombre{{plurial}} de negocio',
  add_job: 'Añadir tu trabajo',
  add_description: 'Añadir {{goal}} descripción{{es}}',
  add_social_content: 'Añadir {{goal}} contenido{{plurial}} social{{es}}',
  add_contact_content: 'Añadir {{goal}} contenido{{plurial}} de contacto',
  add_following: 'Seguir a {{goal}} usuario{{plurial}}',
  add_post_to_bookmark: 'Añadir {{goal}} publicación{{es}} a marcadores',
  add_image_to_bookmark:
    'Añadir {{goal}} imagen{{es}} de una galeria a marcadores',
  add_drop_to_post: 'Regar {{goal}} publicación{{es}}',
  get_view: 'Obtener {{goal}} vista{{plurial}}',
  get_follower: 'Obtener {{goal}} seguidor{{es}}',
  get_drop_to_post: 'Riega {{goal}} de tus publicaciones',
  watch_add: 'Ver {{goal}} anuncio{{plurial}}',
  gain_drop: 'Ganar {{goal}} gota{{plurial}}',
  gain_seed: 'Ganar {{goal}} semilla{{plurial}}',
  gain_coin: 'Ganar {{goal}} moneda{{plurial}}',
  get_sponsorship: 'Patrocinar {{goal}} usuario{{plurial}}',
  play_game: 'Juega un partido',
  use_coin: 'Gasta {{goal}} monedas',
  create_ai_image: 'Crear {{goal}} imagen{{es}} con IA',
  verify_account: 'Verificar tu cuenta',
  claim_daily_resources: 'Reclamar tus recursos diarios',
}


export default quests_titles
