const getBusinessCardById = build =>
  build.query({
    query: ({ cardId }) => {
      return {
        url: `/users/business-card/${cardId}`,
        method: 'GET',
      }
    },
  })

export default getBusinessCardById
