import getQrCodeImageById from './getQrCodeImageById'
import createQrCode from './createQrCode'
import updateQrCodeById from './updateQrCodeById'
import { api } from '@/Services/api'

export const productsApi = api.injectEndpoints({
  endpoints: build => ({
    createQrCode: createQrCode(build),
    getQrCodeImageById: getQrCodeImageById(build),
    updateQrCodeById: updateQrCodeById(build),
  }),
  overrideExisting: true,
})

export const {
  useCreateQrCodeMutation,
  useLazyGetQrCodeImageByIdQuery,
  useUpdateQrCodeByIdMutation,
} = productsApi
