import { infiniteHistoryScrollOptions } from '../infiniteHistoryScrollOptions'

const getTreesByUid = build =>
  build.query({
    query: ({ uid, lastDate = '' }) => {
      return {
        url: `/trees/${uid}/get-trees?lastDate=${lastDate}`,
        method: 'GET',
      }
    },
    providesTags: ['Trees'],
    ...infiniteHistoryScrollOptions,
  })

export default getTreesByUid
