import * as React from 'react'
const SvgStarEpic = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 41})`}>
      <g clipPath="url(#starEpic_svg__a)">
        <path
          fill="#FFC107"
          d="M11.81 12.196 7.39 9.365a.692.692 0 0 1-.128-1.078l1.66-1.66a.69.69 0 0 1 1.084.14l2.762 4.491c.363.62-.347 1.313-.96.938M7.947 25.815l-5.131.103a.69.69 0 0 0-.653.866l.597 2.268a.693.693 0 0 0 1.006.428L8.3 27.108c.625-.353.366-1.309-.353-1.293m11.919 8.715-1.125 4.997a.692.692 0 0 0 .672.853h2.347a.692.692 0 0 0 .668-.865l-1.221-4.997c-.182-.697-1.172-.688-1.341.012m9.156-22.334 4.419-2.831a.692.692 0 0 0 .128-1.078l-1.66-1.66a.69.69 0 0 0-1.084.14l-2.762 4.491c-.363.62.347 1.313.96.938m3.863 13.619 5.131.103a.69.69 0 0 1 .653.866l-.597 2.268a.695.695 0 0 1-1.006.428l-4.534-2.372c-.625-.353-.366-1.309.353-1.293"
        />
        <path
          fill="#FDD835"
          d="m21.682 2.952 4.206 9.594a2.2 2.2 0 0 0 1.819 1.309l10.247.919c1.159.168 1.621 1.59.78 2.409l-7.718 6.484a2.2 2.2 0 0 0-.737 2.147l2.243 10.503c.197 1.154-1.012 2.035-2.05 1.488l-8.943-5.238a2.2 2.2 0 0 0-2.225 0l-8.944 5.235c-1.034.544-2.247-.335-2.05-1.488l2.244-10.503a2.2 2.2 0 0 0-.738-2.147l-7.722-6.478c-.837-.816-.375-2.24.782-2.41l10.246-.918a2.2 2.2 0 0 0 1.82-1.31l4.205-9.593c.522-1.05 2.016-1.05 2.535-.003"
        />
        <path
          fill="#FFFF8D"
          d="m21.376 13.12-.712-7.069c-.028-.394-.11-1.069.522-1.069.5 0 .772 1.041.772 1.041l2.137 5.675c.806 2.16.475 2.9-.303 3.338-.894.5-2.213.109-2.416-1.916"
        />
        <path
          fill="#F4B400"
          d="m30.192 23.039 6.131-4.785c.303-.253.85-.656.413-1.115-.347-.363-1.285.16-1.285.16l-5.366 2.096c-1.6.553-2.662 1.372-2.756 2.403-.122 1.375 1.113 2.435 2.863 1.24"
        />
      </g>
      <defs>
        <clipPath id="starEpic_svg__a">
          <path fill="#fff" d="M0 .5h40v40H0z" />
        </clipPath>
      </defs>
    </g>
  </svg>
)
export default SvgStarEpic
