import {
  GoogleMyBusiness,
  Music,
  SocialAdress,
  SocialBehance,
  SocialCalendly,
  SocialCameo,
  SocialClubHouse,
  SocialDailymotion,
  SocialDiscord,
  SocialDribble,
  SocialEtsy,
  SocialFacebook,
  SocialGithub,
  SocialInstagram,
  SocialLine,
  SocialLinkedin,
  SocialMail,
  SocialMedium,
  SocialMsSkype,
  SocialMym,
  SocialOnlyfans,
  SocialOpensea,
  SocialPhone,
  SocialPinterest,
  SocialReddit,
  SocialSafari,
  SocialSignal,
  SocialSnapchat,
  SocialSoundcloud,
  SocialSpotify,
  SocialStrava,
  SocialSubstack,
  SocialTelegram,
  SocialTiktok,
  SocialTwitch,
  SocialTwitter,
  SocialViber,
  SocialVimeo,
  SocialWechat,
  SocialWhatsapp,
  SocialYoutube,
  Tripadvisor,
  Video,
} from '@/Assets/icons/components'
import { formatPhoneNumber } from '@/Utils/formaters'
import { getEmbedByPlatform } from '@/Utils/getEmbedByPlatform'
import { isValidUrl, validateEmail } from '@/Utils/validators'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React from 'react'

export const contactItems = [
  {
    type: 'phone',
    label: 'profilepage.contact.tel_option',
    icon: <SocialPhone size={32} />,
    placeholder: { code: ['FR', '+33'], number: '612345678' },
    valueLabel: 'items.phone',
    formatValue: formatPhoneNumber,
    validate: value => {
      return isValidPhoneNumber(value.number, value.code[0])
    },
    formatUri: value => {
      return {
        app: `tel:${formatPhoneNumber(value)}`,
        web: null,
      }
    },
  },
  {
    type: 'email',
    label: 'profilepage.contact.email_option',
    icon: <SocialMail size={32} />,
    placeholder: 'youremail@mail.com',
    valueLabel: 'items.mail',
    formatUri: value => {
      return {
        app: `mailto:${value}`,
        web: null,
      }
    },
    validate: value => {
      return validateEmail(value)
    },
  },
  {
    type: 'address',
    label: 'profilepage.contact.address_option',
    icon: <SocialAdress size={32} />,
    placeholder: 'items.address',
    formatUri: value => {
      return {
        app: `https://maps.google.com/maps?daddr=${value}`,
        web: `https://www.google.com/maps/search/?api=1&query=${value}`,
      }
    },
  },
  {
    label: 'Line',
    type: 'Line',
    icon: <SocialLine size={32} />,
    formatValue: value => `line.me/R/ti/p/${value}`,
    formatUri: value => {
      return {
        app: `line://ti/p/${value}`,
        web: `https://line.me/R/ti/p/${value}`,
      }
    },
  },
  {
    label: 'Whatsapp',
    type: 'Whatsapp',
    icon: <SocialWhatsapp size={32} />,
    placeholder: '+33123456789',
    valueLabel: 'items.phone',
    formatValue: value => `wa.me/${value}`,
    formatUri: value => {
      return {
        app: `whatsapp://send?phone=${value}`,
        web: `https://wa.me/${value}`,
      }
    },
    validate: value => {
      const regex =
        /^[+]?[(]?\d{1,4}[)]?[-\s.]?\d{1,3}[-\s.]?\d{1,4}[-\s.]?\d{1,4}$/
      return regex.test(value)
    },
  },
  {
    label: 'Skype',
    type: 'Skype',
    icon: <SocialMsSkype size={32} />,
    placeholder: 'items.skype',
    formatUri: value => {
      return {
        app: `skype:live:${value}`,
        web: 'https://www.skype.com/fr/features/skype-web/',
      }
    },
  },
  {
    label: 'Discord',
    type: 'Discord',
    icon: <SocialDiscord size={32} />,
    placeholder: 'pseudo#0000',
    valueLabel: 'items.discord',
    formatValue: value => `discordapp.com/users/${value}`,
    formatUri: value => {
      return {
        app: 'discord://',
        web: `https://www.discordapp.com/users/${value}`,
      }
    },
    validate: value => {
      const regex = /^[A-Za-z0-9_#-]{2,32}$/
      return regex.test(value)
    },
  },
  {
    label: 'Signal',
    type: 'Signal',
    icon: <SocialSignal size={32} />,
    placeholder: '+33123456789',
    valueLabel: 'items.phone',
    formatUri: value => {
      return {
        app: `sgnl://user?phone=${value}`,
        web: `https://signal.org/${value}`,
      }
    },
    validate: value => {
      const regex =
        /^[+]?[(]?\d{1,4}[)]?[-\s.]?\d{1,3}[-\s.]?\d{1,4}[-\s.]?\d{1,4}$/
      return regex.test(value)
    },
  },
  {
    label: 'Telegram',
    type: 'Telegram',
    icon: <SocialTelegram size={32} />,
    placeholder: 'items.skype',
    formatValue: value => `https://web.telegram.org/a/#${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://web.telegram.org/a/#${value}`,
      }
    },
  },
  {
    label: 'WeChat',
    type: 'WeChat',
    icon: <SocialWechat size={32} />,
    formatUri: value => {
      return {
        app: `weixin://dl/chat?${value}`,
        web: `https://web.wechat.com/${value}`,
      }
    },
  },
]

export const socialItems = [
  {
    label: 'LinkedIn',
    icon: <SocialLinkedin size={32} />,
    formatValue: value => `linkedin.com/in/${value}`,
    formatUri: value => {
      return {
        app: `linkedin://profile/${value}`,
        web: `https://www.linkedin.com/in/${value}`,
      }
    },
  },
  {
    label: 'Instagram',
    icon: <SocialInstagram size={32} />,
    formatValue: value => `instagram.com/${value}`,
    formatUri: value => {
      return {
        app: `instagram://user?username=${value}`,
        web: `https://www.instagram.com/${value}`,
      }
    },
  },
  {
    label: 'Facebook',
    icon: <SocialFacebook size={32} />,
    formatValue: value => `facebook.com/${value}`,
    formatUri: value => {
      return {
        // TODO: trouver le bon schema uri :
        // app: `fb://profile?name=${value}`,
        app: `https://www.facebook.com/${value}`,
        web: `https://www.facebook.com/${value}`,
      }
    },
  },
  {
    label: 'Twitter',
    icon: <SocialTwitter size={32} />,
    formatValue: value => `twitter.com/${value}`,
    formatUri: value => {
      return {
        app: `twitter://user?screen_name=${value}`,
        web: `https://www.twitter.com/${value}`,
      }
    },
  },

  {
    label: 'Snapchat',
    icon: <SocialSnapchat size={32} />,
    formatValue: value => `snapchat.com/add/${value}`,
    formatUri: value => {
      return {
        app: `snapchat://add/${value}`,
        web: `https://www.snapchat.com/add/${value}`,
      }
    },
  },
  {
    label: 'Tiktok',
    icon: <SocialTiktok size={32} />,
    formatValue: value => `tiktok.com/@${value}`,
    formatUri: value => {
      return {
        app: `https://www.tiktok.com/@${value}`,
        web: `https://www.tiktok.com/@${value}`,
      }
    },
  },
  {
    label: 'Spotify',
    icon: <SocialSpotify size={32} />,
    placeholder: 'artistId',
    formatValue: value => `open.spotify/artist/${value}`,
    formatUri: value => {
      return {
        app: `https://open.spotify/artist/${value}`,
        web: `https://open.spotify/artist/${value}`,
      }
    },
  },
  {
    label: 'Youtube',
    icon: <SocialYoutube size={32} />,
    formatValue: value => `youtube.com/${value}`,
    formatUri: value => {
      return {
        app: `youtube://www.youtube.com/${value}`,
        web: `https://www.youtube.com/${value}`,
      }
    },
  },
  {
    label: 'Twitch',
    icon: <SocialTwitch size={32} />,
    formatValue: value => `twitch.tv/${value}`,
    formatUri: value => {
      return {
        app: `twitch://stream/${value}`,
        web: `https://www.twitch.tv/${value}`,
      }
    },
  },
  {
    label: 'Soundcloud',
    icon: <SocialSoundcloud size={32} />,
    placeholder: 'https://soundcloud.com/',
    formatValue: value => `soundcloud.com/${value}`,
    formatUri: value => {
      return {
        app: `soundcloud://users:${value}`,
        web: `https://soundcloud.com/${value}`,
      }
    },
  },
  {
    label: 'Pinterest',
    icon: <SocialPinterest size={32} />,
    formatValue: value => `pinterest.com/${value}`,
    formatUri: value => {
      return {
        app: `pinterest://user/${value}`,
        web: `https://www.pinterest.com/${value}`,
      }
    },
  },

  {
    label: 'Reddit',
    icon: <SocialReddit size={32} />,
    formatValue: value => `reddit.com/user/${value}`,
    formatUri: value => {
      return {
        app: `https://www.reddit.com/user/${value}`,
        web: `https://www.reddit.com/user/${value}`,
      }
    },
  },

  {
    label: 'Cameo',
    icon: <SocialCameo size={32} />,
    formatValue: value => `cameo.com/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://www.cameo.com/${value}`,
      }
    },
  },
  {
    label: 'Substack',
    icon: <SocialSubstack size={32} />,
    formatValue: value => `${value}.substack.com/`,
    formatUri: value => {
      return {
        app: null,
        web: `https://${value}.substack.com/`,
      }
    },
  },
  {
    label: 'Clubhouse',
    icon: <SocialClubHouse size={32} />,
    placeholder: 'club',
    formatValue: value => `joinclubhouse.com/club/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://www.joinclubhouse.com/club/${value}`,
      }
    },
  },
  {
    label: 'OnlyFans',
    icon: <SocialOnlyfans size={32} />,
    formatValue: value => `onlyfans.com/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://onlyfans.com/${value}`,
      }
    },
  },
  {
    label: 'MYM',
    icon: <SocialMym size={32} />,
    formatValue: value => `mym.fans/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://mym.fans/${value}`,
      }
    },
  },
  {
    label: 'Opensea',
    icon: <SocialOpensea size={32} />,
    placeholder: 'collection',
    formatValue: value => `opensea.io/collection/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://opensea.io/collection/${value}`,
      }
    },
  },
  {
    label: 'Behance',
    icon: <SocialBehance size={32} />,
    formatValue: value => `behance.net/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://behance.net/${value}`,
      }
    },
  },
  {
    label: 'Vimeo',
    icon: <SocialVimeo size={32} />,
    formatValue: value => `vimeo.com/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://vimeo.com/${value}`,
      }
    },
  },
  {
    label: 'Medium',
    icon: <SocialMedium size={32} />,
    baseUrl: 'username',
    formatValue: value => `medium.com/@${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://medium.com/@${value}`,
      }
    },
  },
  {
    label: 'Dailymotion',
    icon: <SocialDailymotion size={32} />,
    formatValue: value => `dailymotion.com/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://www.dailymotion.com/${value}`,
      }
    },
  },
  {
    label: 'Dribble',
    icon: <SocialDribble size={32} />,
    formatValue: value => `dribbble.com/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://dribbble.com/${value}`,
      }
    },
  },
  {
    label: 'Etsy',
    icon: <SocialEtsy size={32} />,
    placeholder: 'shop',
    formatValue: value => `etsy.com/shop/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://www.etsy.com/shop/${value}`,
      }
    },
  },
  {
    label: 'Strava',
    icon: <SocialStrava size={32} />,
    placeholder: 'id',
    formatValue: value => `strava.com/athletes/${value}`,
    formatUri: value => {
      return {
        app: null,
        web: `https://www.strava.com/athletes/${value}`,
      }
    },
  },
  {
    label: 'Viber',
    icon: <SocialViber size={32} />,
    placeholder: 'id',
    formatUri: value => {
      return {
        app: `viber://pa?chatURI=<${value}>`,
        web: `viber://pa?chatURI=<${value}>`,
      }
    },
  },
  {
    label: 'Github',
    icon: <SocialGithub size={32} />,
    formatUri: value => {
      return {
        app: `git://github.com/${value}`,
        web: `https://www.github.com/${value}`,
      }
    },
  },
]

export const linkItems = [
  {
    label: 'Site',
    icon: <SocialSafari size={32} />,
    placeholder: 'items.placeholder_url',
    placeholderTitle: 'items.site_title',
    formatUri: value => {
      const urlPattern = /^((http|https):\/\/)/
      return {
        app: null,
        web: !urlPattern.test(value) ? `https://${value}` : value,
      }
    },
    validate: isValidUrl,
    valueLabel: 'items.site',
  },
  {
    label: 'Music',
    icon: <Music size={32} />,
    placeholder: 'URL',
    formatUri: (platform, value) => {
      return {
        app: null,
        web: getEmbedByPlatform(platform, value),
      }
    },
    validate: isValidUrl,
  },
  {
    label: 'Video',
    icon: <Video size={32} />,
    placeholder: 'URL',
    formatUri: (platform, value) => {
      return {
        app: null,
        web: getEmbedByPlatform(platform, value),
      }
    },
    validate: isValidUrl,
  },
  {
    label: 'Calendly',
    icon: <SocialCalendly size={32} />,
    placeholder: 'URL',
    formatUri: value => {
      const urlPattern = /^((http|https):\/\/)/
      return {
        app: null,
        web: !urlPattern.test(value) ? `https://${value}` : value,
      }
    },
    validate: isValidUrl,
  },
  {
    label: 'Trip Advisor',
    icon: <Tripadvisor size={40} />,
    placeholder: 'URL',
    formatUri: value => {
      const urlPattern = /^((http|https):\/\/)/
      return {
        app: null,
        web: !urlPattern.test(value) ? `https://${value}` : value,
      }
    },
    validate: isValidUrl,
  },
  {
    label: 'Google reviews',
    icon: <GoogleMyBusiness size={32} />,
    placeholder: 'URL',
    formatUri: value => {
      const urlPattern = /^((http|https):\/\/)/
      return {
        app: null,
        web: !urlPattern.test(value) ? `https://${value}` : value,
      }
    },
    validate: isValidUrl,
  },
]
