import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineWifi = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M17.331 11a.7.7 0 0 1-.409-.133c-2.996-2.159-6.863-2.159-9.85 0a.705.705 0 0 1-.934-.108.6.6 0 0 1 .115-.872c3.485-2.516 7.993-2.516 11.487 0a.587.587 0 0 1 .116.872.67.67 0 0 1-.525.241"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M16.28 14a.7.7 0 0 1-.447-.157c-2.331-1.82-5.332-1.82-7.663 0a.73.73 0 0 1-1.02-.128.75.75 0 0 1 .126-1.033c2.866-2.243 6.575-2.243 9.44 0 .32.246.38.708.127 1.033a.67.67 0 0 1-.564.285"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M14.245 16a.82.82 0 0 1-.468-.141 3.17 3.17 0 0 0-3.551 0c-.336.22-.814.167-1.069-.115-.254-.292-.193-.707.133-.928a4.9 4.9 0 0 1 5.413 0c.336.22.397.636.132.928-.132.168-.356.256-.59.256"
        />
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M14.878 3.833a12.6 12.6 0 0 0-5.756 0 6.76 6.76 0 0 0-5.039 5.039 12.6 12.6 0 0 0 0 5.756 6.76 6.76 0 0 0 5.039 5.039c1.893.444 3.863.444 5.756 0a6.76 6.76 0 0 0 5.039-5.039 12.6 12.6 0 0 0 0-5.756 6.76 6.76 0 0 0-5.039-5.039M8.78 2.373a14.1 14.1 0 0 1 6.442 0 8.26 8.26 0 0 1 6.156 6.156 14.1 14.1 0 0 1 0 6.442 8.26 8.26 0 0 1-6.156 6.156 14.1 14.1 0 0 1-6.442 0 8.26 8.26 0 0 1-6.156-6.156 14.1 14.1 0 0 1 0-6.442 8.26 8.26 0 0 1 6.156-6.156"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
export default SvgLineWifi
