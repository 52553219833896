export default build =>
  build.query({
    query: ({ url }) => {
      return {
        url: '/links/get-available/url',
        method: 'POST',
        body: { url },
      }
    },
  })
