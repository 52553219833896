import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineAdd = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeWidth={1.5}
          d="M3.353 8.95A7.51 7.51 0 0 1 8.95 3.353c2.006-.47 4.094-.47 6.1 0a7.51 7.51 0 0 1 5.597 5.597c.47 2.006.47 4.094 0 6.1a7.51 7.51 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0a7.51 7.51 0 0 1-5.597-5.597 13.35 13.35 0 0 1 0-6.1Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M14.5 12h-5m2.5 2.5v-5"
        />
      </g>
    </svg>
  )
}
export default SvgLineAdd
