import React from 'react'
import { css, styled } from 'styled-components'
import VerificationInput from 'react-verification-input'

const StyledVerificationInput = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 80px;
    margin-bottom: ${theme.space.xxl};
    .character {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${theme.borderRadius.l};
      background: ${theme.colors.grey1};
      border: none;
      font-size:;
    }
    .character--selected {
      border: 1px solid ${theme.colors.primary};
      outline: none;
    }
    .character--filled {
      color: ${theme.colors.black};
    }
  `}
`

const CodeInput = ({ code, onChange }) => {
  return (
    <StyledVerificationInput>
      <VerificationInput
        value={code}
        onChange={onChange}
        validChars="0-9"
        length={6}
        classNames={{
          container: 'container',
          character: 'character',
          characterInactive: 'character--inactive',
          characterSelected: 'character--selected',
          characterFilled: 'character--filled',
        }}
        placeholder=""
      />
    </StyledVerificationInput>
  )
}

export default CodeInput
