import React from 'react'
import { PanelWrapper } from './PanelWrapper'
import { PanelContent } from './PanelContent'

export const Panel = ({
  isOpen,
  onDismiss,
  onReady,
  children,
  fullScreen,
  ...props
}) => {
  return (
    <PanelWrapper isOpen={isOpen} onDismiss={onDismiss}>
      <PanelContent onDismiss={onDismiss} {...props}>
        {children}
      </PanelContent>
    </PanelWrapper>
  )
}
