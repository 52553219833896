import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineCtr = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m22.075 20.022-1.451.489c-.4.133-.72.444-.854.853l-.49 1.448c-.418 1.252-2.18 1.226-2.572-.027l-1.646-5.286c-.32-1.048.65-2.025 1.691-1.696l5.304 1.643c1.245.39 1.263 2.159.018 2.576"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 11.738c0-5.52-4.48-10-10-10s-10 4.48-10 10 4.48 10 10 10M8 14.738l6-6"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13.495 14.238h.009M8.495 9.238h.008"
        />
      </g>
    </svg>
  )
}
export default SvgLineCtr
