import * as React from 'react'
const SvgWaterdrop = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <path
        fill="#2EA3F2"
        d="M18 15.168c-.051 3.283-2.777 5.894-6.09 5.83-3.314-.062-5.96-2.775-5.91-6.058C6.08 9.75 12.186 3 12.186 3s5.894 6.977 5.814 12.168"
      />
      <path
        fill="#fff"
        d="M11.024 7.08c-.01-.06-.088-.097-.143-.082-.332.092-.547.543-.73.812-.246.36-.479.724-.7 1.1-.22.373-.436.75-.636 1.133-.17.327-.52.786-.48 1.163.02.18.206.345.39.23.336-.213.472-.713.65-1.056.2-.386.398-.772.606-1.153q.312-.572.627-1.142c.163-.296.465-.654.416-1.004M8.14 11.95c-.186-.054-.341.058-.408.223l-.113.277q-.097.175-.152.373a.326.326 0 0 0 .22.395c.184.053.328-.057.397-.216l.282-.645c.07-.163-.07-.361-.226-.407"
      />
    </g>
  </svg>
)
export default SvgWaterdrop
