import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import { Button } from '@/Components/Buttons/Button'
import { AppleStore, PlayStore } from '@/Assets/icons/components'
import { useResizeContext } from '@/ResizeProvider'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

const QRCode = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
`

const DowloadQrcode = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  return (
    <div className="flex items-center">
      <QRCode className="p-5 w-40 h-40 rounded-3xl flex justify-center items-center">
        <QRCodeSVG size={126} value={window.origin + '/dl-app?redirect=true'} />
      </QRCode>
      <div>
        <div className="ml-6">
          <Text className="text-base" color={theme.colors.grey2}>
            {t('download_app.scan_to_upload_to')}
          </Text>
          <div>
            <Text className="text-2xl" color={theme.colors.black}>
              iOS & Android
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

const Buttons = ({ googleURL, appleURL }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <div className="h-12 mb-2">
        <Button
          label={t('download_app.available_on_playStore')}
          className="w-full"
          color={theme.colors.grey1}
          icon={PlayStore}
          onClick={() => window.open(googleURL, '_blank')}
        />
      </div>
      <div className="h-12">
        <Button
          label={t('download_app.available_on_appStore')}
          className="w-full"
          color={theme.colors.grey1}
          icon={AppleStore}
          onClick={() => window.open(appleURL, '_blank')}
        />
      </div>
    </>
  )
}

export const QRCodeButtons = props => {
  const { isSmallWidth } = useResizeContext()
  return <>{isSmallWidth ? <Buttons {...props} /> : <DowloadQrcode />}</>
}
