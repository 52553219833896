const yield_explanation_page = {
  yield_explanation_companion: 'How does the companion work?',
  title: {
    main_text:
      'Earn <color>{{1}}</color>, and <color>{{2}}</color> with your companion',
    1: 'resources',
    2: 'tickets',
  },
  subtitle:
    'Increase the level of your companion to earn more resources and tickets!',
  explanations: {
    title_drop: 'Take care of your companion',
    description_drop:
      'When you play games, your companion uses energy. You can water it so that it can continue to play.',
    title_gains: 'Your companion works while you are away',
    description_gains:
      'Every day, your companion works for you. You can collect the rewards he has earned.',
    title_evolve: 'Evolve your companion',
    description_evolve:
      'By evolving your companion, you increase its rewards. You can evolve your companion by giving it coins.',
  },
}


export default yield_explanation_page
