import { useLockedField } from '@/Hooks/useLockedField'
import BackgroundQrCode from './BackgroundQrCode'
import EyesQrCode from './EyesQrCode'
import LogoQrCode from './LogoQrCode'
import PatternQrCode from './PatternQrCode'
import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import { useMemo } from 'react'
export const useGetOptions = () => {
  const { isFieldDisabled } = useLockedField()

  const options = useMemo(
    () => [
      {
        name: 'patternQrCode',
        component: PatternQrCode,
        title: 'edit_profil.appearance.shape_avatar.motif',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                variant="secondary"
                fieldName={fieldName}
                type="qrcode"
              />
            </div>
          )
        },
        disabled: isFieldDisabled({
          fieldName: 'patternQrCode',
          type: 'qrcode',
        }),
      },
      {
        name: 'backgroundQrCode',
        component: BackgroundQrCode,
        title: 'edit_profil.appearance.shape_avatar.bg',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                variant="secondary"
                fieldName={fieldName}
                type="qrcode"
              />
            </div>
          )
        },
        disabled: isFieldDisabled({
          fieldName: 'backgroundQrCode',
          type: 'qrcode',
        }),
      },
      {
        name: 'eyesQrCode',
        component: EyesQrCode,
        title: 'edit_profil.appearance.shape_avatar.eye',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                variant="secondary"
                fieldName={fieldName}
                type="qrcode"
              />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'eyesQrCode', type: 'qrcode' }),
      },
      {
        name: 'logo',
        component: LogoQrCode,
        title: 'Logo',
        children: fieldName => {
          return (
            <div className="absolute right-12 cursor-pointer">
              <LockedFieldSwitch
                variant="secondary"
                fieldName={fieldName}
                type="qrcode"
              />
            </div>
          )
        },
        disabled: isFieldDisabled({ fieldName: 'logo', type: 'qrcode' }),
      },
    ],
    [isFieldDisabled],
  )

  return options
}
