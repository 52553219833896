const companion_yields = {
  obtained: 'à récolter',
  yield: 'Rendement',
  claim_my_yields: 'Réclamer mon rendement',
  no_claim_yields: 'Prochaine récolte dans ',
  received_at: 'Reçu le ',
  yield_history: 'Historique de rendement',
  rewarded: 'Les rendements de ton compagnon ont été récolté !',
  rewarded_description: 'Tu viens de récupérer tous tes rendement !',
  reminder: 'Reviens chaque jour pour récupérer ton rendement journalier',
  great: 'Super !',
  choose_active_resources: 'Choisissez 3 ressources actives',
  resources_can_be_changed:
    'Les ressources choisies pourront être changées selon vos envies.',
  active: 'actif',
  disabled: 'désactivé',
  validate_selected_resources: 'Valider ma sélection',
  error_claim_yields_before_selecting_it:
    "Impossible de modifier les ressources lorsqu'il y a des rendements à récupérer. Récupérez-les d'abord.",
}

export default companion_yields
