import * as React from 'react'
const SvgSendHome = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 41})`}>
      <path
        fill="#fff"
        d="M39.116 1.482c-1-1.022-2.48-1.4-3.86-1L3.076 9.78C1.62 10.184.588 11.338.31 12.802c-.284 1.492.708 3.388 2.004 4.18l10.062 6.144a2.62 2.62 0 0 0 3.218-.382l11.522-11.522a1.47 1.47 0 0 1 2.12 0c.58.578.58 1.52 0 2.12L17.694 24.864a2.62 2.62 0 0 0-.386 3.218l6.148 10.1a3.83 3.83 0 0 0 3.32 1.88c.16 0 .34 0 .5-.022 1.56-.198 2.8-1.26 3.26-2.76l9.54-31.938c.42-1.36.04-2.84-.96-3.86"
      />
      <path
        fill="#fff"
        d="M15.164 34.61a1.504 1.504 0 0 1 0 2.122l-2.732 2.73c-.292.294-.676.44-1.06.44s-.768-.146-1.06-.44a1.5 1.5 0 0 1 0-2.12l2.73-2.732a1.504 1.504 0 0 1 2.122 0m-1.567-7.576a1.504 1.504 0 0 1 0 2.122l-2.732 2.73c-.292.294-.676.44-1.06.44s-.768-.146-1.06-.44a1.5 1.5 0 0 1 0-2.12l2.73-2.732a1.504 1.504 0 0 1 2.122 0M6.074 24.65a1.504 1.504 0 0 1 0 2.122l-2.732 2.73c-.292.294-.676.44-1.06.44s-.768-.146-1.06-.44a1.5 1.5 0 0 1 0-2.12l2.73-2.732a1.504 1.504 0 0 1 2.122 0"
        opacity={0.4}
      />
    </g>
  </svg>
)
export default SvgSendHome
