import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from './Spinner'
import { useLazyGetQrCodeImageByIdQuery } from '@/Services/modules/qrcode'
import useLocalStorage from '@/Hooks/useLocalStorage'
import deepEqual from 'deep-equal'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'

const QrCode = () => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage()
  const [getQrCodeImageById] = useLazyGetQrCodeImageByIdQuery()
  const [image, setImage] = useState(null)

  const fetchQrCodeThenSaveItToLocalStorage = useCallback(
    async storedQrCodeKey => {
      await getQrCodeImageById(user.qrcode?.id)
      const { data, error } = await getQrCodeImageById(user.qrcode?.id)
      if (error) {
        return console.error(error.data.error)
      }
      const url = data.urls.png
      const newstoredQrCode = {
        url,
        attributes: user.qrcode?.attributes,
      }
      setLocalStorageItem(storedQrCodeKey, newstoredQrCode)
      return setImage(`${data.urls.png}?date=${Date.now()}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.qrcode?.attributes, user.qrcode?.id],
  )

  const getImage = useCallback(
    async () => {
      try {
        setImage(null)
        const storedQrCodeKey = `qrcode_${user.uid}`
        const storedQrCode = getLocalStorageItem(storedQrCodeKey)
        if (
          !storedQrCode ||
          !deepEqual(storedQrCode.attributes, user.qrcode?.attributes)
        ) {
          return fetchQrCodeThenSaveItToLocalStorage(storedQrCodeKey)
        }
        setImage(`${storedQrCode.url}?date=${Date.now()}`)
      } catch (error) {
        console.warn(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.qrcode?.attributes, user.uid],
  )

  useEffect(() => {
    getImage()
  }, [user.qrcode?.attributes, getImage])

  return (
    <div className="w-full aspect-square justify-center items-center flex">
      {!image && <Spinner />}
      {image && (
        <img src={image} alt="QR code" className="w-full h-full object-cover" />
      )}
    </div>
  )
}

export default QrCode
