import { useTheme } from '@/Hooks'
import React, { useCallback, useEffect } from 'react'
import { css, styled } from 'styled-components'
import { useProfileContext } from './Contexts/ProfileContext'
import SectionTab from './SectionTab'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const ProfileShop = () => {
  const { theme } = useTheme()
  const profileContext = useProfileContext()
  const { profile, edition } = profileContext || {}
  const customTheme =
    profile?.styles?.['background-color'] || theme.colors.primary
  let script = document.createElement('script')
  const config = {
    publishable_key: profile?.shopRocket?.live_publishable_key,
    options: {
      products_to_display: 'all',
      categories: [],
      product_size: 'medium',
      image_dimension_value: 'crop',
      image_aspect_ratio: 'portrait',
      button_style: 'standard',
      variation_style: 'on_hover',
      open_product_in: 'in_page',
      button_position: 'inline',
      product_default_sorting_order: 'product_order',
      product_pagination_limit: '12',
      hide_out_of_stock: '0',
    },
    includes: {
      show_search_box: '1',
      show_sort_by: '1',
      show_per_page: '0',
      show_category_dropdown: '1',
      show_currency_dropdown: '1',
      show_language_dropdown: '1',
      show_product_filters: '1',
      show_product_name: '1',
      show_product_price: '1',
      show_product_image: '1',
      show_product_summary: '1',
      open_modal_on_image_click: '1',
      show_view_product_button: '1',
      show_add_to_cart_button: '1',
      show_min_max_order_quantity: '1',
      show_sale: '1',
      show_free_shipping: '1',
      show_new_product: '1',
      show_digital_download: '1',
      show_pwyw: '1',
      image_swap: '1',
      show_button_icons: '1',
    },
    product_popup: {
      show_product_name: '1',
      show_product_price: '1',
      show_product_summary: '1',
      show_product_description: '1',
      show_product_image: '1',
      show_select_quantity: '1',
      show_image_thumbnails: '1',
      show_product_reviews: '1',
      show_product_sku: '0',
      show_product_categories: '0',
      show_social_sharing_icons: '0',
      show_related_products: '0',
      thumbnail_layout: 'horizontal_below',
      image_dimension_value: 'crop',
      image_aspect_ratio: 'portrait',
      variation_styling: 'dropdown',
      show_min_max_order_quantity: '1',
      show_sale: '0',
      show_free_shipping: '0',
      show_new_product: '0',
      show_digital_download: '0',
      show_pwyw: '0',
      show_product_tabs: '0',
      image_zoom: '1',
      lightbox_gallery: '1',
      show_stock: '0',
    },
    styles: {
      align_content: 'center',
      product_title: theme.colors.grey3,
      product_price: theme.colors.grey3,
      product_summary: theme.colors.grey2,
      button_background: customTheme,
      button_color: theme.colors.white,
      view_product_button_background: customTheme,
      view_product_button_color: theme.colors.white,
      view_cart_button_background: customTheme,
      view_cart_button_color: theme.colors.white,
      product_background: theme.colors.white,
      modal_background: theme.colors.white,
      button_font_weight: 'normal',
      popup: {
        colors: {
          product_title: theme.colors.grey3,
          product_price: theme.colors.grey2,
          product_summary: theme.colors.grey2,
          button_background: customTheme,
          button_color: theme.colors.white,
          product_active_tab_background: theme.colors.grey1,
        },
      },
    },
  }

  const loadScript = useCallback(() => {
    const body = document.body
    script.innerHTML = ''
    script.src = 'https://cdn.shoprocket.io/loader.js'
    script.async = true
    script.defer = true
    body.appendChild(script)
    if (edition) {
      const style = document.createElement('style')
      style.innerHTML = '.sr-basket-float-bubble {display: none;}'
      body.appendChild(style)
    }
  }, [script, edition])

  useEffect(() => {
    loadScript()
    return () => {
      script.remove()
      window.Shoprocket?.Destroy?.()
    }
  }, [loadScript, script, edition])

  return (
    <Wrapper>
      <SectionTab title="Mon shop">
        <div className="sr-element sr-products" data-embed="multiple_products">
          <script type="application/json">{JSON.stringify(config)}</script>
        </div>
      </SectionTab>
    </Wrapper>
  )
}

export default ProfileShop
