import * as React from 'react'
const SvgSocialTelegram = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <circle cx={16} cy={16.75} r={14} fill="url(#socialTelegram_svg__a)" />
      <path
        fill="#fff"
        d="M22.987 10.959c.124-.806-.642-1.441-1.358-1.127L7.365 16.095c-.514.225-.476 1.003.056 1.173l2.942.937c.562.179 1.17.086 1.66-.253l6.632-4.582c.2-.138.418.147.247.323l-4.774 4.922c-.463.477-.371 1.286.186 1.636l5.345 3.351c.6.376 1.37-.001 1.483-.726z"
      />
      <defs>
        <linearGradient
          id="socialTelegram_svg__a"
          x1={16}
          x2={16}
          y1={2.75}
          y2={30.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37BBFE" />
          <stop offset={1} stopColor="#007DBB" />
        </linearGradient>
      </defs>
    </g>
  </svg>
)
export default SvgSocialTelegram
