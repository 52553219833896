import * as React from 'react'
const SvgLineSettingGame = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <path
        fill="#EBF8EB"
        fillRule="evenodd"
        d="m7.667 4.696.166-.096c1.52-.876 2.28-1.314 3.086-1.486a5.2 5.2 0 0 1 2.162 0c.806.172 1.566.61 3.086 1.486l.166.096c1.416.816 2.124 1.225 2.656 1.784a5.5 5.5 0 0 1 1.296 2.272c.215.75.215 1.583.215 3.248s0 2.498-.215 3.248a5.5 5.5 0 0 1-1.296 2.272c-.532.56-1.24.968-2.656 1.784l-.166.096c-1.52.876-2.28 1.314-3.086 1.486a5.2 5.2 0 0 1-2.162 0c-.806-.172-1.566-.61-3.086-1.486l-.166-.096c-1.416-.816-2.124-1.225-2.656-1.784a5.5 5.5 0 0 1-1.296-2.272C3.5 14.498 3.5 13.665 3.5 12s0-2.498.215-3.248A5.5 5.5 0 0 1 5.01 6.48c.532-.56 1.24-.968 2.656-1.784M9.344 12c0 1.504 1.189 2.723 2.656 2.723s2.656-1.22 2.656-2.723c0-1.504-1.189-2.723-2.656-2.723S9.344 10.497 9.344 12"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgLineSettingGame
