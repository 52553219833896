const negative_prompt_explanation = {
  title: 'Le prompt négatifs',
  subtitle: {
    main_text:
      'Le "negative prompt" est un outil clé pour affiner la génération d\'images en permettant de spécifier ce que vous ne voulez pas voir dans une image générée.',
  },
  increased_precision_title: 'Précision accrue',
  increased_precision_description:
    "Spécifier des éléments indésirables dans l'image générée, garantissant ainsi une plus grande précision dans les résultats.",
  creative_control_title: 'Contrôle créatif',
  creative_control_description:
    "Garder un contrôle accru sur le contenu de l'image, en définissant clairement ce qui ne doit pas être présent tout en préservant votre liberté créative.",
  elimination_ambiguity_title: "Élimination de l'ambiguïté",
  elimination_ambiguity_description:
    "Éliminer les résultats non conformes à vos attentes, réduisant ainsi l'ambiguïté et vous offrant des images plus conformes.",
  how_use: 'Comment l’utiliser ?',
  how_use_content:
    'Utilisez <strong>"ne pas"</strong> suivi de ce que vous souhaitez exclure.',
  how_use_content_end:
    '<strong>Combinez-le</strong> avec des prompts positifs pour des résultats affinés.',
}

export default negative_prompt_explanation
