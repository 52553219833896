import { initializeApp, getApps } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyChTU-YnwyAOAWn8nxXp-0t2rlOxEM9LIs',
  authDomain: 'gopopme-7353a.firebaseapp.com',
  databaseURL: 'https://gopopme-7353a.firebaseio.com',
  projectId: 'gopopme-7353a',
  storageBucket: 'gopopme-7353a.appspot.com',
  messagingSenderId: '90902568689',
  appId: '1:90902568689:web:13763e264dbe632b3ed350',
  persistence: true,
}
let app = null
if (getApps().length === 0) {
  app = initializeApp(firebaseConfig)
}
export default app
