import { infiniteTreesScrollOptions } from '../infiniteTreesScrollOptions'

const getCollectiveTreesByUid = build =>
  build.query({
    query: ({ uid, lastDate = '' }) => {
      return {
        url: `/trees/${uid}/get-trees?lastDate=${lastDate}&filter=collective`,
        method: 'GET',
      }
    },
    providesTags: ['CollectiveTrees'],
    ...infiniteTreesScrollOptions,
  })

export default getCollectiveTreesByUid
