const getAllQuestsByCategory = build =>
  build.query({
    query: ({ uid, category }) => {
      return {
        url: `/users/${uid}/quests-categories/${category}`,
        method: 'GET',
      }
    },
    providesTags: ['Quests'],
  })

export default getAllQuestsByCategory
