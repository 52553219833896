const promotions_page = {
  tab_name: 'Promos',
  title: 'Promotions',
  label_All_Offers: 'All offers',
  label_My_Offers: 'My offers',
  bloc_title_packs: 'Packs',
  bloc_title_promotion: 'Promotional Offers',
  offer_successfully_buy: 'Offer successfully purchased!',
  empty_results: {
    no_offer_found: 'No offer found',
    it_looks_like: 'It looks like there are no offers matching your search.',
  },
  possessed: 'Possessed',
}


export default promotions_page
