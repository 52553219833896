const discover_companions = {
  title: 'Discover companions',
  no_companion: 'No companion',
  choose_companion: 'Choose your companion',
  choose_companion_subtitle:
    'Gain additional resources daily with your companion.',

  choose_companion_resources: 'Select its resources',
  explanation_title_screen: 'Companions',
  explanation_title: {
    main_text: 'Earn <color>{{1}}</color> with your companion',
    1: 'resources',
  },
  explanation_description:
    'Companions allow you to earn resources daily. It will also accompany you in mini-games to help you collect even more rewards!',
  explanations: {
    title_reward: 'Even more rewards',
    description_reward:
      'Your companion accompanies you daily in your eco-friendly actions by bringing you more resources.',
    title_gains: 'Double your earnings by playing',
    description_gains:
      'Activating your companion during your games will allow you to double the gain of all collected resources!',
    title_evolve: 'Make it evolve',
    description_evolve:
      'By evolving your companion, you increase the rewards! You can improve it with your coins',
  },
  get_your_companion: 'Get your companion',
  monthly: 'Monthly',
  yearly: 'Yearly',
  by_month: '/month',
  by_year: '/year',
}

export default discover_companions
