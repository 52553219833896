import * as React from 'react'
import { Stepper as MUIStepper } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import { useTheme } from '@/Hooks'
import { useOnboardingContext } from './Onboarding/OnBoardingContext'
import { LineCheck2 } from '@/Assets/icons/components'
import Text from './Texts/Text'
import theme from '@/Theme'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

const StyledIcon = styled.div`
  background: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.white};
`

const SECTIONS = [
  {
    label: 'onboarding.page_title',
    description: 'onboarding.personalize_your',
  },
  {
    label: 'onboarding.creating_your_profile',
    description: 'onboarding.enter_your_information',
  },
  {
    label: 'onboarding.verification_code.verification',
    description: 'onboarding.verification_code.verify_your_account',
  },
]

const CustomStepIcon = ({ section }) => {
  const { formStep } = useOnboardingContext()
  const active = section === formStep.section + 1

  return (
    <StyledIcon
      active={active}
      className="w-12 h-12 rounded-3xl flex justify-center items-center"
    >
      {section > formStep.section ? (
        <Text color={active ? theme.colors.white : theme.colors.black}>
          {section}
        </Text>
      ) : (
        <LineCheck2 color={theme.colors.black} />
      )}
    </StyledIcon>
  )
}
export const Stepper = () => {
  const { formStep } = useOnboardingContext()
  const { theme } = useTheme()
  const { t } = useTranslation()
  const stepStyles = {
    '& .Mui-active': {
      '& .MuiStepIcon-root': {
        color: theme.colors.primary,
      },
      '& .MuiStepConnector-line': {
        borderColor: theme.colors.primary,
      },
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: theme.colors.black,
    },
    '& .Mui-disabled': {
      '& .MuiStepConnector-line': {
        borderColor: theme.colors.white,
      },
    },
    '& .Mui-completed': {
      '& .MuiStepLabel-label': {
        borderColor: theme.colors.red,
        color: theme.colors.grey2,
      },
    },

    '.MuiStepLabel-root': {
      fontSize: theme.fontSize.m,
      fontWeight: theme.fontWeight.semibold,
      padding: theme.space.none,
    },
    '.MuiStepLabel-label': {
      fontSize: theme.fontSize.m,
      fontWeight: theme.fontWeight.semibold,
    },
    '.MuiTypography-root': {
      color: theme.colors.grey2,
      fontSize: theme.fontSize.s,
      fontWeight: 300,
    },
    '.MuiStepConnector-line': {
      borderColor: theme.colors.white,
      marginLeft: theme.space.s,
      borderLeftWidth: '2px',
      height: theme.space.xl,
      marginTop: theme.space.xxs,
      marginBottom: theme.space.xxs,
    },
  }

  return (
    <div className="flex-1">
      <MUIStepper
        activeStep={formStep.section}
        orientation="vertical"
        sx={stepStyles}
      >
        {SECTIONS.map((section, index) => {
          return (
            <Step key={section.label}>
              <StepLabel
                StepIconComponent={() => <CustomStepIcon section={index + 1} />}
              >
                {t(section.label)}
                <Typography>{t(section.description)}</Typography>
              </StepLabel>
            </Step>
          )
        })}
      </MUIStepper>
    </div>
  )
}
