const getProfilesByUid = build =>
  build.query({
    query: uid => {
      return {
        url: `/profile/get-profiles/${uid}`,
        method: 'GET',
      }
    },
  })

export default getProfilesByUid
