import React from 'react'
import styled, { css } from 'styled-components'
import { useModalDownloadAppContext } from './Profile/Contexts/ModalDownloadAppContext'
import { useTranslation } from 'react-i18next'

const TagsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${theme.space.xxs};
    flex-wrap: wrap;
  `}
`
const Tag = styled.span`
  ${({ theme }) => css`
    padding: ${theme.space.xs} ${theme.space.std};
    border-radius: ${theme.borderRadius.m};
    border: 2px solid ${theme.colors.grey1};
    font-weight: ${theme.fontWeight.semibold};
    margin-top: ${theme.space.xs};
    color: ${theme.colors.grey3};
  `}
`

const Tags = ({ tags, type, onClose = () => null }) => {
  const { openModalDownloadApp } = useModalDownloadAppContext()
  const { t } = useTranslation()

  const onOpenModalDownloadApp = () => {
    const title = t('modal_download_app.download_the_budly_app')
    const subtitle = t('modal_download_app.explore_budly')
    openModalDownloadApp({ title, subtitle })
  }

  if (!tags?.length) {
    return null
  }

  return (
    <TagsWrapper className="gap-2 mt-3">
      {tags?.map((tag, index) => {
        return (
          <div
            className="py-2 cursor-pointer"
            key={index}
            onClick={() => {
              onClose()
            }}
            // href={`/social/network/hashtags/${tag.replace("#", "")}`}
          >
            <Tag onClick={onOpenModalDownloadApp}>{tag}</Tag>
          </div>
        )
      })}
    </TagsWrapper>
  )
}

export default Tags
