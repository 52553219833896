import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgMobile = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 20})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          fillRule="evenodd"
          d="M4 2H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-1a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 4 2m7-2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
export default SvgMobile
