import React from 'react'
import styled, { css } from 'styled-components'
import { backgroundIsLight } from '@/Utils/transformColor'
import { shapes } from '@/Utils/shapes'
import { useProfileContext } from '../Profile/Contexts/ProfileContext'
import { useTheme } from '@/Hooks'

const TabsWrapper = styled.div`
  ${({ theme, customTheme }) => css`
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-bottom: ${theme.space.xs};
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${theme.size.s};
      border: 4px solid white;
      background-color: ${customTheme || theme.colors.primary};
    }
  `}
`

export const Tabs = ({ tabs, ...props }) => {
  const profileContext = useProfileContext()
  const { profile } = profileContext || {}
  const customTheme = profile?.styles?.['background-color']
  return (
    <div className={props.className ? props.className : 'pl-3'}>
      <TabsWrapper customTheme={customTheme} className="flex flex-row">
        {tabs.map((tab, index) => {
          return <Tab key={index} tab={tab} {...props} />
        })}
      </TabsWrapper>
    </div>
  )
}

const TabWrapper = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    padding: ${theme.space.none} ${theme.space.xxs};
    min-width: 108px;
  `}
`

const StyledTab = styled.div`
  ${({ theme }) => css`
    background-color: ${props =>
      props.selectedTab === props.title
        ? props.customTheme || theme.colors.primary
        : props.styles?.hideBtnBackground
          ? 'transparent'
          : theme.colors.grey1};
    border-width: 1px;
    border-color: ${props =>
      props.title === props.selectedTab
        ? props.customTheme || theme.colors.grey1
        : props.styles?.showBtnOutline
          ? props.styles?.btnOutlineColor || '#000000'
          : theme.colors.grey1};
    border-style: solid;
    ${props => !props.className && `border-radius: ${theme.borderRadius.l};`}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `}
`
const TabContent = styled.div`
  ${({ theme }) => css`
    height: 76px;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space.std} ${theme.space.none};
  `}
`

const TabName = styled.span`
  ${({ theme, isDarkMode }) => css`
    color: ${props =>
      props.selectedTab === props.title
        ? backgroundIsLight(
            props.customTheme || theme.colors.primary,
            isDarkMode,
          )
          ? theme.colors.black
          : theme.colors.white
        : theme.colors.black};
  `}
`

const Tab = ({ tab, selectedTab, setSelectedTab, ...props }) => {
  const { theme, isDarkMode } = useTheme()
  const profileContext = useProfileContext()
  const { profile } = profileContext || {}
  const customTheme = profile?.styles?.['background-color']
  const btnShape = shapes.find(
    shape => shape.shape === (profile?.styles?.btnShape || 'shape1'),
  )

  return (
    <TabWrapper {...props} onClick={() => setSelectedTab(tab.title)}>
      <StyledTab
        selectedTab={selectedTab}
        title={tab.title}
        customTheme={customTheme}
        className={profile ? btnShape.className : ''}
        styles={profile?.styles}
      >
        <TabContent>
          <tab.icon
            color={
              selectedTab === tab.title
                ? backgroundIsLight(
                    customTheme || theme.colors.primary,
                    isDarkMode,
                  )
                  ? theme.colors.black
                  : theme.colors.white
                : theme.colors.black
            }
          />
          <TabName
            customTheme={customTheme}
            selectedTab={selectedTab}
            title={tab.title}
            className={tab.className}
            isDarkMode={isDarkMode}
          >
            {tab.title}
          </TabName>
        </TabContent>
      </StyledTab>
    </TabWrapper>
  )
}
