// import { usePreviewContext } from '../Preview/PreviewContext'
// import Text from '../Text'
// import Badge from './Badge'
// import { useGetHighestLevelCompanionQuery } from '@/Services/modules/companions'
// import MaskedView from '@react-native-masked-view/masked-view'
// import React from 'react'
// import LinearGradient from 'react-native-linear-gradient'

const Web3Badge = () => {
  // const { profile } = usePreviewContext()
  // const { data: companion } = useGetHighestLevelCompanionQuery(profile?.uid, {
  //   pollingInterval: 30000,
  //   refetchOnFocus: true,
  // })

  // if (!companion) {
  return null
  // }

  // return (
  //   <Badge className="px-3 py-1.5 mr-2">
  //     <MaskedView maskElement={<Text>web3</Text>}>
  //       <LinearGradient
  //         colors={['#FF5F4A', '#7A4BFF']}
  //         start={{ x: 0, y: 0 }}
  //         end={{ x: 1, y: 0.25 }}
  //       >
  //         <Text className="opacity-0">web3</Text>
  //       </LinearGradient>
  //     </MaskedView>
  //   </Badge>
  // )
}

export default Web3Badge
