const share_profil = {
  title: 'Share my profile',
  share: 'Share',
  type_profil: {
    share_private_profil: 'Share my private profile',
    share_publique_profil: 'Share my public profile',
    private_profil: 'private profil',
    public_profil: 'public profil',
    share_profil: 'Share my profile',
    share_contact_file: 'Share my contact card',
  },
  qr_code: {
    my_qrcode: 'MY QR CODE',
    save_img: 'Save as image',
    save_wallet: 'Save in your wallet',
  },
  send: {
    send: 'Send',
    send_msg: 'Send via message',
  },
}


export default share_profil
