const getFollowersList = build =>
  build.query({
    query: ({ uid, requesterUid, lastDate }) => {
      const queryParams = lastDate ? `?startAt=${lastDate}` : ''
      return {
        url: `/users/${uid}/list/followers/${requesterUid}${queryParams}`,
        method: 'GET',
      }
    },
  })

export default getFollowersList
