import { LinePlus } from '@/Assets/icons/components'
import { Button } from '@/Components/Buttons/Button'
import GalleryComponent from '@/Components/Gallery'
import PanelAddImageToGallery from '@/Components/Panels/PanelAddImageToGallery/PanelAddImageToGallery'
import Bloc from '@/Components/Profile/Bloc'
import useCurrentMember from '@/Hooks/useCurrentMember'
import useCurrentUser from '@/Hooks/useCurrentUser'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.l} 0;
  `}
`

const Gallery = () => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const { t } = useTranslation()

  return (
    <Wrapper>
      <PanelAddImageToGallery>
        {setIsOpen => (
          <Button
            icon={LinePlus}
            label={t('profilepage.galerie.add_photo')}
            onClick={() => setIsOpen(true)}
            className="w-full"
          />
        )}
      </PanelAddImageToGallery>
      <Bloc title={t('profilepage.galerie.galerie')}>
        <GalleryComponent gallery={user.gallery} profile={user} editable />
      </Bloc>
    </Wrapper>
  )
}

export default Gallery
