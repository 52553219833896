import * as React from 'react'
const SvgLineCross = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 24})`}>
      <path
        fill="#fff"
        d="M14.937 6.212a1 1 0 0 1-1.126 1.653A5 5 0 1 0 16 12.001a1 1 0 0 1 2 0 7 7 0 1 1-3.063-5.789"
      />
      <path
        fill="#fff"
        d="M14.539 14.507a.998.998 0 0 1-1.524-.667 1 1 0 0 1 .446-1.018l3.482-2.227a1 1 0 0 1 1.077 1.685z"
      />
      <path
        fill="#fff"
        d="M19.903 14.411a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815z"
      />
    </g>
  </svg>
)
export default SvgLineCross
