import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useGetFullName = profile => {
  const { currentUser: user } = useSelector(state => state.user)
  const currentUser = profile || user

  const name = useMemo(() => {
    if (!currentUser) {
      return null
    }
    const {
      businessMode,
      businessName = '',
      firstName = '',
      lastName = '',
    } = currentUser
    return businessMode ? businessName : `${firstName} ${lastName}`.trim()
  }, [currentUser])

  return name || ''
}
