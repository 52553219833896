const feedpage = {
  title_tab_bottom: 'Inicio',
  feed: 'Feed',
  insight: 'Inicio',
  fav: 'Mis preferidos',
  add_post_to_bookmarks: 'Añadir este post a preferidos',
  remove_post_from_bookmarks: 'Eliminar este post de preferidos',
  search: 'Buscar',
  scan: {
    qrcode: 'Escáner a través de QRCode',
    nfc: 'Escáner a través de NFC',
  },
  notification: {
    title: 'Mis actividades',
    all: 'Todas',
    add: 'Agregar',
    requests: 'Solicitudes',
    added: 'Agregado',
    unfollow: 'Unfollow',
    follow: 'Follow',
    empty_resource: 'El recurso ya no existe',
    people_watering_post: 'Personas han regado tu publicación',
    no_notification_title: 'No se encontraron notificaciones',
    no_notification_subtitle:
      'Por el momento no hay actividad en tu cuenta. ¡Vuelve más tarde!',
  },
  modify_post: {
    modify: 'Editar esta publicación',
    delete: 'Borra esta publicación',
    edit_btn: 'Modificar',
  },
  bookmark_images: 'Tus imágenes guardadas',
  bookmark_posts: 'Tus publicaciones guardadas',
}


export default feedpage
