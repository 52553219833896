import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import PanelAddContent from '@/Components/Panels/ContentPanels/PanelAddContent'
import Text from '@/Components/Texts/Text'
import { capitalize } from '@/Utils/formaters'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const StyledContentItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -${theme.space.xxs};
    margin-top: ${theme.space.l};
  `}
`

const IconWrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
  `}
`

const ContentItem = ({ item, type, onDismiss }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col w-1/4 p-1 items-center">
      <PanelAddContent item={item} type={type} onDismiss={onDismiss}>
        {setISOpen => (
          <>
            <IconWrapper onClick={() => setISOpen(true)}>
              {item.icon}
            </IconWrapper>
            <Text className="w-full text-center mt-2 mb-3 text-xs">
              {capitalize(t(item.label))}
            </Text>
          </>
        )}
      </PanelAddContent>
    </div>
  )
}

// const ContentItemHorizontal = ({ item, type }) => {
//   const { t } = useTranslation()
//   const { theme } = useTheme()
//   return (
//     <PanelAddContent item={item} type={type}>
//       {setISOpen => (
//         <div className="p-4 flex w-full">
//           <div className="flex grow">
//             {item.icon}
//             <div className="flex flex-col ml-1">
//               <Text>{t(item.label)}</Text>
//               <Text regular className="text-sm" color={theme.colors.grey2}>
//                 lorem ipsum dolor sit amet
//               </Text>
//             </div>
//           </div>
//           <div>
//             <Button
//               label="Ajouter"
//               color={theme.colors.grey1}
//               onClick={() => setISOpen(true)}
//             />
//           </div>
//         </div>
//       )}
//     </PanelAddContent>
//   )
// }

const ContentItems = ({ items, type, search = '', onDismiss }) => {
  return (
    <StyledContentItems>
      {items
        .filter(item =>
          item.label?.toLowerCase().includes(search.toLowerCase()),
        )
        .map((item, index) => {
          return (
            <ContentItem
              key={index}
              item={item}
              type={type}
              onDismiss={onDismiss}
            />
          )
        })}
    </StyledContentItems>
  )
}

export default ContentItems
