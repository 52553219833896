import React, { useState } from 'react'
import { Panel } from '../PanelWrapper/Panel'
import { useTranslation } from 'react-i18next'
import ShareBar from '@/Components/SendProfil/ShareBar'
import { useSelector } from 'react-redux'
import useGetProfileUrl from '@/Hooks/useGetProfileUrl'
import { ShareUrlProvider } from './ShareUrlContext'
import CopyLink from '@/Components/CopyLink'
import CardQrCode from '@/Components/SendProfil/CardQrCode'

const PanelShareProfile = ({ children: button }) => {
  const { currentUser } = useSelector(state => state.user)
  const profileUrl = useGetProfileUrl(currentUser?.id)
  const [url, setUrl] = useState(profileUrl)
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      {button(setIsOpen)}
      <Panel
        title={t('share_profil.title')}
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <ShareUrlProvider value={{ url, setUrl }}>
          <div className="flex flex-col">
            <div className="mb-4">
              <ShareBar />
            </div>
            <CopyLink />
            <CardQrCode />
            {/* <SendBloc prefix={t('others.my_budly_profile')} />  */}
          </div>
        </ShareUrlProvider>
      </Panel>
    </>
  )
}

export default PanelShareProfile
