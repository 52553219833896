import { ProTextInput } from './ProTextInput'

const InputUrl = ({ type, label, contentURL, setContentURL, ...props }) => {
  return (!type &&
    label !== 'Site' &&
    label !== 'Calendly' &&
    label !== 'Google reviews' &&
    label !== 'Trip Advisor' &&
    label !== 'Music' &&
    label !== 'Video') ||
    type === 'social' ? (
    <div className="mt-2">
      <ProTextInput
        {...props}
        value={contentURL}
        onChange={setContentURL}
        label="URL (optional)"
        placeholder={'URL'}
      />
    </div>
  ) : null
}

export default InputUrl
