import useGetBaseUrl from './useGetBaseUrl'
import useGetWhitelabel from './useGetWhitelabel'

function useGetProfileUrl(slug) {
  const baseUrl = useGetBaseUrl()
  const { customDomain } = useGetWhitelabel()

  return customDomain ? `${baseUrl}/${slug}` : `${baseUrl}/u/${slug}`
}

export default useGetProfileUrl
