import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineClock = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 20})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M2.794 7.459A6.26 6.26 0 0 1 7.46 2.794a11.1 11.1 0 0 1 5.082 0 6.26 6.26 0 0 1 4.665 4.665 11.1 11.1 0 0 1 0 5.082 6.26 6.26 0 0 1-4.665 4.665 11.1 11.1 0 0 1-5.082 0 6.26 6.26 0 0 1-4.665-4.665 11.1 11.1 0 0 1 0-5.082Z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m12.083 12.083-2.365-1.505V7.5"
        />
      </g>
    </svg>
  )
}
export default SvgLineClock
