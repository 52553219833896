import React, { useEffect, useState } from 'react'
import Label from '../Controls/Label'
import TextArea from '../Controls/TextArea'
import { LockedFieldSwitch } from '../LockedFieldSwitch'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useLockedField } from '@/Hooks/useLockedField'

export const LabeledTextAreaLock = ({
  label,
  onChange,
  description,
  placeholder,
  disabled,
  maxLength,
  fieldName,
  type = 'user',
}) => {
  const { editCurrentUser } = useEditCurrentUser()
  const { onLocked } = useLockedField()
  const [isLocked, setIsLocked] = useState(
    editCurrentUser.lockedFields?.[type]?.includes(fieldName) || false,
  )
  useEffect(() => {
    setIsLocked(editCurrentUser.lockedFields?.[type]?.includes(fieldName))
  }, [editCurrentUser.lockedFields, type, fieldName])
  return (
    <>
      <Label regular name={label}>
        {label}
      </Label>
      <div className="relative mt-2">
        <TextArea
          className="mt-2"
          value={description || ''}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
          disabled={disabled}
        />
        <div className="absolute right-[3px] top-[3px] cursor-pointer">
          <LockedFieldSwitch
            variant="secondary"
            isLocked={isLocked}
            onChange={e =>
              onLocked({
                e: e,
                setIsLocked: setIsLocked,
                type: type,
                fieldName,
              })
            }
          />
        </div>
      </div>
    </>
  )
}
