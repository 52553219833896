import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    checked: false,
  },
  reducers: {
    updateCurrentUser(state, { payload }) {
      const currentUser = state.currentUser
      state.currentUser = { ...currentUser, ...payload }
    },
    setCurrentUser(state, { payload }) {
      state.currentUser = payload
    },
    setUserChecked(state) {
      state.checked = true
    },
  },
})

export const { updateCurrentUser, setCurrentUser, setUserChecked } =
  slice.actions

export default slice.reducer
