const publication_rules = {
  posting_rules: 'Reglas de publicación',
  subtitle: {
    main_text:
      'La publicación de una publicación genera un gasto de tus semillas. Aquí encontrarás el detalle de los gastos relacionados con la publicación.',
  },
  upload_a_publication: 'Subir una publicación',
  in_order_to_allow:
    'Para permitir que otros usuarios rieguen tu publicación, necesitarás 1 semilla para subirla.',
  cost: 'Coste: 1 semilla',
  write_a_description: 'Escribir una descripción',
  every_100_characters: 'Cada 100 caracteres, se te cobrará 1 semilla.',
  character_cost: 'Coste: 1 semilla / 100 caracteres',
  add_hashtags: 'Agregar hashtags',
  hashtags_allow:
    'Los hashtags te permiten destacar en palabras específicas, cada hashtag requerirá 1 semilla.',
  hashtag_cost: 'Coste: 1 semilla / hashtag',
  add_and_generate: 'Agregar y generar imágenes',
  adding_an_image:
    'Agregar una imagen te costará 1 semilla. Para generar una imagen con nuestra IA, necesitarás 1 semilla por imagen y monedas para la generación.',
  image_cost: 'Coste: 1 semilla / imagen',
}

export default publication_rules
