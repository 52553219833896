const timer = {
  days: 'Día{{plurial}}',
  hours: 'Hr{{plurial}}',
  minutes: 'Min{{plurial}}',
  seconds: 'Seg{{plurial}}',
  text: 'Lanzamiento del Mall en:',
}


export default timer
