import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineFilter = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M4.68 16 3 16.044M4.68 16a2.4 2.4 0 1 0 4.8 0 2.4 2.4 0 0 0-4.8 0Zm5.489.044H21m-8.199-8.493H3m18 0h-2.52m-.6-.151a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Z"
        />
      </g>
    </svg>
  )
}
export default SvgLineFilter
