import QrCode from '@/Components/QrCode'
import React from 'react'

const QrCodePreview = () => {
  return (
    <div className="w-full h-full flex flex-col p-10 justify-center items-center relative tablet:p-16">
      <QrCode />
    </div>
  )
}

export default QrCodePreview
