import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Panel } from './PanelWrapper/Panel'
import { useTranslation } from 'react-i18next'
import ChipTabs from '../Controls/ChipTabs'
import useGetTabs from '../../Containers/Followers/useGetTabs'
import { useSelector } from 'react-redux'
import { handleError } from '@/Utils/errorHelper'
import { useLazyGetFollowersListQuery } from '@/Services/modules/followers'
import { useLazyGetFollowingsListQuery } from '@/Services/modules/followings'
import FollowersListItem from '@/Containers/Followers/FollowersListItem'
import { throttle } from 'lodash'
import { Spinner } from '../Spinner'
import { css, styled } from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
  `}
`

const PanelFollowers = ({ children: button, ...props }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { tabs, selectedTab, setSelectedTab, followersCount, followingsCount } =
    useGetTabs(props)

  const { currentUser } = useSelector(state => state.user)
  const requesterUid = currentUser?.uid
  const [getMoreFollowers] = useLazyGetFollowersListQuery()
  const [getMoreFollowings] = useLazyGetFollowingsListQuery()
  const [followersListLastDate, setFollowersListLastDate] = useState()
  const [followingsListLastDate, setFollowingsListLastDate] = useState(
    Date.now(),
  )
  const [followers, setFollowers] = useState([])
  const [followings, setFollowings] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const uid = props.profileUID

  const list = useMemo(() => {
    if (selectedTab.name === 'followers') {
      return followers
    }
    return followings
  }, [followers, followings, selectedTab])

  const initializeFollowers = useCallback(async () => {
    try {
      const lastDate = Date.now()
      const { data, error } = await getMoreFollowers({
        uid,
        requesterUid,
        lastDate,
      })
      if (error) {
        throw error
      }
      setFollowersListLastDate(data[data.length - 1].date)
      setFollowers(data)
    } catch (error) {
      handleError(error)
    }
  }, [getMoreFollowers, uid, requesterUid])

  const initializeFollowings = useCallback(async () => {
    try {
      const lastDate = Date.now()
      const { data, error } = await getMoreFollowings({
        uid,
        requesterUid,
        lastDate,
      })
      if (error) {
        throw error
      }
      setFollowingsListLastDate(data[data.length - 1].date)
      setFollowings(data)
    } catch (error) {
      handleError(error)
    }
  }, [getMoreFollowings, uid, requesterUid])

  const initializeLists = useCallback(async () => {
    setIsLoading(true)
    await initializeFollowers()
    await initializeFollowings()
    setIsLoading(false)
  }, [initializeFollowers, initializeFollowings])

  const handleGetMoreFollowers = useCallback(async () => {
    try {
      setIsLoading(true)
      if (!followers.length) {
        return
      }

      const { data, error } = await getMoreFollowers({
        uid,
        requesterUid,
        lastDate: followersListLastDate,
      })
      if (error) {
        throw error
      }
      const filteredData = data.filter(
        item => !followers.find(follower => follower.uid === item.uid),
      )
      setFollowers(prevState => [...prevState, ...filteredData])
      setFollowersListLastDate(data[data.length - 1].date)
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }, [followers, getMoreFollowers, followersListLastDate, uid, requesterUid])

  const handleGetMoreFollowings = useCallback(async () => {
    try {
      setIsLoading(true)
      if (!followings.length) {
        return
      }

      const { data, error } = await getMoreFollowings({
        uid,
        requesterUid,
        lastDate: followingsListLastDate,
      })
      if (error) {
        throw error
      }
      const filteredData = data.filter(
        item => !followings.find(following => following.uid === item.uid),
      )
      setFollowings(prevState => [...prevState, ...filteredData])
      setFollowingsListLastDate(data[data.length - 1].date)
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }, [followings, getMoreFollowings, followingsListLastDate, uid, requesterUid])

  const handleEndReached = useCallback(() => {
    if (selectedTab.name === 'followers') {
      return handleGetMoreFollowers()
    }
    handleGetMoreFollowings()
  }, [handleGetMoreFollowers, handleGetMoreFollowings, selectedTab])

  const onOpenPanel = type => {
    setIsOpen(true)
    setSelectedTab(tabs.find(tab => tab.name === type))
  }

  useEffect(() => {
    initializeLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScroll = throttle(e => {
    if (
      isLoading ||
      followers.length === followersCount ||
      followings.length === followingsCount
    ) {
      return
    }

    const { scrollHeight, scrollTop, clientHeight } = e.target
    const offset = 200
    const topRange = scrollHeight - (scrollTop + offset)
    const bottomRange = scrollHeight - scrollTop
    const bottom = clientHeight >= topRange && clientHeight <= bottomRange
    if (!bottom) {
      return
    }
    handleEndReached()
  }, 200)

  return (
    <>
      {button(onOpenPanel)}
      <Panel
        isOpen={isOpen}
        title={t('previewpage.follow.title1_page')}
        onDismiss={() => setIsOpen(false)}
        onScroll={handleScroll}
      >
        <Wrapper className="absolute top-16 pt-4 right-0 left-14 z-10 flex-1 flex">
          <ChipTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Wrapper>
        <div className="pt-8">
          {list.map(item => (
            <FollowersListItem key={item.uid} item={item} />
          ))}
          {isLoading && <Spinner loading={isLoading} />}
        </div>
      </Panel>
    </>
  )
}

export default PanelFollowers
