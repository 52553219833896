import styled, { css } from 'styled-components'
import Text from './Texts/Text'
import { useTheme } from '@/Hooks'
import { LinePlus } from '@/Assets/icons/components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 72px;
    border-radius: ${theme.borderRadius.xl};
    justify-content: space-between;
    align-items: center;
  `}
`
const Icon = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.m};
    border-radius: ${theme.borderRadius.l};
    justify-content: center;
    align-items: center;
    background: ${theme.colors.grey1};
  `}
`

const Name = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.space.xs};
    font-size: ${theme.fontSize.xs};
    font-weight: 500;
    color: ${theme.colors.grey2};
  `}
`

const ThemeColorButton = props => {
  const { theme } = useTheme()
  return (
    <Wrapper>
      <Icon {...props}>
        <LinePlus color={theme.colors.black} />
      </Icon>
      <Name>Perso</Name>
    </Wrapper>
  )
}

export default ThemeColorButton
