import { useEffect, useRef, useState } from 'react'

export default function useDebouncedState(initialValue, delay = 500) {
  const [state, setState] = useState(initialValue)
  const ref = useRef()

  const setDebouncedState = value => {
    clearTimeout(ref.current)
    ref.current = setTimeout(() => setState(value), delay)
  }

  useEffect(() => {
    return () => {
      clearTimeout(ref.current)
    }
  }, [])

  return [state, setDebouncedState]
}
