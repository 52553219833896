const yield_explanation_page = {
  yield_explanation_companion: '¿Cómo funciona el compañero?',
  title: {
    main_text:
      'Gana <color>{{1}}</color> y <color>{{2}}</color> con tu compañero',
    1: 'recursos',
    2: 'boletos',
  },
  subtitle:
    '¡Aumenta el nivel de tu compañero para ganar más recursos y tickets!',
  explanations: {
    title_drop: 'Cuida a tu compañero',
    description_drop:
      'Cuando juegas a juegos, tu compañero usa energía. Puedes regarlo para que pueda seguir jugando.',
    title_gains: 'Tu compañero trabaja mientras estás ausente',
    description_gains:
      'Cada día, tu compañero trabaja para ti. Puedes cosechar las recompensas que ha ganado.',
    title_evolve: 'Haz evolucionar a tu compañero',
    description_evolve:
      'Al hacer evolucionar a tu compañero, aumentas sus recompensas. Puedes hacer evolucionar a tu compañero dándole monedas.',
  },
}


export default yield_explanation_page
