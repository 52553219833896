const activity_companion = {
  your_companion: 'Tu compañero',
  evolution: 'nivel {{evolution}}',
  vitality: '{{energyPercentage}}% vitalidad',
  inactiv: 'Inactivo',
  evolving: 'Actualmente evolucionand ...',
  empty: 'No se encontró compañero',
  description:
    'Aumenta el rendimiento de tu compañero mejorándolo continuamente.',
  upgrade: 'Mejorar',
  evolve: 'Evolucionar {{name}}',
  evolve_now: 'Evolucionar ahora',
  pending_evolve: 'Evolución en progreso',
  description_evolution:
    'Tu compañero está inactivo durante su evolución. Paga para saltarte el tiempo restante',
  remaining_time:
    'Tiempo restante antes de la evolución : {{time}} minuto{{plurial}}',
  details_button: 'Detalles de tu compañero',
  vitality_title: 'Energía',
  yield: 'Rendimiento',
  yield_description: 'El rendimiento de tu compañero es óptimo',
  yield_empty_description:
    'Su compañero ya no tiene energía. Ya no puede brindarle ningún beneficio',
  yield_description_75:
    'Recarga la energía de tu compañero regándolo para mantener su rendimiento óptimo',
  yield_description_50:
    'El rendimiento de tu compañero se divide por 2. Recarga su energía regándolo para mantener su rendimiento óptimo',
  yield_description_25:
    'La energía de tu compañero está casi agotada. Riega para seguir teniendo un rendimiento',
  yield_claim: 'Reclamar mis rendimientos',
  yield_claim_empty: 'No hay rendimiento pendiente',
  yield_history: 'Historial de rendimiento',
  learn_more: 'Más información',
  yield_received: 'Recibido el {{date}}',
  yield_select_resources: 'Seleccionar nuevos recursos',
  yield_select_resources_cost: 'Costo :',
  obtained_resources: '{{quantity}} {{resource}} obtenido',
  obtained_coins_title: 'Coins',
  obtained_exp_title: 'Exp',
  obtained_ticket_title: 'Ticket',
  obtained_resource_subtitle: 'obtenido',
  water_companion: 'Riega a tu compañero',
  level: {
    evolution: 'Costo de evolución : ',
    time_evolution: 'Tiempo de evolución : ',
    level2: 'Nivel ',
  },
  modal: {
    title: 'Detalles de los próximos rendimientos de tu compañero',
  },
  modal_activity: {
    owner: 'Propietario',
    close: 'Cerrar',
  },
  minute: 'minuto',
  inactiv_title: 'Compañero inactivo',
  inactiv_description:
    'Tu compañero está desactivado. Ya no puede traerte ningún beneficio.',
  inactiv_button: 'Reactivar a mi acompañante',
}

export default activity_companion
