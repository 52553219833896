import { animatedBackground } from './animatedBackground'
import { coverr } from './coverr'
import { gallery } from './gallery'
//import { generatedAI } from './generatedAI'
import { unsplash } from './unsplash'

const options = [
  gallery,
  //generatedAI,
  unsplash,
  coverr,
  animatedBackground,
]

export default options
