import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLineUnlock = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 25})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="m4.653 17.78-.694.284zm-.347-5.622.724.196zm15.388 0-.724.196zm-.347 5.623.694.283zm-5.31 3.811-.116-.741zm-4.073 0-.116.741zM8.656 8.367l-.15-.735zm6.688 0-.15.735zm-6.13 13.108.117-.741zm-4.468-3.466.695-.283zm10.04 3.466.115.741zm4.468-3.466-.695-.283zM15.62 8.423l.15-.735zm-7.24 0 .15.735zm-1.486-.228a.75.75 0 0 0 1.5 0zm.75-.236h-.75zm3.275-4.065-.182-.728zm.068-.017.182.728zm3.934 1.026-.52.54zm-.264.786a.75.75 0 1 0 1.038-1.082zm-1.642-1.821.175-.73zM8.53 9.158l.277-.056-.3-1.47-.275.056zm6.666-.056.276.056.298-1.47-.276-.056zm-.526 11.632-.748.117.231 1.482.749-.117zm-4.59.117-.748-.117-.232 1.482.749.117zm8.573-3.353-.093.228 1.39.566.092-.228zm-13.211.228-.093-.228-1.39.566.093.228zm-.093-.228a8.04 8.04 0 0 1-.318-5.144l-1.448-.392a9.54 9.54 0 0 0 .377 6.102zm13.622-5.144a8.04 8.04 0 0 1-.318 5.144l1.39.566a9.54 9.54 0 0 0 .376-6.102zm-5.05 8.497c-1.271.199-2.569.199-3.84 0l-.232 1.482a14 14 0 0 0 4.304 0zM8.807 9.1a16.1 16.1 0 0 1 6.389 0l.298-1.47a17.6 17.6 0 0 0-6.986 0zm.525 11.633c-1.775-.277-3.248-1.43-3.89-3.008l-1.39.566c.85 2.086 2.776 3.569 5.048 3.924zm5.57 1.482c2.272-.355 4.198-1.838 5.048-3.924l-1.39-.566c-.642 1.578-2.115 2.73-3.89 3.008zm.57-13.058c1.72.35 3.066 1.597 3.499 3.196l1.448-.392c-.588-2.172-2.398-3.817-4.649-4.274zm-7.24-1.47c-2.251.457-4.06 2.102-4.649 4.274l1.448.392c.433-1.599 1.778-2.846 3.5-3.196zm.163.507V7.96h-1.5v.236zm2.707-3.573.068-.017-.364-1.456-.068.017zm3.3.822.256.245 1.038-1.082-.255-.246zm-1.56-.847a3.4 3.4 0 0 1 1.56.847L15.44 4.36a4.9 4.9 0 0 0-2.25-1.223zm-1.672.008a3.5 3.5 0 0 1 1.672-.008l.35-1.459a5 5 0 0 0-2.386.011zM8.394 7.959c0-1.549 1.093-2.934 2.707-3.337l-.364-1.456c-2.244.561-3.843 2.518-3.843 4.793z"
        />
        <path
          stroke={props.color || theme?.colors?.black || '#212121'}
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M12 14.25v2"
        />
      </g>
    </svg>
  )
}
export default SvgLineUnlock
