const games_page = {
  btn_play: 'Play',
  tab_details: 'Details',
  tab_ranking: 'Ranking',
  tab_Historical: 'Historical',
  cost_game_ticket: '1 ticket',
  cost_game_bolt: "25% of your companion's vitality",
  costs_per_game: 'Costs per game',
  winnings_per_game: 'Winnings per game',
  your_best_score: 'Your best Score',
  gain_x2: 'Gain x2',
}

export default games_page
