const businessCard = {
  title: 'Encuentre todas sus tarjetas de visita',
  no_business_card_selected: 'No hay tarjeta de visita seleccionada',
  subtitle1:
    'No tienes tarjetas de visita, puedes agregarlas y personalizarlas a tu imagen para compartirlas con tu red.',
  subtitle2:
    'Encuentra todas tus tarjetas de visita, personalízalas a tu imagen para compartirlas con tu red.',
  btn_add: 'Añadir un nuevo mapa',
  left_label: 'Añadir mi tarjeta',
  title_screen1: 'Añadir una tarjeta de visita',
  title_screen2: 'Editar mi tarjeta de visita',
  title_screen3: 'Plantilla',
  title_screen4: 'informaciones',
  title_screen5: 'Logo',
  title_screen6: 'Color',
  title_screen_preview: 'Tarjeta de presentación',
  block_all_fields: 'Bloquear todos los campos.',
  in_multiple_edit_mode:
    'En el modo de edición múltiple, no puede editar ni eliminar tarjetas de presentación',
  create_business_card: 'Crear mi tarjeta de visita',
}

export default businessCard
