import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgAppleStore = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 24})`}>
        <path
          fill={props.color || theme?.colors?.black || '#212121'}
          d="M17.331 20.738c-1.034 1.002-2.162.844-3.25.37-1.149-.486-2.204-.508-3.417 0-1.52.654-2.32.463-3.228-.37-5.148-5.31-4.389-13.394 1.456-13.69 1.424.075 2.415.782 3.249.845 1.245-.253 2.437-.981 3.766-.886 1.593.126 2.796.76 3.587 1.9-3.292 1.973-2.511 6.31.506 7.525-.601 1.583-1.382 3.155-2.68 4.316zM12.035 6.986c-.158-2.354 1.752-4.296 3.946-4.486.306 2.723-2.469 4.75-3.946 4.486"
        />
      </g>
    </svg>
  )
}
export default SvgAppleStore
