import * as React from 'react'
const SvgClaimLocked = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 40})`}>
      <ellipse cx={19.963} cy={17} fill="#BDBDBD" rx={19.088} ry={17} />
      <ellipse cx={19.963} cy={14.614} fill="#E0E0E0" rx={19.088} ry={14.614} />
      <path
        fill="#EEE"
        d="M18.412 4.543c0-.972-.79-1.768-1.757-1.681-1.95.175-3.842.64-5.571 1.372-2.246.952-4.143 2.326-5.516 3.994s-2.177 3.578-2.338 5.55a9.2 9.2 0 0 0 .632 4.158c.376.953 1.478 1.322 2.44.973 1.28-.465 1.773-2.003 1.55-3.345a6.4 6.4 0 0 1-.067-1.565c.112-1.38.675-2.717 1.636-3.885.962-1.168 2.29-2.13 3.861-2.796a12.9 12.9 0 0 1 3.375-.903c.964-.124 1.755-.9 1.755-1.872"
      />
      <path
        fill="#8E8E95"
        fillRule="evenodd"
        d="M23.095 12.315v.894c1.004.314 1.736 1.223 1.736 2.31v2.88c0 1.344-1.114 2.435-2.489 2.435h-4.936c-1.375 0-2.49-1.09-2.49-2.436v-2.88c0-1.086.733-1.995 1.737-2.309v-.894c.006-1.74 1.446-3.148 3.212-3.148 1.79 0 3.23 1.409 3.23 3.148m-3.218-2.134c1.203 0 2.18.957 2.18 2.134v.768H17.69v-.78c.006-1.17.984-2.122 2.187-2.122m.515 7.418c0 .284-.23.51-.521.51a.51.51 0 0 1-.516-.51v-1.287a.51.51 0 0 1 .516-.504c.29 0 .521.226.521.504z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgClaimLocked
