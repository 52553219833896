import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgLinePlane = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 29})`}>
        <g filter="url(#linePlane_svg__a)">
          <path
            fill={props.color || theme?.colors?.black || '#212121'}
            d="M22.29 3.586a2.2 2.2 0 0 0-.844.258l-3.54 1.875-4.781-1.173-.281-.07-.281.141-1.618.937-.96.563.89.68 2.04 1.547-2.555 1.36L7.617 8.46l-.328-.14-.328.164-1.313.702-.867.47.657.703 4.054 4.359.375.445.516-.281 3.867-2.062-.773 4.265-.258 1.36 1.289-.54 1.922-.82.28-.117.118-.282 2.789-6.773 3.938-2.11c1.084-.582 1.52-1.962.937-3.046a2.24 2.24 0 0 0-1.336-1.079 2.3 2.3 0 0 0-.867-.093m.14 1.5a.735.735 0 0 1 .422 1.383l-4.196 2.273-.234.117-.094.258-2.789 6.703-.328.14.797-4.429.281-1.523-1.36.727-4.734 2.554-2.976-3.234.117-.071 2.742 1.22.352.163.304-.187 11.415-6.024a.7.7 0 0 1 .28-.07m-9.399.984 2.883.703-1.547.797-1.687-1.289zM5.25 19.5V21h19.5v-1.5z"
          />
        </g>
        <defs>
          <filter
            id="linePlane_svg__a"
            width={32}
            height={32}
            x={-1}
            y={0}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2909_16125"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_2909_16125"
              result="shape"
            />
          </filter>
        </defs>
      </g>
    </svg>
  )
}
export default SvgLinePlane
