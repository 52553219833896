const image_style_page = {
  polaroid: 'Polaroid',
  steamPunk: 'SteamPunk',
  mythological: 'Mythologique',
  futurist: 'Futuriste',
  three_d_render: 'Rendu 3D',
  mystical: 'Mystique',
  origami: 'Origami',
  graffiti: 'Graffiti',
  cartoon: 'Dessin animé',
  comic_book: 'Bande dessinée',
  neo: 'Neo',
  epic: 'Épique',
  van_gogh: 'Van Gogh',
  hyper_realistic: 'Hyper réaliste',
  oil_painting: "Peinture à l'huile",
  manga_color: 'Manga coloré',
  search_style: 'Rechercher un style',
}

export default image_style_page
