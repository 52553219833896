import addDrop from './addDrop'
import createPost from './createPost'
import deletePostById from './deletePostById'
import getDiscoverPosts from './getDiscoverPosts'
import getFeedPosts from './getFeedPosts'
import getPostById from './getPostById'
import getPostDetailsById from './getPostDetailsById'
import getPosts from './getPosts'
import getPostsByHashtag from './getPostsByHashtag'
import getPostsByUserId from './getPostsByUserId'
import updatePostById from './updatePostById'
import { api } from '@/Services/api'

export const postsApi = api.injectEndpoints({
  endpoints: build => ({
    createPost: createPost(build),
    deletePostById: deletePostById(build),
    updatePostById: updatePostById(build),
    getPosts: getPosts(build),
    getDiscoverPosts: getDiscoverPosts(build),
    getPostById: getPostById(build),
    getPostsByUserId: getPostsByUserId(build),
    getPostsByHashtag: getPostsByHashtag(build),
    addDrop: addDrop(build),
    getPostDetailsById: getPostDetailsById(build),
    getFeedPosts: getFeedPosts(build),
  }),
  overrideExisting: true,
})

export const {
  useGetPostsQuery,
  useGetDiscoverPostsQuery,
  useGetPostsByUserIdQuery,
  useGetPostByIdQuery,
  useCreatePostMutation,
  useGetPostsByHashtagQuery,
  useDeletePostByIdMutation,
  useUpdatePostByIdMutation,
  useLazyGetPostsQuery,
  useAddDropMutation,
  useGetPostDetailsByIdQuery,
  useGetFeedPostsMutation,
} = postsApi
