const publication_rules = {
  posting_rules: 'Règles de publication',
  subtitle: {
    main_text:
      'La publication de post engendre une dépense de vos graines. Vous retrouverez ici le détail des dépenses liés à la publication.',
  },
  upload_a_publication: 'Mettre en ligne une publication',
  in_order_to_allow:
    'Afin de permettre aux autres utilisateurs d’arroser votre publication, il faudra 1 graine pour la mettre en ligne.',
  cost: 'Coût: 1 graine',
  write_a_description: 'Écrire une description',
  every_100_characters: 'Tous les 100 caractères, 1 graine vous sera demander.',
  character_cost: 'Coût: 1 graine / 100 caractères',
  add_hashtags: 'Ajouter des hashtags',
  hashtags_allow:
    'Les hashtags vous permettent de vous mettre en avant sur des mots précis, chaque hashtag demandera 1 graine.',
  hashtag_cost: 'Coût: 1 graine / hashtag',
  add_and_generate: 'Ajouter & Générer des images',
  adding_an_image:
    'L’ajout d’une image vous demandera 1 graine. Pour générer une image avec notre IA, vous aurez besoin d’1 graine par image et des pièces pour la génération.',
  image_cost: 'Coût: 1 graine / image',
}

export default publication_rules
