import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Text from '../Texts/Text'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { TouchableOpacity } from '../Buttons/TouchableOpacity'

const TabButton = styled(TouchableOpacity)`
  ${({ theme, last }) => css`
    padding: ${theme.space.xs} ${theme.space.std};
    border-radius: ${theme.borderRadius.m};
    background-color: ${props =>
      props.selected ? theme.colors.primary : theme.colors.grey1};
    margin-right: ${last ? theme.space.m : theme.space.xs};
    min-width: 96px;
    justify-content: center;
    align-items: center;
    opacity: ${props => (props.disabled ? 0.6 : 1)};
  `}
`

const TabText = styled(Text)`
  ${({ theme }) => css`
    color: ${props =>
      props.selected ? theme.colors.white : theme.colors.black};
    font-size: 14px;
  `}
`

const TabWithIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${props =>
      props.selected ? theme.colors.white : theme.colors.black};
  `}
`

const Wrapper = styled.div`
  flex-shrink: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 24px;

  @media screen and (max-height: 732px) {
    padding-bottom: 12px;
  }
`

const ChipTabs = ({
  tabs,
  selectedTab,
  setSelectedTab = () => null,
  renderCustomTab = null,
}) => {
  const navigate = useNavigate()
  const { search } = useLocation()

  const handleNavigate = useCallback(
    tab => {
      if (!tab.navigation) {
        setSelectedTab(tab)
        return
      }

      navigate({
        search,
        pathname: tab.navigation,
      })
    },
    [search, navigate, setSelectedTab],
  )

  if (!tabs) {
    return null
  }

  return (
    <Wrapper>
      {tabs.map((tab, index) => {
        const tabProps = {
          key: index,
          tab: tab,
          index: index,
          last: index === tabs.length - 1,
          className: 'shrink-0',
          selectedTab: selectedTab,
          onClick: () => handleNavigate(tab),
        }
        if (renderCustomTab) {
          return renderCustomTab({ tabProps, tabComponent: TextOrIconTab })
        }
        return <TextOrIconTab {...tabProps} />
      })}
    </Wrapper>
  )
}

const TextOrIconTab = ({ tab, index, selectedTab, onClick, ...props }) => {
  const { t } = useTranslation()
  const TabContent = tab.icon
  const isSelected = useMemo(() => {
    return selectedTab.navigation
      ? selectedTab.navigation === tab.navigation
      : tab.name === selectedTab.name
  }, [selectedTab.navigation, tab.navigation, tab.name, selectedTab.name])

  return (
    <TabButton
      key={index}
      selected={isSelected}
      onClick={onClick}
      disabled={tab.disabled}
      {...props}
    >
      {TabContent ? (
        <TabWithIcon selected={isSelected}>
          <Text className="mr-1">{tab.icon}</Text>
          <TabText selected={isSelected}>{tab.count}</TabText>
        </TabWithIcon>
      ) : (
        <TabText selected={isSelected}>
          {t(tab.label)}
          {tab.count && ` ${tab.count}`}
        </TabText>
      )}
    </TabButton>
  )
}

// export const FilteredChipTabs = ({ title, options, ...props }) => {
//   return (
//     <ChipTabs {...props}>
//       <div className="mr-4">
//         <ModalFilter
//           title={title}
//           options={options}
//           initialValue={props.selectedTab.name}
//           onSubmit={name =>
//             props.setSelectedTab(options.find(option => option.name === name))
//           }
//         >
//           {ref => (
//             <TabButton onClick={() => ref.current.present()}>
//               <LineFilter />
//             </TabButton>
//           )}
//         </ModalFilter>
//       </div>
//     </ChipTabs>
//   )
// }

export default ChipTabs
