const security = {
  skip: 'Passer',
  title: 'Sécurité',
  pin_code: 'code PIN',
  use: 'Utilisez',
  or: 'ou',
  to_disable: 'pour désactiver',
  to_unlock: 'pour déverrouiller',
  wrong_code: 'Code invalide',
  set_pin_code: 'Définissez votre code PIN',
  pin_code_not_match: 'Les codes PIN ne correspondent pas',
  confirm_pin_code: 'Confirmez le code PIN',
  sentence: 'Heureux de vous revoir',
  try_later: 'Trop de tentatives. Veuillez réessayer plus tard.',
  codepin_modal: {
    forgot: 'Oublié',
    delete: 'Effacer',
    reset: {
      title: 'Réinitialiser votre code PIN',
      subtitle:
        'En confirmant, vous allez recevoir un email qui vous permettra de réinitialiser votre code PIN.',
      success_message: 'Un email vous a été envoyé',
    },
  },
  touchIDModal: {
    title: "S'identifier",
    title2: "Activer l'identification par empreinte digitale",
  },
  faceIDModal: {
    title: "S'identifier",
    title2: "Activez l'identification par reconnaissance faciale",
  },
}


export default security
