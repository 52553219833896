import * as React from 'react'
const SvgSocialMessages = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 33})`}>
      <path
        fill="url(#socialMessages_svg__a)"
        d="M2 12.35c0-3.36 0-5.04.654-6.324a6 6 0 0 1 2.622-2.622C6.56 2.75 8.24 2.75 11.6 2.75h8.8c3.36 0 5.04 0 6.324.654a6 6 0 0 1 2.622 2.622C30 7.31 30 8.99 30 12.35v8.8c0 3.36 0 5.04-.654 6.324a6 6 0 0 1-2.622 2.622c-1.283.654-2.964.654-6.324.654h-8.8c-3.36 0-5.04 0-6.324-.654a6 6 0 0 1-2.622-2.622C2 26.19 2 24.51 2 21.15z"
      />
      <path
        fill="#fff"
        d="M16 23.75c4.97 0 9-3.358 9-7.5s-4.03-7.5-9-7.5-9 3.358-9 7.5c0 2.625 1.619 4.936 4.07 6.276-.082.667-.328 1.557-1.07 2.224 1.402-.252 2.521-.76 3.357-1.329.836.214 1.723.329 2.643.329"
      />
      <defs>
        <linearGradient
          id="socialMessages_svg__a"
          x1={16}
          x2={16}
          y1={2.75}
          y2={30.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5AF575" />
          <stop offset={1} stopColor="#13BD2C" />
        </linearGradient>
      </defs>
    </g>
  </svg>
)
export default SvgSocialMessages
