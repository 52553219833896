const getAllMembers = build =>
  build.query({
    query: uid => {
      return {
        url: `members/${uid}?allProfiles=true`,
        method: 'GET',
      }
    },
    providesTags: ['TeamMembers'],
  })

export default getAllMembers
